<template>
  <div class="login-auth">
    <b-row class="mx-auto">
      <b-col cols="12" sm="auto" class="login-auth__picture" />
      <b-col cols="12" sm="auto" class="login-auth__part-right m-auto">
        <HeaderAuth />
        <form class="login-auth-form d-flex" @submit="login">
          <CCCard padding="card24">
            <!-- title form -->
            <h2 class="login-auth__title m-0">{{ $t("login_page.title") }}</h2>
            <div class="input-block">
              <div class="label">
                <h6>{{ $t("login_page.email_label") }}</h6>
              </div>
              <CCInput placeholder="E-mail" v-model="email" />
            </div>
            <div class="input-block">
              <div class="label">
                <h6>{{ $t("login_page.password_label") }}</h6>
              </div>
              <div class="position-relative">
                <CCInput
                  v-model="newPassword"
                  @keyup="checkPassword"
                  :placeholder="$t('login_page.password_placeholder')"
                  :type="passwordInputs[0].type"
                  class="input-with-icon"
                />
                <div @click="changeTypeInput(0)" class="button-eye-password">
                  <img :src="passwordInputs[0].image" alt="eye-icon" />
                </div>
              </div>
              <!-- button -->
              <CCButton
                class="login-auth__submit"
                fontWeight="bolder"
                :disabled="isLoadingLogin"
                size="lg"
                @click.prevent="login"
              >
                {{ $t("login_page.btn_login") }}
              </CCButton>
              <div class="login-auth__enter mt-4 d-flex justify-content-center">
                <span>{{ $t("login_page.forget_pass") }}</span>
                <router-link
                  to="/reset-password"
                  class="ms-2 link d-flex align-items-center"
                >
                  <img src="@/assets/images/login/key-icon.svg" alt="" />
                  <span class="ms-2">{{ $t("login_page.restore_pass") }}</span>
                </router-link>
              </div>
              <div class="login-auth__enter mt-2 d-flex justify-content-center">
                <span>{{ $t("login_page.no_account") }}</span>
                <router-link
                  to="/registration"
                  class="ms-2 link d-flex align-items-center"
                >
                  <img src="@/assets/images/login/add-user-icon.svg" alt="" />
                  <span class="ms-2">{{ $t("login_page.btn_reg") }}</span>
                </router-link>
              </div>
            </div>
          </CCCard>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";
import ValidationPassword from "@/components/ValidationPassword.vue";
import useValidationPassword from "@/mixins/validationPassword";
import { UserController } from "@/core/system/controllers";
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { useAuthStore } from "@/stores/authStore";

export default {
  name: "login-auth",
  components: { HeaderAuth, ValidationPassword },
  mixins: [useValidationPassword(useAuthStore)],
  data() {
    return {
      email: "",
      newPassword: "",
      isLoadingLogin: false,
    };
  },
  methods: {
    ...mapActions(() => useUserStore(), ["setIsAuth"]),
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    login() {
      const payload = {
        email: this.email.trimStart(),
        password: this.newPassword,
      };
      this.isLoadingLogin = true;
      UserController.login(payload)
        .then((response) => {
          this.setIsAuth(true);
          this.$router.push("/");
        })
        .catch((_error) => {
          this.showMessage("danger", this.$t("messages.error_auth"));
        })
        .finally(() => {
          this.isLoadingLogin = false;
        });
    },
  },
};
</script>
