<template>
  <div
    class="custom-select-crypto"
    :class="{
      pointer: pointer,
      disabled: !isValidMin,
    }"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div class="selected cursor-auto">
      <b-row class="justify-content-between align-items-center">
        <b-col cols="auto">
          <b-row align-v="center">
            <b-col cols="auto" class="pe-2">
              <div class="icon-block">
                <img
                  v-if="variant === 'exchange'"
                  :src="`https://api-exchange.cryptocloud.plus/${selected.icon}`"
                  width="20px"
                  alt=""
                  class="icon-currancy-select"
                />
                <img
                  v-if="variant === 'buy'"
                  :src="selected.icon"
                  width="20px"
                  alt=""
                  class="icon-currancy-select"
                />
              </div>
            </b-col>

            <b-col cols="auto" class="px-0 px-sm-1">
              <h3 class="operator-title mb-0">
                {{ selected.name }}
              </h3>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" class="">
          <b-row align-h="end" align-v="center">
            <b-col cols="auto">
              <h5 class="amount-currency mb-0" v-if="isValidMin">
                {{ showAmount(selected) }}
              </h5>
              <h5 class="amount-currency mb-0" v-else>
                MIN = {{ minAmount }} {{ currencyFrom }}
              </h5>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    default: {
      type: Object,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      default: "exchange",
    },
    type: String,
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    isValidMin: {
      type: Boolean,
      required: false,
      default: true,
    },
    minAmount: Number,
    currencyFrom: String,
    pointer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // selected: this.default
      //   ? this.default
      //   : this.options.length > 0
      //     ? this.options[0]
      //     : null,
      open: false,
    };
  },
  methods: {
    currencySplit(currency) {
      if (
        currency.includes("USDT") ||
        currency.includes("USDC") ||
        currency.includes("TUSD")
      ) {
        return currency.split("-")[0];
      }
      return currency;
    },
    showAmount(operator) {
      if (this.type === "float") {
        return `${operator.amount_from} ${this.currencySplit(
          operator.currency_from
        )} = ${operator.amount_to} ${this.currencySplit(operator.currency_to)}`;
      } else {
        return `${operator.amount_to} ${this.currencySplit(
          operator.currency_to
        )} = ${operator.amount_from} ${this.currencySplit(
          operator.currency_from
        )}`;
      }
    },
  },
  computed: {
    selected: {
      get() {
        return this.default;
      },
      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style lang="scss" scoped>
.custom-select-crypto {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 18px;
  z-index: 9;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  @media (max-width: 576px) {
    margin-bottom: 12px;
  }
  &.disabled {
    opacity: 0.5;
  }
}

.custom-select-crypto.pointer {
  cursor: pointer;
}

.custom-select-crypto.border-accent {
  border: 1px solid #4a40c6;
}

.icon-block {
  width: 32px;
  height: 32px;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.operator-title {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3c3c3c;
}

.amount-currency {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #5f5f5f;
}

@media (max-width: 576px) {
  .operator-title {
    font-size: 16px;
  }
  .amount-currency {
    font-size: 10px;
  }
}

.custom-select-crypto .selected {
  background-color: #fff;
  color: #353945;
  padding: 16px;
  /* padding-left: 1em; */

  border-radius: 8px;
  /* cursor: pointer; */
  user-select: none;
  @media (max-width: 576px) {
    padding: 8px 12px 8px 8px;
  }
}
.custom-select-crypto .basic-text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  /* or 140% */

  text-align: right;
  letter-spacing: 0.01em;
}

.custom-select-crypto .selected.open {
  border: 1px solid #f0f0f0;
}

.custom-select-crypto .selected.open .icon-arrow {
  /* background: url("../../assets/img/arrow_up.svg"); */
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
}

.custom-select-crypto .selected .icon-arrow {
  /* background: url("../../assets/img/arrow_down.svg"); */
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
}

/* .custom-select-crypto .selected:after {
        position: absolute;
        content: "";
        top: 30px;
        right: 18px;
        width: 10px;
        height: 6px;
      } */

.custom-select-crypto .items {
  color: #353945;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 60px;
  z-index: -1;
  padding-top: 10px;
  padding-bottom: 5px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.5s;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
}

.custom-select-crypto .items .wrapper-options {
  color: #353945;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  position: static;
}

.custom-select-crypto .items .wrapper-options:empty {
  display: none;
}

.custom-select-crypto .items > div {
  border: 1px solid transparent;
  /* box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05); */
  border-radius: 8px;
}

.custom-select-crypto .items > div:hover {
  border: 1px solid #bfbcdd;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  border-radius: 8px;
}

.selectHide {
  display: none;
  transition: 0.8s;
}
</style>
