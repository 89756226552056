<template>
  <div class="activation-email">
    <b-row class="mx-auto">
      <b-col cols="auto" class="activation-email__picture" />
      <b-col cols="5" class="activation-email__part-right">
        <HeaderAuth />
        <div class="activation-email-form" action="#">
          <CCCard padding="card24">
            <!-- title form -->
            <div class="d-flex align-items-center">
              <h2 class="activation-email__title m-0">
                {{ $t("activation_email_page.title") }}
              </h2>
              <img
                class="ms-4"
                src="@/assets/images/dag-icon.svg"
                alt="dag-icon"
              />
            </div>
            <p class="p-0 mb-0 activation-email__text">
              {{ $t("activation_email_page.text") }}
            </p>
            <!-- button -->
            <CCButton
              class="activation-email__btn-enter-account"
              fontWeight="bolder"
              size="lg"
            >
              {{ $t("activation_email_page.btn_login") }}
            </CCButton>
          </CCCard>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";
import { UserController } from "@/core/system/controllers";

export default {
  name: "WaitEmail",
  components: { HeaderAuth },
  methods: {
    activate() {
      UserController.confirmEmail(this.$route.params.key)
        .then((reponse) => {
          this.$router.push("/create-wallet");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.activate();
  },
};
</script>
