<template>
  <div class="transaction-currency">
    <div class="transaction-currency__header">
      <b-row align-v="center">
        <b-col cols class="d-flex align-items-center">
          <img
            class="transaction-currency__type-icon"
            src="@/assets/images/incoming-icon.svg"
            alt=""
          />
          <img
            class="ms-2 transaction-currency__currency-icon"
            src="@/assets/images/usdt-icon.svg"
            alt=""
          />
          <h4 class="my-0 ms-2 transaction-currency__name">INV-DS1247S</h4>
        </b-col>
        <b-col cols="auto" class="ms-auto">
          <LinkBackPage />
        </b-col>
      </b-row>
    </div>
    <div class="transaction-currency__info mt-4">
      <b-row class="gx-0">
        <b-col cols="12" md="auto" class="transaction-currency-check">
          <span class="transaction-currency-check__date"
            >15.02.2023 10:24
          </span>
          <h3 class="my-0 mt-2 transaction-currency-check__balance">
            $ 1 257,89
          </h3>
          <span class="mt-2 transaction-currency-check__balance-crypto">
            0,00020027 BTC
          </span>
          <div class="transaction-currency-check__success">
            <img src="@/assets/images/success-check.png" alt="" />
            <span>успешно</span>
          </div>
        </b-col>
        <b-col cols class="mt-4 mt-md-0 ms-md-4 transaction-currency-info">
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Статус</div>
            <div class="transaction-currency-info__param-value">Успешно</div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Дата</div>
            <div class="transaction-currency-info__param-value">
              15.02.2023 10:24 (GMT+5)
            </div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Тип</div>
            <div class="transaction-currency-info__param-value">
              Поступление
            </div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Валюта</div>
            <div class="transaction-currency-info__param-value">Bitcoin</div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Сеть</div>
            <div class="transaction-currency-info__param-value">BTC</div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Сумма</div>
            <div class="transaction-currency-info__param-value">
              0,0020027 BTC
            </div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Сбор</div>
            <div class="transaction-currency-info__param-value">
              0,0020027 BTC
            </div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">Адрес</div>
            <div
              class="transaction-currency-info__param-value d-flex align-items-center"
            >
              <img src="@/assets/images/copy-icon.svg" alt="copy-icon" />
              <a class="ms-2 link" href="#"> 3Gbaro...cpvGFH </a>
            </div>
          </div>
          <div class="transaction-currency-info__card">
            <div class="transaction-currency-info__param-name">TxHash</div>
            <div
              class="transaction-currency-info__param-value d-flex align-items-center"
            >
              <img src="@/assets/images/link-icon.png" alt="link-icon" />
              <a class="ms-2 link" href="#"> 3Gbaro...cpvGFH </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LinkBackPage from "@/components/UI/LinkBackPage.vue";

export default {
  name: "TransactionCurrency",
  components: { LinkBackPage },
};
</script>
