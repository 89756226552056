<template>
  <div class="currency-card-mobile" v-if="!isLoading">
    <div class="opacity-wrapper" v-if="!isVisible"></div>
    <div @click="click" class="card-top d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="icon-block">
          <img class="icon" :src="icon" alt="" />
        </div>
        <div class="content-block">
          <p class="title mb-0">{{ currencyName }}</p>
        </div>
        <div class="bage-network">{{ getNetworkCurrency(badgeName) }}</div>
      </div>
      <img
        @click="get"
        class="accept-btn"
        width="20px"
        height="20px"
        src="@/assets/images/forward-icon.svg"
        alt=""
      />
    </div>
    <div class="card-bottom mt-3">
      <h3 class="balance mb-0">
        {{ convertBalanceCrypto(balanceCurrency) }}
        {{ sliceNetworkCodeCurrency(badgeName) }}
      </h3>
      <div
        class="copy-block d-flex justify-content-between align-items-center"
        @click.prevent
        v-clipboard:copy="address"
        v-clipboard:success="copyData"
      >
        <p class="address mb-0">{{ getShortWalletAddress(address) }}</p>
        <Clipboard
          @onCopySuccess="copyData"
          :isActiveCopy="isCopyData"
          :copyText="address"
          size="18"
        />
      </div>
    </div>
  </div>
  <div class="currency-card-mobile" v-else>
    <div class="card-top d-flex">
      <div class="icon-block">
        <b-skeleton type="avatar" width="29px" height="29px"></b-skeleton>
      </div>
      <div class="content-block">
        <h5 class="wallet-name mb-0">
          <b-skeleton class="sm" width="109px" height="20px"></b-skeleton>
        </h5>
        <div class="d-flex align-items-center">
          <b-skeleton class="sm mt-1" width="80px" height="18px"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="card-bottom mt-3">
      <b-skeleton class="md" width="50%" height="36px"></b-skeleton>
      <b-skeleton class="sm mt-1" width="80%" height="18px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import {
  convertBalanceUsd,
  sliceNetworkCodeCurrency,
  convertBalanceCrypto,
  getNetworkCurrency,
  getShortWalletAddress,
} from "@/utils";
import Clipboard from "../UI/Clipboard.vue";

export default {
  components: { Clipboard },
  data: () => ({
    isCopyData: false,
  }),
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currencyName: String,
    currencyList: {
      type: Array,
      default: () => [],
    },
    badgeName: String,
    balanceCurrency: Number || String,
    icon: String,
    address: String,
    nickname: String,
  },
  methods: {
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
    convertBalanceUsd,
    convertBalanceCrypto,
    sliceNetworkCodeCurrency,
    getNetworkCurrency,
    getShortWalletAddress,
    get() {
      this.$emit("get");
    },
    click() {
      if (this.isVisible) {
        this.$emit("click");
      }
    },
    showWallet() {
      this.$emit("showWallet");
    },
    hideWallet() {
      this.$emit("hideWallet");
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-btn,
.show-btn {
  position: relative;
  z-index: 11;
}
.currency-card-mobile {
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  .opacity-wrapper {
    cursor: auto;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    z-index: 10;
    opacity: 0.5;
  }
  .card-top {
    .icon-block {
      width: 44px;
      height: 44px;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        width: 29px;
        height: 29px;
      }
    }
    .bage-network {
      margin-left: 6px;
      padding: 0 6px 0 6px;
      font-size: 6px;
      font-weight: 500;
      line-height: 12px;
      color: #4a40c6;
      border-radius: 32px;
      border: 1px solid #f0f0f0;
      background: #fbfbfb;
    }
    .content-block {
      margin-left: 12px;
      .wallet-name {
        color: #3c3c3c;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .title {
        color: #5f5f5f;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
      .networks-list {
        margin-left: 4px;
        .network-icon {
          width: 15px;
          height: 15px;
          margin-left: 8px;
        }
      }
    }
  }
  .accept-btn {
    cursor: pointer;
  }
  .card-bottom {
    .balance {
      color: #3c3c3c;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
    }
    .copy-block {
      margin-top: 8px;
      .address {
        color: #4a40c6;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
</style>
