<template>
  <div class="last-transactions">
    <div
      class="transaction-item"
      v-for="transaction in historyList"
      :key="transaction.id"
      @click="selectAddress(transaction.address)"
    >
      <div class="d-flex align-items-center">
        <img
          class="transaction-item__icon"
          :src="transaction.network_object.icon"
          alt=""
        />
        <p class="transaction-item__address mb-0">
          {{ showAddressInterface(transaction.address) }}
        </p>
      </div>
      <p class="transaction-item__date mb-0">
        {{ formatDateTime(transaction.create_at).date }}
        {{ formatDateTime(transaction.create_at).time }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import { getShortWalletAddress } from "@/utils";

export default {
  props: {
    historyList: {
      type: Array,
      default: false,
    },
    network: String,
  },
  methods: {
    showAddressInterface(address) {
      return this.network === "Bitcoin"
        ? getShortWalletAddress(address)
        : address;
    },
    formatDateTime,
    selectAddress(address) {
      this.$emit("select", address);
    },
  },
};
</script>

<style lang="scss" scoped>
.last-transactions {
  width: 100%;
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 30;
  padding: 12px;
  border-radius: 6px;
  background: #f9f9f9;
  @media (max-width: 576px) {
    top: 55px;
    padding: 8px;
  }
  .transaction-item {
    margin-top: 12px;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    &__icon {
      width: 11px;
      height: 11px;
      @media (max-width: 576px) {
        width: 8px;
        height: 8px;
      }
    }
    &__address {
      color: #5f5f5f;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-left: 6px;
      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 12px;
        margin-left: 4px;
      }
    }
    &__date {
      color: #9f9f9f;
      font-size: 12px;
      font-weight: 300;
      line-height: 10px;
      margin-top: 6px;
      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 12px;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
