<template>
  <div class="history-list">
    <div class="history-list__header d-none d-lg-block">
      <b-row>
        <b-col cols="2">
          <p class="column-name">Валюта</p>
        </b-col>
        <b-col cols="2">
          <p class="column-name">количество</p>
        </b-col>
        <b-col cols="2">
          <p class="column-name">Общая Сумма</p>
        </b-col>
        <b-col cols="4">
          <p class="column-name">Кошелек</p>
        </b-col>
        <b-col cols="2">
          <p class="column-name">ДАТА</p>
        </b-col>
      </b-row>
    </div>
    <div class="list-wrapper" v-if="!isLoading && historyList.length">
      <div v-for="(item, index) in historyList" :key="item.id">
        <div class="d-none d-lg-block">
          <a
            :href="
              getBlockchainLink(
                item.currency_object.network_object.full_name,
                item.tx_id
              )
            "
            target="_blank"
          >
            <HistoryItem
              :isEven="(index + 1) % 2 != 0"
              :amountCrypto="convertBalanceCrypto(item.amount)"
              :amountUsd="convertBalanceUsd(item.amount_usd)"
              :type="item.type"
              :address="item.address_from"
              :dateTime="item.created_at"
              :icon="item.currency_object.icon_base"
              :currencyName="item.currency_object.code"
              :networkName="item.currency_object.network_object.full_name"
              :txId="item.tx_id"
            />
          </a>
        </div>
        <div class="d-block d-lg-none">
          <a
            :href="
              getBlockchainLink(
                item.currency_object.network_object.full_name,
                item.tx_id
              )
            "
            target="_blank"
          >
            <HistoryItemMobile
              :amountCrypto="convertBalanceCrypto(item.amount)"
              :amountUsd="convertBalanceUsd(item.amount_usd)"
              :type="item.type"
              :address="item.address_from"
              :dateTime="item.created_at"
              :icon="item.currency_object.icon_base"
              :currencyName="item.currency_object.code"
              :networkName="item.currency_object.network_object.full_name"
              :txId="item.tx_id"
            />
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <div v-for="index in 7" :key="index">
        <div class="d-none d-lg-block">
          <HistoryItem :isEven="(index + 1) % 2 != 0" isLoading />
        </div>
        <div class="d-block d-lg-none">
          <HistoryItemMobile isLoading />
        </div>
      </div>
    </div>
    <div
      class="list-wrapper w-100 d-flex justify-content-center align-items-center"
      v-else-if="!isLoading && !historyList.length"
    >
      Транзакции криптовалюты отсутствуют
    </div>

    <div class="pagination-block" v-if="!isLoading">
      <slot name="pagination"></slot>
    </div>
    <div class="d-flex justify-content-end mt-4 pb-4" v-else>
      <b-skeleton class="sm" width="150px" height="24px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import { convertBalanceCrypto, convertBalanceUsd } from "@/utils";
import HistoryItem from "./HistoryItem.vue";
import HistoryItemMobile from "./HistoryItemMobile.vue";
export default {
  components: { HistoryItem, HistoryItemMobile },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    historyList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    convertBalanceCrypto,
    convertBalanceUsd,
    getBlockchainLink(network, txId) {
      let link;
      switch (network) {
        case "TRON":
          link = `https://tronscan.org/#/transaction/${txId}`;
          break;
        case "Ethereum":
          link = `https://etherscan.io/tx/${txId}`;
          break;
        case "Bitcoin":
          link = `https://mempool.space/tx/${txId}`;
          break;
        case "Litecoin":
          link = `https://live.blockcypher.com/ltc/tx/${txId}`;
          break;

        default:
          break;
      }
      return link;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-list {
  @media (max-width: 992px) {
    padding: 24px 16px 16px 16px;
  }
  .list-wrapper {
    text-align: center;
    min-height: 280px;
    color: #5f5f5f;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  &__header {
    padding: 16px 24px 8px 24px;
    .column-name {
      margin-bottom: 0;
      color: #3c3c3c;
      font-size: 10px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}
.pagination-block {
  padding: 24px;
  display: flex;
  justify-content: end;
}
</style>
