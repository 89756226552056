<template>
  <div class="blockchain-field">
    <p class="blockchain-field__name mb-0">{{ name }}</p>
    <p class="blockchain-field__value mb-0">{{ getRoundedAmountCrypto(value) }}</p>
  </div>
</template>

<script>
import { getRoundedAmountCrypto } from '@/utils';

export default {
  props: {
    name: {
      type: String,
      default: "name",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getRoundedAmountCrypto
  },
};
</script>

<style lang="scss" scoped>
.blockchain-field {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  padding: 8px 12px;
  &__name {
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  &__value {
    margin-top: 4px;
    color: #5f5f5f;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.18px;
  }
}
</style>
