<template>
  <div class="row-item" :class="{ invalid: !isValid }">
    <p class="value mb-0 d-none d-lg-block">{{ address }}</p>
    <p class="value mb-0 d-block d-lg-none">
      {{ getShortWalletAddress(address) }}
    </p>
    <div class="amount d-flex align-items-center">
      <p class="value mb-0">{{ getRoundedAmountCrypto(amount) }}</p>
      <img
        v-if="isValid"
        @click="removeItem"
        class="remove-btn ms-2"
        src="@/assets/images/remove-icon-gray.svg"
        alt=""
      />
      <img
        v-else
        @click="removeItem"
        class="remove-btn ms-2"
        src="@/assets/images/remove-icon.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getShortWalletAddress, getRoundedAmountCrypto } from "@/utils";
export default {
  props: {
    isValid: {
      type: Boolean,
      default: true,
    },
    address: String | Number,
    amount: String | Number,
  },
  methods: {
    removeItem() {
      this.$emit("remove");
    },
    getShortWalletAddress,
    getRoundedAmountCrypto
  },
};
</script>

<style lang="scss" scoped>
.row-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .value {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #3c3c3c;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .remove-btn {
    cursor: pointer;
  }
  &.invalid {
    .value {
      color: #fa6666;
    }
  }
}
</style>
