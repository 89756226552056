<template>
  <div class="loading-block">
    <div class="loader-wrapper">
      <span class="loader-circle"></span>
    </div>
    <p class="loading-text">{{ loadingText }}</p>
    <div class="mt-4">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    percent: 0,
    interval: null,
  }),
  props: {
    timeSecond: {
      type: Number,
      default: 90,
    },
    isLoadingPercent: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Ожидаем оплату...",
    },
  },
  computed: {
    different() {
      return 100 / this.timeSecond;
    },
  },
  methods: {
    calcPercent() {
      if (this.percent >= 100) {
        this.percent = 100;
        clearInterval(this.interval);
        return;
      } else {
        const roundPercent = Math.round(this.percent + this.different);
        this.percent = roundPercent;
      }
    },
  },
  mounted() {
    if (this.isLoadingPercent) {
      this.interval = setInterval(() => {
        this.calcPercent();
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.loading-block {
  position: relative;
  @media (max-width: 576px) {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.loading-block {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: #4a40c6;
}

.loading-text {
  margin-top: 36px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    margin-top: 24px;
    font-size: 20px;
  }
}

.loader-wrapper {
  width: 100%;
  height: 140px;
  top: 70px;
  display: flex;
  justify-content: center;
  position: relative;
}
.loader-circle {
  font-size: 20px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.8s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4a40c6,
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.2),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.2),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.2),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.5),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(74, 64, 198, 0.2),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.2),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.2),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.5),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.2),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.2),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.2),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.5),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.2),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.2),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.5),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.2),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.2),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.2),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.5),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.2),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.2),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.2),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.5),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(74, 64, 198, 0.2),
      1.8em -1.8em 0 0em rgba(74, 64, 198, 0.2),
      2.5em 0em 0 0em rgba(74, 64, 198, 0.2),
      1.75em 1.75em 0 0em rgba(74, 64, 198, 0.2),
      0em 2.5em 0 0em rgba(74, 64, 198, 0.2),
      -1.8em 1.8em 0 0em rgba(74, 64, 198, 0.5),
      -2.6em 0em 0 0em rgba(74, 64, 198, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.loading-circle {
  width: 200px;
  height: 200px;
  @media (max-width: 576px) {
    width: 139px;
    height: 139px;
  }
}
</style>
