<template>
  <div class="not-found">
    <HeaderAuth />
    <div class="not-found-logo">
      404
      <div class="image-wrapper">
        <img src="@/assets/images/notFound/1.png" alt="" />
      </div>
      <div class="image-wrapper">
        <img src="@/assets/images/notFound/2.png" alt="" />
      </div>
    </div>
    <div class="not-found__text" v-html="$t('404_page.text')"></div>
    <router-link to="/">
      <CCButton
        class="not-found__button"
        variant="secondary"
        fontWeight="bolder"
        size="lg"
      >
        {{ $t("404_page.btn_home") }}
        <img
          class="not-found__arrow"
          src="@/assets/images/notFound/arrow.png"
          alt="arrow-left-icon"
        />
      </CCButton>
    </router-link>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";

export default {
  name: "NotFound",
  components: { HeaderAuth },
};
</script>
