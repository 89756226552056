<template>
  <div class="d-none d-sm-flex flex-column sidebar px-2">
    <!-- logotype -->
    <router-link to="/">
      <div class="d-flex pb-4">
        <img
          class="logo-dropdown__image pe-2"
          src="@/assets/images/logo-icon.svg"
          alt="logo-icon"
        />
        <h3 class="sidebar-logotype__title m-0 d-block d-sm-none d-xxl-block">
          Wallet
        </h3>
      </div>
    </router-link>
    <!-- main-menu -->
    <b-nav
      class="sidebar-menu py-3"
      vertical
      v-for="(nav, index) in navItems"
      :key="nav.id"
      :class="{
        'mt-3': index > 0,
      }"
    >
      <div v-if="nav.name === 'main'">
        <div
          v-for="link in nav.links"
          :key="link.id"
          class="sidebar-menu__item py-1"
          @mouseover="showHoverLink(link)"
          @mouseout="hideHoverLink(link)"
        >
          <router-link
            :to="link.route"
            class="item-link"
            :class="{
              'router-link-exact-active router-link-active': isActiveLink(link),
            }"
          >
            <img v-if="isActiveLink(link)" :src="link.icon_active" alt="" />
            <img v-else-if="link.is_hover" :src="link.icon_hover" alt="" />
            <img v-else :src="link.icon_base" alt="" />
            <span class="ms-2 d-none d-xxl-inline">{{
              $t(`sidebar.${link.code}`)
            }}</span>
          </router-link>
        </div>
      </div>
      <div v-if="nav.name === 'support'">
        <div
          v-for="link in nav.links"
          :key="link.id"
          class="sidebar-menu__item py-1"
          @mouseover="showHoverLink(link)"
          @mouseout="hideHoverLink(link)"
        >
          <a :href="link.route" target="_blank" class="item-link">
            <img v-if="link.is_hover" :src="link.icon_hover" alt="" />
            <img v-else :src="link.icon_base" alt="" />
            <span class="ms-2 d-none d-xxl-inline">{{
              $t(`sidebar.${link.code}`)
            }}</span>
          </a>
        </div>
      </div>
    </b-nav>
    <!-- policy -->
    <a
      href="#"
      class="d-none d-xxl-block mt-2 align-self-center sidebar-policy"
    >
      {{ $t("sidebar.licence") }} <br />
      © CryptoCloud - {{ currentYear }}
    </a>
  </div>
</template>

<script>
import { getFullYear } from "@/utils/date";

export default {
  name: "Sidebar",
  data: () => ({
    navItems: [
      {
        id: 1,
        name: "main",
        links: [
          {
            id: 1,
            code: "wallet",
            route: "/",
            icon_base: require("@/assets/images/sidebar/home.svg"),
            icon_hover: require("@/assets/images/sidebar/home-hover.svg"),
            icon_active: require("@/assets/images/sidebar/home-active.svg"),
            is_hover: false,
            active_routes: ["/wallet", "/send", "/accept"],
          },
          {
            id: 2,
            code: "exchange",
            route: "/exchange",
            icon_base: require("@/assets/images/sidebar/exchange.svg"),
            icon_hover: require("@/assets/images/sidebar/exchange-hover.svg"),
            icon_active: require("@/assets/images/sidebar/exchange-active.svg"),
            is_hover: false,
            active_routes: ["/exchange"],
          },
          {
            id: 3,
            code: "buycrypto",
            route: "/buy",
            icon_base: require("@/assets/images/sidebar/cart.svg"),
            icon_hover: require("@/assets/images/sidebar/cart-hover.svg"),
            icon_active: require("@/assets/images/sidebar/cart-active.svg"),
            is_hover: false,
            active_routes: ["/buy"],
          },
          {
            id: 4,
            code: "staking",
            route: "/staking",
            icon_base: require("@/assets/images/sidebar/staking.svg"),
            icon_hover: require("@/assets/images/sidebar/staking-hover.svg"),
            icon_active: require("@/assets/images/sidebar/staking-active.svg"),
            is_hover: false,
            active_routes: ["/staking"],
          },
          {
            id: 5,
            code: "energy",
            route: "/buy-energy",
            icon_base: require("@/assets/images/sidebar/energy.svg"),
            icon_hover: require("@/assets/images/sidebar/energy-hover.svg"),
            icon_active: require("@/assets/images/sidebar/energy-active.svg"),
            is_hover: false,
            active_routes: ["/buy-energy"],
          },
          {
            id: 6,
            code: "aml",
            route: "/buy-aml",
            icon_base: require("@/assets/images/sidebar/aml.svg"),
            icon_hover: require("@/assets/images/sidebar/aml-hover.svg"),
            icon_active: require("@/assets/images/sidebar/aml-active.svg"),
            is_hover: false,
            active_routes: ["/buy-aml"],
          },
        ],
      },
      {
        id: 2,
        name: "support",
        links: [
          {
            id: 1,
            code: "support",
            route: "https://cryptocloud.plus/faq",
            icon_base: require("@/assets/images/sidebar/support.svg"),
            icon_hover: require("@/assets/images/sidebar/support-hover.svg"),
            is_hover: false,
          },
        ],
      },
    ],
  }),
  methods: {
    showHoverLink(link) {
      if (this.$route.path !== link.route) {
        link.is_hover = true;
      }
    },
    hideHoverLink(link) {
      link.is_hover = false;
    },
    isActiveLink(link) {
      if (link.route.includes("buy") || this.$route.path === "/") {
        return this.$route.path === link.route;
      } else {
        if (link.active_routes) {
          return link.active_routes.some((route) =>
            this.$route.path.startsWith(route)
          );
        }
      }
    },
  },
  computed: {
    currentYear() {
      return getFullYear();
    },
  },
};
</script>
