<template>
  <div class="registration">
    <b-row class="mx-auto">
      <b-col cols="auto" class="registration__picture" />
      <b-col cols="auto" class="registration__part-right">
        <HeaderAuth />
        <form class="registration-form" @submit.prevent="registration">
          <CCCard padding="card24">
            <!-- title form -->
            <h2 class="registration__title m-0">
              {{ $t("registration_page.title") }}
            </h2>
            <div class="input-block">
              <div class="label">
                <h6>{{ $t("registration_page.email_label") }}</h6>
              </div>
              <CCInput placeholder="E-mail" v-model="email" />
            </div>
            <div class="input-block">
              <div class="label">
                <h6>{{ $t("registration_page.password_label") }}</h6>
              </div>
              <div class="position-relative">
                <CCInput
                  v-model="newPassword"
                  @keyup="checkPassword"
                  :placeholder="$t('registration_page.password_placeholder')"
                  :type="passwordInputs[1].type"
                  class="input-with-icon"
                />
                <div @click="changeTypeInput(1)" class="button-eye-password">
                  <img :src="passwordInputs[1].image" alt="eye-icon" />
                </div>
              </div>
              <ValidationPassword
                class="mt-2"
                :gap="1"
                :length="passwordRules.length"
                :upper-case-letters="passwordRules.letterBig"
                :numbers="passwordRules.number"
                :lower-case-letters="passwordRules.letterSmall"
              />
              <div class="input-block">
                <div class="label">
                  <h6>{{ $t("registration_page.password_repeat_label") }}</h6>
                </div>
                <div class="position-relative">
                  <CCInput
                    v-model="newPasswordRepeat"
                    :placeholder="$t('registration_page.password_repeat_label')"
                    :type="passwordInputs[2].type"
                    class="input-with-icon"
                  />
                  <div @click="changeTypeInput(2)" class="button-eye-password">
                    <img :src="passwordInputs[2].image" alt="eye-icon" />
                  </div>
                </div>
              </div>
              <!-- button -->
              <CCButton
                class="registration__submit"
                fontWeight="bolder"
                size="lg"
                type="submit"
                :disabled="isLoadingRegistration"
              >
                {{ $t("registration_page.btn_reg") }}
              </CCButton>
              <!-- policy -->
              <div class="policy">
                <input
                  id="policy-input"
                  v-model="isAgreePolicy"
                  class="policy__input"
                  type="checkbox"
                  name="policy"
                />
                <label for="policy-input">
                  <p
                    class="p-0 m-0"
                    v-html="$t('registration_page.policy')"
                  ></p>
                </label>
              </div>
              <!--  -->
              <div
                class="registration__enter mt-4 d-flex justify-content-center"
              >
                <span>{{ $t("registration_page.has_account") }}</span>
                <router-link
                  to="/login"
                  class="ms-2 link d-flex align-items-center"
                >
                  <img
                    src="@/assets/images/registration/enter-icon.svg"
                    alt=""
                  />
                  <span class="ms-1">{{
                    $t("registration_page.btn_login")
                  }}</span>
                </router-link>
              </div>
            </div>
          </CCCard>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";
import ValidationPassword from "@/components/ValidationPassword.vue";
import useValidationPassword from "@/mixins/validationPassword";
import { UserController } from "@/core/system/controllers";
import { mapActions } from "pinia";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { useAuthStore } from '@/stores/authStore';

export default {
  name: "Registration",
  components: { HeaderAuth, ValidationPassword },
  mixins: [useValidationPassword(useAuthStore)],
  data() {
    return {
      email: "",
      newPassword: "",
      newPasswordRepeat: "",
      isLoadingRegistration: false,
      isAgreePolicy: false,
    };
  },
  methods: {
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    registration() {
      const payload = {
        email: this.email,
        password: this.newPassword,
        password_confirm: this.newPasswordRepeat,
      };
      this.isLoadingRegistration = true;
      UserController.register(payload)
        .then((response) => {
          UserController.getCurrentUser().then((result) => {
            console.log("Result", result);
            if (result.is_email_confirming === false) {
              this.$router.push("/confirm-email");
            }
          });
        })
        .catch((error) => {
          this.showMessage("danger", this.$t("messages.error_reg"));
        })
        .finally(() => {
          this.isLoadingRegistration = false;
        });
    },
  },
};
</script>
