const formatDateTime = (dateTimeStr) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };
  const date = new Date(dateTimeStr);
  const formattedDate = date
    .toLocaleDateString("ru-RU", options)
    .split(", ")[0];
  const formattedTime = date
    .toLocaleTimeString("ru-RU", options)
    .split(", ")[1]
    .slice(0, 5);

  return {
    date: formattedDate.split(".").join("."),
    time: formattedTime,
  };
};

const getFullYear = () => {
  return new Date().getFullYear();
};

export { formatDateTime, getFullYear };
