<template>
  <div class="add-contact__wrapper">
    <div>
      <p class="address-book__main-text mb-0 mt-3">
        {{ $t("send_page.address_book.add_contact_form.text") }}
      </p>
      <!-- input -->
      <div class="send-transfer__input mt-3 mt-sm-4">
        <div class="input-block">
          <div class="label">
            <h6>
              {{ $t("send_page.address_book.add_contact_form.nickname_label") }}
            </h6>
          </div>
        </div>
        <div class="mt-2 d-none d-sm-block">
          <CCInput
            v-model="nickname"
            placeholder="Investor G. Finansist"
          ></CCInput>
        </div>
        <div class="mt-2 d-block d-sm-none">
          <CCInput
            v-model="nickname"
            placeholder="Investor G. Finansist"
            size="md"
          ></CCInput>
        </div>
      </div>
      <SelectNetwork
        :options="networksList"
        :default="selectedNetworkCurrency"
        @change="setSelectedNetworkCurrency"
        class="mt-3 mt-sm-4"
      />
      <!-- input -->
      <div class="address-book__add-address send-transfer__input mt-3 mt-sm-4">
        <div class="input-block">
          <div class="label">
            <h6>
              {{ $t("send_page.address_book.add_contact_form.address_label") }}
            </h6>
          </div>
        </div>
        <div class="mt-2 d-none d-sm-block">
          <CCInput
            placeholder="3Gbaro9CXaiAGK8w3xqHRqL6N5DkcpvGFH"
            v-model="address"
          ></CCInput>
        </div>
        <div class="mt-2 d-block d-sm-none">
          <CCInput
            placeholder="3Gbaro9CXaiAGK8w3xqHRqL6N5DkcpvGFH"
            v-model="address"
            size="md"
          ></CCInput>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-3 mt-sm-4 d-none d-sm-block">
        <CCButton
          @click="addContact"
          :disabled="!isValidAddContact || isLoadingAddContact"
          size="lg"
          variant="primary"
          styled="accent"
          fontWeight="medium"
        >
          {{ $t("send_page.address_book.add_contact_form.btn_add") }}
        </CCButton>
      </div>
      <div class="mt-3 mt-sm-4 d-block d-sm-none">
        <CCButton
          @click="addContact"
          :disabled="!isValidAddContact || isLoadingAddContact"
          size="md"
          variant="primary"
          styled="accent"
          fontWeight="medium"
        >
          {{ $t("send_page.address_book.add_contact_form.btn_add") }}
        </CCButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import LinkBackPage from "../../UI/LinkBackPage.vue";
import { useWalletStore } from "@/stores/wallet";
import SelectNetwork from "../../UI/SelectNetwork.vue";
import { WalletController } from "@/core/system/controllers";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { validateBlockchainAddress } from "@/utils/validateInput";
import { useInterfaceStore } from "@/stores/interfaceStore";

export default {
  name: "FormAddContact",
  components: {
    LinkBackPage,
    SelectNetwork,
  },
  data: () => ({
    isLoadingAddContact: false,
  }),
  methods: {
    ...mapActions(
      () => useWalletStore(),
      ["getAllNetwokCurrency", "setSelectedNetworkCurrency"]
    ),
    ...mapActions(
      () => useSendTransferStore(),
      [
        "getContactsList",
        "setStepAddressBook",
        "setAddressAddContact",
        "setNicknameAddContact",
      ]
    ),
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    async addContact() {
      this.isLoadingAddContact = true;
      const payload = {
        address: this.address,
        name: this.nickname,
        network_id: this.selectedNetworkCurrency.id,
      };
      const isValidAddress = validateBlockchainAddress(
        this.address,
        this.selectedNetworkCurrency.fullname
      );
      try {
        if (isValidAddress) {
          await WalletController.addContactToAddressList(payload);
          this.getContactsList();
          this.setStepAddressBook("list");
        } else {
          this.showMessage("danger", "Адрес не подходит для выбранной сети");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingAddContact = false;
      }
    },
    clearData() {
      this.nickname = "";
      this.address = "";
    },
  },
  computed: {
    ...mapState(
      () => useWalletStore(),
      ["networksList", "selectedNetworkCurrency"]
    ),
    ...mapState(() => useSendTransferStore(), ["formAddContact"]),
    isValidAddContact() {
      return this.address != "" && this.nickname != "";
    },
    address: {
      get() {
        return this.formAddContact.address;
      },
      set(address) {
        this.setAddressAddContact(address);
      },
    },
    nickname: {
      get() {
        return this.formAddContact.nickname;
      },
      set(nickname) {
        this.setNicknameAddContact(nickname);
      },
    },
  },
  async mounted() {
    const networks = await this.getAllNetwokCurrency();
    if (this.formAddContact.network === null) {
      this.setSelectedNetworkCurrency(networks[0]);
    } else {
      const selectedNetwork = this.networksList.find(
        (network) => network.code === this.formAddContact.network
      );
      this.setSelectedNetworkCurrency(selectedNetwork);
    }
  },
  beforeDestroy() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
.add-contact__wrapper {
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
