const getRoundedAmountCrypto = (amount) => {
  const numberAmount = parseFloat(amount);
  return numberAmount > 0 ? numberAmount.toFixed(7) : numberAmount;
};

const getRoundedAmountFiat = (amount) => {
  const numberAmount = parseFloat(amount);
  return numberAmount > 0 ? numberAmount.toFixed(2) : numberAmount;
};

const sliceNetworkCodeCurrency = (code) => {
  if (code.includes("USDT") || code.includes("USDC") || code.includes("TUSD")) {
    return code.split("_")[0];
  }
  return code;
};

const getNetworkCurrency = (code) => {
  if (code.includes("USDT") || code.includes("USDC") || code.includes("TUSD")) {
    return code.split("_")[1];
  }
  return code;
};

const convertBalanceUsd = (amount) => {
  return Number(getRoundedAmountFiat(amount));
};

const convertBalanceCrypto = (amount) => {
  return Number(getRoundedAmountCrypto(amount));
};

const getShortWalletAddress = (address) => {
  return `${address.substring(0, 6)}<...>${address.substring(
    address.length - 6
  )}`;
};

const getHistoryAddressLink = (network, address) => {
  let link;
  switch (network) {
    case "TRON":
      link = `https://tronscan.org/#/address/${address}`;
      break;
    case "Ethereum":
      link = `https://etherscan.io/address/${address}`;
      break;
    case "Litecoin":
      link = `https://blockchair.com/litecoin/address/${address}?from=CryptoCloud`;
      break;
    case "Bitcoin":
      link = `https://blockchair.com/bitcoin/address/${address}?from=CryptoCloud`;
      break;

    default:
      break;
  }
  return link;
};

export {
  getRoundedAmountCrypto,
  getRoundedAmountFiat,
  sliceNetworkCodeCurrency,
  getNetworkCurrency,
  convertBalanceUsd,
  convertBalanceCrypto,
  getShortWalletAddress,
  getHistoryAddressLink
};
