<template>
  <div
    v-if="!isLoading"
    class="history-item"
    :class="{
      even: isEven,
      uneven: !isEven,
    }"
  >
    <div class="history-item__content">
      <b-row align-v="center">
        <b-col cols="2">
          <div class="currency-info d-flex align-items-center">
            <div class="icon-wrapper">
              <img class="icon-currency" :src="icon" alt="" />
            </div>
            <p class="currency-name mb-0">{{ currency }}</p>
            <p class="network-name mb-0">{{ networkName }}</p>
          </div>
        </b-col>
        <b-col cols="2">
          <p
            class="amount-transaction mb-0"
            :class="{
              up: type === 'up',
              out: type === 'out',
            }"
          >
            {{ typeTransaction }} {{ amountCrypto }} {{ currency }}
          </p>
        </b-col>
        <b-col cols="2">
          <p
            class="amount-transaction-usd mb-0"
            :class="{
              up: type === 'up',
              out: type === 'out',
            }"
          >
            {{ typeTransaction }}${{ amountUsd }}
          </p>
        </b-col>
        <b-col cols="4">
          <div
            class="address-info d-flex align-items-center"
            @click.prevent
            v-clipboard:copy="address"
            v-clipboard:success="copyData"
          >
            <Clipboard
              @onCopySuccess="copyData"
              :isActiveCopy="isCopyData"
              :copyText="address"
              :size="18"
            />
            <p class="address mb-0">{{ address }}</p>
          </div>
        </b-col>
        <b-col cols="2">
          <p class="datetime mb-0">
            {{ normalizedDate }}
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
  <div v-else>
    <b-skeleton
      class="sm"
      :class="{ white: isEven }"
      width="100%"
      height="40px"
    ></b-skeleton>
  </div>
</template>

<script>
import Clipboard from "@/components/UI/Clipboard.vue";
import { sliceNetworkCodeCurrency } from "@/utils";
import moment from "moment";

export default {
  components: { Clipboard },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEven: Boolean,
    icon: String,
    currencyName: {
      type: String,
      default: () => "USDT",
    },
    networkName: {
      type: String,
      default: () => "TRC20",
    },
    type: String,
    amountCrypto: {
      type: Number,
      default: () => 0,
    },
    amountUsd: {
      type: Number,
      default: () => 0,
    },
    address: {
      type: String,
      default: () => "TXwWQoAbT9bPd2vbQdGfpeMKCxKVKdCey3",
    },
    txId: {
      type: String,
      default: () => "txid",
    },
    dateTime: {
      type: String,
      default: () => "2024-05-27 07:20:35.633698+00:00",
    },
  },
  data: () => ({
    isCopyData: false,
  }),
  methods: {
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
  },
  computed: {
    normalizedDate() {
      const localDate = moment.utc(this.dateTime).local();
      return localDate.format("DD.MM.YYYY • HH:mm");
    },
    typeTransaction() {
      return this.type === "up" ? "+" : "-";
    },
    currency() {
      return sliceNetworkCodeCurrency(this.currencyName);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-item {
  cursor: pointer;
  width: 100%;
  border: 1px solid transparent;
  &.even {
    background: #fbfbfb;
  }
  &.uneven {
    background: #fff;
  }
  &:hover {
    background: #fafaff;
    border: 1px solid #6a62ca;
  }
  &__content {
    padding: 4px 24px 4px 24px;
    .currency-info {
      .icon-wrapper {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 0.6px solid #f0f0f0;
        background: #fff;
        .icon-currency {
          width: 20px;
          height: 20px;
        }
      }
      .currency-name {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #3c3c3c;
      }
      .network-name {
        margin-left: 6px;
        color: #acacac;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .amount-transaction {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.12px;
      &.up {
        color: #58bd7d;
      }
      &.out {
        color: #fa6666;
      }
    }
    .amount-transaction-usd {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.12px;
      &.up {
        color: #58bd7d;
      }
      &.out {
        color: #fa6666;
      }
    }
    .address-info {
      .address {
        margin-left: 4px;
        color: #4a40c6;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .tx-info {
      .txid {
        color: #3c3c3c;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .datetime {
      color: #3c3c3c;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
