<template>
  <b-modal
    :size="size"
    :id="id"
    centered
    hide-footer
    title="BootstrapVue"
    v-bind="$attrs"
  >
    <template #modal-title>
      <div class="p-3 p-md-4 pt-0 pb-0 pb-md-0 pt-md-0">
        <slot name="modal-header"></slot>
      </div>
    </template>
    <div class="modal-body p-0 m-0">
      <div class="p-3 p-md-4 pt-2 pt-md-0">
        <slot name="modal-body"></slot>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "modal-component",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
  },
};
</script>

<style></style>
