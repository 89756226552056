<template>
  <div class="buy-page">
    <b-container class="container-header-back-link">
      <HeaderBackLink class="d-none d-sm-block" :title="$t('buy_page.title')">
        <img
          class="header-buy-icon"
          src="../assets/images/buy-icon.svg"
          alt=""
        />
      </HeaderBackLink>
    </b-container>
    <HeaderMobile :title="$t('buy_page.title')" class="d-block d-sm-none">
      <img
        v-if="step > 1"
        @click="clearAmountsChangeStep"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
      <router-link v-else slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link v-if="step === 1" slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <div class="buy-card">
          <div class="buy-card__header">
            <div class="d-flex justify-content-between">
              <h2
                class="h3-text"
                v-if="step === 1"
                v-html="$t('buy_page.step1_title')"
              ></h2>
              <h2
                v-if="step === 2"
                class="h3-text"
                v-html="$t('buy_page.step2_title')"
              ></h2>
              <h2
                v-if="step === 3"
                class="h3-text"
                v-html="$t('buy_page.step3_title')"
              ></h2>
              <LinkBackPage
                class="d-none d-sm-block"
                v-if="step > 1 && step < 4"
                @click="clearAmountsChangeStep"
              />
              <div v-if="step < 4" class="step-info d-block d-sm-none">
                {{ $t("buy_page.step") }} {{ step }} из 4
              </div>
            </div>
          </div>

          <div class="buy-card__steps">
            <TypeAmount
              v-if="step === 1"
              class="mt-4"
              :input-amount-give="inputGive"
              :input-amount-receive="inputReceive"
              placeholderInputGive="~180"
              placeholderInputReceive="0.031556"
              :is-loading-currency="isLoadingCurrencyList"
              :is-loading-amount-give="isLoadingGive"
              :is-loading-amount-receive="isLoadingReceive"
              :list-currency-give="fiatCurrencyList"
              :list-currency-receive="cryptoCurrencyList"
              :selected-currency-give="selectedFiatCurrency"
              :selected-currency-receive="selectedCryptoCurrency"
              @selectCurrencyGive="selectCurrencyGive"
              @selectCurrencyReceive="selectCurrencyReceive"
              @typeAmountGive="getAmountGive"
              @typeAmountReceive="getAmountReceive"
            />
            <div v-if="step === 2">
              <b-row class="mt-4">
                <b-col
                  @click="setSelectedOperator(operator)"
                  v-for="operator in operatorsList"
                  :key="operator.id"
                  cols="12"
                >
                  <SelectOperator
                    variant="buy"
                    :default="operator"
                    :type="typeBuy"
                    :class="{
                      'border-accent': selectedOperator.id == operator.id,
                    }"
                  ></SelectOperator>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 3">
              <SelectOperator
                variant="buy"
                class="mt-4"
                style="cursor: auto"
                :type="typeBuy"
                :default="selectedOperator"
              ></SelectOperator>
              <div class="mt-4">
                <div class="label">
                  <h6>{{ $t("buy_page.wallet_pay") }}</h6>
                </div>
                <SelectWallet
                  :options="walletsReceiveList"
                  :default="selectedWalletReceive"
                  :zIndex="20"
                  class="mt-2"
                  @changeSelect="selectWalletReceive"
                />
              </div>
            </div>
            <div v-if="step === 4">
              <LoadingScreen :is-loading-percent="false">
                <b-row
                  slot="buttons"
                  align-h="center"
                  class="d-none d-sm-flex"
                  v-if="payLink != ''"
                >
                  <b-col cols="5">
                    <a target="_blank" :href="payLink">
                      <CCButton size="lg" fontWeight="medium">{{
                        $t("buy_page.site_link")
                      }}</CCButton>
                    </a>
                  </b-col>
                  <b-col cols="5">
                    <CCButton
                      @click="clearTransaction"
                      size="lg"
                      variant="secondary"
                      fontWeight="medium"
                      >{{ $t("buy_page.btn_cancel") }}</CCButton
                    >
                  </b-col>
                </b-row>
                <b-row
                  slot="buttons"
                  align-h="center"
                  class="d-flex d-sm-none"
                  v-if="payLink != ''"
                >
                  <b-col cols="12">
                    <a target="_blank" :href="payLink">
                      <CCButton size="md" fontWeight="medium">{{
                        $t("buy_page.site_link")
                      }}</CCButton>
                    </a>
                  </b-col>
                  <b-col class="mt-3" cols="12">
                    <CCButton
                      @click="clearTransaction"
                      size="md"
                      variant="secondary"
                      fontWeight="medium"
                      >{{ $t("buy_page.btn_cancel") }}</CCButton
                    >
                  </b-col>
                </b-row>
              </LoadingScreen>
            </div>
          </div>
          <div class="step-card d-none d-sm-block" v-if="step < 3">
            <b-row align-v="center" align-h="between">
              <b-col cols="auto">
                <div class="step-info">
                  {{ $t("buy_page.step") }} {{ step }} из 4
                </div>
              </b-col>
              <b-col cols="6">
                <CCButton
                  @click="getOperatorsList"
                  :disabled="!blockedBuy || isLoadingOperatorsList"
                  v-if="step === 1"
                  size="lg"
                  class="px-4"
                  fontWeight="medium"
                  >{{ $t("buy_page.btn_next") }}</CCButton
                >
                <CCButton
                  v-if="step === 2"
                  @click="selectOperator"
                  :disabled="isLoadingWallets"
                  size="lg"
                  class="px-4"
                  fontWeight="medium"
                  >{{ $t("buy_page.btn_select") }}</CCButton
                >
              </b-col>
            </b-row>
          </div>
          <div class="buy-bottom mt-4 mt-sm-0" v-if="step === 3">
            <div class="tooltip-card d-flex">
              <img
                width="22px"
                height="22px"
                src="@/assets/images/info-tooltip.svg"
                alt=""
              />
              <p class="tooltip-card__text mb-0 ms-2">
                {{ $t("buy_page.text1") }}
              </p>
            </div>
            <div class="tooltip-card d-flex mt-4">
              <img
                width="22px"
                height="22px"
                src="@/assets/images/info-tooltip.svg"
                alt=""
              />
              <p class="tooltip-card__text mb-0 ms-2">
                {{ $t("buy_page.text2") }}
              </p>
            </div>
            <div class="d-none d-sm-block mt-4 pb-4">
              <CCButton
                :disabled="isLoadingBuy"
                @click="goToPay"
                size="lg"
                fontWeight="medium"
                >{{ $t("buy_page.btn_pay") }}</CCButton
              >
            </div>
          </div>
          <div class="mt-4 pb-4 d-block d-sm-none">
            <CCButton
              @click="getOperatorsList"
              :disabled="!blockedBuy || isLoadingOperatorsList"
              v-if="step === 1"
              size="md"
              class="px-4"
              fontWeight="medium"
              >{{ $t("buy_page.btn_next") }}</CCButton
            >
            <CCButton
              v-if="step === 2"
              @click="selectOperator"
              :disabled="isLoadingWallets"
              size="md"
              class="px-4"
              fontWeight="medium"
              >{{ $t("buy_page.btn_select") }}</CCButton
            >
            <CCButton
              v-if="step === 3"
              :disabled="isLoadingBuy"
              @click="goToPay"
              size="md"
              fontWeight="medium"
              >{{ $t("buy_page.btn_pay") }}</CCButton
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import TypeAmount from "@/components/BuyCrypto/TypeAmount.vue";
import LinkBackPage from "@/components/UI/LinkBackPage.vue";
import SelectOperator from "@/components/SelectOperator.vue";
import SelectWallet from "@/components/UI/SelectWallet.vue";
import { useWalletStore } from "@/stores/wallet";
import ConfirmTransaction from "@/components/Transactions/ConfirmTransaction";
import LoadingScreen from "@/components/Transactions/LoadingScreen.vue";
import { useBuyStore } from "@/stores/buyStore";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";

export default {
  components: {
    HeaderBackLink,
    TypeAmount,
    LinkBackPage,
    SelectOperator,
    SelectWallet,
    ConfirmTransaction,
    LoadingScreen,
    LoadingScreen,
    HeaderMobile,
  },
  data: () => ({
    inputGive: "",
    inputReceive: "",
    isLoadingGive: false,
    isLoadingReceive: false,
    isLoadingWallets: false,
    isLoadingBuy: false,
    interval: null,
  }),
  methods: {
    ...mapActions(useBuyStore, [
      "setStep",
      "setStepBack",
      "setStatus",
      "setSelectedFiatCurrency",
      "setSelectedCryptoCurrency",
      "setTypeBuy",
      "setSelectedOperator",
      "setSelectedWalletReceive",
      "fetchCurrencyList",
      "fetchProfitAmount",
      "fetchOperatorsList",
      "fetchAvailableWallets",
      "createTransactionBuy",
      "changeTransactionBuy",
      "checkStatusBuy",
      "restartTransaction",
    ]),
    ...mapActions(useWalletStore, ["setTransactionData"]),
    clearAmountsChangeStep() {
      this.setStepBack();
      if (this.step === 1) {
        (this.inputGive = ""), (this.inputReceive = "");
      }
    },
    async selectCurrencyGive(value) {
      this.setSelectedFiatCurrency(value);
      if (this.typeBuy === "float") {
        this.isLoadingReceive = true;
        try {
          const resp = await this.fetchProfitAmount(this.amount);
          this.inputReceive = resp.toAmount;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingReceive = false;
        }
      } else {
        this.isLoadingGive = true;
        try {
          const resp = await this.fetchProfitAmount(this.amount);
          this.inputGive = resp.toAmount;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingGive = false;
        }
      }
    },
    async selectCurrencyReceive(value) {
      this.setSelectedCryptoCurrency(value);
      if (this.typeBuy === "float") {
        this.isLoadingReceive = true;
        try {
          const resp = await this.fetchProfitAmount(this.amount);
          this.inputReceive = resp.toAmount;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingReceive = false;
        }
      } else {
        this.isLoadingGive = true;
        try {
          const resp = await this.fetchProfitAmount(this.amount);
          this.inputGive = resp.toAmount;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingGive = false;
        }
      }
    },
    selectWalletReceive(value) {
      this.setSelectedWalletReceive(value);
    },
    async getAmountGive(amount) {
      this.inputGive = amount;
      this.setTypeBuy("float");
      this.isLoadingReceive = true;
      try {
        const resp = await this.fetchProfitAmount(this.amount);
        this.inputReceive = resp.toAmount;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingReceive = false;
      }
    },
    async getAmountReceive(amount) {
      this.inputReceive = amount;
      this.setTypeBuy("fixed");
      this.isLoadingGive = true;
      try {
        const resp = await this.fetchProfitAmount(this.amount);
        this.inputGive = resp.toAmount;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingGive = false;
      }
    },
    async getOperatorsList() {
      await this.fetchOperatorsList(this.amount);
    },
    async selectOperator() {
      this.isLoadingWallets = true;
      try {
        await this.fetchAvailableWallets();
        await this.createTransactionBuy(this.amount);
        this.setStep(3);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingWallets = false;
      }
    },
    async goToPay() {
      this.isLoadingBuy = true;
      try {
        await this.changeTransactionBuy();
        this.setStep(4);
        this.checkBuy();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingBuy = false;
      }
    },
    clearTransaction() {
      this.restartTransaction();
      clearInterval(this.interval);
    },
    checkBuy() {
      this.interval = setInterval(async () => {
        await this.checkStatusBuy();
        if (this.status === "paid") {
          this.clearTransaction();
        }
      }, 30000);
    },
  },
  computed: {
    ...mapState(useBuyStore, [
      "step",
      "status",
      "typeBuy",
      "payLink",
      "fiatCurrencyList",
      "cryptoCurrencyList",
      "selectedFiatCurrency",
      "selectedCryptoCurrency",
      "operatorsList",
      "selectedOperator",
      "walletsFromList",
      "selectedWalletReceive",
      "isLoadingOperatorsList",
      "isLoadingCurrencyList",
      "walletsReceiveList",
    ]),
    amount() {
      return this.typeBuy === "float"
        ? Number(this.inputGive)
        : Number(this.inputReceive);
    },
    blockedBuy() {
      return Number(this.inputGive) > 0 && Number(this.inputReceive) > 0;
    },
  },
  mounted() {
    this.fetchCurrencyList();
  },
  beforeDestroy() {
    this.clearTransaction();
  },
};
</script>

<style lang="scss" scoped>
.buy-bottom {
  padding: 0 24px 0 24px;
  @media (max-width: 576px) {
    padding: 0;
  }
}

.header-buy-icon {
  width: 24px;
  height: 24px;
  @media (max-width: 1200px) {
    width: 18px;
    height: 18px;
  }
}

.buy-card {
  border-radius: 12px;
  background: #fff;
  &__header {
    padding: 24px 24px 0 24px;
  }
  &__steps {
    padding: 0 24px 50px 24px;
  }
  @media (max-width: 576px) {
    background: none;
    border-radius: 0px;
    .buy-card__steps {
      padding: 0;
    }
    .buy-card__header {
      padding: 0;
    }
  }
}
</style>
