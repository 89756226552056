<template>
  <div class="history-full">
    <div class="history-full__header">
      <div class="header-content">
        <b-row align-h="between" align-v="center">
          <b-col cols="12" lg="4">
            <div class="d-flex align-items-center">
              <img
                class="icon-title"
                src="@/assets/images/history-icon2.svg"
                alt=""
              />
              <h3 class="content-title mb-0">История операций</h3>
            </div>
          </b-col>
          <b-col cols="12" lg="5">
            <b-row
              :align-h="
                selectedNetwork != 'BTC' && selectedNetwork != 'LTC'
                  ? 'between'
                  : 'start'
              "
              align-v="center"
            >
              <b-col
                cols="6"
                md="5"
                order="2"
                order-lg="1"
                class="mt-2 mt-lg-0"
                v-if="selectedNetwork != 'BTC' && selectedNetwork != 'LTC'"
              >
                <div class="filter-currency d-flex align-items-center">
                  <span v-if="!isLoadingWallet" class="label d-none d-lg-block"
                    >Валюта</span
                  >
                  <SelectFilter
                    :isLoading="isLoadingWallet"
                    :options="filtersCurrency"
                    :default="selectedCurrency"
                    @changeSelect="selectCurrency"
                    :tabindex="1"
                    class="ms-lg-2"
                  />
                </div>
              </b-col>
              <b-col class="mt-2 d-block d-lg-none" order="2" cols="6">
                <Calendar
                  v-if="!isLoadingWallet"
                  @onDateSelected="selectDate"
                  @onResetDate="resetDate"
                />
                <b-skeleton
                  v-else
                  class="sm"
                  width="100%"
                  height="32px"
                ></b-skeleton>
              </b-col>
              <b-col
                cols="12"
                lg="7"
                order="1"
                order-lg="2"
                class="mt-3 mt-lg-0"
              >
                <Search
                  @search="searchTransaction"
                  @onDateSelected="selectDate"
                  :isLoading="isLoadingWallet"
                >
                  <template v-slot:calendar>
                    <div class="calendar-block">
                      <Calendar
                        @onDateSelected="selectDate"
                        @onResetDate="resetDate"
                      />
                    </div>
                  </template>
                </Search>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <HistoryTableList
      :historyList="historyList"
      :isLoading="isLoadingHistory"
    >
      <Pagination
        v-if="totalPagesHistory > 1"
        :currentPage="currentPageHistory"
        :totalPages="totalPagesHistory"
        @changePage="setCurrentPage"
        slot="pagination"
      />
    </HistoryTableList>
  </div>
</template>

<script>
import SelectFilter from "./SelectFilter.vue";
import Search from "./Search.vue";
import HistoryTableList from "./HistoryTableList.vue";
import Calendar from "./Calendar.vue";
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import Pagination from "@/components/UI/Pagination.vue";
export default {
  components: { Search, SelectFilter, HistoryTableList, Calendar, Pagination },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedNetwork: String,
  },
  data: () => ({
    selectedCurrency: null,
  }),
  methods: {
    ...mapActions(useWalletStore, [
      "setQuerySearchHistory",
      "addFilterHistory",
      "removeFilterHistory",
      "fetchHistoryList",
      "setCurrentPage",
    ]),
    selectCurrency(item) {
      this.selectedCurrency = item;
      if (item.id != "all") {
        this.addFilterHistory("currency__code", ["=", item.code]);
      } else {
        if (this.historyFilters["currency__code"]) {
          this.removeFilterHistory("currency__code");
        }
      }
      this.fetchHistoryList();
    },
    selectDate(date) {
      if (date.start !== null && date.end !== null) {
        this.addFilterHistory("created_at", [
          "between",
          [date.start, date.end],
        ]);
        this.fetchHistoryList();
      }
    },
    resetDate() {
      this.removeFilterHistory("created_at");
      this.fetchHistoryList();
    },
    searchTransaction(query) {
      this.setQuerySearchHistory(query);
      this.fetchHistoryList();
    },
  },
  computed: {
    ...mapState(useWalletStore, [
      "selectedWallet",
      "historyFilters",
      "historyList",
      "currentPageHistory",
      "totalPagesHistory",
      "isLoadingHistory",
      "isLoadingWallet",
    ]),
    filtersCurrency() {
      return [
        { id: "all", full_name: "Все" },
        ...(Array.isArray(this.selectedWallet.currency_list)
          ? this.selectedWallet.currency_list
          : []),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-title {
  width: 40px;
  height: 40px;

  @media (max-width: 992px) {
    width: 24px;
    height: 24px;
  }
}

.history-full {
  border-radius: 12px;
  background: #fff;

  &__header {
    border-bottom: 2px solid #f0f0f0;

    .header-content {
      padding: 24px;

      .content-title {
        margin-left: 12px;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        color: #3c3c3c;

        @media (max-width: 992px) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .filter-currency {
        .label {
          color: #5f5f5f;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
