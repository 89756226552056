import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    openSection: "baseSettings",
    statusChangePassword: 'start',
    statusChangePasswordInvoice: 'start',
    status2Fa: 'start',
    lang: 'ru',
    isEnable2fa: false,
    sendNotifyEmail: false,
  }),
  actions: {
    setOpenSection(section) {
      this.openSection = section;
    },
    setStatusChangePassword(status) {
      this.statusChangePassword = status;
    },
    setStatusChangePasswordInvoice(status) {
      this.statusChangePasswordInvoice = status;
    },
    setStatus2Fa(status) {
      this.status2Fa = status;
    },
    setIsEnable2Fa(boolean) {
      this.isEnable2fa = boolean;
    },
  },
  getters: {},
});
