<template>
  <div class="reset-password">
    <b-row class="mx-auto">
      <b-col cols="12">
        <HeaderAuth />
      </b-col>
      <b-col cols="12">
        <form class="reset-password-form" @submit="resetPassword">
          <CCCard padding="card24">
            <!-- title form -->
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="reset-password__title m-0">
                {{ $t("reset_pass_page.title") }}
              </h2>
              <img
                @click="$router.back()"
                class="pointer"
                src="@/assets/images/back-icon.svg"
                alt=""
              />
            </div>
            <p
              class="p-0 mb-0 reset-password__text"
              v-html="$t('reset_pass_page.text')"
            ></p>
            <div class="input-block">
              <div class="label">
                <h6>{{ $t("reset_pass_page.email_label") }}</h6>
              </div>
              <CCInput placeholder="E-mail" v-model="email" />
            </div>
            <!-- button -->
            <CCButton
              class="reset-password__submit"
              fontWeight="bolder"
              :disabled="isDisabledBtn || isLoadingReset"
              size="lg"
              @click.prevent="resetPassword"
            >
              {{ $t("reset_pass_page.btn_sendpass") }}
            </CCButton>
          </CCCard>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";
import { UserController } from "@/core/system/controllers";
import { mapActions } from "pinia";
import { useInterfaceStore } from "@/stores/interfaceStore";

export default {
  name: "ResetPassword",
  components: { HeaderAuth },
  data() {
    return {
      email: "",
      isLoadingReset: false,
    };
  },
  computed: {
    isDisabledBtn() {
      return this.email === "";
    },
  },
  methods: {
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    resetPassword() {
      this.isLoadingReset = true;
      UserController.resetPassword(this.email)
        .then((response) => {
          this.showMessage("success", this.$t("messages.success_reset_pass"));
          this.$router.push("/login");
        })
        .catch((_error) => {
          this.showMessage("danger", this.$t("messages.error_reset_pass"));
        })
        .finally(() => {
          this.isLoadingReset = false;
        });
    },
  },
};
</script>
