<template>
  <div class="rows-list" v-if="rowsList.length">
    <RowItem
      :class="{ 'mt-3': index > 0 }"
      v-for="(item, index) in rowsList"
      :key="index"
      :address="item.address"
      :amount="item.amount"
      :isValid="item.isValid"
      @remove="removeItem(item.id)"
    />
  </div>
  <div v-else class="rows-list d-flex justify-content-center align-items-center">
    <p class="empty-list mb-0">Нет адресов для отправки</p>
  </div>
</template>

<script>
import RowItem from "./RowItem.vue";

export default {
  components: { RowItem },
  props: {
    rowsList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    removeItem(id) {
      this.$emit("removeItem", id);
      console.log(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.rows-list {
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  padding: 16px;
  background: #fff;
  height: 280px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    height: 56px;
    background-color: #9f9f9f;
    border-radius: 100px;
  }
  .empty-list {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #3c3c3c;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>
