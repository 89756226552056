<template>
  <div
    class="select-wallet"
    :tabindex="tabindex"
    @blur="closeSelect"
    :style="{ 'z-index': zIndex }"
    :class="{
      disabled: options.length <= 1,
    }"
    @click="toggleSelect"
  >
    <div
      class="select-wallet__selected d-flex align-items-center justify-content-between"
    >
      <div class="selected-content d-flex align-items-center">
        <p class="wallet-name mb-0">{{ selected.nickname }}:</p>
        <p class="wallet-address mb-0">
          {{ shortenWalletAddress(selected.address) }}
        </p>
      </div>
      <img
        v-if="options.length > 1"
        class="arrow-select"
        :class="{
          active: isOpen,
        }"
        src="@/assets/images/arrow-select.svg"
        alt=""
      />
    </div>

    <div class="select-wallet__items" v-show="isOpen" v-if="options.length > 1">
      <div
        class="item"
        v-for="option in options"
        :key="option.id"
        @click.stop="selectItem(option)"
      >
        <div
          class="item-content d-flex align-items-center"
          v-if="option.id != selected.id"
        >
          <p class="wallet-name mb-0">{{ option.nickname }}:</p>
          <p class="wallet-address mb-0">
            {{ shortenWalletAddress(option.address) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: [],
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    zIndex: {
      type: Number || String,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    closeSelect() {
      this.isOpen = false;
    },
    selectItem(item) {
      this.$emit("changeSelect", item);
      this.selected = item;
      this.closeSelect();
    },
    shortenWalletAddress(address) {
      return `${address.substring(0, 6)}...${address.substring(
        address.length - 14
      )}`;
    },
  },
  computed: {
    selected: {
      get() {
        return this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0]
          : null;
      },
      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 12px;
  }
}
.wallet-address {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4a40c6;
  @media (max-width: 576px) {
    font-size: 12px;
  }
}
.arrow-select {
  user-select: none;
  transition: 0.1s ease-in;
  &.active {
    transform: rotate(180deg);
  }
}
.select-wallet {
  position: relative;
  cursor: pointer;
  padding: 16px 16px 16px 20px;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  background: #fff;
  @media (max-width: 576px) {
    padding: 12px 12px 12px 16px;
  }
  &.disabled {
    cursor: auto;
  }
  &__selected {
    position: relative;
    z-index: 12;
    .selected-content {
    }
  }
  &__items {
    width: 100.4%;
    // max-height: 395px;
    color: #3c3c3c;
    border-radius: 0px 0px 6px 6px;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    position: absolute;
    background-color: #fff;
    top: 50px;
    left: -1px;
    z-index: 10;
    padding-top: 10px;
    padding-bottom: 5px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: 0.5s;
    box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
    .item {
      width: 100%;
      &:hover {
        background: #f7f7f7;
      }

      .item-content {
        padding: 16px 16px 16px 20px;
        @media (max-width: 576px) {
          padding: 12px 12px 12px 16px;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100.6%;
    }
    @media (max-width: 576px) {
      width: 100.6%;
      top: 40px;
    }
  }
}
</style>
