<template>
  <div class="select-network">
    <CCCard padding="card24">
      <div class="d-flex justify-content-between">
        <h1 v-if="type === 'default'" class="select-network__title mb-0">
          {{ $t("create_wallet_page.select_network.title") }}
        </h1>
        <h1 v-if="type === 'hold'" class="select-network__title mb-0">
          Выберите сеть для копилки
        </h1>
        <img
          @click="$router.back()"
          class="pointer"
          src="../../assets/images/close-modal.svg"
          alt=""
        />
      </div>
      <div v-if="type === 'default'">
        <p
          class="select-network__text mb-0 mt-3"
          v-html="$t('create_wallet_page.select_network.text1')"
        ></p>
        <p
          class="select-network__text mb-0 mt-2"
          v-html="$t('create_wallet_page.select_network.text2')"
        ></p>
      </div>
      <div v-if="type === 'hold'">
        <p class="select-network__text mb-0 mt-3">
          Теперь безопасно можно ставить цель и копить до конкретной суммы и
          что-то еще дополнительное.
        </p>
        <p class="select-network__text mb-0 mt-2">
          Управляйте своими сбрежениями, положив любую сумму, снимая когда
          угодно.
        </p>
      </div>
    </CCCard>
    <div v-if="!isLoadingNetworks">
      <div
        v-for="network in networksList"
        :key="network.id"
        @click="selectNetwork(network)"
      >
        <NetworkCard
          class="mt-2 mt-sm-3"
          :name="network.fullname"
          :icon="network.icon"
          :currencies="network.currency"
        />
      </div>
    </div>
    <div v-else>
      <div v-for="index in 4" :key="index">
        <NetworkCard :isLoading="isLoadingNetworks" class="mt-2 mt-sm-3" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import NetworkCard from "./NetworkCard.vue";
export default {
  props: {
    type: {
      type: String,
      default: "default",
    },
  },
  methods: {
    ...mapActions(useWalletStore, [
      "setSelectedNetwork",
      "setStep",
      "getAllNetwokCurrency",
    ]),
    selectNetwork(network) {
      this.setSelectedNetwork(network);
      this.setStep(2);
    },
  },
  computed: {
    ...mapState(useWalletStore, ["networksList", "isLoadingNetworks"]),
  },
  mounted() {
    this.getAllNetwokCurrency();
  },
  components: { NetworkCard },
};
</script>

<style lang="scss" scoped>
.select-network__title {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px; /* 118.75% */
  letter-spacing: 0.32px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.create-wallet {
  .select-network {
    .select-network__text {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: #3c3c3c;
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .save-aes {
    .save-aes__input {
      min-height: 210px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #f0f0f0;
      background: #fff;
      color: #4a40c6;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 16px;
      padding-right: 50px;
      &:focus-visible {
        outline: none !important;
      }
    }
  }
}
</style>
