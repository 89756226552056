import Api from "../../api/BaseAPI";
import UserEntity from "../entities/userEntity";
import router from "@/router";

class UserController {
  constructor() {
    this.api = new Api();
  }

  async register(userData) {
    try {
      const response = await this.api.post(
        "/api/v1/user/registration",
        userData
      );
      const userObj = new UserEntity(response.result);
      localStorage.setItem("token", userObj.token);
      return userObj;
    } catch (error) {
      console.log(error);
    }
  }

  async getCurrentUser() {
    try {
      const response = await this.api.get("/api/v1/user/info", true);
      return new UserEntity(response.result);
    } catch (error) {
      // console.log(error);
      throw error;
    }
  }

  async login(credentials) {
    try {
      const response = await this.api.post("/api/v1/user/login", credentials);
      const userObj = new UserEntity(response.result);
      localStorage.setItem("token", userObj.token);
      return userObj;
    } catch (error) {
      console.log(error);
    }
  }

  async resetPassword(email) {
    try {
      await this.api.post("/api/v1/user/password/reset", { email });
    } catch (error) {
      console.log(error);
    }
  }

  async changePassword(data) {
    try {
      await this.api.post("/api/v1/user/password/change", data, true);
    } catch (error) {
      console.log(error);
    }
  }

  async getHistoryAuth() {
    try {
      const response = await this.api.get(
        "/api/v1/user/history/login/list",
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }

  async confirmEmail(activationKey) {
    try {
      await this.api.post(
        "/api/v1/user/email-confirm",
        { activation_key: activationKey },
        true
      );
    } catch (error) {
      console.log(error);
    }
  }
}

export default new UserController();
