import Api from "../../api/BaseAPI";

let cancelPrevRequest = null;

class ExchangeController {
  constructor() {
    this.api = new Api();
  }
  async getCurrencyList() {
    try {
      const response = await this.api.get(
        `/api/v1/wallet/exchange/currency/list`,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getProfitAmount(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/exchange/profit/amount`,
        data,
        true,
        true
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getInfoPair(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/exchange/info/pair`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async createExchange(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/exchange/create`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getStatusExchange(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/exchange/status`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new ExchangeController();
