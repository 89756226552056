<template>
  <div class="select-pills d-flex align-items-center">
    <div
      @click="selectOption(countPill)"
      class="pill"
      :class="{
        active: countPill.id === selectedOption.id,
      }"
    >
      {{ countPill.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countPill: {
      type: Object,
      default: () => ({
        id: null,
        name: "",
        value: null,
      }),
    },
    selectedOption: {
      type: Object,
      default: () => ({
        id: null,
        name: "",
        value: null,
      }),
    },
  },
  computed: {
    selectedOptionModel: {
      get() {
        return this.selectedOption;
      },
      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("change", option);
      this.selectedOptionModel = option;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-pills {
  margin-left: 10px;
  .pill {
    user-select: none;
    margin-left: 4px;
    width: 80px;
    height: 56px;
    cursor: pointer;
    padding: 16px 14px;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &.active {
      border: 1px solid #6a62ca;
      background: #fafaff;
      color: #6a62ca;
    }
  }
  @media (max-width: 576px) {
    margin-left: 0;
    .pill {
      width: 100%;
      height: 36px;
      margin-left: 0;
      font-size: 14px;
    }
  }
}
</style>
