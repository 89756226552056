<template>
  <div class="messages-list">
    <Alert
      class="mt-2 mt-sm-3"
      v-for="message in messagesList"
      @hide="hideMessage(message.id)"
      :key="message.id"
      :variant="message.variant"
      :text="message.text"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useInterfaceStore } from "@/stores/interfaceStore";
import Alert from "./Alert.vue";
export default {
  methods: {
    ...mapActions(() => useInterfaceStore(), ["hideMessage"]),
  },
  computed: {
    ...mapState(() => useInterfaceStore(), ["messagesList"]),
  },
  components: { Alert },
};
</script>

<style lang="scss" scoped>
.messages-list {
  width: 567px;
  height: auto;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 99999;
  @media (max-width: 576px) {
    width: 95%;
    right: 10px;
    left: 10px;
  }
}
</style>
