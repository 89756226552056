<template>
  <div class="change-password-account">
    <div class="d-flex align-items-center">
      <img
        width="24px"
        height="24px"
        src="@/assets/images/settings/key-icon.svg"
        alt=""
      />
      <h3 class="change-password-account__title mb-0 ms-2">
        {{ $t("settings_page.user_data.change_password.title") }}
      </h3>
    </div>
    <form @submit.prevent="changePassword" action="" class="mt-3">
      <div>
        <div class="label">
          <h6>
            {{ $t("settings_page.user_data.change_password.oldpass_label") }}
          </h6>
        </div>
        <div class="position-relative">
          <CCInput
            :placeholder="
              $t('settings_page.user_data.change_password.pass_placeholder')
            "
            v-model="oldPassword"
            :type="passwordInputs[0].type"
          ></CCInput>
          <div @click="changeTypeInput(0)" class="button-eye-password">
            <img :src="passwordInputs[0].image" alt="eye-icon" />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="label">
          <h6>
            {{ $t("settings_page.user_data.change_password.pass_label") }}
          </h6>
        </div>
        <div class="position-relative">
          <CCInput
            v-model="newPassword"
            @keyup="checkPassword"
            :type="passwordInputs[1].type"
            :placeholder="
              $t('settings_page.user_data.change_password.pass_placeholder')
            "
          ></CCInput>
          <div @click="changeTypeInput(1)" class="button-eye-password">
            <img :src="passwordInputs[1].image" alt="eye-icon" />
          </div>
        </div>
        <ValidationPassword
          class="mt-2"
          :length="passwordRules.length"
          :upper-case-letters="passwordRules.letterBig"
          :numbers="passwordRules.number"
          :lower-case-letters="passwordRules.letterSmall"
        ></ValidationPassword>
      </div>
      <div class="mt-4">
        <div class="label">
          <h6>
            {{
              $t("settings_page.user_data.change_password.confirmpass_label")
            }}
          </h6>
        </div>
        <div class="position-relative">
          <CCInput
            :placeholder="
              $t('settings_page.user_data.change_password.pass_placeholder')
            "
            v-model="passwordRepeat"
            :type="passwordInputs[2].type"
          ></CCInput>
          <div @click="changeTypeInput(2)" class="button-eye-password">
            <img :src="passwordInputs[2].image" alt="eye-icon" />
          </div>
        </div>
      </div>
      <CCButton
        :disabled="disabledBtnChangePasswords"
        class="mt-4"
        size="lg"
        fontWeight="medium"
        >{{
          $t("settings_page.user_data.change_password.btn_change")
        }}</CCButton
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import Modal from "../UI/Modal.vue";
import ValidationPassword from "../ValidationPassword.vue";
import { useSettingsStore } from "@/stores/settingsStore";
import { UserController } from "@/core/system/controllers";
import { useInterfaceStore } from "@/stores/interfaceStore";
import useValidationPassword from "@/mixins/validationPassword";
export default {
  components: { Modal, ValidationPassword },
  mixins: [useValidationPassword(useSettingsStore)],
  data: () => ({
    disabledBtnChangePasswords: false,
    passwordRules: {
      letterSmall: false,
      letterBig: false,
      number: false,
      length: false,
    },
  }),
  methods: {
    ...mapActions(useSettingsStore, ["setStatusChangePassword"]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    checkPassword() {
      // Validate lowercase letters
      let lowerCaseLetters = /[a-z]/g;
      if (this.newPassword.match(lowerCaseLetters)) {
        this.passwordRules.letterSmall = true;
      } else {
        this.passwordRules.letterSmall = false;
      }

      // Проверка заглавных букв
      let upperCaseLetters = /[A-Z]/g;
      if (this.newPassword.match(upperCaseLetters)) {
        this.passwordRules.letterBig = true;
      } else {
        this.passwordRules.letterBig = false;
      }

      // Проверка чисел
      let numbers = /[0-9]/g;
      if (this.newPassword.match(numbers)) {
        this.passwordRules.number = true;
      } else {
        this.passwordRules.number = false;
      }

      // Проверка длины
      if (this.newPassword.length >= 8) {
        this.passwordRules.length = true;
      } else {
        this.passwordRules.length = false;
      }
    },
    changePassword() {
      const payload = {
        password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirm: this.passwordRepeat,
      };
      if (this.isValidPassword) {
        if (this.isValidPasswordRepeat) {
          this.disabledBtnChangePasswords = true;
          UserController.changePassword(payload)
            .then((response) => {
              this.setStatusChangePassword("success");
              this.$bvModal.hide("change-password-account");
              this.showMessage("success", this.$t('messages.pass_change_success'));
              this.resetFields();
            })
            .catch((_error) => {
              this.showMessage("danger", this.$t('messages.pass_change_error'));
            })
            .finally(() => {
              this.disabledBtnChangePasswords = false;
            });
        } else {
          this.showMessage("danger", this.$t('messages.pass_no_match'));
        }
      } else {
        this.showMessage("danger", this.$t('messages.pass_uncorrect'));
      }
    },
    resetFields() {
      this.oldPassword = "";
      this.newPassword = "";
      this.passwordRepeat = "";
      this.passwordRules.number = false;
      this.passwordRules.length = false;
      this.passwordRules.letterBig = false;
      this.passwordRules.letterSmall = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.change-password-account {
  padding: 18px;
  border-radius: 10px;
  background: #fbfbfb;
  @media (max-width: 576px) {
    padding: 0;
    background: none;
  }
  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #5f5f5f;
  }
}
</style>
