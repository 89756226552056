<template>
  <header class="header-auth">
    <div class="d-flex align-items-center">
      <img
        class="logo-dropdown__image pe-2"
        src="@/assets/images/logo-icon.svg"
        alt="logo-icon"
      />
      <h3 class="sidebar-logotype__title m-0">
        Wallet
      </h3>
    </div>
    <LangDropdown />
  </header>
</template>

<script>
import LangDropdown from "@/components/Lang/LangDropdown.vue";

export default {
  name: "AuthHeader",
  components: { LangDropdown },
};
</script>
