<template>
  <div class="validation-check">
    <b-row align-h="between" class="gy-3" :class="{ 'gx-1': gap == 1 }">
      <b-col cols="6" xl="auto">
        <div class="d-flex align-items-center">
          <div
            class="check-block"
            :class="{
              success: upperCaseLetters,
            }"
          >
            <img
              class="check-block__marker"
              src="@/assets/images/uncastodial/marker.svg"
              alt=""
            />
          </div>
          <div class="validation-check__name ms-1">
            {{ $t("validation_pass.big_letters") }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" xl="auto">
        <div class="d-flex align-items-center">
          <div
            class="check-block"
            :class="{
              success: lowerCaseLetters,
            }"
          >
            <img
              class="check-block__marker"
              src="@/assets/images/uncastodial/marker.svg"
              alt=""
            />
          </div>
          <div class="validation-check__name ms-1">
            {{ $t("validation_pass.small_letters") }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" xl="auto">
        <div class="d-flex align-items-center">
          <div
            class="check-block"
            :class="{
              success: numbers,
            }"
          >
            <img
              class="check-block__marker"
              src="@/assets/images/uncastodial/marker.svg"
              alt=""
            />
          </div>
          <div class="validation-check__name ms-1">
            {{ $t("validation_pass.numbers") }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" xl="auto">
        <div class="d-flex align-items-center">
          <div
            class="check-block"
            :class="{
              success: length,
            }"
          >
            <img
              class="check-block__marker"
              src="@/assets/images/uncastodial/marker.svg"
              alt=""
            />
          </div>
          <div class="validation-check__name ms-1">
            {{ $t("validation_pass.symbols") }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    gap: {
      type: Number,
      required: false,
      default: 0,
    },
    upperCaseLetters: {
      type: Boolean,
      required: true,
      default: false,
    },
    lowerCaseLetters: {
      type: Boolean,
      required: true,
      default: false,
    },
    numbers: {
      type: Boolean,
      required: true,
      default: false,
    },
    length: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style></style>
