<template>
  <div class="alert-cc" :class="variant">
    <div class="alert-border"></div>
    <div class="alert-content">
      <img :src="require(`@/assets/images/alerts/${variant}-icon.svg`)" alt="" />
      <p class="content-text mb-0">{{ text }}</p>
    </div>
    <button @click="hide" class="close-btn">
      <img src="@/assets/images/close-modal.svg" alt="" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "info",
    },
    text: {
      type: String,
      default: "message",
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 576px) {
    width: 18px;
    height: 18px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.alert-cc {
  position: relative;
  border-radius: 0 !important;
  width: 100%;
  padding: 16px;
  background: #fff;
  box-shadow: 0 15px 30px -10px rgba(60, 60, 60, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    padding: 12px;
  }
  .alert-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }
  .alert-content {
    width: 90%;
    display: flex;
    align-items: center;
    .content-text {
      margin-left: 8px;
      color: #181818;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  &.info {
    .alert-border {
      background: #4a40c6;
    }
  }
  &.warning {
    .alert-border {
      background: #fca004;
    }
  }
  &.success {
    .alert-border {
      background: #23a047;
    }
  }
  &.danger {
    .alert-border {
      background: #f44336;
    }
  }
}
</style>
