<template>
  <div class="history-auth">
    <div class="title-block">
      <div class="settings-title d-flex align-items-center">
        <img src="@/assets/images/settings/history-icon.svg" alt="" />
        <h3 class="mb-0 ms-2 ms-sm-3">История авторизаций</h3>
      </div>
    </div>
    <div class="table-header">
      <b-row class="table-header__content">
        <b-col cols="4">
          <p class="table-header__item mb-0">Дата</p>
        </b-col>
        <b-col cols="8">
          <p class="table-header__item mb-0">Местополжение</p>
        </b-col>
      </b-row>
    </div>
    <div class="history-list mb-4 mb-sm-0" v-if="!isLoadingHistoryList">
      <b-row
        align-v="center"
        align-h="between"
        class="mt-3"
        v-for="auth in historyAuthList"
        :key="auth.id"
      >
        <b-col cols="4">
          <div class="history-auth__text history-auth__text--date">
            {{ formatDateTime(auth.create_at).date }}
            {{ formatDateTime(auth.create_at).time }}
          </div>
        </b-col>
        <b-col cols="8">
          <div class="history-auth__text">
            {{ auth.ip }}
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="history-list mb-4 mb-sm-0" v-else>
      <b-row
        align-v="center"
        align-h="between"
        class="mt-3"
        v-for="index in 6"
        :key="index"
      >
        <b-col cols="4">
          <div class="history-auth__text history-auth__text--date">
            <b-skeleton class="sm" width="100%" height="24px"></b-skeleton>
          </div>
        </b-col>
        <b-col cols="8">
          <div class="history-auth__text">
            <b-skeleton class="sm" width="100%" height="24px"></b-skeleton>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import userController from "@/core/system/controllers/userController";
import { formatDateTime } from "@/utils/date";

export default {
  data: () => ({
    isLoadingHistoryList: false,
    historyAuthList: [],
  }),
  methods: {
    async getHistoryAuthList() {
      this.isLoadingHistoryList = true;
      try {
        this.historyAuthList = await userController.getHistoryAuth();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingHistoryList = false;
      }
    },
    formatDateTime,
  },
  mounted() {
    this.getHistoryAuthList();
  },
};
</script>

<style lang="scss" scoped>
.history-auth {
  border-radius: 12px;
  background: #fff;
  .title-block {
    padding: 24px;
  }
  .table-header {
    &__content {
      padding: 0 24px 10px 24px;
    }
    &__item {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c3c;
    }
    border-bottom: 2px solid #f0f0f0;
  }
  .history-list {
    padding: 8px 24px 24px 24px;
  }
  @media (max-width: 576px) {
    border-radius: 0 0 12px 12px;
    .title-block {
      padding: 16px;
      padding-bottom: 18px;
    }
    .history-list {
      padding: 16px;
    }
    .table-header {
      &__content {
        padding: 0 16px 8px 16px;
      }
      &__item {
        font-size: 12px;
        font-weight: 300;
      }
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
</style>
