export const validateAndFormatNumber = (input) => {
  input = input.replace(/,/g, "."); // Заменяем все запятые на точки
  input = input.replace(/\.{2,}/g, "."); // Удаляем повторяющиеся точки
  input = input.replace(/[^0-9.]/g, ""); // Удаляем все, кроме цифр и точек
  input = input.replace(/^00+/, "0"); // Заменяем начальные нули на один ноль, если число не десятичное
  const parts = input.split(".");
  if (parts.length > 1) {
    const integerPart = parts[0].replace(/^0+/, "") || "0"; // Удаляем ведущие нули в целой части, но если число начинается с точки, ставим 0
    const decimalPart = parts[1].substring(0, 9); // Ограничиваем десятичную часть шестью знаками
    input = `${integerPart}.${decimalPart}`;
  }
  return input;
};

export const isValidNumberFormat = (input) => {
  const originalInput = input;
  const formattedInput = validateAndFormatNumber(input);
  return formattedInput === originalInput.replace(/,/g, "."); // Проверяем, что форматированное значение соответствует исходному значению с замененными запятыми на точки
};

export const validateBlockchainAddress = (address, network) => {
  if (!address) return false;
  switch (network) {
    case "TRON":
      // В TRC20 адреса начинаются на T
      return /^T[a-zA-Z\d]{33}$/.test(address);
    case "Ethereum":
      // Для ERC20
      return /^(0x)[0-9a-fA-F]{40}$/.test(address);
    case "Bitcoin":
      // Учет различных начальных символов для BTC адресов.
      return /^(1|3)[a-zA-Z\d]{25,34}|bc1[a-zA-Z0-9]{11,71}$/.test(address);
    case "Litecoin":
      // Для Litecoin, адреса обычно начинаются с L или M, но также добавим 'ltc' для учета.
      return /^(ltc1[0-9a-zA-Z]{39}|[LM][0-9a-zA-Z]{26,34})$/.test(address);
    default:
      return false;
  }
};
