<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="my-0 send-transfer__title">
        {{ $t("send_page.token_send") }}
      </h3>
      <div class="send-transfer__step d-block d-sm-none">
        {{ $t("send_page.step") }} {{ step }} из 2
      </div>
    </div>
    <SelectCurrencyWallet
      :isLoading="isLoadingWalletList"
      :options="walletsList"
      :defaultCurrency="selectedCurrency"
      :defaultWallet="selectedWallet"
      @selectCurrency="setSelectedCurrency"
      @selectWallet="setSelectedWallet"
      class="mt-3 mt-sm-4"
    />
    <TypeForm />
    <div class="send-transfer__footer d-none d-sm-block">
      <div class="send-transfer__line"></div>
      <b-row align-v="center">
        <b-col cols="6" class="color-light send-transfer__step">
          {{ $t("send_page.step") }} {{ step }} из 2
        </b-col>
        <b-col cols="6" class="ms-auto">
          <CCButton
            fontWeight="medium"
            :disabled="isLoadingWallets"
            size="lg"
            class="px-4"
            @click="changeStep"
          >
            {{ $t("send_page.btn_next") }}
          </CCButton>
        </b-col>
      </b-row>
    </div>
    <div class="d-block d-sm-none mt-4">
      <CCButton
        fontWeight="medium"
        :disabled="isLoadingWallets"
        size="md"
        class="px-4"
        @click="changeStep"
      >
        {{ $t("send_page.btn_next") }}
      </CCButton>
    </div>
  </div>
</template>

<script>
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { mapState, mapActions } from "pinia";
import { sliceNetworkCodeCurrency, getRoundedAmountCrypto } from "@/utils";
import { validateBlockchainAddress } from "@/utils/validateInput";
import { useInterfaceStore } from "@/stores/interfaceStore";
import TypeForm from "./TypeForm.vue";
import SelectCurrencyWallet from "../UI/SelectCurrencyWallet.vue";
import { useWalletStore } from "@/stores/wallet";

export default {
  name: "SendTransferStep1",
  components: {
    TypeForm,
    SelectCurrencyWallet,
  },
  props: {
    step: Number,
    selectedCurrency: Object,
    optionsCurrency: Array,
    giveCurrency: String,
  },
  data() {
    return {
      isLoadingFee: false,
    };
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "amount",
      "address",
      "selectedCurrency",
      "fee",
      "isLoadingWallets",
      "selectedWallet",
    ]),
    ...mapState(useWalletStore, [
      "walletsList",
      "isLoadingWalletList",
      "recipientBalance",
    ]),
    selectedNetwork() {
      return this.selectedWallet.network_object.full_name;
    },
    checkFields() {
      return this.amount > 0 && this.address != "";
    },
    isValidAddress() {
      return validateBlockchainAddress(
        this.address,
        this.selectedWallet.network_object.full_name
      );
    },
    checkBalance() {
      return Number(this.amount) <= Number(this.selectedCurrency.balance);
    },
    checkMinAmount() {
      if (this.selectedCurrency.code === "TRX") {
        return this.amount >= 1.1;
      } else {
        return this.amount > 0;
      }
    },
  },
  methods: {
    ...mapActions(useSendTransferStore, [
      "setAmount",
      "setAddress",
      "setStep",
      "setFee",
      "setSelectedCurrency",
      "setSelectedWallet",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    sliceNetworkCodeCurrency,
    getRoundedAmountCrypto,
    selectCurrency(value) {
      this.$emit("selectCurrency", value);
    },
    changeGiveCurrency(value) {
      this.$emit("changeGiveCurrency", value);
    },
    async changeStep() {
      if (this.checkFields) {
        if (this.checkBalance) {
          if (this.isValidAddress) {
            this.setStep(2);
          } else {
            this.showMessage("danger", this.$t("messages.uncorrect_address"));
          }
        } else {
          this.showMessage("danger", this.$t("messages.low_balance"));
        }
      } else {
        this.showMessage("warning", this.$t("messages.type_amount_address"));
      }
    },
  },
  // mounted() {
  //   this.getFeeBtc();
  // },
};
</script>

<style lang="scss" scoped></style>
