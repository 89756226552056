import { AmlController, ServiceController } from "@/core/system/controllers";
import { defineStore } from "pinia";
import { useUserStore } from "./userStore";

export const useAmlStore = defineStore("amlStore", {
  state: () => ({
    isLoadingAmlCheck: false,
    amlUuid: "",
    amlLink: "",
    checksInput: "1",
    isLoadingHistoryList: true,
    historyList: [],
    totalItemsHistory: 10,
    limitItemsHistory: 5,
    currentPageHistory: 1,
  }),
  actions: {
    setUuid(uuid) {
      this.amlUuid = uuid;
    },
    setChecksInput(text) {
      this.checksInput = text;
    },
    async amlCheck(id) {
      this.isLoadingAmlCheck = true;
      const payload = {
        wallet_id: id,
      };
      try {
        const resp = await AmlController.checkAmlActivate(payload);
        console.log(resp.uuid);
        this.setUuid(resp.uuid);
      } catch (error) {
        console.log(error);
      }
    },
    async amlCheckInfo() {
      const userStore = useUserStore();
      const payload = {
        uuid: this.amlUuid,
      };
      try {
        const resp = await AmlController.checkAmlInfo(payload);
        if (resp.result != "None") {
          this.isLoadingAmlCheck = false;
          userStore.setCurrenctUser();
          this.amlLink = resp.result;
          const link = document.createElement("a");
          link.href = resp.result;
          link.click();
        }
        return resp.result;
      } catch (error) {
        console.log(error);
      }
    },
    async loadHistoryList() {
      this.isLoadingHistoryList = true;
      const payload = {
        service_id: 1,
        limit: this.limit,
        offset: this.offset,
      };
      try {
        const response = await ServiceController.serviceOrdersList(payload);
        this.historyList = response.data;
        this.totalItemsHistory = response.total_row;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingHistoryList = false;
      }
    },
    setCurrentPage(page) {
      this.currentPageHistory = page;
      this.loadHistoryList();
    },
  },
  getters: {
    limit() {
      return this.currentPageHistory * 5;
    },
    offset: (state) => {
      return (state.currentPageHistory - 1) * state.limitItemsHistory;
    },
    totalPagesHistory: (state) => {
      return Math.ceil(state.totalItemsHistory / state.limitItemsHistory);
    },
  },
});
