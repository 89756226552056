<template>
  <div class="type-form">
    <div class="mt-4 input-block">
      <div class="label">
        <h6>{{ $t("send_page.address_label") }}</h6>
      </div>
      <div class="position-relative d-none d-sm-block">
        <div class="send-transfer__input-with-icon position-relative">
          <CCInput
            :placeholder="$t('send_page.address_placeholder')"
            @focus="showHistory"
            @input="debouncedSearch"
            class="send-address-input mt-3"
            v-model="addressSend"
          ></CCInput>
          <img
            v-if="isVisibleAddContactBtn"
            @click="addHistoryAddressToContactsList"
            class="add-contact-btn"
            src="../../assets/images/book.svg"
            alt=""
          />
        </div>
        <LastTransactions
          v-show="isVisibleHistory"
          :historyList="lastTransactionsList"
          :network="selectedNetwork"
          @select="selectAddressFromHistory"
        />
      </div>
      <div class="position-relative d-block d-sm-none">
        <div class="send-transfer__input-with-icon position-relative">
          <CCInput
            :placeholder="$t('send_page.address_placeholder')"
            @focus="showHistory"
            @input="debouncedSearch"
            class="send-address-input mt-2"
            v-model="addressSend"
            size="md"
          ></CCInput>
          <img
            v-if="isVisibleAddContactBtn"
            @click="addHistoryAddressToContactsList"
            class="add-contact-btn"
            src="../../assets/images/book.svg"
            alt=""
          />
        </div>
        <LastTransactions
          v-show="isVisibleHistory"
          :network="selectedNetwork"
          :historyList="lastTransactionsList"
          @select="selectAddressFromHistory"
        />
      </div>
    </div>
    <div class="mt-3 mt-sm-4 input-block">
      <div class="label">
        <b-row align-v="center">
          <b-col cols="auto">
            <h6 class="my-0">
              {{ $t("send_page.amount_label") }}
              {{ sliceNetworkCodeCurrency(selectedCurrency.code) }}
            </h6>
          </b-col>
        </b-row>
      </div>
      <div class="send-transfer__input-with-icon d-none d-sm-block">
        <CCInput
          :placeholder="$t('send_page.amount_placeholder')"
          @input="validateAmount"
          class="mt-3"
          v-model="amountSend"
        ></CCInput>
        <p @click="setMaxAmountToSend" class="max-icon">
          {{ $t("send_page.max") }}
        </p>
      </div>
      <div class="send-transfer__input-with-icon d-block d-sm-none">
        <CCInput
          :placeholder="$t('send_page.amount_placeholder')"
          @input="validateAmount"
          class="mt-2"
          v-model="amountSend"
          size="md"
        ></CCInput>
        <p @click="setMaxAmountToSend" class="max-icon">
          {{ $t("send_page.max") }}
        </p>
      </div>
      <div
        class="balance-block d-flex justify-content-between align-items-center mt-4"
      >
        <p class="balance-block__title mb-0">{{ $t("send_page.balance") }}:</p>
        <p class="balance-block__balance mb-0">
          {{ getRoundedAmountCrypto(selectedCurrency.balance) }}
          {{ sliceNetworkCodeCurrency(selectedCurrency.code) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { sliceNetworkCodeCurrency, getRoundedAmountCrypto } from "@/utils";
import { validateAndFormatNumber } from "@/utils/validateInput";
import LastTransactions from "./LastTransactions.vue";
import { debounce } from "@/utils/debounce";
import { WalletController } from "@/core/system/controllers";

export default {
  data: () => ({
    isVisibleHistory: false,
    isVisibleAddContactBtn: false,
  }),
  methods: {
    ...mapActions(
      () => useSendTransferStore(),
      [
        "setAddress",
        "setAmount",
        "getContactsList",
        "setStepAddressBook",
        "setAddressAddContact",
        "setNetworkAddContact",
      ]
    ),
    sliceNetworkCodeCurrency,
    getRoundedAmountCrypto,
    setMaxAmountToSend() {
      this.setAmount(this.selectedCurrency.balance);
    },
    validateAmount() {
      this.setAmount(validateAndFormatNumber(this.amountSend));
    },
    showHistory() {
      if (!this.lastTransactionsList.length) return;
      this.isVisibleHistory = true;
    },
    hideHistory() {
      document.addEventListener("click", (evt) => {
        if (
          !evt.target.closest(".last-transactions") &&
          !evt.target.closest(".send-address-input")
        ) {
          this.isVisibleHistory = false;
        }
      });
    },
    selectAddressFromHistory(address) {
      this.setAddress(address);
      this.searchAddressInAddressBook();
      this.isVisibleHistory = false;
    },
    async searchAddressInAddressBook() {
      const payload = {
        limit: 10000000,
        offset: 0,
        q: this.addressSend,
      };
      const response = await WalletController.getAddressBookList(payload);
      if (response.length <= 0) {
        this.isVisibleAddContactBtn = true;
      } else {
        this.isVisibleAddContactBtn = false;
      }
    },
    async addHistoryAddressToContactsList() {
      await this.setStepAddressBook("add");
      const addressBook = document.querySelector(".address-book__add-address");
      // console.log(addressBook);
      addressBook.scrollIntoView();
      this.setAddressAddContact(this.addressSend);
      this.setNetworkAddContact(this.selectedWallet.network_object.code);
    },
  },
  computed: {
    ...mapState(
      () => useSendTransferStore(),
      [
        "address",
        "amount",
        "selectedCurrency",
        "lastTransactionsList",
        "selectedWallet",
      ]
    ),
    selectedNetwork() {
      return this.selectedWallet.network_object.full_name;
    },
    addressSend: {
      get() {
        return this.address;
      },
      set(value) {
        this.setAddress(value);
      },
    },
    amountSend: {
      get() {
        return this.amount;
      },
      set(value) {
        this.setAmount(value);
      },
    },
  },
  components: { LastTransactions },
  mounted() {
    this.hideHistory();
  },
  created() {
    this.debouncedSearch = debounce(() => {
      this.searchAddressInAddressBook();
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.max-icon {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 16px;
  color: #4a40c6;
  font-size: 16px;
  font-weight: 600;
  line-height: 12px;
  @media (max-width: 576px) {
    top: 19px;
    font-size: 14px;
  }
}

.add-contact-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 28px;
  right: 15px;
  background: none;
  border: none;
  color: #4a40c6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.42px;
  @media (max-width: 576px) {
    top: 25px;
    right: 12px;
  }
}

.balance-block {
  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #3c3c3c;
  }
  &__balance {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #3c3c3c;
  }
}
</style>
