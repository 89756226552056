import Api from "../../api/BaseAPI";

class StakingController {
  constructor() {
    this.api = new Api();
  }
  async getBalanceAll(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/staking/balance/all`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new StakingController();
