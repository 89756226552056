<template>
  <div>
    <div class="search d-none d-lg-block" v-if="!isLoading">
      <img class="search-icon" src="@/assets/images/search2.svg" alt="" />
      <input
        v-model="searchQuery"
        @input="searchTransaction"
        placeholder="Поиск операции"
        class="search-input"
        type="text"
      />
      <slot name="calendar"> </slot>
    </div>
    <div class="search d-none d-lg-block" v-else>
      <b-skeleton class="sm" width="100%" height="32px"></b-skeleton>
    </div>
    <div class="search d-block d-lg-none" v-if="!isLoading">
      <img class="search-icon" src="@/assets/images/search2.svg" alt="" />
      <CCInput
        v-model="searchQuery"
        @input="searchTransaction"
        placeholder="Поиск"
        class="send-address-input mt-2"
        size="md"
      ></CCInput>
    </div>
    <div class="search d-block d-lg-none" v-else>
      <b-skeleton class="sm" width="100%" height="50px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";
import { debounce } from "@/utils/debounce";

export default {
  components: { Calendar },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchQuery: "",
  }),
  methods: {
    searchTransaction() {
      this.debouncedEmitSearch();
    },
  },
  created() {
    this.debouncedEmitSearch = debounce(() => {
      this.$emit("search", this.searchQuery);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  .search-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    left: 8px;
    @media (max-width: 992px) {
      width: 14px;
      height: 14px;
      left: inherit;
      right: 12px;
      top: 18px;
    }
  }
  .calendar-block {
    cursor: pointer;
    padding: 8px;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #f0f0f0;
  }
  .search-input {
    width: 100%;
    height: 32px;
    padding-left: 30px;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    background: #f9f9f9;
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    &:focus {
      outline: none;
    }
  }
}
</style>
