<template>
  <div id="energy_type_form">
    <CCCard padding="card24 w-100" class="d-none d-sm-block">
      <form @submit.prevent="calcTrxForEnergyRent" action="">
        <h3 class="my-0 type-form__title">{{ $t("energy_page.title") }}</h3>
        <div class="d-flex align-items-end">
          <div class="mt-4 input-block energy">
            <div class="label">
              <h6>{{ $t("energy_page.amount_label") }}</h6>
            </div>
            <div class="send-transfer__input-with-icon">
              <CCInput
                @input="validateAmount"
                @blur="checkAmount"
                v-model="amountInput"
                :placeholder="$t('energy_page.amount_placeholder')"
                class="mt-3"
              ></CCInput>
              <img width="9" src="@/assets/images/lightning.svg" />
            </div>
          </div>
          <SelectPills
            v-for="pill in energyPillsAmount"
            :key="pill.id"
            :countPill="pill"
            :selectedOption="selectedPillAmount"
            @change="setAmountFromPills(pill)"
          />
        </div>
        <div class="d-flex align-items-end">
          <div class="mt-4 input-block energy">
            <div class="label">
              <b-row align-v="center">
                <b-col cols="auto">
                  <h6 class="my-0">{{ $t("energy_page.time_label") }}</h6>
                </b-col>
              </b-row>
            </div>
            <div class="send-transfer__input-with-icon">
              <CCInput
                readonly
                v-model="timeInput"
                @input="validateTime"
                :placeholder="$t('energy_page.time_placeholder')"
                class="mt-3"
              ></CCInput>
              <img width="14" src="@/assets/images/clock.svg" />
            </div>
          </div>
          <SelectPills
            v-for="pill in energyPillsTime"
            :key="pill.id"
            :countPill="pill"
            :selectedOption="selectedPillTime"
            @change="setTimeFromPills(pill)"
          />
        </div>
        <div class="send-transfer__footer">
          <div class="send-transfer__line"></div>
          <b-row align-v="center">
            <b-col cols="6" class="color-light send-transfer__step">
              {{ $t("energy_page.step") }} {{ step }} из 3
            </b-col>
            <b-col cols="6" class="ms-auto">
              <CCButton
                :disabled="isLoadingCalcAmount || isLoadingEnergyPage"
                fontWeight="medium"
                size="lg"
                class="px-4"
              >
                {{ $t("energy_page.btn_next") }}
              </CCButton>
            </b-col>
          </b-row>
        </div>
      </form>
    </CCCard>
    <div class="d-block d-sm-none">
      <form @submit.prevent="calcTrxForEnergyRent" action="">
        <div class="d-flex justify-content-between">
          <h3 class="my-0 type-form__title">{{ $t("energy_page.title") }}</h3>
          <div class="send-transfer__step">
            {{ $t("energy_page.step") }} {{ step }} из 3
          </div>
        </div>
        <div class="mt-4 input-block energy">
          <div class="label">
            <h6>{{ $t("energy_page.amount_label") }}</h6>
          </div>
          <div class="send-transfer__input-with-icon">
            <CCInput
              @input="validateAmount"
              @blur="checkAmount"
              v-model="amountInput"
              :placeholder="$t('energy_page.amount_placeholder')"
              size="md"
              class="mt-2"
            ></CCInput>
            <img width="9" src="@/assets/images/lightning.svg" />
          </div>
        </div>
        <b-row class="mt-2">
          <b-col
            cols="6"
            v-for="pill in energyPillsAmount"
            :key="pill.id"
            :class="{
              'pe-1': pill.id === 1,
              'ps-1': pill.id === 2,
            }"
          >
            <SelectPills
              :countPill="pill"
              :selectedOption="selectedPillAmount"
              @change="setAmountFromPills(pill)"
            />
          </b-col>
        </b-row>
        <div class="mt-4 input-block energy">
          <div class="label">
            <b-row align-v="center">
              <b-col cols="auto">
                <h6 class="my-0">{{ $t("energy_page.time_label") }}</h6>
              </b-col>
            </b-row>
          </div>
          <div class="send-transfer__input-with-icon">
            <CCInput
              readonly
              v-model="timeInput"
              @input="validateTime"
              :placeholder="$t('energy_page.time_placeholder')"
              class="mt-2"
              size="md"
            ></CCInput>
            <img width="14" src="@/assets/images/clock.svg" />
          </div>
        </div>
        <b-row class="mt-2">
          <b-col
            cols="6"
            v-for="pill in energyPillsTime"
            :key="pill.id"
            :class="{
              'pe-1': pill.id === 1,
              'ps-1': pill.id === 2,
            }"
          >
            <SelectPills
              :countPill="pill"
              :selectedOption="selectedPillTime"
              @change="setTimeFromPills(pill)"
            />
          </b-col>
        </b-row>
        <div class="mt-4">
          <CCButton
            :disabled="isLoadingCalcAmount || isLoadingEnergyPage"
            fontWeight="medium"
            size="md"
            class="px-4"
          >
            {{ $t("energy_page.btn_next") }}
          </CCButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ServiceController } from "@/core/system/controllers";
import { useEnergyStore } from "@/stores/energyStore";
import { validateAndFormatNumber } from "@/utils/validateInput";

import { mapState, mapActions } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { useInterfaceStore } from "@/stores/interfaceStore";
import SelectPills from "../UI/SelectPills.vue";
import { useStakingStore } from "@/stores/stakingStore";
import { useWalletStore } from "@/stores/wallet";

export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    isLoadingCalcAmount: false,
  }),
  methods: {
    ...mapActions(useEnergyStore, [
      "setStepEnergy",
      "setSelectedPillAmount",
      "setSelectedPillTime",
      "setAddress",
      "setTotalAmount",
      "setCountEnergy",
      "setQuantityInput",
      "setDurationInput",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    validateAmount() {
      this.amountInput = validateAndFormatNumber(this.amountInput);
    },
    validateTime() {
      this.timeInput = validateAndFormatNumber(this.timeInput);
    },
    setAmountFromPills(pill) {
      this.setSelectedPillAmount(pill);
      this.amountInput = pill.value;
    },
    setTimeFromPills(pill) {
      this.setSelectedPillTime(pill);
      this.timeInput = pill.valueClient;
    },
    checkAmount() {
      if (this.amountInput != "") {
        if (Number(this.amountInput) < 32000) {
          this.amountInput = "32000";
        }
        if (Number(this.amountInput) > 1000000) {
          this.amountInput = "1000000";
        }
      }
    },
    async calcTrxForEnergyRent() {
      this.isLoadingCalcAmount = true;
      const payload = {
        service_id: 2,
        count: Number(this.amountInput),
        duration: this.selectedPillTime.valueServer,
      };
      try {
        const resp = await ServiceController.calculationAmount(payload);
        this.setAddress(resp.address);
        this.setTotalAmount(resp.calculation_amount);
        this.setCountEnergy(Number(this.amountInput));
        this.setStepEnergy(2);
      } catch (error) {
        console.log(error);
        this.showMessage("danger", this.$t("messages.error_global"));
      } finally {
        this.isLoadingCalcAmount = false;
      }
    },
  },
  computed: {
    ...mapState(useEnergyStore, [
      "address",
      "energyPillsAmount",
      "energyPillsTime",
      "selectedPillAmount",
      "selectedPillTime",
      "isLoadingEnergyPage",
      "quantityInput",
      "durationInput",
    ]),
    ...mapState(useSendTransferStore, ["selectedCurrency"]),
    currencyTrx() {
      return JSON.parse(localStorage.getItem("tron_wallet"));
    },
    timeInput: {
      get() {
        return this.durationInput;
      },
      set(value) {
        this.setDurationInput(value);
      },
    },
    amountInput: {
      get() {
        return this.quantityInput;
      },
      set(value) {
        this.setQuantityInput(value);
      },
    },
  },
  components: { SelectPills },
};
</script>

<style lang="scss" scoped>
.input-block {
  &.energy {
    width: 65%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
.type-form__title {
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.2px;
  }
}
</style>
