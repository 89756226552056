<template>
  <div class="storage-account position-relative">
    <CCCard class="h-100" padding="card24">
      <b-row align-v="center">
        <b-col xl="12">
          <b-row class="gx-0" align-v="center">
            <b-col cols="12">
              <h3 class="m-0 storage-account__title">
                Храните и копите криптовалюту
              </h3>
            </b-col>
            <b-col cols="12" md="6">
              <p class="storage-account__text py=0 mt-2 mt-md-3 mb-0">
                Счет в кошельке надежно защитит ваши сбережения
              </p>
            </b-col>
          </b-row>

          <div
            class="d-flex flex-column flex-md-row align-items-md-center mt-4 mt-lg-3"
          >
            <div @click="createWallet('default')">
              <CCButton
                class="storage-account__button d-flex align-items-center d-none d-lg-block"
                variant="primary"
                fontWeight="medium"
                size="lg"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="@/assets/images/wallet-icon-white.svg" alt="" />
                  <span class="ms-1">Открыть счет</span>
                </div>
              </CCButton>
              <CCButton
                class="storage-account__button d-flex align-items-center d-block d-lg-none"
                variant="primary"
                fontWeight="medium"
                size="md"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="@/assets/images/wallet-icon-white.svg" alt="" />
                  <span class="ms-1">Открыть счет</span>
                </div>
              </CCButton>
            </div>
            <div @click="createWallet('hold')">
              <CCButton
                class="storage-account__button hold d-flex align-items-center d-none d-lg-block"
                variant="secondary"
                fontWeight="medium"
                size="lg"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Wallet">
                      <g id="Group 2085663084">
                        <path
                          id="Icon"
                          class="btn-icon"
                          d="M9.32421 10.5756C9.26052 10.3999 9.1442 10.2467 8.99022 10.1358C8.83624 10.0249 8.65166 9.96139 8.46021 9.95335H7.18021C6.92561 9.95335 6.68143 10.0517 6.50139 10.2267C6.32136 10.4018 6.22021 10.6392 6.22021 10.8867C6.22021 11.1342 6.32136 11.3716 6.50139 11.5467C6.68143 11.7217 6.92561 11.82 7.18021 11.82H8.46021C8.71482 11.82 8.959 11.9184 9.13904 12.0934C9.31907 12.2684 9.42021 12.5058 9.42021 12.7534C9.42021 13.0009 9.31907 13.2383 9.13904 13.4133C8.959 13.5884 8.71482 13.6867 8.46021 13.6867H7.18021C6.98877 13.6786 6.80419 13.6151 6.65021 13.5042C6.49623 13.3933 6.37991 13.2402 6.31622 13.0645M7.82021 9.02002V9.95335M7.82021 13.6867V14.62"
                          stroke=""
                          stroke-width="0.954545"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Icon_2"
                          d="M6.97976 6.81818C7.5449 4.62244 9.53812 3 11.9103 3C14.7219 3 17.0012 5.27928 17.0012 8.09091C17.0012 10.3325 15.5524 12.2357 13.5401 12.9153M13.1818 11.9091C13.1818 14.7207 10.9025 17 8.09091 17C5.27928 17 3 14.7207 3 11.9091C3 9.09746 5.27928 6.81818 8.09091 6.81818C10.9025 6.81818 13.1818 9.09746 13.1818 11.9091Z"
                          stroke=""
                          class="btn-icon"
                          stroke-width="0.954545"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Создать копилку</span>
                </div>
              </CCButton>
              <CCButton
                class="storage-account__button hold d-flex align-items-center d-block d-lg-none"
                variant="secondary"
                fontWeight="medium"
                size="md"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Wallet">
                      <g id="Group 2085663084">
                        <path
                          id="Icon"
                          class="btn-icon"
                          d="M9.32421 10.5756C9.26052 10.3999 9.1442 10.2467 8.99022 10.1358C8.83624 10.0249 8.65166 9.96139 8.46021 9.95335H7.18021C6.92561 9.95335 6.68143 10.0517 6.50139 10.2267C6.32136 10.4018 6.22021 10.6392 6.22021 10.8867C6.22021 11.1342 6.32136 11.3716 6.50139 11.5467C6.68143 11.7217 6.92561 11.82 7.18021 11.82H8.46021C8.71482 11.82 8.959 11.9184 9.13904 12.0934C9.31907 12.2684 9.42021 12.5058 9.42021 12.7534C9.42021 13.0009 9.31907 13.2383 9.13904 13.4133C8.959 13.5884 8.71482 13.6867 8.46021 13.6867H7.18021C6.98877 13.6786 6.80419 13.6151 6.65021 13.5042C6.49623 13.3933 6.37991 13.2402 6.31622 13.0645M7.82021 9.02002V9.95335M7.82021 13.6867V14.62"
                          stroke=""
                          stroke-width="0.954545"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Icon_2"
                          d="M6.97976 6.81818C7.5449 4.62244 9.53812 3 11.9103 3C14.7219 3 17.0012 5.27928 17.0012 8.09091C17.0012 10.3325 15.5524 12.2357 13.5401 12.9153M13.1818 11.9091C13.1818 14.7207 10.9025 17 8.09091 17C5.27928 17 3 14.7207 3 11.9091C3 9.09746 5.27928 6.81818 8.09091 6.81818C10.9025 6.81818 13.1818 9.09746 13.1818 11.9091Z"
                          stroke=""
                          class="btn-icon"
                          stroke-width="0.954545"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Создать копилку</span>
                </div>
              </CCButton>
            </div>
          </div>
          <img class="safe-img" src="@/assets/images/safe.png" alt="" />
        </b-col>
      </b-row>
    </CCCard>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import ButtonMobile from "../UI/ButtonMobile.vue";
import { useWalletStore } from "@/stores/wallet";

export default {
  name: "StorageAccountCard",
  components: {
    ButtonMobile,
  },
  props: {
    variant: {
      type: String,
      default: "lg",
    },
  },
  methods: {
    createWallet(type) {
      localStorage.setItem("typeCreatedWallet", type);
      this.$router.push("/create-wallet");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 24px;
  height: 24px;
  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
}
.safe-img {
  width: 202px;
  position: absolute;
  top: 3px;
  right: 0;
  @media (max-width: 1400px) {
    display: none;
  }
}

.storage-account {
  .card-main {
    @media (min-width: 768px) {
      padding-right: 40px !important;
    }
  }
  &__image {
    width: 100px;
    border-radius: 10px;
    border: 1px solid var(--Stroke, #f0f0f0);
    img {
      width: 100%;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
  }
  &__text {
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
  &__button {
    width: 180px !important;
    @media (max-width: 1399px) {
      width: 194px !important;
    }
    @media (max-width: 576px) {
      width: 100% !important;
    }
    &.hold {
      margin-left: 12px;
      @media (max-width: 576px) {
        margin-left: 0;
        margin-top: 12px;
      }
    }
    .btn-icon {
      stroke: #4a40c6;
    }
  }
}

@media (max-width: 767px) {
  .storage-account {
    &__title {
      font-size: 18px;
    }
    &__text {
      font-size: 14px;
    }
    &__currency-list {
      img {
        width: 18px;
        height: 18px;
      }
    }
    &__buttons {
      .button-mobile:last-child {
        margin-left: 36px;
      }
    }
    &__balance {
      font-size: 24px;
    }
    &__currency {
      font-size: 10px;
    }
    &__image {
    }
  }
}

@media (max-width: 780px) {
  .storage-account {
    &__currency-list {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media (max-width: 767px) {
  .storage-account {
    &__inner {
      width: 100%;
    }
  }
}
</style>
