<template>
  <div @blur="handleOutsideClick" tabindex="1" class="lang-dropdown">
    <div
      @click.stop="changeState"
      class="lang-dropdown__button d-flex align-items-center"
    >
      <span class="lang-dropdown__lang-title">{{ lang }}</span>
      <div class="ms-2 arrow-down" :class="{ open: isOpen === true }">
        <img src="@/assets/images/arrow-icon.svg" alt="arrow-icon" />
      </div>
    </div>
    <div v-if="isOpen" @click.stop class="lang-dropdown__content">
      <div class="lang-dropdown__triangle"></div>
      <b-row class="gy-2">
        <b-col cols="12">
          <div
            @click.stop="switchLang('EN')"
            class="d-flex align-items-center lang-dropdown__item"
          >
            <img
              src="@/assets/images/langDropdown/en-icon.svg"
              alt="lang-icon"
            />
            <span class="lang-dropdown__lang ms-2">EN</span>
          </div>
        </b-col>
        <b-col cols="12">
          <div
            @click.stop="switchLang('RU')"
            class="d-flex align-items-center lang-dropdown__item"
          >
            <img
              src="@/assets/images/langDropdown/ru-icon.svg"
              alt="lang-icon"
            />
            <span class="lang-dropdown__lang ms-2">RU</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import dropdownMixin from "@/mixins/dropdown";

export default {
  name: "LangDropdown",
  mixins: [dropdownMixin],
  data() {
    return {
      lang: "RU",
    };
  },
  methods: {
    switchLang(lang) {
      this.isOpen = false;
      if (lang) {
        this.lang = lang;
        this.$i18n.locale = lang.toLowerCase();
      }
    },
  },
};
</script>
