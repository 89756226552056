<template>
  <div class="wait-email">
    <b-row class="mx-auto">
      <b-col cols="auto" class="wait-email__picture" />
      <b-col cols="5" class="wait-email__part-right">
        <HeaderAuth />
        <div class="wait-email-form" action="#">
          <CCCard padding="card24">
            <!-- title form -->
            <h2 class="wait-email__title m-0">
              {{ $t("confirm_email_page.title") }}
            </h2>
            <p class="p-0 wait-email__text-1">
              {{ $t("confirm_email_page.text1") }}
            </p>
            <span class="wait-email__email">{{ email }}</span>
            <a @click="changePassword" class="link pointer">
              <img src="@/assets/images/email-icon.svg" alt="email-icon" />
              <span class="ms-2">{{
                $t("confirm_email_page.change_email")
              }}</span>
            </a>
            <p class="p-0 wait-email__text-2">
              {{ $t("confirm_email_page.text2") }}
            </p>
            <!-- buttons -->
            <b-row class="gy-3">
              <b-col sm="6">
                <CCButton
                  class="wait-email__submit"
                  fontWeight="bolder"
                  size="lg"
                  @click="checkUser()"
                >
                  {{ $t("confirm_email_page.btn_check") }}
                </CCButton>
              </b-col>
              <b-col sm="6">
                <CCButton
                  class="wait-email__submit"
                  variant="secondary"
                  fontWeight="bolder"
                  size="lg"
                >
                  {{ $t("confirm_email_page.btn_send_again") }}
                </CCButton>
              </b-col>
            </b-row>
          </CCCard>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderAuth from "@/components/Headers/HeaderAuth.vue";
import { UserController } from "@/core/system/controllers";

export default {
  name: "WaitEmail",
  components: { HeaderAuth },
  data() {
    return {
      email: "",
      interval: null,
    };
  },
  methods: {
    checkUser() {
      UserController.getCurrentUser()
        .then((result) => {
          this.email = result.email;
          if (result.is_email_confirming === true) {
            this.$router.push("/");
            clearInterval(this.interval);
          }
        })
        .catch((result) => {
          console.log("Error", result);
        });
    },
    changePassword() {
      localStorage.removeItem("token");
      this.$router.push("/registration");
    },
  },
  mounted() {
    this.checkUser();
    this.interval = setInterval(this.checkUser, 3000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
