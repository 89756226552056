<template>
  <div class="create-password">
    <CCCard padding="card24">
      <div class="d-flex justify-content-between">
        <h2
          class="create-password__title mb-0"
          v-html="$t('create_wallet_page.create_password.title')"
        ></h2>
        <img
          @click="setStepBack"
          class="pointer"
          width="24px"
          height="24px"
          src="@/assets/images/back-icon.svg"
          alt=""
        />
      </div>
      <div class="warning d-flex mt-4">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.create_password.text1") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          width="24px"
          height="24px"
          class="warning-icon"
          src="@/assets/images/uncastodial/guard-icon.svg"
          alt=""
        />
        <p
          class="warning-text mb-0 ms-2"
          v-html="$t('create_wallet_page.create_password.text2')"
        ></p>
      </div>
      <div class="input-block mt-4">
        <div class="label">
          <h6>
            {{ $t("create_wallet_page.create_password.create_pass_label") }}
          </h6>
        </div>
        <div class="position-relative">
          <CCInput
            v-model="newPassword"
            @keyup="checkPassword"
            :placeholder="
              $t('create_wallet_page.create_password.create_pass_placeholder')
            "
            :type="passwordInputs[0].type"
            class="input-with-icon"
          />
          <div @click="changeTypeInput(0)" class="button-eye-password">
            <img :src="passwordInputs[0].image" alt="eye-icon" />
          </div>
        </div>
      </div>
      <ValidationPassword
        class="mt-2"
        :gap="1"
        :length="passwordRules.length"
        :upper-case-letters="passwordRules.letterBig"
        :numbers="passwordRules.number"
        :lower-case-letters="passwordRules.letterSmall"
      />
      <div class="mt-4">
        <div class="input-block">
          <div class="label">
            <h6>
              {{ $t("create_wallet_page.create_password.repeat_pass_label") }}
            </h6>
          </div>
          <div class="position-relative">
            <CCInput
              :placeholder="
                $t('create_wallet_page.create_password.repeat_pass_placeholder')
              "
              v-model="passwordRepeat"
              :type="passwordInputs[1].type"
              class="input-with-icon"
            />
            <img
              class="input-icon"
              v-show="isValidPasswordRepeat"
              style="cursor: auto"
              src="@/assets/images/uncastodial/guard-icon.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <CCButton
        :disabled="disabledBtn || isLoadingCreateWallet"
        @click="createWallet"
        size="lg"
        class="mt-4"
        >{{ $t("create_wallet_page.create_password.btn_next") }}</CCButton
      >
    </CCCard>
  </div>
</template>

<script>
import ValidationPassword from "@/components/ValidationPassword.vue";
import useValidationPassword from "@/mixins/validationPassword";
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import { CreateTronWallet } from "@/wallets/Tron";
import { CreateEthereumWallet } from "@/wallets/Ethereum";
import { CreateLitecoinWallet } from "@/wallets/Litecoin";
import { CreateBitcoinWallet } from "@/wallets/Bitcoin";
import { WalletController } from "@/core/system/controllers";
import { useInterfaceStore } from "@/stores/interfaceStore";

export default {
  components: { ValidationPassword },
  mixins: [useValidationPassword()],
  data: () => ({
    isLoadingCreateWallet: false,
  }),
  methods: {
    ...mapActions(useWalletStore, [
      "setAesString",
      "setStep",
      "setStepBack",
      "setAddressString",
      "setIdCreatedWallet",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    async createWallet() {
      let createWallet;
      switch (this.selectedNetwork.fullname) {
        case "TRON":
          createWallet = new CreateTronWallet(this.newPassword);
          break;
        case "Ethereum":
          createWallet = new CreateEthereumWallet(this.newPassword);
          break;
        case "Litecoin":
          createWallet = new CreateLitecoinWallet(this.newPassword);
          break;
        case "Bitcoin":
          createWallet = new CreateBitcoinWallet(this.newPassword);
          break;

        default:
          break;
      }
      const encrypted = await createWallet.create();
      this.setAesString(encrypted.encrypted);
      this.setAddressString(encrypted.address);
      const payload = {
        address: encrypted.address,
        network: this.selectedNetwork.id,
        is_piggy_bank: this.typeWallet === "hold",
      };
      this.isLoadingCreateWallet = true;
      try {
        const response = await WalletController.createWallet(payload);
        this.setIdCreatedWallet(response[0].id);
        this.setStep(3);
      } catch (error) {
        this.showMessage("danger", "Ошибка создания кошелька");
      } finally {
        this.isLoadingCreateWallet = false;
      }
    },
  },
  computed: {
    ...mapState(useWalletStore, ["selectedNetwork", "typeWallet"]),
    disabledBtn() {
      return !this.isValidPassword || !this.isValidPasswordRepeat;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-password__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  color: #3c3c3c;
}
.input-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
