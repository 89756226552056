import { BuyController, WalletController } from "@/core/system/controllers";
import { defineStore } from "pinia";

export const useBuyStore = defineStore("buy", {
  state: () => ({
    step: 1,
    codeWalletToBuy: null,
    isLoadingCurrencyList: false,
    isLoadingOperatorsList: false,
    typeBuy: "",
    status: "start",
    uuid: "",
    payLink: "",
    fiatCurrencyList: [],
    selectedFiatCurrency: {
      id: null,
      code: "",
      icon: "",
      is_stablecoin: null,
      is_popular: null,
      name: "",
      full_name: "",
      network: "",
      full_network: "",
    },
    cryptoCurrencyList: [],
    selectedCryptoCurrency: {
      id: null,
      code: "",
      icon: "",
      is_stablecoin: null,
      is_popular: null,
      name: "",
      full_name: "",
      network: "",
      full_network: "",
    },
    operatorsList: [],
    selectedOperator: {
      id: null,
      name: "",
      icon: "",
      amount_from: null,
      amount_to: null,
      currency_from: "",
      currency_to: "",
      min_summ_exchange: null,
      is_valid_min: null,
    },
    walletsReceiveList: [],
    selectedWalletReceive: {
      id: null,
      address: "",
    },
  }),
  actions: {
    setStep(step) {
      this.step = step;
    },
    setCodeWalletToBuy(code) {
      this.codeWalletToBuy = code;
    },
    setStepBack() {
      this.step -= 1;
    },
    setStatus(status) {
      this.status = status;
    },
    setTypeBuy(type) {
      this.typeBuy = type;
    },
    setSelectedFiatCurrency(currency) {
      this.selectedFiatCurrency = currency;
    },
    setSelectedCryptoCurrency(currency) {
      this.selectedCryptoCurrency = currency;
    },
    setSelectedOperator(operator) {
      this.selectedOperator = operator;
    },
    setSelectedWalletReceive(wallet) {
      this.selectedWalletReceive = wallet;
    },
    async fetchCurrencyList() {
      this.isLoadingCurrencyList = true;
      try {
        const list = await BuyController.getCurrencyList();
        this.fiatCurrencyList = list.fiat;
        this.cryptoCurrencyList = list.crypto;
        if (this.codeWalletToBuy != null) {
          const selectedCurrencyToBuy = this.cryptoCurrencyList.find(
            (currency) => currency.code === this.codeWalletToBuy
          );
          this.setSelectedCryptoCurrency(selectedCurrencyToBuy);
        } else {
          this.setSelectedCryptoCurrency(this.cryptoCurrencyList[1]);
        }
        this.setSelectedFiatCurrency(this.fiatCurrencyList[0]);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingCurrencyList = false;
      }
    },
    async fetchProfitAmount(amount) {
      const payload = {
        type: this.typeBuy,
        from_currency: this.selectedFiatCurrency.code,
        to_currency: this.selectedCryptoCurrency.code,
        amount: amount,
      };
      try {
        const rate = await BuyController.getProfitAmount(payload);
        return rate;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchOperatorsList(amount) {
      const payload = {
        type: this.typeBuy,
        from_currency: this.selectedFiatCurrency.code,
        to_currency: this.selectedCryptoCurrency.code,
        amount: amount,
      };
      this.isLoadingOperatorsList = true;
      try {
        const operators = await BuyController.getOperatorsList(payload);
        this.operatorsList = operators;
        this.setStep(2);
        this.setSelectedOperator(this.operatorsList[0]);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingOperatorsList = false;
      }
    },
    async fetchAvailableWallets() {
      const currencyTo = {
        currency: this.selectedCryptoCurrency.code,
      };
      try {
        const walletsReceive = await WalletController.getSelectedBlockchain(
          currencyTo
        );
        this.walletsReceiveList = walletsReceive.map((wallet) => {
          return {
            id: wallet.id,
            address: wallet.address,
            nickname: wallet.nickname,
          };
        });
        this.setSelectedWalletReceive(this.walletsReceiveList[0]);
      } catch (error) {
        console.log(error);
      }
    },
    async createTransactionBuy(amount) {
      const payload = {
        type: this.typeBuy,
        from_currency: this.selectedFiatCurrency.code,
        to_currency: this.selectedCryptoCurrency.code,
        amount: amount,
      };
      try {
        const resp = await BuyController.createBuy(payload);
        this.uuid = resp.uuid;
      } catch (error) {
        console.log(error);
      }
    },
    async changeTransactionBuy() {
      const payload = {
        address: this.selectedWalletReceive.address,
        uuid: this.uuid,
      };
      try {
        const resp = await BuyController.changeBuy(payload);
        if (resp === "ok") {
          this.checkStatusBuy();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkStatusBuy() {
      const payload = {
        uuid: this.uuid,
      };
      try {
        const resp = await BuyController.getStatusBuy(payload);
        this.status = resp.status;
        this.payLink = resp.pay_url;
      } catch (error) {
        console.log(error);
      }
    },
    restartTransaction() {
      this.step = 1;
    },
  },
  // getters: {
  //   shortFromCurrency: (state) => {
  //     return state.selectedCurrencyGive.code.split("-")[0];
  //   },
  //   selectedBlockchain: (state) => {
  //     return state.selectedCurrencyGive.full_network === "Tron"
  //       ? "TRON"
  //       : state.selectedCurrencyGive.full_network;
  //   },
  //   filteredGiveCurrency: (state) => {
  //     return state.listCurrencyGive.filter(
  //       (currency) => currency.code != state.selectedCurrencyReceive.code
  //     );
  //   },
  //   filteredReceiveCurrency: (state) => {
  //     return state.listCurrencyReceive.filter(
  //       (currency) => currency.code != state.selectedCurrencyGive.code
  //     );
  //   },
  // },
});
