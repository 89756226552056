<template>
  <div @click.stop="changeState" tabindex="1" class="user-dropdown">
    <div class="user-dropdown__button d-flex align-items-center">
      <b-avatar
        :text="user.email.slice(0, 1)"
        class="user-dropdown__avatar header-user-icon"
      ></b-avatar>
      <div class="ms-2 arrow-down" :class="{ open: isOpen === true }">
        <img src="@/assets/images/arrow-icon.svg" alt="arrow-icon" />
      </div>
    </div>
    <div v-show="isOpen" @click.stop class="user-dropdown__content">
      <div class="user-dropdown__triangle"></div>
      <b-row class="gy-3">
        <b-col cols="12" class="d-flex">
          <b-avatar
            :text="user.email.slice(0, 1)"
            class="user-dropdown__avatar header-user-icon"
          ></b-avatar>
          <span class="ms-2 user-dropdown__email">{{ user.email }}</span>
        </b-col>
        <b-col @click="handleOutsideClick" cols="12">
          <router-link to="/settings" class="link">
            <img
              src="@/assets/images/userDropdown/setting-icon.svg"
              alt="setting-icon"
            />
            <span class="ms-2">{{ $t("user_dropdown.settings") }}</span>
          </router-link>
        </b-col>
        <b-col cols="12">
          <a @click="logout()" class="link link--exit pointer">
            <img
              src="@/assets/images/userDropdown/exit-icon.svg"
              alt="setting-icon"
            />
            <span class="ms-2">{{ $t("user_dropdown.logout") }}</span>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { UserController } from "@/core/system/controllers";
import dropdownMixin from "@/mixins/dropdown";
import { useUserStore } from "@/stores/userStore";

import { mapState, mapActions } from "pinia";
export default {
  name: "UserDropdown",
  mixins: [dropdownMixin],
  computed: {
    ...mapState(useUserStore, ["user"]),
  },
  methods: {
    logout() {
      this.handleOutsideClick();
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
  mounted() {
    document.addEventListener("click", (evt) => {
      if (!evt.target.closest(".user-dropdown")) this.handleOutsideClick();
    });
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
