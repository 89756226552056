<template>
  <a @click.prevent="click" href="#" class="link link-back">
    <span class="d-none d-xl-inline">{{ $t("btn_back") }}</span>
    <img
      class="ms-2"
      src="@/assets/images/back-page-icon.png"
      alt="back-page-icon"
    />
  </a>
</template>

<script>
export default {
  name: "LinkBackPage",
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  height: fit-content;
}
</style>
