import BaseEntity from "./baseEntity";
import NetworkEntity from "./networkEntity";

class CurrencyNetworkEntity extends BaseEntity {
  constructor(data = {}) {
    super("currency");
    this.id = data.id || "";
    this.code = data.code || "";
    this.fullCode = data.full_code || "";
    this.name = data.name || "";
    this.fullName = data.full_name || "";
    this.isStableCoin = data.is_stable_coin || false;
    this.isActive = data.is_active || false;
    this.iconBase = data.icon_base || "";
    this.iconNetwork = data.icon_network || "";
    this.iconQr = data.icon_qr || "";
    this.addressSmartContract = data.address_smart_contract || "";
    this.countDecimals = data.count_decimals || 0;
    this.order = data.order || 0;
  }
}

export default CurrencyNetworkEntity;
