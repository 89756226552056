const dropdownMixin = {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    changeState() {
      this.isOpen = !this.isOpen;
    },
    handleOutsideClick() {
      this.isOpen = false;
    },
  },
};

export default dropdownMixin;
