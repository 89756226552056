<template>
  <div>
    <div class="d-none d-sm-block w-100">
      <div class="d-flex align-items-end mt-4" v-if="!isLoading">
        <div class="input-block aml">
          <div class="label">
            <h6>{{ $t("aml_page.quantity") }}</h6>
          </div>
          <div class="send-transfer__input-with-icon">
            <CCInput
              @input="typeInputChecks"
              v-model="inputAmlChecks"
              :placeholder="$t('aml_page.type_placeholder')"
              class="mt-3"
            ></CCInput>
          </div>
        </div>
        <SelectPills
          v-for="check in pillsOptions"
          :key="check.id"
          :selectedOption="selectedPill"
          :countPill="check"
          @change="selectCheck(check)"
        />
      </div>
      <div class="d-flex align-items-end mt-4" v-else>
        <div class="input-block aml">
          <b-skeleton class="sm" width="40%" height="24px"></b-skeleton>
          <b-skeleton class="sm mt-3" width="100%" height="56px"></b-skeleton>
        </div>
        <div
          class="select-pills d-flex align-items-center justify-content-between"
        >
          <b-skeleton class="sm" width="76px" height="56px"></b-skeleton>
          <b-skeleton class="sm ms-2" width="76px" height="56px"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none w-100">
      <div class="mt-4" v-if="!isLoading">
        <b-row>
          <b-col cols="12">
            <div class="input-block aml">
              <div class="label">
                <h6>{{ $t("aml_page.quantity") }}</h6>
              </div>
              <div class="send-transfer__input-with-icon">
                <CCInput
                  @input="typeInputChecks"
                  v-model="inputAmlChecks"
                  :placeholder="$t('aml_page.type_placeholder')"
                  class="mt-2"
                  size="md"
                ></CCInput>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            cols="6"
            v-for="check in pillsOptions"
            :key="check.id"
            :class="{
              'pe-1': check.id === 1,
              'ps-1': check.id === 2,
            }"
          >
            <SelectPills
              :selectedOption="selectedPill"
              :countPill="check"
              @change="selectCheck(check)"
            />
          </b-col>
        </b-row>
      </div>
      <div class="d-flex align-items-end mt-4" v-else>
        <div class="input-block aml">
          <b-skeleton class="sm" width="40%" height="24px"></b-skeleton>
          <b-skeleton class="sm mt-3" width="100%" height="56px"></b-skeleton>
        </div>
        <div
          class="select-pills d-flex align-items-center justify-content-between"
        >
          <b-skeleton class="sm" width="76px" height="56px"></b-skeleton>
          <b-skeleton class="sm ms-2" width="76px" height="56px"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  validateAndFormatNumber,
  isValidNumberFormat,
} from "@/utils/validateInput";
import { mapActions, mapState } from "pinia";
import { useAmlStore } from "@/stores/amlStore";
import SelectPills from "@/components/UI/SelectPills.vue";

export default {
  components: {
    SelectPills,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    pillsOptions: {
      type: Array,
      default: [],
    },
    selectedPill: {
      type: Object,
      default: {
        id: null,
        name: "",
        value: null,
      },
    },
  },
  methods: {
    ...mapActions(() => useAmlStore(), ["setCountAml", "setChecksInput"]),
    selectCheck(check) {
      this.$emit("changePills", check);
    },
    typeInputChecks() {
      const isValid = isValidNumberFormat(this.inputAmlChecks);
      this.inputAmlChecks = validateAndFormatNumber(this.inputAmlChecks);
      if (isValid) this.$emit("typeInput");
    },
  },
  computed: {
    ...mapState(() => useAmlStore(), ["checksInput"]),
    inputAmlChecks: {
      get() {
        return this.checksInput;
      },
      set(value) {
        this.setChecksInput(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-block {
  &.aml {
    width: 65%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
</style>
