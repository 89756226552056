<template>
  <div class="status-transaction">
    <div
      class="status-transaction__header d-flex align-items-center justify-content-between"
    >
      <h3 class="status-transaction__title mb-0" v-if="variant === 'success'">
        Готово
      </h3>
      <h3 class="status-transaction__title mb-0" v-if="variant === 'error'">
        Ошибка
      </h3>
    </div>
    <div class="status-transaction__content" v-if="variant === 'success'">
      <p class="status-message mb-0">
        Транзакция завершена <span class="success">успешно</span>
      </p>
      <img
        class="status-icon"
        src="@/assets/images/success-marker.svg"
        alt=""
      />
      <div class="status-txid mt-3 mt-sm-4" v-if="isShowTxId">
        <CopyDataCard
          title="txid"
          :data="txId"
        >
          <img slot="icon" src="@/assets/images/key-icon.svg" alt="" />
        </CopyDataCard>
      </div>
      <div class="status-btn mt-3 mt-sm-4">
        <router-link to="/">
          <CCButton
            fontWeight="medium"
            size="lg"
            class="d-none d-sm-block px-4"
          >
            На главную
          </CCButton>
          <CCButton
            fontWeight="medium"
            size="md"
            class="d-block d-sm-none px-4"
          >
            На главную
          </CCButton>
        </router-link>
      </div>
    </div>
    <div class="status-transaction__content" v-if="variant === 'error'">
      <p class="status-message mb-0">
        Транзакция завершена <span class="error">с ошибкой</span>
      </p>
      <img class="status-icon" src="@/assets/images/error.svg" alt="" />
      <p class="error-text mb-0 mt-4">
        Подробнее об ошибке вы можете узнать <br />
        по
        <a :href="linkBlockchain" target="_blank" class="error-link">
          ссылке
        </a>
      </p>
      <div class="status-btn mt-3 mt-sm-4">
        <router-link to="/">
          <CCButton
            fontWeight="medium"
            size="lg"
            class="d-none d-sm-block px-4"
          >
            На главную
          </CCButton>
          <CCButton
            fontWeight="medium"
            size="md"
            class="d-block d-sm-none px-4"
          >
            На главную
          </CCButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CopyDataCard from "../CreateWallet/CopyDataCard.vue";

export default {
  components: { CopyDataCard },
  props: {
    variant: {
      type: String,
      default: "success",
    },
    isShowTxId: {
      type: Boolean,
      default: false,
    },
    txId: {
      type: String,
      default: "txid",
    },
    network: {
      type: String,
      default: "TRON",
    },
  },
  computed: {
    linkBlockchain() {
      let link;
      switch (this.network) {
        case "TRON":
          link = `https://tronscan.org/#/transaction/${this.txId}`;
          break;
        case "Ethereum":
          link = `https://etherscan.io/tx/${this.txId}`;
          break;
        case "Litecoin":
          link = `https://blockchair.com/litecoin/transaction/${this.txId}`;
          break;
        case "Bitcoin":
          link = `https://blockchair.com/bitcoin/transaction/${this.txId}`;
          break;

        default:
          break;
      }
      return link;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-transaction {
  &__title {
    color: #3c3c3c;
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  &__content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .status-message {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      .success {
        color: #58bd7d;
      }
      .error {
        color: #fa6666;
      }
    }
    .error-text {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #3c3c3c;
    }
    .error-link {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #4a40c6;
      text-decoration: underline;
      word-break: break-all;
    }
    .status-icon {
      margin-top: 10px;
      width: 80px;
      height: 80px;
    }
    .status-txid {
      width: 90%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    .status-btn {
      width: 60%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}
</style>
