<template>
  <div class="resourses">
    <b-row align-h="between" align-v="center">
      <b-col cols="auto">
        <div class="d-flex align-items-center">
          <img
            class="resourses__title-icon"
            src="@/assets/images/resourses.svg"
            alt=""
          />
          <h3 class="resourses__title basic ms-2 ms-sm-3 mb-0">
            {{ $t("staking_page.title_res") }}
          </h3>
        </div>
      </b-col>
    </b-row>
    <div v-if="!isLoading">
      <ProgressBar
        name="Energy"
        :placeholderText="$t('staking_page.available')"
        :amountValue="availableEnergy"
        :amountMax="amountEnergy"
        class="mt-3 mt-sm-4"
      />
      <ProgressBar
        name="Bandwidth"
        :placeholderText="$t('staking_page.available')"
        :amountValue="availableBandwidth"
        :amountMax="amountBandwidth"
        class="mt-4"
      />
    </div>
    <div v-else>
      <div class="mt-4">
        <div class="d-flex justify-content-between">
          <b-skeleton class="sm" width="25%" height="16px"></b-skeleton>
          <b-skeleton class="sm" width="30%" height="24px"></b-skeleton>
        </div>
        <b-skeleton class="sm mt-3" width="100%" height="4px"></b-skeleton>
      </div>
      <div class="mt-4">
        <div class="d-flex justify-content-between">
          <b-skeleton class="sm" width="25%" height="16px"></b-skeleton>
          <b-skeleton class="sm" width="30%" height="24px"></b-skeleton>
        </div>
        <b-skeleton class="sm mt-3" width="100%" height="4px"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../UI/ProgressBar.vue";

export default {
  components: { ProgressBar },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    amountEnergy: {
      type: Number,
      default: 0,
    },
    availableEnergy: {
      type: Number,
      default: 0,
    },
    amountBandwidth: {
      type: Number,
      default: 0,
    },
    availableBandwidth: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.resourses {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
  }
  &__title-icon {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 576px) {
    padding: 0;
    background: none;
    .resourses__title {
      font-size: 20px;
      line-height: 22px;
    }
    .resourses__title-icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
