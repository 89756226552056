import * as bitcoin from "bitcoinjs-lib";
import { encodePassword } from "@/utils/encryption";
import axios from "axios";

export class CreateBitcoinWallet {
  constructor(password) {
    this.password = password;
  }

  create() {
    const btcNetwork = bitcoin.networks.bitcoin; // Для основной сети Bitcoin
    const keyPair = bitcoin.ECPair.makeRandom({ network: btcNetwork });
    const { address } = bitcoin.payments.p2wpkh({
      pubkey: keyPair.publicKey,
      network: btcNetwork,
    });

    const wallet = {
      privateKey: keyPair.toWIF(),
      address: address,
    };

    const encrypted = encodePassword(this.password, JSON.stringify(wallet));
    const data = {
      address: wallet.address,
      encrypted: encrypted,
    };
    return data;
  }
}

export class CalculateFeeTransactionBtc {
  constructor(amount, address) {
    this.amount = amount;
    this.address = address;
  }
  async getActualInputs(amount) {
    try {
      const utxs = await this.getUTXOsForAddress(this.address);
      let sum = 0;
      let result = [];

      for (let i = 0; i < utxs.length; i++) {
        const utxValue = Number(utxs[i].value);
        if (sum <= amount) {
          sum += utxValue;
          result.push(utxs[i]);
        }
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  async getFeeSatoshiByte() {
    const response = await axios.get(
      "https://mempool.space/api/v1/fees/recommended"
    );
    return response.data;
  }
  calculateTransactionWeight(inputs, numberOfRecipients) {
    const inputsCount = inputs.length || 0;
    const outputsCount = numberOfRecipients;
    return inputsCount * 68 + outputsCount * 34 + 12;
  }
  async getUTXOsForAddress(address) {
    const headers = {
      "api-key": "d06c9e92-609b-4128-a497-d0c2c289d013",
    };
    const response = await axios.get(
      `https://btcbook.nownodes.io/api/v2/utxo/${address}`,
      { headers: headers }
    );
    return response.data;
  }
  async getFee(feeSatoshiByte, numberOfRecipients = 1) {
    const amountSatoshi = Math.ceil(this.amount * 10 ** 8);
    const inputs = await this.getActualInputs(amountSatoshi);
    const totalInputAmount = inputs.reduce(
      (acc, input) => acc + Number(input.value),
      0
    );
    const change = totalInputAmount - amountSatoshi - feeSatoshiByte;
    if (change > 0) numberOfRecipients++;
    const weightTransaction = this.calculateTransactionWeight(
      inputs,
      numberOfRecipients
    );
    console.log("inputs", inputs);
    console.log("numberOfRecipients", numberOfRecipients);

    console.log("weight", weightTransaction);

    const fee = feeSatoshiByte * weightTransaction;
    return fee / 10 ** 8;
  }
}

export class CreateBitcoinTransactions {
  constructor(
    privateKey,
    address = "",
    amount = 0,
    addressTo = "",
    outputs = [],
    feeBtc = 0
  ) {
    this.privateKey = privateKey;
    this.address = address;
    this.amount = amount;
    this.addressTo = addressTo;
    this.feeBtc = feeBtc;
    this.outputs = outputs;
    this.calcFee = new CalculateFeeTransactionBtc(this.amount, this.address);
  }
  getAddressScript(address, network) {
    const decoded = bitcoin.address.toOutputScript(address, network);
    return decoded.toString("hex");
  }
  async buildTransaction() {
    const btcNetwork = bitcoin.networks.bitcoin;
    const keyPair = bitcoin.ECPair.fromWIF(this.privateKey, btcNetwork);
    const psbt = new bitcoin.Psbt({ network: btcNetwork });

    let amountSatoshi = Math.ceil(this.amount * 10 ** 8);

    const inputs = await this.calcFee.getActualInputs(amountSatoshi);

    const totalInputAmount = inputs.reduce(
      (acc, input) => acc + Number(input.value),
      0
    );

    const feeSatoshiByte = this.feeBtc * 10 ** 8;

    const change = totalInputAmount - amountSatoshi - feeSatoshiByte;

    inputs.forEach((input) => {
      psbt.addInput({
        hash: input.txid,
        index: input.vout,
        witnessUtxo: {
          script: Buffer.from(
            this.getAddressScript(this.address, btcNetwork),
            "hex"
          ),
          value: parseInt(input.value),
        },
      });
    });

    let amountSend = amountSatoshi;

    if (change > 0) {
      psbt.addOutput({
        address: this.address,
        value: change,
      });
    } else {
      amountSend = amountSatoshi;
    }

    if (this.outputs.length > 0) {
      this.outputs.forEach((output) => {
        psbt.addOutput({
          address: output.address,
          value: amountSend,
        });
      });
    } else {
      psbt.addOutput({
        address: this.addressTo,
        value: amountSend,
      });
    }

    for (let i = 0; i < inputs.length; i++) {
      psbt.signInput(i, keyPair);
    }

    psbt.finalizeAllInputs();
    const tx = psbt.extractTransaction();
    return tx.toHex();
  }
  async sendRawTransaction() {
    const headers = {
      "api-key": "d06c9e92-609b-4128-a497-d0c2c289d013",
    };
    const hexTransaction = await this.buildTransaction();
    console.log("hex", hexTransaction);
    const raw = {
      API_key: "d06c9e92-609b-4128-a497-d0c2c289d013",
      jsonrpc: "2.0",
      id: "test",
      method: "sendrawtransaction",
      params: [hexTransaction],
    };
    const response = await axios.post(`https://btc.nownodes.io`, raw, {
      headers: headers,
    });
    return response.data;
  }
}
