import { encodePassword } from "@/utils/encryption";
import { Wallet, utils, providers, Contract, BigNumber } from "ethers";
import axios from "axios";

export class CreateEthereumWallet {
  constructor(password) {
    this.password = password;
  }
  async create() {
    const wallet = {
      privateKey: "",
      address: "",
    };
    const newWallet = Wallet.createRandom();
    wallet.privateKey = newWallet.privateKey;
    wallet.address = newWallet.address;
    const encrypted = encodePassword(this.password, JSON.stringify(wallet));
    const data = {
      address: wallet.address,
      encrypted: encrypted,
    };
    return data;
  }
}

export class CalculateFeeEthereumTransaction {
  constructor(currency, mode) {
    this.currency = currency;
    this.gasLimit = this.currency === "ETH" ? 21000 : 100000;
    this.chainId = 1;
    this.url = `https://gas.api.infura.io/networks/${this.chainId}/suggestedGasFees`;
  }
  async getAllVariantsFee() {
    try {
      const { data } = await axios.get("/api/suggestedGasFees");
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  // async getFee() {
  //   const provider = new providers.JsonRpcProvider(this.infuraUrl);
  //   const calcGasPrice = parseInt((await provider.getGasPrice())._hex);
  //   const gasPrice = calcGasPrice * 0.4 + calcGasPrice;
  //   const gasLimit = this.currency === "ETH" ? 21000 : 100000;
  //   const fee = (gasLimit * gasPrice) / 10 ** 18;

  //   return { gasPrice, gasLimit, fee };
  // }
}

export class CreateEthereumTransactions {
  constructor(
    privateKey,
    amount,
    toAddress,
    gasLimit,
    maxPriorityFeePerGas,
    maxFeePerGas
  ) {
    this.privateKey = privateKey;
    this.toAddress = toAddress;
    this.amount = amount;
    this.gasLimit = gasLimit;
    this.maxPriorityFeePerGas = maxPriorityFeePerGas;
    this.maxFeePerGas = maxFeePerGas;
  }
  async create() {
    const wallet = new Wallet(this.privateKey);
    // const calcFee = new CalculateFeeTransaction(this.currency);
    const provider = new providers.JsonRpcProvider(
      "https://mainnet.infura.io/v3/6faca9efa3fc4bd3a1af907bcbaec2f4"
    );
    console.log(wallet);
    console.log(provider);
    console.log(Number(this.amount));
    const connectedWallet = wallet.connect(provider);

    const recipientAddress = this.toAddress;
    const amount = utils.parseEther(this.amount.toString()); // Теперь используем utils.parseEther

    console.log(amount);

    // const { gasPrice, gasLimit } = calcFee.getFee();
    const maxFeePerGas = this.maxFeePerGas.toString();
    const maxPriorityFeePerGas = this.maxPriorityFeePerGas.toString();

    const trimmedMaxFeePerGas = maxFeePerGas.includes(".")
      ? maxFeePerGas.substring(0, maxFeePerGas.indexOf(".") + 10) // Обрезка до 9 десятичных знаков
      : maxFeePerGas;

    const trimmedMaxPriorityFeePerGas = maxPriorityFeePerGas.includes(".")
      ? maxPriorityFeePerGas.substring(
          0,
          maxPriorityFeePerGas.indexOf(".") + 10
        ) // Обрезка до 9 десятичных знаков
      : maxPriorityFeePerGas;

    const tx = {
      to: recipientAddress,
      value: utils.parseEther(this.amount.toString()), // Конвертация value в wei
      gasLimit: BigNumber.from(this.gasLimit),
      maxFeePerGas: utils.parseUnits(trimmedMaxFeePerGas, "gwei"), // Конвертация maxFeePerGas в gwei
      maxPriorityFeePerGas: utils.parseUnits(
        trimmedMaxPriorityFeePerGas,
        "gwei"
      ), // Конвертация maxPriorityFeePerGas в gwei
    };

    try {
      const transaction = await connectedWallet.sendTransaction(tx);
      console.log("Transaction Hash:", transaction.hash);
      // const receipt = await provider.waitForTransaction(transaction.hash);
      // console.log("Transaction was mined in block", receipt.blockNumber);
      return transaction.hash;
    } catch (error) {
      console.error("Error:", error);
    }
  }
}

export class CreateTokensTransactions {
  constructor(
    privateKey,
    currency,
    amount,
    toAddress,
    gasLimit,
    maxPriorityFeePerGas,
    maxFeePerGas
  ) {
    this.privateKey = privateKey;
    this.currency = currency;
    this.amount = amount;
    this.toAddress = toAddress;
    this.gasLimit = gasLimit;
    this.maxPriorityFeePerGas = maxPriorityFeePerGas;
    this.maxFeePerGas = maxFeePerGas;
    this.infuraUrl =
      "https://mainnet.infura.io/v3/6faca9efa3fc4bd3a1af907bcbaec2f4";
    this.tokenList = [
      {
        name: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        abi_address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimal: 6,
      },
      {
        name: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        abi_address: "0xa2327a938Febf5FEC13baCFb16Ae10EcBc4cbDCF",
        decimal: 6,
      },
      {
        name: "TUSD",
        address: "0x0000000000085d4780B73119b644AE5ecd22b376",
        abi_address: "0xB650eb28d35691dd1BD481325D40E65273844F9b",
        decimal: 18,
      },
    ];
  }
  async getContractABI() {
    const apiKey = "5ZG9XJ2V6F8CMKRQ58DBHXRJ91VHIEGXC2";

    const selectedContract = this.tokenList.find(
      (token) => token.name === this.currency
    );

    const url = `https://api.etherscan.io/api?module=contract&action=getabi&address=${selectedContract.abi_address}&apikey=${apiKey}`;

    const { data } = await axios.get(url);

    if (data.status === "1" && data.result) {
      return JSON.parse(data.result);
    } else {
      throw new Error(data.result);
    }
  }

  async sendToken() {
    const wallet = new Wallet(this.privateKey);
    // const calcFee = new CalculateFeeTransaction(this.currency);
    const provider = new providers.JsonRpcProvider(this.infuraUrl);
    const connectedWallet = wallet.connect(provider);
    const ERC20_ABI = await this.getContractABI();
    const selectedContract = this.tokenList.find(
      (token) => token.name === this.currency
    );

    console.log(this.privateKey);
    console.log(this.currency);
    console.log(provider);

    const tokenContract = new Contract(
      selectedContract.address,
      ERC20_ABI,
      connectedWallet
    );

    const amountToSend = utils.parseUnits(
      this.amount.toString(),
      selectedContract.decimal
    ); // Предполагается, что у токена 18 десятичных знаков

    const maxFeePerGas = this.maxFeePerGas.toString();
    const maxPriorityFeePerGas = this.maxPriorityFeePerGas.toString();

    const trimmedMaxFeePerGas = maxFeePerGas.includes(".")
      ? maxFeePerGas.substring(0, maxFeePerGas.indexOf(".") + 10) // Обрезка до 9 десятичных знаков
      : maxFeePerGas;

    const trimmedMaxPriorityFeePerGas = maxPriorityFeePerGas.includes(".")
      ? maxPriorityFeePerGas.substring(
          0,
          maxPriorityFeePerGas.indexOf(".") + 10
        ) // Обрезка до 9 десятичных знаков
      : maxPriorityFeePerGas;

    try {
      const tx = await tokenContract.transfer(this.toAddress, amountToSend, {
        gasLimit: BigNumber.from(this.gasLimit),
        maxFeePerGas: utils.parseUnits(trimmedMaxFeePerGas, "gwei"), // Конвертация maxFeePerGas в gwei
        maxPriorityFeePerGas: utils.parseUnits(
          trimmedMaxPriorityFeePerGas,
          "gwei"
        ), // Конвертация maxPriorityFeePerGas в gwei
      });
      console.log("Transaction Hash:", tx.hash);
      return tx.hash;
    } catch (error) {
      console.log(error);
    }
  }
}
