<template>
  <div class="send-transfer">
    <!-- mobile-header -->
    <HeaderBackLink class="d-none d-sm-block" :title="$t('send_page.title')">
      <img
        class="download-icon"
        src="@/assets/images/upload-icon.svg"
        alt="upload-icon"
      />
    </HeaderBackLink>
    <HeaderMobile :title="$t('send_page.title')" class="d-block d-sm-none">
      <img
        v-if="step > 1"
        @click="setStepBack"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
      <router-link v-else slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link v-if="step === 1" slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <!-- mobile-header -->
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <div class="send-block">
          <Step1
            v-if="step === 1"
            :optionsCurrency="optionsCurrency"
            :giveCurrency="giveCurrencyName"
            :step="step"
            @changeGiveCurrency="setGiveCurrencyName"
            @selectCurrency="setSelectedCurrency"
            @setStep="setStep"
          />
          <Step2 v-if="step === 2" :step="step" />
          <ConfirmTransaction
            v-if="step === 3"
            @submitForm="confirmSendCrypto"
            @cancel="clearTransaction"
          />
          <StatusTransaction isShowTxId :txId="txId" v-if="step === 4" />
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="mt-4 mt-xl-0 mb-4 mb-sm-0">
        <div class="address-book h-100">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img
                class="address-book__title-icon"
                src="../assets/images/address-book.png"
                alt=""
              />
              <h3 class="my-0 send-transfer__title ms-2 ms-sm-3">
                {{ $t("send_page.address_book.title") }}
              </h3>
            </div>
            <img
              v-if="stepAddressBook === 'list'"
              @click="setStepAddressBook('add')"
              class="pointer"
              width="24px"
              height="24px"
              src="../assets/images/add-icon.png"
              alt=""
            />
            <img
              v-else
              @click="setStepAddressBook('list')"
              class="pointer"
              width="24px"
              height="24px"
              src="../assets/images/back-icon.svg"
              alt=""
            />
          </div>
          <SearchContacts
            @search="searchContacts"
            v-if="stepAddressBook === 'list' && contactsList.length"
            class="mt-3 mt-sm-4"
          />
          <ContactsList
            :isLoading="isLoadingContactsList"
            @edit="goToEditContact"
            @remove="removeContact"
            @send="setAddressFromContact"
            :contacts="contactsList"
            class="w-100"
            v-if="stepAddressBook === 'list'"
          />
          <FormAddContact v-if="stepAddressBook === 'add'" />
          <FormEditContact
            v-if="stepAddressBook === 'edit'"
            :isLoading="isLoadingContactDetail"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { mapActions, mapState } from "pinia";
import Step1 from "@/components/SendTransfer/Step1.vue";
import Step2 from "@/components/SendTransfer/Step2.vue";
import ConfirmTransaction from "@/components/Transactions/ConfirmTransaction";
import { useWalletStore } from "@/stores/wallet";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import ContactsList from "@/components/SendTransfer/AddressBook/ContactsList.vue";
import SearchContacts from "@/components/SendTransfer/AddressBook/SearchContacts.vue";
import FormAddContact from "@/components/SendTransfer/AddressBook/FormAddContact.vue";
import FormEditContact from "@/components/SendTransfer/AddressBook/FormEditContact.vue";
import StatusTransaction from "@/components/Transactions/StatusTransaction.vue";

export default {
  name: "SendTransfer",
  data() {
    return {
      // isOpenFormAddContact: false,
      // isOpenFormEditContact: false,
      interval: null,
    };
  },
  components: {
    HeaderBackLink,
    Step1,
    Step2,
    ConfirmTransaction,
    HeaderMobile,
    ContactsList,
    SearchContacts,
    FormAddContact,
    FormEditContact,
    StatusTransaction,
  },
  methods: {
    ...mapActions(useSendTransferStore, [
      "setGiveCurrencyName",
      "setSelectedCurrency",
      "setSelectedWallet",
      "setStep",
      "setStepAddressBook",
      "getAllCurrencyBalance",
      "clearTransaction",
      "setStepBack",
      "getContactsList",
      "getContactDetail",
      "removeContact",
      "setAddress",
      "setAmount",
      "getLastTransactions",
      "addTransactionToHistory",
      "updateBalanceWallet",
    ]),
    ...mapActions(useWalletStore, ["getAllWallets"]),
    async setSelectedWalletAndCurrency() {
      if (this.dataWalletToSend != null) {
        const needWallet = this.walletsList.find(
          (wallet) => wallet.address == this.dataWalletToSend.address
        );
        const needCurrency = needWallet.currency_list.find(
          (currency) => currency.code == this.dataWalletToSend.code
        );
        this.setSelectedWallet(needWallet);
        this.setSelectedCurrency(needCurrency);
      } else {
        this.setSelectedWallet(this.walletsList[0]);
        this.setSelectedCurrency(this.walletsList[0].currency_list[0]);
      }
    },
    async confirmSendCrypto() {
      this.setStep(4);
      this.updateBalanceWallet();
      this.addTransactionToHistory();
    },
    goToEditContact(id) {
      this.getContactDetail(id);
      this.setStepAddressBook("edit");
    },
    setAddressFromContact(address) {
      if (this.step === 1) {
        const sendBlock = document.querySelector(".send-transfer");
        this.setAddress(address);
        sendBlock.scrollIntoView();
      }
    },
    searchContacts() {
      this.getContactsList();
    },
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "step",
      "stepAddressBook",
      "optionsCurrency",
      "selectedCurrency",
      "giveCurrencyName",
      "contactsList",
      "isLoadingContactsList",
      "isLoadingContactDetail",
    ]),
    ...mapState(useWalletStore, ["txId", "walletsList"]),
    ...mapState(useSendTransferStore, ["optionsCurrency", "dataWalletToSend"]),
    currencyTrx() {
      return this.optionsCurrency.find((currency) => currency.code === "TRX");
    },
  },
  async mounted() {
    this.getContactsList();
    await this.getAllWallets();
    await this.setSelectedWalletAndCurrency();
    this.getLastTransactions();
  },
  beforeDestroy() {
    this.clearTransaction();
  },
};
</script>

<style lang="scss" scoped>
.send-block {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 0;
    background: none;
  }
}
</style>
