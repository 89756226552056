import Api from "../../api/BaseAPI";
import WalletEntity from "../entities/walletEntity";
import CurrencyEntity from "../entities/currencyEntitry";
import NetworkCyrrencyEntity from "../entities/networkCurrencyEntity";

class WalletController {
  constructor() {
    this.api = new Api();
  }

  async getWallets(is_grouped = true) {
    const data = {
      limit: 100,
    };
    try {
      const response = await this.api.post("/api/v1/wallet/list", data, true);
      if (is_grouped === true) {
        return response.result.map(
          (walletData) => new WalletEntity(walletData)
        );
      } else {
        return this.groupWalletsByNetworkAndAddress(
          response.result.map((walletData) => new WalletEntity(walletData))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async groupWalletsByNetworkAndAddress(wallets) {
    // Сначала группируем данные по сети и адресу
    const groupedData = wallets.reduce((acc, item) => {
      const networkCode = item.currencyObject.network_object.code;
      const address = item.address;
      const key = `${networkCode}_${address}`;

      if (!acc[key]) {
        acc[key] = {
          network: item.currencyObject.network_object,
          address: item.address,
          currencies: [],
        };
      }

      acc[key].currencies.push({
        id: item.id,
        currency: item.currencyObject.name,
        code: item.currencyObject.code.split("_")[0],
        icon: item.currencyObject.icon_network,
        balance: item.balance,
        balance_usd: item.balance_usd,
      });

      return acc;
    }, {});

    // Преобразовываем объект в массив для удобства использования
    const formattedData = Object.values(groupedData);
    return formattedData;
  }

  async getWalletDetail(data) {
    try {
      const response = await this.api.post(`/api/v1/wallet/detail`, data, true);
      return new WalletEntity(response.result);
    } catch (error) {
      console.log(error);
    }
  }
  async getWalletBalance(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/balance`,
        data,
        true,
        false
      );
      return new WalletEntity(response);
    } catch (error) {
      console.log(error);
    }
  }
  async updateBalance(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/update/balance`,
        data,
        true,
        false
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getBlockchainBalance() {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/balance/network`,
        null,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getSelectedBlockchain(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/list/network`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getActiveCurrencies() {
    try {
      const response = await this.api.get("/api/v1/wallet/currency/list", true);
      return response.map((currencyData) => new CurrencyEntity(currencyData));
    } catch (error) {
      console.log(error);
    }
  }
  async getActiveNetworkCurrencies() {
    try {
      const response = await this.api.get("/api/v1/wallet/network/list", true);
      return response.result.map(
        (currencyData) => new NetworkCyrrencyEntity(currencyData)
      );
    } catch (error) {
      console.log(error);
    }
  }
  async createWallet(walletData) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/create",
        walletData,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async addPiggyBank(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/add/piggy_bank",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async addNicknameWallet(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/add/nickname",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getAddressBookList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/book/list",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getAddressContactDetail(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/book/detail",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async addContactToAddressList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/book/create",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async editContactFromAddressList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/book/update",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async removeContactFromAddressList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/book/delete",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getHistoryList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/recent/list",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async addHistoryItem(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/recent/create",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getBalanceAddress(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/address/balance",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async toggleVisibleWallet(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/visibility",
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getTransactionsList(data) {
    try {
      const response = await this.api.post(
        "/api/v1/wallet/transaction/list",
        data,
        true
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new WalletController();
