import { ServiceController } from "@/core/system/controllers";
import { defineStore } from "pinia";

export const useEnergyStore = defineStore("energyStore", {
  state: () => ({
    isLoadingEnergyPage: true,
    stepEnergy: 1,
    address: "",
    orderId: "",
    quantityInput: "32000",
    durationInput: "1",
    totalAmount: null,
    countEnergy: null,
    isLoadingHistoryList: true,
    historyList: [],
    totalItemsHistory: 10,
    limitItemsHistory: 5,
    currentPageHistory: 1,
    energyPillsAmount: [
      {
        id: 1,
        name: "32,000",
        value: 32000,
      },
      {
        id: 2,
        name: "65,000",
        value: 65000,
      },
    ],
    energyPillsTime: [
      {
        id: 1,
        name: "1 час",
        valueServer: 1,
        valueClient: 1,
      },
      {
        id: 2,
        name: "1 день",
        valueServer: 24,
        valueClient: 1,
      },
    ],
    selectedPillAmount: {
      id: 1,
      name: "32,000",
      value: 32000,
    },
    selectedPillTime: {
      id: 1,
      name: "1 час",
      valueServer: 1,
      valueClient: 1,
    },
  }),
  actions: {
    setStepEnergy(step) {
      this.stepEnergy = step;
    },
    setStepEnergyBack() {
      this.stepEnergy -= 1;
    },
    setQuantityInput(text) {
      this.quantityInput = text;
    },
    setDurationInput(text) {
      this.durationInput = text;
    },
    setSelectedPillAmount(pill) {
      this.selectedPillAmount = pill;
    },
    setSelectedPillTime(pill) {
      this.selectedPillTime = pill;
    },
    setAddress(address) {
      this.address = address;
    },
    setIsLoadingEnergyPage(boolean) {
      this.isLoadingEnergyPage = boolean;
    },
    setTotalAmount(amount) {
      this.totalAmount = amount;
    },
    setCountEnergy(count) {
      this.countEnergy = count;
    },
    setOrderId(id) {
      this.orderId = id;
    },
    async loadHistoryList() {
      this.isLoadingHistoryList = true;
      const payload = {
        service_id: 2,
        limit: this.limit,
        offset: this.offset,
      };
      try {
        const response = await ServiceController.serviceOrdersList(payload);
        this.historyList = response.data;
        this.totalItemsHistory = response.total_row;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingHistoryList = false;
      }
    },
    setCurrentPage(page) {
      this.currentPageHistory = page;
      this.loadHistoryList();
    },
  },
  getters: {
    limit() {
      return this.currentPageHistory * 5;
    },
    offset: (state) => {
      return (state.currentPageHistory - 1) * state.limitItemsHistory;
    },
    totalPagesHistory: (state) => {
      return Math.ceil(state.totalItemsHistory / state.limitItemsHistory);
    },
  },
});
