<template>
  <div class="pagination">
    <div 
      class="pagination__item"
      @click="setActiveItem(currentPage - 1)"
      :disabled="currentPage === 1"
    >
      Назад
    </div>
    <div
      class="pagination__item"
      :class="{ active: index === currentPage }"
      v-for="index in visiblePages"
      :key="index"
      @click="setActiveItem(index)"
    >
      {{ index }}
    </div>
    <div 
      class="pagination__item"
      @click="setActiveItem(currentPage + 1)"
      :disabled="currentPage === totalPages"
    >
      Вперед
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      default: 5,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    visiblePages() {
      let pages = [];
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(startPage + 4, this.totalPages);

      // Адаптируем начальную страницу, если достигнут конец списка
      if (endPage === this.totalPages) {
        startPage = Math.max(this.totalPages - 4, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    setActiveItem(index) {
      if (index > 0 && index <= this.totalPages) {
        this.$emit("changePage", index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  .pagination__item {
    cursor: pointer;
    padding: 3px 10px;
    color: #9f9f9f;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    &:disabled {
      color: #ccc;
      cursor: not-allowed;
    }
    &.active {
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      background: #fff;
      box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
      color: #3c3c3c;
    }
  }
}
</style>
