<template>
  <div class="fee-card" :class="{ active: isActive }">
    <b-row align-h="between" align-v="center">
      <b-col cols="6" sm="5">
        <div class="fee-card__left">
          <img
            class="variant-icon"
            :src="require(`@/assets/images/feeSettings/${variant}.svg`)"
            alt=""
          />
          <div>
            <p class="variant-text mb-0">{{ variantText }}</p>
            <div
              class="fee-card__center d-flex align-items-center d-sm-none"
              v-if="duration"
            >
              <p class="duration-text mb-0">Время:</p>
              <p
                class="duration-data mb-0"
                :class="{
                  red: !isOptimalVariant,
                  green: isOptimalVariant,
                }"
              >
                ~{{ duration }} сек
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="3" v-if="duration" class="d-none d-sm-block">
        <div class="fee-card__center">
          <p
            class="duration-data mb-0"
            :class="{
              red: !isOptimalVariant,
              green: isOptimalVariant,
            }"
          >
            ~{{ duration }} сек
          </p>
          <p class="duration-text mb-0">Время</p>
        </div>
      </b-col>
      <b-col cols="6" sm="4" class="d-flex justify-content-end">
        <div class="fee-card__right">
          <p class="fee-data mb-0">{{ amountFee }} {{ currencyFee }}</p>
          <p class="fee-text text-end text-md-start mb-0">Комиссия</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    isOptimalVariant: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    variantText: {
      type: String,
      default: "medium",
    },
    variant: {
      type: String,
      default: "medium",
    },
    duration: {
      type: Number,
    },
    amountFee: {
      type: Number | String,
      default: 0,
    },
    currencyFee: {
      type: String,
      default: "USD",
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-card {
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
  background: #fbfbfb;
  border: 1px solid transparent;
  &.active {
    border: 1px solid #4a40c6;
    background: #fafaff;
  }
  &__left {
    display: flex;
    align-items: center;
    .variant-icon {
      width: 32px;
      height: 32px;
      @media (max-width: 576px) {
        width: 24px;
        height: 24px;
      }
    }
    .variant-text {
      margin-left: 12px;
      color: #3c3c3c;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 16px;
        margin-left: 8px;
      }
    }
  }
  &__center {
    .duration-data {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      &.red {
        color: #fa6666;
      }
      &.green {
        color: #58bd7d;
      }
      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 16px;
        margin-left: 4px;
      }
    }
    .duration-text {
      margin-top: 6px;
      color: #9f9f9f;
      font-size: 12px;
      font-weight: 300;
      line-height: 10px;
      @media (max-width: 576px) {
        margin-top: 0;
        font-size: 10px;
        line-height: 16px;
      }
    }
    @media (max-width: 576px) {
      margin-left: 8px;
    }
  }
  &__right {
    .fee-data {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .fee-text {
      margin-top: 6px;
      color: #9f9f9f;
      font-size: 12px;
      font-weight: 300;
      line-height: 10px;
      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
</style>
