<template>
  <div class="select-currency-wallet" v-if="isLoading">
    <div class="select-item-wrapper">
      <div class="d-flex align-items-center">
        <div class="icon-block">
          <b-skeleton class="md" width="60%" height="60%"></b-skeleton>
        </div>
        <b-skeleton class="sm ms-2" width="64px" height="20px"></b-skeleton>
      </div>
      <b-skeleton class="button" width="45px" height="20px"></b-skeleton>
    </div>
  </div>
  <div
    class="select-currency-wallet"
    @click="toggleSelect"
    @blur="closeSelect"
    :tabindex="0"
    v-else
  >
    <div class="select-item-wrapper">
      <div class="d-flex align-items-center">
        <div class="icon-block">
          <img class="icon" :src="selectedCurrency.icon_base" alt="" />
        </div>
        <h5 class="currency-name mb-0">{{ selectedCurrency.name }}</h5>
        <img
          class="arrow"
          :class="{
            active: isOpen,
          }"
          src="@/assets/images/arrow-icon.svg"
          alt=""
        />
      </div>
      <div class="bage">{{ selectedWallet.network_object.full_name }}</div>
    </div>
    <div
      class="select-dropdown"
      v-if="options.length"
      @click.stop
      v-show="isOpen"
    >
      <WalletsSwitcher @onSwitch="switchTypeWallets" variant="selector" />
      <div
        class="wallet-item"
        @click="selectWallet(wallet)"
        v-for="wallet in filteredWallets"
        :key="wallet.id"
        v-show="wallet.visibility_state"
      >
        <div>
          <div class="wallet-item__header">
            <h5 v-if="!wallet.is_piggy_bank" class="wallet-item__name mb-0">
              {{ wallet.nickname }}
            </h5>
            <h5 v-else class="wallet-item__name mb-0">
              {{ wallet.piggy_bank.target_title }}
            </h5>
            <p class="wallet-item__address mb-0">
              {{ getShortWalletAddress(wallet.address) }}
            </p>
          </div>
          <div class="wallet-item__currency-list">
            <div
              v-for="currency in wallet.currency_list"
              :key="currency.id"
              class="wallet-item__currency-item"
              :class="{
                selected:
                  selectedCurrency.id === currency.id &&
                  selectedWallet.id === wallet.id,
              }"
              @click="selectCurrency(currency)"
              :id="`currency-item_${wallet.id}`"
            >
              <div class="d-flex align-items-center">
                <div class="icon-block">
                  <img class="icon" :src="currency.icon_base" alt="" />
                </div>
                <h5 class="name mb-0">{{ currency.name }}</h5>
              </div>
              <div class="d-flex align-items-center">
                <h6 class="balance mb-0">
                  {{ convertBalanceCrypto(currency.balance) }}
                  {{ sliceNetworkCodeCurrency(currency.code) }}
                </h6>
                <div class="bage ms-2">
                  {{ wallet.network_object.full_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getShortWalletAddress,
  convertBalanceCrypto,
  sliceNetworkCodeCurrency,
  getNetworkCurrency,
} from "@/utils";
import WalletsSwitcher from "../Wallets/WalletsSwitcher.vue";

export default {
  components: { WalletsSwitcher },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      default: [],
    },
    defaultCurrency: {
      type: Object,
      required: false,
      default: null,
    },
    defaultWallet: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    typeWallets: "",
    filteredWallets: [],
  }),
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    closeSelect() {
      this.isOpen = false;
    },
    selectCurrency(item) {
      this.$emit("selectCurrency", item);
      this.closeSelect();
    },
    selectWallet(item) {
      this.$emit("selectWallet", item);
      this.closeSelect();
    },
    filterWallets(typeWallet) {
      if (typeWallet === "default") {
        return this.options.filter((wallet) => !wallet.is_piggy_bank);
      } else {
        return this.options.filter((wallet) => wallet.is_piggy_bank);
      }
    },
    switchTypeWallets(type) {
      this.typeWallets = type;
    },
    getShortWalletAddress,
    convertBalanceCrypto,
    sliceNetworkCodeCurrency,
    getNetworkCurrency,
  },
  watch: {
    typeWallets(newValue) {
      this.filteredWallets = this.filterWallets(newValue);
    },
    isLoading() {
      this.typeWallets = "default";
    }
  },
  computed: {
    selectedCurrency: {
      get() {
        return this.defaultCurrency;
      },
      set(value) {
        this.$emit("update:defaultCurrency", value);
      },
    },
    selectedWallet: {
      get() {
        return this.defaultWallet;
      },
      set(value) {
        this.$emit("update:defaultWallet", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  width: 10px;
  height: 10px;
  transition: 0.1s ease-in;
  margin-left: 11px;
  user-select: none;
  &.active {
    transform: rotate(180deg);
  }
  @media (max-width: 576px) {
    width: 8px;
    height: 8px;
  }
}

.icon-block {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 18px;
    height: 18px;
    @media (max-width: 576px) {
      width: 16px;
      height: 16px;
    }
  }
}

.bage {
  padding: 0 8px;
  border-radius: 50px;
  border: 0.5px solid #f0f0f0;
  background: #fbfbfb;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  color: #4a40c6;
  user-select: none;
  @media (max-width: 576px) {
    padding: 0 6px;
    font-size: 6px;
    line-height: 12px;
  }
}

// .wallets-filters {
//   display: flex;
//   align-items: center;
// }

.select-currency-wallet {
  cursor: pointer;
  position: relative;
  .select-item-wrapper {
    max-height: 56px;
    padding: 16px 12px 16px 16px;
    border-radius: 8px;
    border: 1px solid #4a40c6;
    background: #fff;
    box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 576px) {
      padding: 6px 10px 6px 6px;
    }

    .currency-name {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c3c;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
  .select-dropdown {
    width: 100%;
    max-height: 360px;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    top: 65px;
    left: 0;
    z-index: 50;
    padding: 12px 12px 24px 12px;
    border-radius: 12px;
    background: #f9f9f9;
    @media (max-width: 576px) {
      max-height: 430px;
      top: 50px;
      padding: 12px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: #4a40c6;
    }
    .wallet-item {
      margin-top: 12px;
      padding: 16px 12px 12px 12px;
      border-radius: 6px;
      background: #fff;
      @media (max-width: 576px) {
        padding: 12px;
      }
      &:first-child {
        margin-top: 0;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__name {
        color: #5f5f5f;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.18px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
      &__address {
        color: #4a40c6;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.14px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
      &__currency-list {
        margin-top: 4px;
      }
      &__currency-item {
        margin-top: 12px;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid #f0f0f0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.selected {
          border: 1px solid #4a40c6;
          background: #fafaff;
        }
        .name {
          margin-left: 12px;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #3c3c3c;
          @media (max-width: 576px) {
            font-size: 14px;
            margin-left: 8px;
          }
        }
        .balance {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.18px;
          color: #3c3c3c;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
