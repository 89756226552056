<template>
  <b-container>
    <div class="create-wallet">
      <b-row align-h="center">
        <b-col cols="12" md="9" lg="7" xl="5" v-if="step === 1">
          <SelectNetwork :type="typeWallet" />
        </b-col>
        <b-col cols="12" md="7" lg="5" xl="4" v-if="step === 2">
          <CreatePassword />
        </b-col>
        <b-col cols="12" md="7" lg="6" xl="4" v-if="step === 3">
          <SaveWallet />
        </b-col>
        <b-col cols="12" md="7" lg="6" xl="4" v-if="step === 4">
          <WalletSuccess v-if="typeWallet === 'default'" />
          <CreateHoldWallet v-if="typeWallet === 'hold'" />
        </b-col>
        <b-col
          cols="12"
          md="7"
          lg="6"
          xl="5"
          v-if="step === 5 && typeWallet === 'hold'"
        >
          <HoldWalletSuccess />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import NetworkCard from "@/components/CreateWallet/NetworkCard.vue";
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import SelectNetwork from "@/components/CreateWallet/SelectNetwork.vue";
import CreatePassword from "@/components/CreateWallet/CreatePassword.vue";
import SaveWallet from "@/components/CreateWallet/SaveWallet.vue";
import WalletSuccess from "@/components/CreateWallet/WalletSuccess.vue";
import CreateHoldWallet from "@/components/CreateWallet/CreateHoldWallet.vue";
import HoldWalletSuccess from "@/components/CreateWallet/HoldWalletSuccess.vue";

export default {
  components: {
    NetworkCard,
    SelectNetwork,
    CreatePassword,
    SaveWallet,
    WalletSuccess,
    CreateHoldWallet,
    HoldWalletSuccess,
  },
  methods: {
    ...mapActions(useWalletStore, ["setSelectedNetwork", "setStep"]),
  },
  computed: {
    ...mapState(useWalletStore, ["step", "typeWallet"]),
  },
  beforeDestroy() {
    this.setStep(1);
  },
};
</script>

<style lang="scss" scoped>
.create-wallet {
  margin-top: 50px;
  @media (max-width: 576px) {
    margin-top: 24px;
  }
}
</style>
