<template>
  <div class="auth-layout">
    <b-container fluid class="auth-layout__inner">
      <router-view class="auth-layout__content"></router-view>
      <a class="auth-layout__policy">
        {{ $t("licence") }} © CryptoCloud - {{ currentYear }}
      </a>
    </b-container>
  </div>
</template>

<script>
import { getFullYear } from "@/utils/date";

export default {
  name: "AuthLayout",
  computed: {
    currentYear() {
      return getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    height: 100%;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  &__content {
    margin-top: auto;
    // @media (max-width: 768px) {
    //   margin-top: 0;
    // }
    @media (max-width: 430px) {
      width: 100%;
    }
  }
  &__policy {
    margin-top: auto;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    padding-top: 48px;
    padding-bottom: 48px;
    text-decoration: none;
    cursor: pointer;
    color: #3c3c3c;
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 420px) {
      font-size: 12px;
    }
  }
}
</style>
