<template>
  <div class="invoice-aml">
    <div class="h-100" v-if="!isLoading">
      <b-row align-v="center">
        <b-col cols="auto" class="d-flex align-items-center">
          <div class="invoice-balance__image aml">
            <img src="@/assets/images/eye.svg" alt="current-balance-icon" />
          </div>
          <h4 class="ps-2 m-0 aml-widget__title">
            {{ $t("aml_widget.title") }}
          </h4>
        </b-col>
      </b-row>
      <p
        class="aml-widget__balance mt-4 mb-0"
        v-html="
          $t('aml_widget.quantity', { quantity: user.available_aml_check })
        "
      ></p>
      <p class="aml-widget__title mt-2">{{ $t("aml_widget.text") }}</p>
      <b-row class="d-none d-md-flex mt-4">
        <b-col cols="12" md="6" class="pe-2">
          <router-link to="/buy-aml">
            <CCButton
              class="d-flex align-items-center"
              variant="primary"
              fontWeight="medium"
              size="lg"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 3L3.04936 3.20987C3.91136 3.38227 4.55973 4.09732 4.6472 4.97203L4.8 6.5L5.7886 14.7383C5.90922 15.7435 6.76195 16.5 7.77435 16.5H16.7673C18.3733 16.5 19.7733 15.407 20.1628 13.8489L21.2855 9.35783C21.6485 7.90619 20.5505 6.5 19.0542 6.5H13.4H8.5"
                  stroke="#4A40C6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M13 13.5H9"
                  stroke="#4A40C6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="8.5" cy="20" r="1.5" fill="#4A40C6" />
                <circle cx="17.5" cy="20" r="1.5" fill="#4A40C6" />
              </svg>

              <div class="ms-1">{{ $t("aml_widget.btn_buy") }}</div>
            </CCButton>
          </router-link>
        </b-col>
        <b-col cols="12" md="6" class="ps-2">
          <CCButton
            class="d-flex align-items-center"
            @click="amlCheckStart"
            variant="secondary"
            :disabled="user.available_aml_check < 1 || isLoadingAmlCheck"
            fontWeight="medium"
            size="lg"
          >
            <svg
              width="24"
              :class="{
                'loader-backward': isLoadingAmlCheck,
              }"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3082 8.7459C18.7081 7.39808 17.747 6.24245 16.5311 5.40679C15.3152 4.57112 13.8919 4.08798 12.4185 4.01076C10.9452 3.93354 9.47917 4.26527 8.18258 4.96926C7.50896 5.33501 6.8947 5.79383 6.3565 6.32947"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M3.23187 5.51544L3.96128 8.67497C4.08551 9.2131 4.62246 9.54864 5.16059 9.4244L8.32013 8.69499"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M4.69161 15.2539C5.2917 16.6017 6.25282 17.7574 7.46872 18.593C8.68462 19.4287 10.1079 19.9118 11.5813 19.989C13.0546 20.0663 14.5207 19.7345 15.8172 19.0305C16.4318 18.6969 16.9969 18.2858 17.5 17.8095"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20.7681 18.4846L20.0387 15.325C19.9145 14.7869 19.3775 14.4514 18.8394 14.5756L15.6799 15.305"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>

            <div class="ms-1">{{ $t("aml_widget.btn_check") }}</div>
          </CCButton>
        </b-col>
      </b-row>
      <b-row class="d-flex d-md-none mt-4">
        <b-col cols="12" md="6">
          <router-link to="/buy-aml">
            <CCButton
              class="d-flex align-items-center"
              variant="primary"
              fontWeight="medium"
              size="md"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 3L3.04936 3.20987C3.91136 3.38227 4.55973 4.09732 4.6472 4.97203L4.8 6.5L5.7886 14.7383C5.90922 15.7435 6.76195 16.5 7.77435 16.5H16.7673C18.3733 16.5 19.7733 15.407 20.1628 13.8489L21.2855 9.35783C21.6485 7.90619 20.5505 6.5 19.0542 6.5H13.4H8.5"
                  stroke="#4A40C6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M13 13.5H9"
                  stroke="#4A40C6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="8.5" cy="20" r="1.5" fill="#4A40C6" />
                <circle cx="17.5" cy="20" r="1.5" fill="#4A40C6" />
              </svg>

              <div class="ms-1">{{ $t("aml_widget.btn_buy") }}</div>
            </CCButton>
          </router-link>
        </b-col>
        <b-col cols="12" md="6" class="mt-2">
          <CCButton
            class="d-flex align-items-center"
            @click="amlCheckStart"
            variant="secondary"
            :disabled="user.available_aml_check < 1 || isLoadingAmlCheck"
            fontWeight="medium"
            size="md"
          >
            <svg
              width="24"
              :class="{
                'loader-backward': isLoadingAmlCheck,
              }"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3082 8.7459C18.7081 7.39808 17.747 6.24245 16.5311 5.40679C15.3152 4.57112 13.8919 4.08798 12.4185 4.01076C10.9452 3.93354 9.47917 4.26527 8.18258 4.96926C7.50896 5.33501 6.8947 5.79383 6.3565 6.32947"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M3.23187 5.51544L3.96128 8.67497C4.08551 9.2131 4.62246 9.54864 5.16059 9.4244L8.32013 8.69499"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M4.69161 15.2539C5.2917 16.6017 6.25282 17.7574 7.46872 18.593C8.68462 19.4287 10.1079 19.9118 11.5813 19.989C13.0546 20.0663 14.5207 19.7345 15.8172 19.0305C16.4318 18.6969 16.9969 18.2858 17.5 17.8095"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20.7681 18.4846L20.0387 15.325C19.9145 14.7869 19.3775 14.4514 18.8394 14.5756L15.6799 15.305"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>

            <div class="ms-1">{{ $t("aml_widget.btn_check") }}</div>
          </CCButton>
        </b-col>
      </b-row>
    </div>
    <div class="h-100" v-else>
      <b-row align-v="center">
        <b-col cols="auto" class="d-flex align-items-center">
          <b-skeleton class="md" width="247px" height="20px"></b-skeleton>
        </b-col>
      </b-row>
      <b-skeleton class="md mt-4" width="247px" height="36px"></b-skeleton>
      <b-skeleton class="sm mt-1" width="280px" height="17px"></b-skeleton>
      <b-row class="mt-4">
        <b-col cols="12" md="6">
          <b-skeleton class="button" width="100%" height="48px"></b-skeleton>
        </b-col>
        <b-col cols="12" md="6">
          <b-skeleton class="button" width="100%" height="48px"></b-skeleton>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useAmlStore } from "@/stores/amlStore";
import { useWalletStore } from "@/stores/wallet";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    interval: null,
  }),
  methods: {
    ...mapActions(() => useAmlStore(), ["amlCheck", "amlCheckInfo"]),
    openModal() {
      this.$bvModal.show("aml-buy-modal");
    },
    async amlCheckStart() {
      await this.amlCheck(this.selectedWallet.id);
      this.interval = setInterval(async () => {
        const result = await this.amlCheckInfo();
        if (result != "None") {
          clearInterval(this.interval);
        }
      }, 30000);
    },
  },
  computed: {
    ...mapState(() => useUserStore(), ["user"]),
    ...mapState(() => useWalletStore(), ["selectedWallet"]),
    ...mapState(() => useAmlStore(), ["isLoadingAmlCheck", "amlLink"]),
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.aml-widget__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
  color: #5f5f5f;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
}
.aml-widget__balance {
  color: #3c3c3c;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
