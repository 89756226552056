<template>
  <div class="hold-wallet-widget">
    <div v-if="!isLoading">
      <div class="hold-wallet-widget__header">
        <img src="@/assets/images/hold-wallet3.svg" alt="" />
        <p class="title mb-0">Накопительный счет</p>
      </div>
      <p class="hold-wallet-widget__target-name mb-0">
        {{ targetName }}
      </p>
      <ProgressBar
        class="mt-3"
        variant="hold_wallet"
        :amountValue="balance"
        :amountMax="targetAmount"
      />
      <div class="mt-4" @click="topUpBalanceHoldWallet">
        <CCButton
          fontWeight="medium"
          size="lg"
          class="top-up-btn d-none d-sm-block px-4"
        >
          <div class="d-flex justify-content-center align-items-center">
            <img src="@/assets/images/download-icon-white.svg" alt="" />
            <span class="ms-2">Пополнить</span>
          </div>
        </CCButton>
        <CCButton
          fontWeight="medium"
          size="md"
          class="top-up-btn d-block d-sm-none px-4"
        >
          <div class="d-flex justify-content-center align-items-center">
            <img src="@/assets/images/download-icon-white.svg" alt="" />
            <span class="ms-2">Пополнить</span>
          </div>
        </CCButton>
      </div>
    </div>
    <div class="h-100" v-else>
      <b-row align-v="center">
        <b-col cols="auto" class="d-flex align-items-center">
          <b-skeleton class="md" width="247px" height="20px"></b-skeleton>
        </b-col>
      </b-row>
      <b-skeleton class="md mt-4" width="247px" height="36px"></b-skeleton>
      <b-skeleton class="sm mt-1" width="280px" height="17px"></b-skeleton>
      <b-row class="mt-4">
        <b-col cols="12" md="6">
          <b-skeleton class="button" width="100%" height="48px"></b-skeleton>
        </b-col>
        <b-col cols="12" md="6">
          <b-skeleton class="button" width="100%" height="48px"></b-skeleton>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../UI/ProgressBar.vue";
export default {
  components: { ProgressBar },
  props: {
    balance: {
      type: Number | String,
      default: 0,
    },
    targetAmount: {
      type: Number | String,
      default: 0,
    },
    targetName: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    topUpBalanceHoldWallet() {
      this.$emit("onTopUpBalance");
    },
  },
};
</script>

<style lang="scss" scoped>
.hold-wallet-widget {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 0;
    background: none;
  }
  &__header {
    display: flex;
    align-items: center;
    .title {
      margin-left: 8px;
      color: #5f5f5f;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.16px;
    }
  }
  &__target-name {
    margin-top: 12px;
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.top-up-btn {
  max-width: 194px;
  @media (max-width: 576px) {
    max-width: none;
  }
}
</style>
