<template>
  <div class="accept-transfer">
    <HeaderBackLink :title="$t('accept_page.title')" class="d-none d-sm-block">
      <img
        src="@/assets/images/download-icon.svg"
        class="download-icon"
        alt="download-icon"
      />
    </HeaderBackLink>
    <HeaderMobile :title="$t('accept_page.title')" class="d-block d-sm-none">
      <img
        @click="$router.back()"
        slot="button-left"
        src="@/assets/images/back-icon.svg"
        alt=""
      />
    </HeaderMobile>
    <b-row class="mt-4 accept-transfer__content d-none d-sm-flex">
      <b-col cols="12" lg="4" xl="3">
        <!-- qr-code -->
        <QrCode :isLoading="isLoadingWalletInfo" :qrData="qrOptions" />
      </b-col>
      <b-col cols="12" xl="9" class="mt-4 mt-xl-0">
        <AcceptData
          v-if="!isLoadingWalletInfo"
          class="h-100"
          @buy="buyToken"
          @exchange="exchangeToken(wallet.address, wallet.currencyObject.code)"
          @send="sendToken(wallet.address, wallet.currencyObject.code)"
          :fullName="wallet.currencyObject.full_name"
          :address="wallet.address"
          :network="wallet.currencyObject.code"
          :balance="wallet.balance"
          :balanceUsd="wallet.balanceUsd"
          :currencyCode="wallet.currencyObject.code"
        />
        <CCCard class="h-100" v-else>
          <div class="h-100 d-flex justify-content-center align-items-center">
            <img
              width="80px"
              class="loader"
              src="../assets/images/loader-accent.svg"
              alt=""
            />
          </div>
        </CCCard>
      </b-col>
    </b-row>
    <b-row class="d-flex d-sm-none">
      <b-col cols="12">
        <AcceptDataMobile
          v-if="!isLoadingWalletInfo"
          @buy="buyToken"
          @exchange="exchangeToken(wallet.address, wallet.currencyObject.code)"
          @send="sendToken(wallet.address, wallet.currencyObject.code)"
          :isLoading="isLoadingWalletInfo"
          :fullName="wallet.currencyObject.full_name"
          :address="wallet.address"
          :network="wallet.currencyObject.code"
          :balance="wallet.balance"
          :balanceUsd="wallet.balanceUsd"
          :currencyCode="wallet.currencyObject.code"
          :qrData="qrOptions"
        />
        <CCCard style="height: 480px" v-else>
          <div class="h-100 d-flex justify-content-center align-items-center">
            <img
              width="80px"
              class="loader"
              src="../assets/images/loader-accent.svg"
              alt=""
            />
          </div>
        </CCCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import ButtonMobile from "@/components/UI/ButtonMobile.vue";
import { WalletController } from "@/core/system/controllers";
import WalletEntity from "@/core/system/entities/walletEntity";
import {
  sliceNetworkCodeCurrency,
  getRoundedAmountCrypto,
  getRoundedAmountFiat,
} from "@/utils";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { mapActions, mapState } from "pinia";
import Clipboard from "@/components/UI/Clipboard.vue";
import { useExchangeStore } from "@/stores/exchangeStore";
import { useBuyStore } from "@/stores/buyStore";
import QrCode from "@/components/AcceptCrypto/QrCode.vue";
import AcceptData from "@/components/AcceptCrypto/AcceptData.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import AcceptDataMobile from "@/components/AcceptCrypto/AcceptDataMobile.vue";
import { useWalletStore } from "@/stores/wallet";

export default {
  name: "AcceptTransfer",
  components: {
    HeaderBackLink,
    ButtonMobile,
    QRCanvas,
    Clipboard,
    QrCode,
    AcceptData,
    HeaderMobile,
    AcceptDataMobile,
  },
  data() {
    return {
      isLoadingWalletInfo: true,
      wallet: new WalletEntity({}),
      qrOptions: {
        size: 1068,
        correctLevel: "H",
        padding: 0,
        effect: "fusion",
        foreground: "#3C3C3C",
      },
    };
  },
  computed: {
    ...mapState(useWalletStore, ["selectedWallet"]),
    ...mapState(useSendTransferStore, ["optionsCurrency"]),
    walletCode() {
      return this.wallet.currencyObject.code.split("_").join("-");
    },
  },
  methods: {
    ...mapActions(useSendTransferStore, [
      "setSelectedCurrency",
      "getAllCurrencyBalance",
      "setDataWalletToSend",
    ]),
    ...mapActions(useExchangeStore, ["setDataWalletToExchange"]),
    ...mapActions(useBuyStore, ["setCodeWalletToBuy"]),
    sliceNetworkCodeCurrency,
    getRoundedAmountCrypto,
    getRoundedAmountFiat,
    goBack() {
      this.$router.back();
    },
    sendToken(address, code) {
      const sendData = {
        address,
        code,
      };
      this.setDataWalletToSend(sendData);
      this.$router.push("/send");
    },
    exchangeToken(address, code) {
      const exchangeData = {
        address,
        code,
      };
      this.setDataWalletToExchange(exchangeData);
      this.$router.push("/exchange");
    },
    buyToken() {
      this.setCodeWalletToBuy(this.walletCode);
      this.$router.push("/buy");
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    async getWalletInfo() {
      const payload = {
        code: this.$route.params.currency.toUpperCase(),
        address: localStorage.getItem("selected_wallet_address"),
      };
      try {
        const response = await WalletController.getWalletDetail(payload);
        this.wallet = response;
        const image = new Image();
        image.src = this.wallet.currencyObject.icon_qr;
        image.width = 286;
        image.height = 286;
        this.qrOptions = {
          ...this.qrOptions,
          data: this.wallet.address,
          logo: {
            image,
          },
        };
      } catch (error) {
        // this.goBack();
        console.log(error);
      } finally {
        this.isLoadingWalletInfo = false;
      }
    },
  },

  async mounted() {
    await this.getWalletInfo();
    // this.getAllCurrencyBalance();
  },
};
</script>

<style lang="scss" scoped>
.fs-14 {
  font-size: 14px !important;
}
.pointer {
  cursor: pointer;
}
</style>
