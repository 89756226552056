import * as bitcoin from "bitcoinjs-lib";
import { encodePassword, decodePassword } from "@/utils/encryption";
import axios from "axios";

export class CreateLitecoinWallet {
  constructor(password) {
    this.password = password;
  }
  create() {
    const wallet = {
      privateKey: "",
      address: "",
    };
    const litecoinNetwork = {
      messagePrefix: "\x19Litecoin Signed Message:\n",
      bech32: "ltc",
      bip32: {
        public: 0x019da462,
        private: 0x019d9cfe,
      },
      pubKeyHash: 0x30,
      scriptHash: 0x32,
      wif: 0xb0,
    };
    const keyPair = bitcoin.ECPair.makeRandom({ network: litecoinNetwork });
    const { address } = bitcoin.payments.p2wpkh({
      pubkey: keyPair.publicKey,
      network: litecoinNetwork,
    });

    wallet.privateKey = keyPair.toWIF();
    wallet.address = address;
    const encrypted = encodePassword(this.password, JSON.stringify(wallet));
    const data = {
      address: wallet.address,
      encrypted: encrypted,
    };
    return data;
  }
}

export class CreateLitecoinTransactions {
  constructor(privateKey, address, amount, addressTo, outputs = []) {
    this.privateKey = privateKey;
    this.address = address;
    this.amount = amount;
    this.addressTo = addressTo;
    this.outputs = outputs;
  }
  getAddressScript(address, network) {
    const decoded = bitcoin.address.toOutputScript(address, network);
    return decoded.toString("hex");
  }
  async getUTXOsForAddress() {
    const headers = {
      "api-key": "d06c9e92-609b-4128-a497-d0c2c289d013",
    };
    const response = await axios.get(
      `https://ltcbook.nownodes.io/api/v2/utxo/${this.address}`,
      { headers: headers }
    );
    return response.data;
  }
  async getActualInputs(amount) {
    try {
      const utxs = await this.getUTXOsForAddress();
      let sum = 0;
      let result = [];

      for (let i = 0; i < utxs.length; i++) {
        const utxValue = Number(utxs[i].value);

        if (sum <= amount) {
          sum += utxValue;
          result.push(utxs[i]);
        }
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  async buildTransaction() {
    const ltcNetwork = {
      messagePrefix: "\x19Litecoin Signed Message:\n",
      bech32: "ltc",
      bip32: {
        public: 0x019da462,
        private: 0x019d9cfe,
      },
      pubKeyHash: 0x30,
      scriptHash: 0x32,
      wif: 0xb0,
    };

    const fee = 100000;
    const keyPair = bitcoin.ECPair.fromWIF(this.privateKey, ltcNetwork);
    const psbt = new bitcoin.Psbt({ network: ltcNetwork });

    const amountSatoshi = Math.ceil(this.amount * 10 ** 8);

    const inputs = await this.getActualInputs(amountSatoshi);

    inputs.forEach((input) => {
      psbt.addInput({
        hash: input.txid,
        index: input.vout,
        witnessUtxo: {
          script: Buffer.from(
            this.getAddressScript(this.address, ltcNetwork),
            "hex"
          ),
          value: parseInt(input.value),
        },
      });
    });

    const totalInputAmount = inputs.reduce(
      (acc, input) => acc + Number(input.value),
      0
    );

    let amountSend = amountSatoshi;

    const change = totalInputAmount - amountSatoshi - fee;

    if (change > 0) {
      psbt.addOutput({
        address: this.address,
        value: change,
      });
    } else {
      amountSend = amountSatoshi - fee;
    }

    if (this.outputs.length > 0) {
      this.outputs.forEach((output) => {
        psbt.addOutput({
          address: output.address,
          value: amountSend,
        });
      });
    } else {
      psbt.addOutput({
        address: this.addressTo,
        value: amountSend,
      });
    }

    for (let i = 0; i < inputs.length; i++) {
      psbt.signInput(i, keyPair);
    }

    psbt.finalizeAllInputs();
    const tx = psbt.extractTransaction();
    return tx.toHex();
  }
  async sendRawTransaction() {
    const headers = {
      "api-key": "d06c9e92-609b-4128-a497-d0c2c289d013",
    };
    const hexTransaction = await this.buildTransaction();
    const raw = {
      API_key: "d06c9e92-609b-4128-a497-d0c2c289d013",
      jsonrpc: "2.0",
      id: "test",
      method: "sendrawtransaction",
      params: [hexTransaction],
    };
    const response = await axios.post(`https://ltc.nownodes.io`, raw, {
      headers: headers,
    });
    console.log(response.data);
    return response.data;
  }
}
