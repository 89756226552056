<template>
  <div class="address-list">
    <div class="address-list__header">
      <div class="title-block d-flex align-items-center">
        <p class="title mb-0">Список адресов в формате CSV</p>
        <img class="ms-2" src="@/assets/images/tooltip-icon2.svg" alt="" />
      </div>
      <div
        @click="openSettings"
        class="settings-block d-none d-md-flex align-items-center"
      >
        <p class="settings mb-0">Настройки</p>
        <img class="ms-2" src="@/assets/images/settings/settings.svg" alt="" />
      </div>
    </div>
    <div class="textarea-container mt-3">
      <div class="line-numbers" ref="lineNumbers">
        <div class="line-number" v-for="line in lines" :key="line">
          {{ line }}
        </div>
      </div>
      <textarea
        ref="textarea"
        placeholder="3Gbaro9CXaiAGK8w3xqHRqL6N5DkcpvGFH, 0.000056"
        v-model="addressesDataInput"
        @input="saveTransactionData"
        @scroll="syncScroll"
      ></textarea>
      <div class="d-none d-md-block">
        <button class="load-csv-btn">
          <label
            for="fileInputDesktop"
            class="w-100 h-100 pointer d-flex justify-content-center align-items-center"
          >
            <img
              class="btn-icon"
              src="@/assets/images/upload-icon.svg"
              alt=""
            />
            <span class="btn-text">Загрузить CSV</span>
          </label>
        </button>
        <input
          @change="loadCsv"
          type="file"
          id="fileInputDesktop"
          accept=".csv"
          style="display: none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSendMultiStore } from "@/stores/sendMultiStore";
import { validateAndFormatNumber } from "@/utils/validateInput";
export default {
  data() {
    return {
      lines: [1],
      idRow: 0,
    };
  },
  methods: {
    ...mapActions(useSendMultiStore, ["setAddressList", "setAddressesData"]),
    openSettings() {
      this.$emit("onOpenSettings");
    },
    loadCsv(event) {
      console.log(event);
      this.$emit("onLoadCsv", event);
    },
    updateLines() {
      const lineCount = this.addressesDataInput.split("\n").length;
      this.lines = Array.from({ length: lineCount }, (_, i) => i + 1);
    },
    syncScroll() {
      this.$refs.lineNumbers.scrollTop = this.$refs.textarea.scrollTop;
    },
    saveTransactionData() {
      this.updateLines();
      const arrayLinesData = this.addressesDataInput.split("\n");
      const newArr = arrayLinesData.map((line) => {
        const separator = this.separator != "" ? this.separator : ";";
        const amountAdressArr = line.split(separator);
        this.idRow++;
        const data = {
          id: this.idRow,
          address: amountAdressArr[0],
          amount: amountAdressArr[1],
        };
        return data;
      });
      this.setAddressList(newArr);
    },
  },
  computed: {
    ...mapState(useSendMultiStore, ["separator", "addressesTypedData"]),
    addressesDataInput: {
      get() {
        return this.addressesTypedData;
      },
      set(value) {
        this.setAddressesData(value);
      },
    },
  },
  mounted() {
    this.saveTransactionData()
  }
};
</script>

<style lang="scss" scoped>
.load-csv-btn {
  width: 125px;
  height: 40px;
  border-radius: 60px;
  border: 1px solid #f0f0f0;
  background: #fff;
  position: absolute;
  right: 24px;
  bottom: 24px;
  .btn-icon {
    width: 12px;
    height: 12px;
  }
  .btn-text {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #3c3c3c;
  }
}
.address-list {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }
    .settings-block {
      cursor: pointer;
      .settings {
        color: #4a40c6;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .textarea-container {
    height: 280px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    background: #fff;
    position: relative;
  }

  .line-numbers {
    padding: 16px;
    border-right: 1px solid #f0f0f0;
    user-select: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40px; /* adjust as needed */
    @media (max-width: 768px) {
      border-right: 0;
    }
  }

  .line-number {
    font-size: 16px;
    font-weight: 400;
    display: block;
    height: 2em; /* match line height of textarea */
    line-height: 2;
    color: #3c3c3c;
  }

  textarea {
    flex: 1;
    padding-left: 60px; /* adjust to match width of line numbers */
    padding-top: 16px;
    line-height: 2;
    border: none;
    outline: none;
    resize: none;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 300;
    &::-webkit-scrollbar {
      width: 2px !important;
    }
    &::-webkit-scrollbar-track {
      background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
      height: 56px;
      background-color: #9f9f9f;
      border-radius: 100px;
    }
    @media (max-width: 768px) {
      padding-left: 38px;
      resize: horizontal;
      max-width: 100%;
      width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      display: block;
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>
