<template>
  <CCCard padding="card24" class="header-back-link">
    <b-row align-v="center">
      <b-col cols>
        <div class="header-back-link__title d-flex align-items-center">
          <div class="header-back-link__image">
            <slot></slot>
          </div>
          <h3 class="my-0 ms-3 header-back-link__title">{{ title }}</h3>
        </div>
      </b-col>
      <b-col cols="auto" class="ms-auto">
        <LinkBackPage @click="$router.back()" />
      </b-col>
    </b-row>
  </CCCard>
</template>

<script>
import LinkBackPage from "../UI/LinkBackPage.vue";

export default {
  name: "HeaderBackLink",
  components: {
    LinkBackPage,
  },
  props: {
    title: String,
  },
};
</script>
