<template>
  <div class="wallet-created">
    <CCCard padding="card24">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/wallet/suceess.svg" alt="" />
        <h2 class="select-network__title mb-0 ms-2">
          {{ $t("create_wallet_page.wallet_success.title") }}
        </h2>
      </div>
      <p class="select-network__text mb-0 mt-2">
        {{ $t("create_wallet_page.wallet_success.text1") }}
      </p>
      <div class="input-block mt-4">
        <div class="label">
          <h6>{{ $t("create_wallet_page.wallet_success.nickname_label") }}</h6>
        </div>
        <CCInput
          v-model="nickname"
          placeholder="Никнейм"
          class="input-with-icon"
        />
      </div>
      <div class="warning d-flex mt-4">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.wallet_success.text2") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.wallet_success.text3") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p
          class="warning-text mb-0 ms-2"
          v-html="$t('create_wallet_page.wallet_success.text4')"
        ></p>
      </div>
      <CCButton
        :disabled="!isValidNickname"
        @click="setNicknameWallet"
        size="lg"
        class="mt-4"
        >{{ $t("create_wallet_page.wallet_success.btn_save") }}</CCButton
      >
    </CCCard>
  </div>
</template>

<script>
import { WalletController } from "@/core/system/controllers";
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import { useInterfaceStore } from "@/stores/interfaceStore";
export default {
  data: () => ({
    isLoadingSetNickname: false,
    nickname: "",
  }),
  methods: {
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    async setNicknameWallet() {
      this.isLoadingSetNickname = true;
      const payload = {
        wallet_network_id: this.idCreatedWallet,
        nickname: this.nickname,
      };
      try {
        await WalletController.addNicknameWallet(payload);
      } catch (error) {
        console.log(error);
        this.showMessage("danger", "Ошибка установки никнейма");
      } finally {
        this.isLoadingSetNickname = false;
        this.$router.push("/");
      }
    },
  },
  computed: {
    isValidNickname() {
      if (this.nickname != "") {
        return this.nickname.length > 2 && this.nickname.length <= 14;
      }
      return true;
    },
    ...mapState(() => useWalletStore(), ["idCreatedWallet"]),
  },
};
</script>

<style></style>
