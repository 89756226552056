<template>
  <b-modal id="eth-fee-modal" centered hide-footer>
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 fee-modal__title">Настройка комиссии</h3>
      <img
        @click="closeModal"
        class="pointer"
        src="@/assets/images/close-modal.svg"
        alt=""
      />
    </div>
    <div class="fee-modal__content" v-if="settingsMode === 'default'">
      <div v-if="!isLoadingVariants">
        <div
          v-for="variant in variantsFee"
          @click="selectVariantFee(variant)"
          :key="variant.mode"
        >
          <VariantFeeCard
            currencyFee="ETH"
            class="fee-modal__card"
            :isActive="variant.mode === selectedVariantFee.mode"
            :duration="variant.maxWaitTimeEstimateSeconds"
            :isOptimalVariant="variant.mode != 'low'"
            :variant="variant.mode"
            :variantText="variant.modeName"
            :amountFee="getRoundedAmountCrypto(variant.amountFeeEth)"
          />
        </div>
      </div>
      <div v-else>
        <b-skeleton
          v-for="index in 3"
          :key="index"
          class="sm fee-modal__card"
          width="100%"
          height="58px"
        ></b-skeleton>
      </div>
    </div>
    <div class="w-100" v-if="settingsMode === 'custom'">
      <div class="input-block mt-4">
        <div class="d-flex align-items-center">
          <div class="label">
            <h6 class="mb-0">Максимальная базовая комиссия (Гвей)</h6>
          </div>
          <img
            class="ms-2 pointer"
            src="@/assets/images/tooltip-icon.svg"
            alt=""
          />
        </div>
        <div class="input-block__input">
          <CCInput
            v-model="maxBaseFee"
            @input="validateMaxBaseFee"
            class="send-address-input mt-2"
          ></CCInput>
          <span class="amount-eth"
            >~{{ getRoundedAmountCrypto(maxBaseFeeEth) }} ETH</span
          >
        </div>
      </div>
      <div class="input-block mt-4">
        <div class="d-flex align-items-center">
          <div class="label">
            <h6 class="mb-0">Плата за приоритет (Гвей)</h6>
          </div>
          <img
            class="ms-2 pointer"
            src="@/assets/images/tooltip-icon.svg"
            alt=""
          />
        </div>
        <div class="input-block__input">
          <CCInput
            v-model="priorityFeeGwei"
            @input="validatePriorityFee"
            class="send-address-input mt-2"
          ></CCInput>
          <span class="amount-eth"
            >~{{ getRoundedAmountCrypto(priorityFeeEth) }} ETH</span
          >
        </div>
      </div>
    </div>
    <div class="checkbox-block" @click="saveChangesToggle">
      <Checkbox id="checkbox1" v-model="isSaveDefaultSettings" />
      <p class="checkbox-block__text mb-0">
        Сохранить эти значения по умолчанию для сети ETH.
      </p>
    </div>
    <div class="mt-4 w-100 d-none d-sm-block">
      <CCButton
        :disabled="isLoadingVariants"
        @click="saveChanges"
        fontWeight="medium"
        size="lg"
        class="px-4"
      >
        Сохранить
      </CCButton>
    </div>
    <div class="mt-4 w-100 d-block d-sm-none">
      <CCButton
        :disabled="isLoadingVariants"
        @click="saveChanges"
        fontWeight="medium"
        size="md"
        class="px-4"
      >
        Сохранить
      </CCButton>
    </div>
    <!-- <template #modal-footer>
      <div class="w-100">
        <div class="more-settings-btn" @click="goToSecondStep">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/feeSettings/settings.svg" alt="" />
            <p class="mb-0 text">Дополнительные настройки</p>
          </div>
          <img src="@/assets/images/feeSettings/forward.svg" alt="" />
        </div>
        
      </div>
      <div class="mt-4 w-100">
        <CCButton
          @click="saveChanges"
          fontWeight="medium"
          size="lg"
          class="px-4"
        >
          Сохранить
        </CCButton>
      </div>
    </template> -->
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Checkbox from "../UI/Checkbox.vue";
import VariantFeeCard from "./VariantFeeCard.vue";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { getRoundedAmountCrypto } from "@/utils";
import { validateAndFormatNumber } from "@/utils/validateInput";

export default {
  components: { VariantFeeCard, Checkbox },
  props: {
    isLoadingVariants: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isSaveDefaultSettings: false,
    settingsMode: "default",
  }),
  methods: {
    ...mapActions(useSendTransferStore, [
      "setSelectedVariantFee",
      "setMaxFeeGwei",
      "setPriorityFeeGwei",
      "setFee",
    ]),
    getRoundedAmountCrypto,
    validateMaxBaseFee() {
      this.setMaxFeeGwei(validateAndFormatNumber(this.maxBaseFeeGwei));
    },
    validatePriorityFee() {
      this.setPriorityFeeGwei(validateAndFormatNumber(this.priorityFeeGwei));
    },
    saveChangesToggle() {
      this.isSaveDefaultSettings = !this.isSaveDefaultSettings;
    },
    closeModal() {
      this.$bvModal.hide("eth-fee-modal");
      this.goToFirstStep();
    },
    goToFirstStep() {
      this.settingsMode = "default";
    },
    goToSecondStep() {
      this.settingsMode = "custom";
    },
    selectVariantFee(variant) {
      this.setSelectedVariantFee(variant);
      this.setMaxFeeGwei(variant.suggestedMaxFeePerGas);
      this.setPriorityFeeGwei(variant.suggestedMaxPriorityFeePerGas);
    },
    saveChanges() {
      const newFeeObj = {
        total: this.selectedVariantFee.amountFeeEth,
        gasLimit: this.gasLimitEth,
        maxPriorityFeePerGas:
          this.selectedVariantFee.suggestedMaxPriorityFeePerGas,
        maxFeePerGas: this.selectedVariantFee.suggestedMaxFeePerGas,
      };
      this.closeModal();
      this.setFee(newFeeObj);
      if (this.isSaveDefaultSettings) {
        localStorage.setItem(
          "selectedVariantFeeEth",
          JSON.stringify(this.selectedVariantFee)
        );
      } else if (
        !this.isSaveDefaultSettings &&
        localStorage.getItem("selectedVariantFeeEth")
      ) {
        localStorage.removeItem("selectedVariantFeeEth");
      }
    },
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "variantsFee",
      "selectedVariantFee",
      "maxBaseFeeGwei",
      "priorityFeeGwei",
      "gasLimitEth",
    ]),
    maxBaseFee: {
      get() {
        return this.maxBaseFeeGwei;
      },
      set(value) {
        this.setMaxFeeGwei(value);
      },
    },
    priorityFee: {
      get() {
        return this.priorityFeeGwei;
      },
      set(value) {
        this.setPriorityFeeGwei(value);
      },
    },
    maxBaseFeeEth() {
      return (Number(this.maxBaseFeeGwei) * this.gasLimitEth) / 10 ** 9;
    },
    priorityFeeEth() {
      return (Number(this.priorityFeeGwei) * this.gasLimitEth) / 10 ** 9;
    },
  },
  mounted() {
    if (localStorage.getItem("selectedVariantFeeEth")) {
      this.isSaveDefaultSettings = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.input-block__input {
  position: relative;

  .amount-eth {
    position: absolute;
    top: 17px;
    right: 20px;
    color: #9f9f9f;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.checkbox-block {
  cursor: pointer;
  margin-top: 12px;
  display: flex;
  align-items: center;

  &__text {
    margin-left: 8px;
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}

.more-settings-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px 12px 12px;
  border-radius: 6px;
  background: #fbfbfb;

  .text {
    margin-left: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
  }
}

.close-btn {
  cursor: pointer;
  user-select: none;
}

.confirm-btn {
  margin-top: 36px;

  @media (max-width: 576px) {
    margin-top: 24px;
    height: 40px !important;
  }
}

.fee-modal__title {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.fee-modal__content {
  padding-top: 12px;

  //   border-bottom: 2px solid #F0F0F0;
  .fee-modal__card {
    margin-top: 12px;
  }

  .content-text {
    color: #939393;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.loading-block {
  height: 239px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    user-select: none;
  }
}
</style>
