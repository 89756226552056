import BaseEntity from "../entities/baseEntity";

class UserEntitry extends BaseEntity {
  constructor(data = {}) {
    super("user");
    this.email = data.email || "";
    this.token = data.token || "";
    this.is_active = data.is_active || false;
    this.is_email_confirming = data.is_email_confirming || false;
    this.balance_usd = data.balance_usd || 0;
    this.available_aml_check = data.available_aml_check || 0;
  }
}

export default UserEntitry;
