<template>
  <div class="invoice-balance h-100" v-if="variant === 'inside'">
    <CCCard padding="card24" class="h-100" v-if="!isLoading">
      <b-row class="align-content-between h-100">
        <b-col cols="12">
          <b-row align-v="center">
            <b-col cols="auto" class="d-flex align-items-center">
              <img
                src="@/assets/images/check2.svg"
                alt="current-balance-icon"
              />
              <h4 class="ps-2 m-0 invoice-balance__title">
                {{ $t("balance_card.title2") }}
              </h4>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <a
                target="_blank"
                :href="historyLink"
                class="invoice-balance__title d-flex align-items-center"
              >
                <span class="">{{ $t("balance_card.history") }}</span>
                <img
                  class="ms-2"
                  width="24px"
                  height="24px"
                  src="@/assets/images/history-icon.svg"
                  alt="history-stack-icon"
                />
              </a>
            </b-col>
          </b-row>
          <div class="mt-4 invoice-balance__balance">$ {{ convertBalanceUsd(balance) }}</div>
          <div class="invoice-balance__address mt-3">{{ address }}</div>
        </b-col>
      </b-row>
    </CCCard>
    <CCCard padding="card24" class="h-100" v-else>
      <b-row class="align-content-between h-100">
        <b-col cols="12">
          <b-row align-v="center">
            <b-col cols="auto" class="d-flex align-items-center">
              <img
                src="@/assets/images/check2.svg"
                alt="current-balance-icon"
              />
              <h4 class="ps-2 m-0 invoice-balance__title">
                {{ $t("balance_card.title2") }}
              </h4>
            </b-col>
          </b-row>
          <b-skeleton
            class="sm accent mt-4"
            width="305px"
            height="29px"
          ></b-skeleton>
          <b-skeleton
            class="md accent mt-1"
            width="366px"
            height="42px"
          ></b-skeleton>
          <b-skeleton
            class="sm accent mt-3"
            width="80%"
            height="29px"
          ></b-skeleton>
        </b-col>
      </b-row>
    </CCCard>
  </div>
  <div class="invoice-balance general" v-else>
    <CCCard v-if="!isLoading" class="card-main h-100" padding="card24">
      <b-row align-v="center" class="align-content-between h-100">
        <b-col cols="12">
          <b-row align-v="center">
            <b-col cols="auto" class="d-flex align-items-center">
              <img
                src="@/assets/images/check2.svg"
                alt="current-balance-icon"
              />
              <h4 class="ps-2 m-0 invoice-balance__title">
                {{ $t("balance_card.title1") }}
              </h4>
            </b-col>
          </b-row>
          <div class="mt-4 invoice-balance__balance small">
            $ {{ convertBalanceUsd(balance) }}
          </div>
          <router-link to="/send">
            <button class="send-btn mt-4">
              <div class="d-flex align-items-center">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 6.5L17 6.5C19.2091 6.5 21 8.29086 21 10.5L21 15.5C21 17.7091 19.2091 19.5 17 19.5L16 19.5L11 19.5L6 19.5L5 19.5C2.79086 19.5 1 17.7091 1 15.5L1 10.5C1 8.29086 2.79086 6.5 5 6.5L7 6.5"
                    class="arrow-send"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M14 3.5L11.7071 1.20711C11.3166 0.816583 10.6834 0.816583 10.2929 1.20711L8 3.5"
                    class="arrow-send"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11 1.5L11 13.5"
                    class="arrow-send"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <span>{{ $t("balance_card.btn_send") }}</span>
              </div>
            </button>
          </router-link>
        </b-col>
      </b-row>
    </CCCard>
    <CCCard v-else class="card-main h-100" padding="card24">
      <b-row align-v="center" class="align-content-between h-100">
        <b-col cols="12">
          <b-row align-v="center">
            <b-col cols="auto" class="d-flex align-items-center">
              <img
                src="@/assets/images/check2.svg"
                alt="current-balance-icon"
              />
              <h4 class="ps-2 m-0 invoice-balance__title">
                {{ $t("balance_card.title1") }}
              </h4>
            </b-col>
          </b-row>
          <b-skeleton
            class="sm accent mt-4"
            width="252px"
            height="37px"
          ></b-skeleton>
          <b-skeleton
            class="button accent mt-4"
            width="166px"
            height="48px"
          ></b-skeleton>
        </b-col>
      </b-row>
    </CCCard>
  </div>
</template>

<script>
import { getHistoryAddressLink } from "@/utils";
import ButtonMobile from "../UI/ButtonMobile.vue";
import { convertBalanceUsd } from "@/utils";

export default {
  components: { ButtonMobile },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "general",
      validator: (value) => {
        return ["inside", "general"].includes(value);
      },
    },
    balance: {
      type: Number || undefined,
      required: true,
      default: () => 0,
    },
    address: String,
    network: {
      type: String,
      default: ''
    },
  },
  name: "InvoiceBalanceCard",
  methods: {
    convertBalanceUsd
  },
  computed: {
    historyLink() {
      return getHistoryAddressLink(this.network, this.address);
    },
  },
};
</script>

<style lang="scss" scoped>
.staking-btn {
  width: 35%;
}

.send-btn {
  width: fit-content;
  transition: 0.2s ease-in;
  height: 48px;
  padding: 0 24px 0 24px;
  border-radius: 90px;
  border: 1px solid #fff;
  background: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  span {
    margin-left: 12px;
  }
  .arrow-send {
    transition: 0.2s ease-in;
    width: 24px;
    height: 24px;
    stroke: #fff;
  }
  &:hover {
    background: #fff;
    border: 1px solid #fff;
    color: #6450d1;
    .arrow-send {
      stroke: #6450d1;
    }
  }
}
</style>
