import { render, staticRenderFns } from "./SaveWallet.vue?vue&type=template&id=af01e56a&scoped=true"
import script from "./SaveWallet.vue?vue&type=script&lang=js"
export * from "./SaveWallet.vue?vue&type=script&lang=js"
import style0 from "./SaveWallet.vue?vue&type=style&index=0&id=af01e56a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af01e56a",
  null
  
)

export default component.exports