<template>
  <div class="confirm-data" v-if="!isLoading">
    <b-row>
      <b-col cols="12" md="6">
        <h4 class="confirm-data__title mb-0">Баланс вашего токена</h4>
        <p class="confirm-data__value mb-0 mt-md-2">{{ balance }}</p>
      </b-col>
      <b-col cols="12" md="6" class="mt-3 mt-md-0">
        <h4 class="confirm-data__title mb-0">Количество токенов отправки</h4>
        <p class="confirm-data__value mb-0 mt-md-2">
          {{ amount }} {{ currency }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="6">
        <h4 class="confirm-data__title mb-0">Комиссия сети</h4>
        <p class="confirm-data__value mb-0 mt-md-2 accent">
          {{ feeAmount }} {{ currency }}
        </p>
      </b-col>
      <b-col cols="6">
        <h4 class="confirm-data__title mb-0">Режим</h4>
        <div
          class="d-flex align-items-center pointer mt-md-2"
          @click="openSettingsMode"
        >
          <p class="confirm-data__value mb-0 accent">{{ feeMode }}</p>
          <img class="ms-2" src="@/assets/images/change-icon.svg" alt="" />
        </div>
      </b-col>
    </b-row>
  </div>
  <div class="confirm-data" v-else>
    <b-row>
      <b-col cols="12" md="6">
        <b-skeleton class="sm" width="50%" height="24px"></b-skeleton>
        <b-skeleton
          class="sm mb-0 mt-md-2"
          width="30%"
          height="24px"
        ></b-skeleton>
      </b-col>
      <b-col cols="12" md="6" class="mt-3 mt-md-0">
        <b-skeleton class="sm" width="70%" height="24px"></b-skeleton>
        <b-skeleton
          class="sm mb-0 mt-md-2"
          width="40%"
          height="24px"
        ></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="6">
        <b-skeleton class="sm" width="50%" height="24px"></b-skeleton>
        <b-skeleton
          class="sm mb-0 mt-md-2"
          width="60%"
          height="24px"
        ></b-skeleton>
      </b-col>
      <b-col cols="6">
        <b-skeleton class="sm" width="30%" height="24px"></b-skeleton>
        <b-skeleton
          class="sm mb-0 mt-md-2"
          width="40%"
          height="24px"
        ></b-skeleton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: Number | String,
      default: 0,
    },
    amount: {
      type: Number | String,
      default: 0,
    },
    currency: String,
    feeMode: String,
    feeAmount: Number | String,
  },
  methods: {
    openSettingsMode() {
      this.$emit("openSettingsMode");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-data {
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  &__title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #3c3c3c;
  }
  &__value {
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &.accent {
      color: #4a40c6;
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
    .confirm-data__title {
      font-size: 14px;
    }
    .confirm-data__value {
      font-size: 12px;
    }
  }
}
</style>
