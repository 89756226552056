<template>
  <div class="staking-main">
    <HeaderMobile title="Стейкинг TRX" class="d-block d-sm-none">
      <router-link slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row>
      <b-col cols="12" xl="7">
        <PreviewCard class="h-100" />
      </b-col>
      <b-col cols="12" xl="5" class="mt-5 mt-xl-0">
        <Resourses
          class="h-100"
          :isLoading="isLoadingStakingData"
          :amountEnergy="allResources.in_energy"
          :availableEnergy="allResources.available_energy"
          :amountBandwidth="allResources.in_bandwidth"
          :availableBandwidth="allResources.available_bandwidth"
        />
      </b-col>
    </b-row>
    <b-row class="mt-5 mt-lg-4">
      <b-col cols="12" lg="6">
        <BlockchainCard
          :isLoading="isLoadingStakingData"
          :amountFirst="allResources.available_energy"
          :amountLast="allResources.in_energy"
          :fromStaking="energyResources.from_staking"
          :delegatedBy="energyResources.delegated_by_other"
          :delegatedTo="energyResources.delegated_to_other"
          @getEnergy="getEnergy"
          name="Energy"
          variant="energy"
        />
      </b-col>
      <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
        <BlockchainCard
          :isLoading="isLoadingStakingData"
          :amountFirst="allResources.available_bandwidth"
          :amountLast="allResources.in_bandwidth"
          :fromStaking="bandwidthResources.from_staking"
          :delegatedBy="bandwidthResources.delegated_by_other"
          :delegatedTo="bandwidthResources.delegated_to_other"
          @getBandwidth="getBandwidth"
          name="Bandwidth"
          variant="bandwidth"
        />
      </b-col>
    </b-row>
    <StakingPopup @updateInfo="fetchStakingData(addressWallet)" />
    <SelectWalletPopup
      variant="staking"
      currencyTron="TRX"
      @confirm="confirmWallet"
    />
  </div>
</template>

<script>
import PreviewCard from "@/components/Staking/PreviewCard.vue";
import BlockchainCard from "@/components/BlockchainCard.vue";
import Resourses from "@/components/Staking/Resourses.vue";
import StakingPopup from "@/components/modals/StakingPopup.vue";
import { mapActions, mapState } from "pinia";
import { useStakingStore } from "@/stores/stakingStore";
import SelectWalletPopup from "@/components/modals/SelectWalletPopup.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
export default {
  components: {
    PreviewCard,
    BlockchainCard,
    Resourses,
    StakingPopup,
    SelectWalletPopup,
    HeaderMobile,
  },
  methods: {
    ...mapActions(
      () => useStakingStore(),
      [
        "setSelectedStakingPill",
        "fetchStakingData",
        "fetchWalletTrx",
        "updateBalanceTrx",
        "setIsLoadingStakingData",
      ]
    ),
    getEnergy() {
      this.setSelectedStakingPill(this.stakingPills[1]);
      this.$bvModal.show("staking-modal");
    },
    getBandwidth() {
      this.setSelectedStakingPill(this.stakingPills[0]);
      this.$bvModal.show("staking-modal");
    },
    async confirmWallet() {
      this.$root.$emit("bv::hide::modal", "select-wallet-modal");
      await this.fetchStakingData(this.addressWallet);
      await this.fetchWalletTrx(this.addressWallet);
      this.updateBalanceTrx();
    },
  },
  computed: {
    ...mapState(
      () => useStakingStore(),
      [
        "stakingPills",
        "allResources",
        "energyResources",
        "bandwidthResources",
        "isLoadingStakingData",
      ]
    ),
    addressWallet() {
      return JSON.parse(localStorage.getItem("tron_wallet")).address || "";
    },
  },
  async mounted() {
    this.setIsLoadingStakingData(true);
    this.$bvModal.show("select-wallet-modal");
  },
};
</script>

<style></style>
