import { mapActions } from "pinia";
import ValidationPassword from "@/components/ValidationPassword.vue";

function useValidationPassword(store) {
  return {
    components: { ValidationPassword },
    data() {
      return {
        newPassword: "",
        passwordRepeat: "",
        passwordRules: {
          letterSmall: false,
          letterBig: false,
          number: false,
          length: false,
        },
        passwordInputs: [
          {
            id: 0,
            type: "password",
            image: require("@/assets/images/eye-open-icon.svg"),
          },
          {
            id: 1,
            type: "password",
            image: require("@/assets/images/eye-open-icon.svg"),
          },
          {
            id: 2,
            type: "password",
            image: require("@/assets/images/eye-open-icon.svg"),
          },
        ],
      };
    },
    methods: {
      ...mapActions(store, ["setStatusChangePassword"]),
      checkPassword() {
        // Validate lowercase letters
        let lowerCaseLetters = /[a-z]/g;
        if (this.newPassword.match(lowerCaseLetters)) {
          this.passwordRules.letterSmall = true;
        } else {
          this.passwordRules.letterSmall = false;
        }

        // Проверка заглавных букв
        let upperCaseLetters = /[A-Z]/g;
        if (this.newPassword.match(upperCaseLetters)) {
          this.passwordRules.letterBig = true;
        } else {
          this.passwordRules.letterBig = false;
        }

        // Проверка чисел
        let numbers = /[0-9]/g;
        if (this.newPassword.match(numbers)) {
          this.passwordRules.number = true;
        } else {
          this.passwordRules.number = false;
        }

        // Проверка длины
        if (this.newPassword.length >= 8) {
          this.passwordRules.length = true;
        } else {
          this.passwordRules.length = false;
        }
      },
      changePassword() {
        this.setStatusChangePassword("success");
        this.$bvModal.hide("change-password-account");
      },
      changeTypeInput(id) {
        const neededInput = this.passwordInputs.find(
          (input) => id === input.id
        );
        if (!neededInput) return;
        if (neededInput.type === "password") {
          neededInput.type = "text";
          neededInput.image = require("@/assets/images/eye-hide-icon.svg");
        } else {
          neededInput.type = "password";
          neededInput.image = require("@/assets/images/eye-open-icon.svg");
        }
      },
    },
    computed: {
      isValidPassword() {
        return (
          this.passwordRules.letterSmall &&
          this.passwordRules.letterBig &&
          this.passwordRules.number &&
          this.passwordRules.length
        );
      },
      isValidPasswordRepeat() {
        return this.newPassword === this.passwordRepeat
      },
    },
  };
}

export default useValidationPassword;
