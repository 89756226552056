<template>
  <b-modal id="select-wallet-modal" centered hide-footer>
    <div v-if="!isLoadingTronWallets">
      <div class="d-flex justify-content-between">
        <h1 class="select-wallet__title mb-0">
          {{ $t("select_wallet_popup.title") }}
        </h1>
        <img
          @click="closeModal"
          height="24px"
          class="close-btn"
          src="@/assets/images/close-modal.svg"
          alt=""
        />
      </div>
      <div class="select-wallet__content" v-if="isTronWallet">
        <p
          class="content-text mb-0"
          v-if="variant === 'staking'"
          v-html="$t('select_wallet_popup.text1')"
        ></p>
        <p
          class="content-text mb-0"
          v-if="variant === 'aml'"
          v-html="$t('select_wallet_popup.text2')"
        ></p>
        <p
          class="content-text mb-0"
          v-if="variant === 'energy'"
          v-html="$t('select_wallet_popup.text3')"
        ></p>
        <SelectWallet
          :options="tronWalletsList"
          :default="selectedTronWallet"
          :zIndex="30"
          class="mt-3 mt-sm-4"
          @changeSelect="setSelectedTronWallet"
        />
        <CCButton
          @click="confirmWallet"
          size="lg"
          class="confirm-btn"
          fontWeight="medium"
          >{{ $t("select_wallet_popup.btn_confirm") }}</CCButton
        >
      </div>
      <div class="select-wallet__content" v-else>
        <div class="text-center">
          <img src="@/assets/images/error.svg" alt="" />
          <p class="content-text mt-2">
            {{ $t("select_wallet_popup.text_no_wallet") }}
          </p>
          <router-link to="/create-wallet">
            <CCButton size="lg" class="mt-4" fontWeight="medium">{{
              $t("select_wallet_popup.btn_create")
            }}</CCButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="loading-block" v-else>
      <img class="loader" src="@/assets/images/loader-accent.svg" alt="" />
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import SelectWallet from "../UI/SelectWallet.vue";
export default {
  props: {
    currencyTron: {
      type: String,
      default: "TRX",
    },
    variant: {
      type: String,
      default: "staking",
      validator: (value) => {
        return ["staking", "aml", "energy"].includes(value);
      },
    },
  },
  methods: {
    ...mapActions(
      () => useWalletStore(),
      ["fetchTronWallets", "setSelectedTronWallet"]
    ),
    closeModal() {
      this.$bvModal.hide("select-wallet-modal");
    },
    confirmWallet() {
      this.$emit("confirm");
    },
  },
  computed: {
    ...mapState(
      () => useWalletStore(),
      [
        "tronWalletsList",
        "selectedTronWallet",
        "isLoadingTronWallets",
        "isTronWallet",
      ]
    ),
  },
  beforeRouteUpdate() {
    console.log("route");
  },
  mounted() {
    this.fetchTronWallets(this.currencyTron);
  },
  components: { SelectWallet },
};
</script>

<style lang="scss" scoped>
.close-btn {
  cursor: pointer;
  user-select: none;
}
.confirm-btn {
  margin-top: 36px;
  @media (max-width: 576px) {
    margin-top: 24px;
    height: 40px !important;
  }
}
.select-wallet__title {
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 20px;
  }
}
.select-wallet__content {
  margin-top: 12px;
  .content-text {
    color: #939393;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.loading-block {
  height: 239px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    user-select: none;
  }
}
</style>
