<template>
  <b-modal id="btc-fee-modal" centered hide-footer>
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 fee-modal__title">Настройка комиссии</h3>
      <img
        @click="closeModal"
        class="pointer"
        src="@/assets/images/close-modal.svg"
        alt=""
      />
    </div>
    <div class="fee-modal__content">
      <div v-if="!isLoadingVariants">
        <div
          v-for="variant in variantsFee"
          @click="selectVariantFee(variant)"
          :key="variant.mode"
        >
          <VariantFeeCard
            currencyFee="BTC"
            class="fee-modal__card"
            :isActive="variant.mode === selectedVariantFee.mode"
            :isOptimalVariant="variant.mode != 'low'"
            :variant="variant.mode"
            :variantText="variant.modeName"
            :amountFee="getRoundedAmountCrypto(variant.amountFeeBtc)"
          />
        </div>
      </div>
      <div v-else>
        <b-skeleton
          v-for="index in 3"
          :key="index"
          class="sm fee-modal__card"
          width="100%"
          height="58px"
        ></b-skeleton>
      </div>
    </div>
    <div class="input-block mt-4">
      <div class="label">
        <h6 class="mb-0">Ввести размер вручную</h6>
      </div>
      <CCInput
        v-model="feeSatoshiModel"
        @input="handleTypeFeeSatoshi"
        placeholder="2 сатоши"
        class="send-address-input mt-2"
      ></CCInput>
    </div>
    <div class="checkbox-block" @click="saveChangesToggle">
      <Checkbox id="checkbox1" v-model="isSaveDefaultSettings" />
      <p class="checkbox-block__text mb-0">
        Сохранить эти значения по умолчанию для сети BTC.
      </p>
    </div>
    <div class="mt-4 w-100 d-none d-sm-block">
      <CCButton
        :disabled="isLoadingVariants || isLoadingGetFeeBtc"
        @click="saveChanges"
        fontWeight="medium"
        size="lg"
        class="px-4"
      >
        Сохранить
      </CCButton>
    </div>
    <div class="mt-4 w-100 d-block d-sm-none">
      <CCButton
        :disabled="isLoadingVariants || isLoadingGetFeeBtc"
        @click="saveChanges"
        fontWeight="medium"
        size="md"
        class="px-4"
      >
        Сохранить
      </CCButton>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Checkbox from "../UI/Checkbox.vue";
import VariantFeeCard from "./VariantFeeCard.vue";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { getRoundedAmountCrypto } from "@/utils";
import { validateAndFormatNumber } from "@/utils/validateInput";
import { CalculateFeeTransactionBtc } from "@/wallets/Bitcoin";
import { useInterfaceStore } from "@/stores/interfaceStore";

export default {
  components: { VariantFeeCard, Checkbox },
  props: {
    isLoadingVariants: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isSaveDefaultSettings: false,
    settingsMode: "default",
    isCustomFeeSettings: false,
    isLoadingGetFeeBtc: false,
    feeBtc: null,
  }),
  methods: {
    ...mapActions(useSendTransferStore, [
      "setSelectedVariantFee",
      "setFee",
      "setFeeSatoshi",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    getRoundedAmountCrypto,
    handleTypeFeeSatoshi() {
      this.setFeeSatoshi(validateAndFormatNumber(this.feeSatoshi));
      this.isCustomFeeSettings = true;
    },
    saveChangesToggle() {
      this.isSaveDefaultSettings = !this.isSaveDefaultSettings;
    },
    closeModal() {
      this.$bvModal.hide("btc-fee-modal");
    },
    selectVariantFee(variant) {
      this.setSelectedVariantFee(variant);
    },
    async saveChanges() {
      const calcFee = new CalculateFeeTransactionBtc(
        Number(this.amount),
        this.selectedWallet.address
      );
      if (this.isCustomFeeSettings) {
        this.isLoadingGetFeeBtc = true;
        try {
          this.feeBtc = await calcFee.getFee(this.feeSatoshiModel);
          this.setSelectedVariantFee({
            mode: "custom",
            modeName: "Ручной",
            amountFeeBtc: this.feeBtc,
            amountFeeSatoshi: +this.feeSatoshiModel,
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingGetFeeBtc = false;
        }
      } else {
        this.feeBtc = this.selectedVariantFee.amountFeeBtc;
      }
      if (this.feeBtc < this.amount) {
        const newFeeObj = {
          total: this.feeBtc,
          gasLimit: null,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        };
        this.closeModal();
        this.setFee(newFeeObj);
        if (this.isSaveDefaultSettings) {
          localStorage.setItem(
            "selectedVariantFeeBtc",
            JSON.stringify(this.selectedVariantFee)
          );
        } else if (
          !this.isSaveDefaultSettings &&
          localStorage.getItem("selectedVariantFeeBtc")
        ) {
          localStorage.removeItem("selectedVariantFeeBtc");
        }
      } else {
        this.showMessage("danger", "Слишком большая комиссия");
      }
    },
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "variantsFee",
      "selectedVariantFee",
      "feeSatoshi",
      "selectedWallet",
      "amount",
    ]),
    feeSatoshiModel: {
      get() {
        return this.feeSatoshi;
      },
      set(value) {
        this.setFeeSatoshi(value);
      },
    },
    maxBaseFeeEth() {
      return (Number(this.maxBaseFeeGwei) * this.gasLimitEth) / 10 ** 9;
    },
    priorityFeeEth() {
      return (Number(this.priorityFeeGwei) * this.gasLimitEth) / 10 ** 9;
    },
  },
  mounted() {
    if (localStorage.getItem("selectedVariantFeeBtc")) {
      this.isSaveDefaultSettings = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.input-block__input {
  position: relative;

  .amount-eth {
    position: absolute;
    top: 17px;
    right: 20px;
    color: #9f9f9f;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.checkbox-block {
  cursor: pointer;
  margin-top: 12px;
  display: flex;
  align-items: center;

  &__text {
    margin-left: 8px;
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}

.more-settings-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px 12px 12px;
  border-radius: 6px;
  background: #fbfbfb;

  .text {
    margin-left: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
  }
}

.close-btn {
  cursor: pointer;
  user-select: none;
}

.confirm-btn {
  margin-top: 36px;

  @media (max-width: 576px) {
    margin-top: 24px;
    height: 40px !important;
  }
}

.fee-modal__title {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.fee-modal__content {
  padding-top: 12px;

  //   border-bottom: 2px solid #F0F0F0;
  .fee-modal__card {
    margin-top: 12px;
  }

  .content-text {
    color: #939393;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.loading-block {
  height: 239px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    user-select: none;
  }
}
</style>
