<template>
  <div class="create-hold-wallet">
    <CCCard padding="card24">
      <h2 class="select-network__title mb-0">Крипто-копилка</h2>
      <p class="select-network__text mb-0 mt-2">
        Остался последний шаг для создания копилки
      </p>
      <div class="warning d-flex mt-4">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.wallet_success.text2") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.wallet_success.text3") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p
          class="warning-text mb-0 ms-2"
          v-html="$t('create_wallet_page.wallet_success.text4')"
        ></p>
      </div>
      <div class="input-block mt-4">
        <div class="label">
          <h6>Придумайте цель копилки</h6>
        </div>
        <CCInput
          placeholder="Поездка на море в далекие страны"
          class="input-with-icon"
          v-model="targetInput"
        />
      </div>
      <div class="input-block mt-4">
        <div class="label">
          <h6>Сумма накоплений</h6>
        </div>
        <div class="position-relative">
          <CCInput
            v-model="amountInput"
            @input="validateAmount"
            placeholder="2 000"
            class="input-with-icon"
          />
          <div class="usd-currency">
            <img src="@/assets/images/usd-icon.svg" alt="" />
            <p class="text mb-0">USD</p>
          </div>
        </div>
      </div>
      <CCButton
        @click="createPiggyBank"
        :disabled="isLoadingCreateWallet"
        size="lg"
        class="d-none d-md-block mt-4"
        >Создать</CCButton
      >
      <CCButton
        @click="createPiggyBank"
        :disabled="isLoadingCreateWallet"
        size="md"
        class="d-block d-md-none mt-4"
        >Создать</CCButton
      >
    </CCCard>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import { WalletController } from "@/core/system/controllers";
import { validateAndFormatNumber } from "@/utils/validateInput";
import { useInterfaceStore } from "@/stores/interfaceStore";
export default {
  data: () => ({
    isLoadingCreateWallet: false,
    targetInput: "",
    amountInput: "",
  }),
  methods: {
    ...mapActions(useWalletStore, ["setStep", "setStepBack"]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    validateAmount() {
      this.amountInput = validateAndFormatNumber(this.amountInput);
    },
    async createPiggyBank() {
      const payload = {
        wallet_network_id: this.idCreatedWallet,
        target_amount: Number(this.amountInput),
        target_title: this.targetInput,
      };
      if (this.amountInput != "" && this.targetInput != "") {
        if (Number(this.amountInput) > 0) {
          this.isLoadingCreateWallet = true;
          try {
            await WalletController.addPiggyBank(payload);
            this.setStep(5);
          } catch (error) {
            this.showMessage("danger", "Ошибка создания копилки");
          } finally {
            this.isLoadingCreateWallet = false;
          }
        } else {
          this.showMessage("warning", "Сумма должна быть больше 0");
        }
      } else {
        this.showMessage("warning", "Заполните все поля");
      }
    },
  },
  computed: {
    ...mapState(useWalletStore, ["idCreatedWallet"]),
  },
};
</script>

<style lang="scss" scoped>
.select-network__title {
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
  }
}
.select-network__text {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.usd-currency {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  .text {
    margin-left: 2px;
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
