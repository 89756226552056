import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue } from "bootstrap-vue";
import * as components from "@xossse/cryptocloud_uikit";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueMask from "v-mask";
import VueCryptojs from "vue-cryptojs";
import VueClipboard from "vue-clipboard2";
import i18n from "./i18n";
import { DatePicker } from "v-calendar";

import { UserController, WalletController } from "./core/system/controllers";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@xossse/cryptocloud_uikit/lib/vue-cryptocloud-ui-kit.css";
import "./styles/swiper-bundle.min.css";
import "./styles/app.scss";

const pinia = createPinia();
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});

Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(UserController);
Vue.use(WalletController);
Vue.use(VueCryptojs);
Vue.use(VueMask);
Vue.use(PiniaVuePlugin);

Vue.component("VDatePicker", DatePicker);

Vue.config.productionTip = false;

if (typeof process === "undefined") {
  global.process = {
    env: { DEBUG: undefined },
  };
}

new Vue({
  router,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
