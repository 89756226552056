import { decodePassword, encodePassword } from "@/utils/encryption";
import TronWeb from "tronweb";
import { Buffer } from "buffer";
window.Buffer = Buffer;

export class CreateTronWallet {
  constructor(password) {
    this.password = password;
    this.tronWeb = this.initweb();
  }
  initweb() {
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io", // или ваш полный узел
    });
    return tronWeb;
  }
  async create() {
    const wallet = {
      privateKey: "",
      address: "",
    };
    const newAccount = await this.tronWeb.createAccount();
    wallet.privateKey = newAccount.privateKey;
    wallet.address = newAccount.address.base58;
    const encrypted = encodePassword(this.password, JSON.stringify(wallet));
    const data = {
      address: wallet.address,
      encrypted: encrypted,
    };
    return data;
  }
}

export class CreateTronTransaction {
  constructor(privateKey, fromAddress, amount, toAddress, currency = "TRX") {
    this.tronWeb = this.initweb(privateKey);
    this.privateKey = privateKey;
    this.toAddress = toAddress;
    this.fromAddress = fromAddress;
    this.currency = currency;
    this.amount = amount;
    this.tokenList = [
      {
        name: "USDT",
        address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimal: 6,
      },
      {
        name: "USDC",
        address: "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
        decimal: 6,
      },
      {
        name: "TUSD",
        address: "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4",
        decimal: 18,
      },
    ];
  }
  initweb(privateKey) {
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io", // или ваш полный узел
      privateKey: privateKey, // Замените на ваш приватный ключ
    });
    return tronWeb;
  }
  async buildTransactionTrx(amount) {
    const transaction = await this.tronWeb.transactionBuilder.sendTrx(
      this.toAddress,
      amount
    );
    return transaction;
  }
  async buildTransactionTokens(fromAddress, toAddress, amount) {
    const selectedToken = this.tokenList.find(
      (currency) => currency.name === this.currency
    );
    const contractAddress = this.tronWeb.address.toHex(selectedToken.address);
    const ownerAddress = this.tronWeb.address.toHex(fromAddress);
    const functionSelector = "transfer(address,uint256)";
    const parameters = [
      { type: "address", value: this.tronWeb.address.toHex(toAddress) },
      {
        type: "uint256",
        value: BigInt(amount * 10 ** selectedToken.decimal),
      },
    ];

    try {
      const transaction =
        await this.tronWeb.transactionBuilder.triggerSmartContract(
          contractAddress,
          functionSelector,
          { feeLimit: this.tronWeb.toSun(35) },
          parameters,
          ownerAddress
        );
      if (!transaction.result || !transaction.result.result) {
        throw new Error("Failed to build transaction");
      }
      return transaction.transaction;
    } catch (error) {
      console.error(error);
    }
  }
  async signTransaction(transaction) {
    const signedTransaction = await this.tronWeb.trx.sign(transaction);
    const result = await this.tronWeb.trx.broadcast(signedTransaction);
    return result;
  }
  async create() {
    console.log(this.fromAddress);
    console.log(this.toAddress);
    console.log(this.amount);
    if (this.currency === "TRX") {
      // Конвертируем сумму в Sun (единицу измерения в сети Tron, равную 10^-6 TRX)
      const amountInSun = this.tronWeb.toSun(this.amount);

      try {
        // Подготавливаем транзакцию
        const transaction = await this.buildTransactionTrx(amountInSun);

        // Подписываем транзакцию
        const result = await this.signTransaction(transaction);
        console.log("Транзакция успешно отправлена!", result);
        return result;
      } catch (error) {
        console.error("Ошибка при отправке транзакции", error);
      }
    } else {
      try {
        const transaction = await this.buildTransactionTokens(
          this.fromAddress,
          this.toAddress,
          this.amount
        );
        console.log(transaction);
        const result = await this.signTransaction(transaction);
        console.log("Transaction ID:", result.txid);
        return result;
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }
}

export class TronStaking {
  constructor(privateKey, amount, resource = "ENERGY") {
    this.tronWeb = this.initweb(privateKey);
    this.privateKey = privateKey;
    this.amount = amount;
    this.resource = resource;
  }
  initweb(privateKey) {
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
      privateKey: privateKey,
    });
    return tronWeb;
  }
  async freezeTrx() {
    try {
      const amountInSun = this.tronWeb.toSun(this.amount); // Конвертируем TRX в Sun
      const ownerAddress = this.tronWeb.defaultAddress.base58;

      // Создаем транзакцию для заморозки TRX
      const transaction = await this.tronWeb.transactionBuilder.freezeBalanceV2(
        Number(amountInSun),
        this.resource,
        ownerAddress,
      );

      // Подписываем транзакцию
      const signedTransaction = await this.tronWeb.trx.sign(
        transaction,
        this.privateKey
      );

      // Отправляем транзакцию
      const result = await this.tronWeb.trx.sendRawTransaction(
        signedTransaction
      );

      console.log("Заморозка TRX успешно выполнена", result);
      return result;
    } catch (error) {
      console.error("Ошибка при заморозке TRX", error);
      throw error;
    }
  }
}
