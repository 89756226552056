<template>
  <div class="d-flex flex-column">
    <HeaderMobile :title="selectedWallet.nickname" class="d-block d-sm-none">
      <img
        @click="$router.back()"
        slot="button-left"
        width="24px"
        src="../assets/images/back-icon.svg"
        alt=""
      />
      <router-link slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row>
      <b-col cols="12" lg="7">
        <BalanceCard
          class="d-none d-md-block"
          :isLoading="isLoadingWallet"
          variant="inside"
          :balance="selectedWallet.balance_usd"
          :address="selectedWallet.address"
          :network="
            selectedWallet.network_object
              ? selectedWallet.network_object.full_name
              : ''
          "
        />
        <BalanceCardMobile
          class="d-block d-md-none"
          :titleCard="$t('balance_card.title2')"
          :isLoading="isLoadingWallet"
          variant="inside"
          :balance="selectedWallet.balance_usd"
          :address="selectedWallet.address"
          :network="
            selectedWallet.network_object
              ? selectedWallet.network_object.full_name
              : ''
          "
        />
      </b-col>
      <b-col lg="5" class="mt-4 mt-lg-0">
        <div v-if="!selectedWallet.is_piggy_bank">
          <CCCard class="d-none d-md-block" padding="card24">
            <AmlWidget
              :isLoading="isLoadingUser || isLoadingWallet"
              class="h-100"
            />
          </CCCard>
          <AmlWidget
            :isLoading="isLoadingUser || isLoadingWallet"
            class="d-block d-md-none h-100"
          />
        </div>
        <HoldWalletWidget
          :balance="selectedWallet.balance_usd"
          :targetAmount="selectedWallet.piggy_bank.target_amount"
          :targetName="selectedWallet.piggy_bank.target_title"
          :isLoading="isLoadingWallet"
          @onTopUpBalance="topUpBalanceHoldWallet"
          class="h-100"
          v-else
        />
      </b-col>
    </b-row>
    <div class="d-none d-md-block">
      <b-row align-content="stretch" v-if="!isLoadingWallet">
        <b-col
          cols="12"
          lg="3"
          v-for="card in selectedWallet.currency_list"
          :key="card.id"
        >
          <CurrencyCard
            @send="sendCrypto(selectedWallet.address, card.code)"
            @get="getCrypto(card.code)"
            class="mt-4"
            :id="card.id"
            :currencyName="card.full_name"
            :badgeName="card.code"
            :balanceCurrency="card.balance"
            :icon="card.icon_base"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4" align-content="stretch" v-else>
        <b-col cols="3" v-for="index in 4" :key="index">
          <CurrencyCard typeCard="part" :isLoading="isLoadingWallet" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <HistoryFull :selectedNetwork="selectedWalletNetwork" />
        </b-col>
      </b-row>
    </div>
    <div class="d-block d-md-none mb-4">
      <b-row align-content="stretch" v-if="!isLoadingWallet">
        <b-col
          cols="12"
          lg="3"
          v-for="card in selectedWallet.currency_list"
          :key="card.id"
        >
          <CurrencyCardMobile
            @get="getCrypto(card.code)"
            typeCard="part"
            class="mt-4"
            :id="card.id"
            :address="selectedWallet.address"
            :currencyName="card.full_name"
            :badgeName="card.code"
            :balanceCurrency="card.balance"
            :icon="card.icon_base"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4" align-content="stretch" v-else>
        <b-col cols="12" v-for="index in 4" :key="index">
          <CurrencyCardMobile
            :class="{ 'mt-4': index > 0 }"
            :isLoading="isLoadingWallet"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <HistoryFull :selectedNetwork="selectedWalletNetwork" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BalanceCard from "@/components/Balance/BalanceCard.vue";
import CurrencyCard from "@/components/Currency/CurrencyCard.vue";
import { useUserStore } from "@/stores/userStore";
import { mapState, mapActions } from "pinia";
import AmlWidget from "@/components/AmlBuy/AmlWidget.vue";
import { useWalletStore } from "@/stores/wallet";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import BalanceCardMobile from "@/components/Balance/BalanceCardMobile.vue";
import CurrencyCardMobile from "@/components/Currency/CurrencyCardMobile.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import HistoryFull from "@/components/HistoryTransactions/HistoryTable/HistoryFull.vue";
import HoldWalletWidget from "@/components/Wallets/HoldWalletWidget.vue";
export default {
  name: "InvoicePayments",
  components: {
    BalanceCard,
    CurrencyCard,
    AmlWidget,
    BalanceCardMobile,
    CurrencyCardMobile,
    HeaderMobile,
    HistoryFull,
    HoldWalletWidget,
  },
  data: () => ({
    selectedWalletNetwork: null,
  }),
  methods: {
    ...mapActions(
      () => useWalletStore(),
      [
        "updateBalance",
        "addFilterHistory",
        "fetchHistoryList",
        "setIsLoadingHistory",
      ],
    ),
    ...mapActions(
      () => useSendTransferStore(),
      ["setSelectedCurrency", "setDataWalletToSend", "setAddress"],
    ),
    async sendCrypto(address, code) {
      const sendData = {
        address,
        code,
      };
      this.setDataWalletToSend(sendData);
      this.$router.push("/send");
    },
    getCrypto(currency) {
      this.$router.push(`/accept/${currency.toLowerCase()}`);
    },
    topUpBalanceHoldWallet() {
      this.setAddress(this.selectedWallet.address);
      this.$router.push("/send");
    },
  },
  computed: {
    ...mapState(() => useUserStore(), ["user", "isLoadingUser"]),
    ...mapState(() => useSendTransferStore(), ["optionsCurrency"]),
    ...mapState(
      () => useWalletStore(),
      [
        "selectedWallet",
        "isLoadingWallet",
        "getBalanceWallet",
        "selectedIdWalletNetwork",
      ],
    ),
  },
  async mounted() {
    this.setIsLoadingHistory(true);
    await this.updateBalance(this.$route.params.address);
    this.selectedWalletNetwork = this.selectedWallet.network_object.code;
    localStorage.setItem("selected_wallet_address", this.$route.params.address);
    this.addFilterHistory("wallet__address", ["=", this.$route.params.address]);
    this.fetchHistoryList();
  },
};
</script>
