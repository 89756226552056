<template>
  <div class="blockchain-card" v-if="!isLoading">
    <b-row align-h="between" class="d-none d-sm-flex">
      <b-col cols="7">
        <h3 class="blockchain-card__title">{{ name }}</h3>
        <div class="amount-block">
          <span class="amount-block__num">{{ amountFirst }} </span>
          <span class="amount-block__num">{{ amountLast }}</span>
        </div>
        <CCButton
          v-if="variant === 'energy'"
          @click="getEnergy"
          size="lg"
          class="blockchain-card__btn mt-4"
          fontWeight="medium"
          ><div class="d-flex align-items-center">
            <img src="../assets/images/lightning-white.svg" alt="" />
            <span class="ms-2">{{ $t("staking_page.btn_energy") }}</span>
          </div></CCButton
        >
        <CCButton
          v-if="variant === 'bandwidth'"
          @click="getBandwidth"
          size="lg"
          class="blockchain-card__btn mt-4"
          fontWeight="medium"
          ><div class="d-flex align-items-center">
            <img src="../assets/images/speedometr.svg" alt="" />
            <span class="ms-2">{{ $t("staking_page.btn_bandwidth") }}</span>
          </div></CCButton
        >
      </b-col>
      <b-col cols="5">
        <div>
          <BlockchainField :value="fromStaking" name="From staking" />
          <BlockchainField
            class="mt-2"
            :value="delegatedBy"
            name="Delegated by others"
          />
          <BlockchainField
            class="mt-2"
            :value="delegatedTo"
            name="Delegated to others"
          />
        </div>
      </b-col>
    </b-row>
    <b-row align-h="between" class="d-flex d-sm-none">
      <b-col cols="12">
        <h3 class="blockchain-card__title">{{ name }}</h3>
        <div class="amount-block">
          <span class="amount-block__num">{{ amountFirst }} </span>
          <span class="amount-block__num">{{ amountLast }}</span>
        </div>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-row>
          <b-col cols="12">
            <div>
              <p class="blockchain-card__param-title mb-0">From staking</p>
              <p class="blockchain-card__param-value mb-0">{{ fromStaking }}</p>
            </div>
          </b-col>
          <b-col cols="6" class="mt-3">
            <div>
              <p class="blockchain-card__param-title mb-0">Delegated by</p>
              <p class="blockchain-card__param-value mb-0">{{ delegatedBy }}</p>
            </div>
          </b-col>
          <b-col cols="6" class="mt-3">
            <div>
              <p class="blockchain-card__param-title mb-0">Delegated to</p>
              <p class="blockchain-card__param-value mb-0">{{ delegatedTo }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mt-4">
        <CCButton
          v-if="variant === 'energy'"
          @click="getEnergy"
          size="md"
          class="blockchain-card__btn"
          fontWeight="medium"
          ><div class="d-flex align-items-center">
            <img src="../assets/images/lightning-white.svg" alt="" />
            <span class="ms-2">{{ $t("staking_page.btn_energy") }}</span>
          </div></CCButton
        >
        <CCButton
          v-if="variant === 'bandwidth'"
          @click="getBandwidth"
          size="md"
          class="blockchain-card__btn"
          fontWeight="medium"
          ><div class="d-flex align-items-center">
            <img src="../assets/images/speedometr.svg" alt="" />
            <span class="ms-2">{{ $t("staking_page.btn_bandwidth") }}</span>
          </div></CCButton
        >
      </b-col>
    </b-row>
  </div>
  <div class="blockchain-card" v-else>
    <b-row align-h="between">
      <b-col cols="7">
        <b-skeleton class="sm" width="40%" height="24px"></b-skeleton>
        <div class="amount-block">
          <b-skeleton class="sm" width="90%" height="57px"></b-skeleton>
        </div>
        <b-skeleton class="button mt-4" width="80%" height="48px"></b-skeleton>
      </b-col>
      <b-col cols="5">
        <div>
          <b-skeleton class="sm" width="100%" height="62px"></b-skeleton>
          <b-skeleton class="sm mt-2" width="100%" height="62px"></b-skeleton>
          <b-skeleton class="sm mt-2" width="100%" height="62px"></b-skeleton>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BlockchainField from "./UI/BlockchainField.vue";
export default {
  components: { BlockchainField },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: String,
    name: {
      type: String,
      default: "Name",
    },
    amountFirst: {
      type: Number,
      default: 0,
    },
    amountLast: {
      type: Number,
      default: 0,
    },
    fromStaking: {
      type: Number,
      default: 0,
    },
    delegatedBy: {
      type: Number,
      default: 0,
    },
    delegatedTo: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getEnergy() {
      this.$emit("getEnergy");
    },
    getBandwidth() {
      this.$emit("getBandwidth");
    },
  },
};
</script>

<style lang="scss" scoped>
.blockchain-card {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 16px;
  }
  &__title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__param-title {
    @media (max-width: 576px) {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #3c3c3c;
    }
  }
  &__param-value {
    @media (max-width: 576px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c3c;
    }
  }
  &__btn {
    width: fit-content;
    padding: 0 24px 0 24px;
  }
  .amount-block {
    margin-top: 46px;
    &__num {
      font-size: 48px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.64px;
      color: #3c3c3c;
      @media (max-width: 576px) {
        font-size: 28px;
      }
      &:last-child {
        color: #5f5f5f;
        font-size: 16px;
        font-weight: 600;
        line-height: 12px; /* 75% */
        letter-spacing: 0.16px;
        &::before {
          content: "/ ";
        }
      }
    }
    @media (max-width: 576px) {
      margin-top: 11px;
    }
  }
}
</style>
