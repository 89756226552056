<template>
  <div class="network-card">
    <CCCard padding="card18" v-if="!isLoading">
      <b-row align-v="center">
        <b-col cols="auto" class="pe-0">
          <div class="network-card__image">
            <img :src="icon" class="network-icon" alt="network-icon" />
          </div>
        </b-col>
        <b-col class="ps-0">
          <h3 class="ms-2 ms-sm-3 network-card__crypto-name mb-0">
            {{ name }}
          </h3>
        </b-col>
        <b-col cols="auto" class="ms-auto">
          <div class="networks-list" v-if="currencies.length > 1">
            <img
              v-for="item in currencies"
              :key="item.id"
              :src="item.icon_network"
              alt=""
            />
          </div>
        </b-col>
      </b-row>
    </CCCard>
    <CCCard padding="card18" v-else>
      <b-row align-v="center">
        <b-col cols="auto">
          <div class="network-card__image">
            <b-skeleton type="avatar" width="36px" height="36px"></b-skeleton>
          </div>
        </b-col>
        <b-col class="ps-1">
          <b-skeleton class="sm" width="50%" height="32px"></b-skeleton>
        </b-col>
        <b-col cols="auto" class="ms-auto">
          <div class="networks-list d-flex">
            <b-skeleton
              class="ms-2"
              v-for="index in 4"
              :key="index"
              type="avatar"
              width="20px"
              height="20px"
            ></b-skeleton>
          </div>
        </b-col>
      </b-row>
    </CCCard>
  </div>
</template>

<script>
export default {
  name: "CurrencyCard",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    name: String,
    icon: String,
    currencies: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.network-card {
  cursor: pointer;
  &__image {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    border: 2px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    .network-icon {
      width: 36px;
      height: 36px;
    }
    @media (max-width: 576px) {
      border-radius: 7px;
      width: 40px;
      height: 40px;
      .network-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__crypto-name {
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.32px;
    color: #3c3c3c;
    @media (max-width: 576px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .networks-list {
    img {
      margin-left: 12px;
      width: 20px;
      height: 20px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 576px) {
        margin-left: 9px;
        width: 15px;
        height: 15px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
