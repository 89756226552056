<template>
  <div class="energy-widget" v-if="!isLoadingStakingData">
    <div class="energy-widget__content">
      <div class="d-flex align-items-center">
        <h6 class="content-title mb-0">Energy</h6>
        <img
          @click="fetchStakingData(address)"
          class="swap-icon"
          src="@/assets/images/swap.svg"
          alt=""
        />
      </div>
      <div class="energy-block mt-1">
        <span class="avaliable">{{ $t("energy_widget.available") }}</span>
        <span class="avaliable ms-2">{{ availableEnergy }}</span>
        <span class="avaliable total ms-1">{{ inEnergy }}</span>
      </div>
    </div>
    <a
      class="energy-widget__button d-none d-sm-block"
      target="_blank"
      href="/buy-energy"
    >
      {{ $t("energy_widget.btn_buy") }}
    </a>
    <a class="d-block d-sm-none" href="/buy-energy" target="_blank">
      <ButtonMobile :name="$t('energy_widget.btn_buy')" variant="row">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 3L3.04936 3.20987C3.91136 3.38227 4.55973 4.09732 4.6472 4.97203L4.8 6.5L5.7886 14.7383C5.90922 15.7435 6.76195 16.5 7.77435 16.5H16.7673C18.3733 16.5 19.7733 15.407 20.1628 13.8489L21.2855 9.35783C21.6485 7.90619 20.5505 6.5 19.0542 6.5H13.4H8.5"
            stroke="#4A40C6"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M13 13.5H9"
            stroke="#4A40C6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="8.5" cy="20" r="1.5" fill="#4A40C6" />
          <circle cx="17.5" cy="20" r="1.5" fill="#4A40C6" />
        </svg>
      </ButtonMobile>
    </a>
  </div>
  <div class="energy-widget" v-else>
    <div>
      <b-skeleton class="sm" width="154px" height="24px"></b-skeleton>
      <b-skeleton class="sm mt-2" width="210px" height="12px"></b-skeleton>
    </div>
    <b-skeleton class="button" width="55px" height="22px"></b-skeleton>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useStakingStore } from "@/stores/stakingStore";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import ButtonMobile from "../UI/ButtonMobile.vue";
export default {
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(() => useStakingStore(), ["fetchStakingData"]),
  },
  computed: {
    ...mapState(() => useSendTransferStore(), ["selectedCurrency"]),
    ...mapState(
      () => useStakingStore(),
      ["allResources", "isLoadingStakingData"]
    ),
    availableEnergy() {
      return this.allResources.available_energy;
    },
    inEnergy() {
      return this.allResources.in_energy;
    },
  },
  mounted() {
    this.fetchStakingData(this.address);
  },
  components: { ButtonMobile },
};
</script>

<style lang="scss" scoped>
.energy-widget {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__content {
    .content-title {
      font-size: 16px;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0.16px;
      color: #3c3c3c;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .swap-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
    .energy-block {
      .avaliable {
        font-size: 16px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0.16px;
        color: #5f5f5f;
        @media (max-width: 576px) {
          font-size: 14px;
        }
        &.total {
          font-weight: 400;
          &::before {
            content: "/ ";
          }
        }
      }
    }
  }
  &__button {
    color: #4a40c6;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
}
</style>
