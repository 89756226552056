<template>
  <div class="address-book__contacts-list" v-if="!isLoading">
    <div v-if="contacts.length">
      <div
        class="address-book__contact-item"
        v-for="contact in contacts"
        :key="contact.id"
        @click="sendToAddressContact(contact.address)"
      >
        <div class="d-flex align-items-center">
          <div class="contact-avatar">{{ contact.name.slice(0, 1) }}</div>
          <div class="contact-info">
            <h4 class="contact-name mb-0">{{ contact.name }}</h4>
            <div class="contact-network">
              <img
                class="network-icon"
                :src="contact.network_object.icon"
                alt=""
              />
              <p class="address mb-0 d-none d-sm-block">
                {{ contact.address }}
              </p>
              <p class="address mb-0 d-block d-sm-none">
                {{ getShortWalletAddress(contact.address) }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <button @click.stop="editContact(contact.id)" class="change-btn">
            <img
              class="change-btn__icon"
              src="@/assets/images/change-icon.svg"
              alt=""
            />
          </button>
          <button
            @click.stop="removeContact(contact.id)"
            class="change-btn ms-2"
          >
            <img
              class="change-btn__icon"
              src="@/assets/images/remove-icon.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-error mb-0">
        {{ $t("send_page.address_book.title_empty") }}
      </p>
    </div>
  </div>
  <div v-else>
    <div class="address-book__contact-item" v-for="index in 5" :key="index">
      <div class="d-flex align-items-center">
        <b-skeleton type="avatar" width="32px" height="32px"></b-skeleton>
        <div class="contact-info">
          <b-skeleton class="sm" width="100px" height="18px"></b-skeleton>
          <b-skeleton class="sm mt-1" width="150px" height="12px"></b-skeleton>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <b-skeleton class="button" width="24px" height="24px"></b-skeleton>
        <b-skeleton class="button ms-2" width="24px" height="24px"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import { getShortWalletAddress } from "@/utils";

export default {
  name: "AdressBook",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      default: [],
    },
  },
  methods: {
    getShortWalletAddress,
    editContact(id) {
      this.$emit("edit", id);
    },
    removeContact(id) {
      this.$emit("remove", id);
    },
    sendToAddressContact(address) {
      this.$emit("send", address);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  margin-top: 34px;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
</style>
