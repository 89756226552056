<template>
  <div class="settings-page">
    <HeaderBackLink
      class="d-none d-sm-block"
      :title="$t('settings_page.title')"
    >
      <img
        class="exchange-icon icon"
        src="../assets/images/download-icon.svg"
        alt=""
      />
    </HeaderBackLink>
    <HeaderMobile
      :title="$t('settings_page.title_mobile')"
      class="d-block d-sm-none"
    >
      <img
        @click="$router.back()"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
    </HeaderMobile>
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <UserData :email="user.email"></UserData>
      </b-col>
      <b-col cols="12" lg="6" class="mt-0 mt-sm-4 mt-lg-0">
        <HistoryAuth></HistoryAuth>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserData from "@/components/Settings/UserData.vue";
import HistoryAuth from "@/components/Settings/HistoryAuth.vue";
import { mapActions, mapState } from "pinia";
import { useSettingsStore } from "@/stores/settingsStore";
import ChangePasswordAccount from "@/components/Settings/ChangePasswordAccount.vue";
import Enable2fa from "@/components/Settings/Enable2fa.vue";
import ChangePasswordInvoice from "@/components/Settings/ChangePasswordInvoice.vue";
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import { useUserStore } from "@/stores/userStore";

export default {
  components: {
    UserData,
    HistoryAuth,
    ChangePasswordAccount,
    Enable2fa,
    ChangePasswordInvoice,
    HeaderBackLink,
    HeaderMobile,
  },
  methods: {
    ...mapActions(useSettingsStore, [
      "setStatusChangePassword",
      "setStatusChangePasswordInvoice",
    ]),
  },
  computed: {
    ...mapState(useSettingsStore, [
      "openSection",
      "statusChangePassword",
      "statusChangePasswordInvoice",
    ]),
    ...mapState(useUserStore, ["user"]),
  },
};
</script>

<style></style>
