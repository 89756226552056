<template>
  <div class="history-transactions__item" v-if="!loading">
    <div class="item-left d-flex align-items-center">
      <div v-if="inProcess">
        <img
          class="item-left__icon"
          src="@/assets/images/history/waiting.svg"
          alt=""
        />
      </div>
      <div v-else>
        <img
          class="item-left__icon"
          :src="require(`@/assets/images/history/${status}.svg`)"
          alt=""
        />
      </div>
      <div class="item-left__content" v-if="type === 'energy'">
        <div class="d-none d-sm-block">
          <h5 class="content-title mb-0">
            {{ quantity }} {{ $t("history.energy") }}
          </h5>
          <p class="content-date mb-0">
            {{ dateModel.date }} • {{ dateModel.time }}
          </p>
        </div>
        <div class="d-block d-sm-none">
          <h5 class="content-title mb-0">
            {{ quantity }} {{ $t("history.energy") }}; {{ durationModel }}
          </h5>
          <p class="content-date mb-0">
            {{ dateModel.date }} • {{ dateModel.time }}
          </p>
        </div>
      </div>
      <div class="item-left__content" v-else>
        <h5 class="content-title mb-0">
          {{ $t("history.quantity") }}: {{ quantity }}
        </h5>
        <p class="content-date mb-0">
          {{ dateModel.date }} • {{ dateModel.time }}
        </p>
      </div>
    </div>
    <div
      v-if="type === 'energy'"
      class="item-center d-none d-sm-flex align-items-center"
    >
      <div class="item-center__content">
        <h5 class="content-title mb-0">{{ durationModel }}</h5>
        <p class="content-date mb-0">{{ $t("history.time") }}</p>
      </div>
    </div>
    <div class="item-right d-flex align-items-center">
      <h5 class="item-right__amount mb-0">
        -{{ amountModel }} {{ currencyModel }}
      </h5>
      <img
        class="item-right__icon mb-0"
        src="@/assets/images/history/swap.svg"
        alt=""
      />
    </div>
  </div>
  <div class="history-transactions__item" v-else>
    <div class="item-left d-flex align-items-center">
      <b-skeleton type="avatar" width="32px" height="32px"></b-skeleton>
      <div class="item-left__content" v-if="type === 'energy'">
        <div class="d-none d-sm-block">
          <b-skeleton class="sm" width="100px" height="16px"></b-skeleton>
          <b-skeleton class="sm mt-1" width="80px" height="10px"></b-skeleton>
        </div>
        <div class="d-block d-sm-none">
          <b-skeleton class="sm" width="90px" height="16px"></b-skeleton>
          <b-skeleton class="sm mt-1" width="70px" height="10px"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="item-right d-flex align-items-center">
      <b-skeleton class="sm" width="60px" height="24px"></b-skeleton>
      <b-skeleton class="button ms-2" width="24px" height="24px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import {
  getRoundedAmountFiat,
  sliceNetworkCodeCurrency,
} from "@/utils";
import { formatDateTime } from "@/utils/date";

export default {
  props: {
    type: String,
    status: {
      type: String,
      default: "created",
      validator: (value) => {
        return ["created", "pay", "cancel"].includes(value);
      },
    },
    inProcess: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: "TRX",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "00.00.0000",
    },
  },
  computed: {
    durationModel() {
      return this.duration === 24
        ? this.$t("history.day")
        : this.$t("history.hour");
    },
    amountModel() {
      return getRoundedAmountFiat(this.amount);
    },
    dateModel() {
      return formatDateTime(this.date);
    },
    currencyModel() {
      return sliceNetworkCodeCurrency(this.currency);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-transactions__item {
  cursor: pointer;
  margin-top: 12px;
  padding: 12px;
  border-radius: 6px;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    padding: 8px;
  }
  .item-left {
    &__icon {
      width: 32px;
      height: 32px;
      @media (max-width: 576px) {
        width: 24px;
        height: 24px;
      }
    }
    &__content {
      margin-left: 12px;
      .content-title {
        color: #3c3c3c;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
      .content-date {
        margin-top: 6px;
        color: #9f9f9f;
        font-size: 12px;
        font-weight: 300;
        line-height: 10px;
      }
      @media (max-width: 576px) {
        margin-left: 8px;
        .content-title {
          font-size: 12px;
          line-height: 16px;
        }
        .content-date {
          margin-top: 0;
          font-size: 10px;
        }
      }
    }
  }
  .item-center {
    &__content {
      .content-title {
        color: #3c3c3c;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
      .content-date {
        margin-top: 6px;
        color: #9f9f9f;
        font-size: 12px;
        font-weight: 300;
        line-height: 10px;
      }
    }
    @media (max-width: 576px) {
      .content-title {
        font-size: 12px;
        line-height: 16px;
      }
      .content-date {
        margin-top: 0;
        font-size: 10px;
      }
    }
  }
  .item-right {
    &__amount {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__icon {
      margin-left: 12px;
      width: 24px;
      height: 24px;
    }
    @media (max-width: 576px) {
      &__amount {
        font-size: 14px;
      }
    }
  }
}
</style>
