import { defineStore } from "pinia";

export const useInterfaceStore = defineStore("interface", {
  state: () => ({
    idMessage: 1,
    messagesList: [],
    isOpenAlert: false,
    textAlert: "alert",
    variantAlert: "info",
  }),
  actions: {
    showMessage(variant, text) {
      const message = {
        id: this.idMessage,
        variant,
        text,
      };
      if (this.messagesList.length <= 10) {
        this.idMessage++;
        this.messagesList = [message, ...this.messagesList];
        setTimeout(() => {
          this.hideMessage(this.idMessage);
        }, 15000);
      }
    },
    hideMessage(id) {
      let el = this.messagesList.find((message) => message.id === id);
      let index = this.messagesList.indexOf(el);
      this.messagesList.splice(index, 1);
    },
  },
});
