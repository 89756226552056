<template>
  <div v-if="!isLoading" class="edit-contact__wrapper">
    <div>
      <p class="address-book__main-text mb-0 mt-3">
        {{ $t("send_page.address_book.edit_contact_form.text") }}
      </p>
      <!-- input -->
      <div class="send-transfer__input mt-3 mt-sm-4">
        <div class="input-block">
          <div class="label">
            <h6>
              {{
                $t("send_page.address_book.edit_contact_form.nickname_label")
              }}
            </h6>
          </div>
        </div>
        <div class="d-none d-sm-block mt-2">
          <CCInput
            v-model="selectedContact.name"
            placeholder="Investor G. Finansist"
          ></CCInput>
        </div>
        <div class="d-block d-sm-none mt-2">
          <CCInput
            v-model="selectedContact.name"
            placeholder="Investor G. Finansist"
            size="md"
          ></CCInput>
        </div>
      </div>
      <SelectNetwork
        :options="networksList"
        :default="selectedNetworkCurrency"
        @change="setSelectedNetworkCurrency"
        class="mt-3 mt-sm-4"
      />
      <!-- input -->
      <div class="send-transfer__input mt-3 mt-sm-4">
        <div class="input-block">
          <div class="label">
            <h6>
              {{ $t("send_page.address_book.edit_contact_form.address_label") }}
            </h6>
          </div>
        </div>
        <div class="d-none d-sm-block mt-2">
          <CCInput
            placeholder="3Gbaro9CXaiAGK8w3xqHRqL6N5DkcpvGFH"
            v-model="selectedContact.address"
          ></CCInput>
        </div>
        <div class="d-block d-sm-none mt-2">
          <CCInput
            placeholder="3Gbaro9CXaiAGK8w3xqHRqL6N5DkcpvGFH"
            v-model="selectedContact.address"
            size="md"
          ></CCInput>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-3 mt-sm-4 d-none d-sm-block">
        <CCButton
          :disabled="!isValidEditContact || isLoadingEditContact"
          @click="editContact"
          size="lg"
          variant="primary"
          styled="accent"
          fontWeight="medium"
        >
          {{ $t("send_page.address_book.edit_contact_form.btn_edit") }}
        </CCButton>
      </div>
      <div class="mt-3 mt-sm-4 d-block d-sm-none">
        <CCButton
          :disabled="!isValidEditContact || isLoadingEditContact"
          @click="editContact"
          size="md"
          variant="primary"
          styled="accent"
          fontWeight="medium"
        >
          {{ $t("send_page.address_book.edit_contact_form.btn_edit") }}
        </CCButton>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="address-book__main-text mb-0 mt-3">
      {{ $t("send_page.address_book.edit_contact_form.text") }}
    </p>
    <!-- input -->
    <div class="send-transfer__input mt-3 mt-sm-4">
      <div class="input-block">
        <div class="label">
          <h6>
            {{ $t("send_page.address_book.edit_contact_form.nickname_label") }}
          </h6>
        </div>
      </div>
      <div class="d-none d-sm-block mt-2">
        <b-skeleton class="md" width="100%" height="56px"></b-skeleton>
      </div>
      <div class="d-block d-sm-none mt-2">
        <b-skeleton class="md" width="100%" height="50px"></b-skeleton>
      </div>
    </div>
    <SelectNetwork
      :isLoading="isLoading"
      :options="networksList"
      :default="selectedNetworkCurrency"
      @change="setSelectedNetworkCurrency"
      class="mt-3 mt-sm-4"
    />
    <!-- input -->
    <div class="send-transfer__input mt-3 mt-sm-4">
      <div class="input-block">
        <div class="label">
          <h6>
            {{ $t("send_page.address_book.edit_contact_form.address_label") }}
          </h6>
        </div>
      </div>
      <div class="d-none d-sm-block mt-2">
        <b-skeleton class="md" width="100%" height="56px"></b-skeleton>
      </div>
      <div class="d-block d-sm-none mt-2">
        <b-skeleton class="md" width="100%" height="50px"></b-skeleton>
      </div>
    </div>
    <div class="mt-3 mt-sm-4 d-none d-sm-block">
      <b-skeleton class="button" width="100%" height="48px"></b-skeleton>
    </div>
    <div class="mt-3 mt-sm-4 d-block d-sm-none">
      <b-skeleton class="button" width="100%" height="40px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import LinkBackPage from "../../UI/LinkBackPage.vue";
import { useWalletStore } from "@/stores/wallet";
import SelectNetwork from "../../UI/SelectNetwork.vue";
import { WalletController } from "@/core/system/controllers";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { validateBlockchainAddress } from "@/utils/validateInput";

export default {
  name: "FormAddContact",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LinkBackPage,
    SelectNetwork,
  },
  data: () => ({
    isLoadingEditContact: false,
  }),
  methods: {
    ...mapActions(
      () => useWalletStore(),
      ["getAllNetwokCurrency", "setSelectedNetworkCurrency"]
    ),
    ...mapActions(
      () => useSendTransferStore(),
      ["getContactsList", "setStepAddressBook"]
    ),
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    async editContact() {
      this.isLoadingEditContact = true;
      const payload = {
        address_book_id: this.selectedContact.id,
        address: this.selectedContact.address,
        name: this.selectedContact.name,
        network_id: this.selectedNetworkCurrency.id,
      };
      const isValidAddress = validateBlockchainAddress(
        this.selectedContact.address,
        this.selectedNetworkCurrency.fullname
      );
      try {
        if (isValidAddress) {
          await WalletController.editContactFromAddressList(payload);
          this.getContactsList();
          this.setStepAddressBook("list");
        } else {
          this.showMessage(
            "danger",
            this.$t("messages.uncorrect_address_network")
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingEditContact = false;
      }
    },
  },
  computed: {
    ...mapState(
      () => useWalletStore(),
      ["networksList", "selectedNetworkCurrency"]
    ),
    ...mapState(() => useSendTransferStore(), ["selectedContact"]),
    isValidEditContact() {
      return (
        this.selectedContact.address != "" && this.selectedContact.name != ""
      );
    },
  },
  async mounted() {
    const networks = await this.getAllNetwokCurrency();
    const currentNetwork = networks.find(
      (network) =>
        network.fullname === this.selectedContact.network_object.full_name
    );
    this.setSelectedNetworkCurrency(currentNetwork);
  },
};
</script>

<style lang="scss" scoped>
.edit-contact__wrapper {
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
