<template>
  <div class="app">
    <b-container id="main-container">
      <div class="d-flex">
        <Sidebar :style="{ 'z-index': isOpenStories ? 1 : 999 }" class="mt-4" />
        <b-container
          id="content-container"
          :class="{ 'container-fluid': $route.path === '/exchange' }"
        >
          <header class="header d-none d-sm-block mt-4">
            <div class="d-flex align-items-center header__inner">
              <h3
                v-if="$route.path.includes('/wallet')"
                class="header__title mb-0"
              >
                {{ selectedWallet.nickname }}
              </h3>
              <h3
                v-else
                :class="{ 'header-fluid': $route.path === '/exchange' }"
                class="header__title mb-0"
              >
                {{ $route.meta.title }}
              </h3>
              <!-- <a href="#" class="ms-2">
                <img
                  class="header__question"
                  src="@/assets/images/tooltip-icon.svg"
                  alt=""
                />
              </a> -->
              <div
                class="header__info d-none d-sm-flex align-items-center ms-auto"
              >
                <!-- <div class="me-3 fs-4 header__balance">
                  $ {{ user.balance_usd }}
                </div> -->
                <UserDropdown />
              </div>
            </div>
          </header>
          <main class="content w-100">
            <router-view />
          </main>
          <MobileMenu />
        </b-container>
      </div>
    </b-container>
  </div>
</template>

<script>
import Sidebar from "@/components/Navigation/Sidebar.vue";
import Notification from "@/components/Notification.vue";
import UserDropdown from "@/components/UserDropdown";
import MobileMenu from "@/components/Navigation/MobileMenu.vue";
import { mapState, mapActions } from "pinia";
import { useStoriesStore } from "@/stores/storiesStore";
import { useUserStore } from "@/stores/userStore";
import { useWalletStore } from "@/stores/wallet";

export default {
  components: {
    Sidebar,
    Notification,
    UserDropdown,
    MobileMenu,
  },
  computed: {
    ...mapState(useStoriesStore, ["isOpenStories"]),
    ...mapState(useUserStore, ["user", "is_auth"]),
    ...mapState(useWalletStore, ["selectedWallet"]),
  },
  methods: {
    ...mapActions(useUserStore, ["setCurrenctUser"]),
    checkConfirmEmail() {
      if (this.is_auth) {
        if (this.user.is_email_confirming === false) {
          this.$router.push("/confirm-email");
        }
      }
    },
  },
  async mounted() {
    await this.setCurrenctUser();
    this.checkConfirmEmail();
  },
  async beforeRouteUpdate() {
    console.log(1);
    await this.setCurrenctUser();
    this.checkConfirmEmail();
  },
};
</script>

<style lang="scss" scoped>
.app {
  padding-bottom: 70px;
  display: flex;
  #main-container {
    margin: 0 auto;
    // .content {
    //   position: relative;
    //   z-index: 1;
    // }
  }
  .content {
    padding-top: 25px;
    @media (max-width: 576px) {
      padding-top: 85px;
    }
  }
}
</style>
