<template>
  <div
    class="button-mobile"
    :class="variant === 'row' ? 'button-mobile--row' : ''"
  >
    <div class="button-mobile__inner">
      <slot />
      <span>{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonMobile",
  props: {
    name: String,
    variant: {
      type: String,
      required: false,
      default: "column",
    },
  },
};
</script>
