var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('div',{staticClass:"select-currency-wallet"},[_c('div',{staticClass:"select-item-wrapper"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"icon-block"},[_c('b-skeleton',{staticClass:"md",attrs:{"width":"60%","height":"60%"}})],1),_c('b-skeleton',{staticClass:"sm ms-2",attrs:{"width":"64px","height":"20px"}})],1),_c('b-skeleton',{staticClass:"button",attrs:{"width":"45px","height":"20px"}})],1)]):_c('div',{staticClass:"select-currency-wallet",attrs:{"tabindex":0},on:{"click":_vm.toggleSelect,"blur":_vm.closeSelect}},[_c('div',{staticClass:"select-item-wrapper"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"icon-block"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.selectedCurrency.icon_base,"alt":""}})]),_c('h5',{staticClass:"currency-name mb-0"},[_vm._v(_vm._s(_vm.selectedCurrency.name))]),_c('img',{staticClass:"arrow",class:{
          active: _vm.isOpen,
        },attrs:{"src":require("@/assets/images/arrow-icon.svg"),"alt":""}})]),_c('div',{staticClass:"bage"},[_vm._v(_vm._s(_vm.selectedWallet.network_object.full_name))])]),(_vm.options.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"select-dropdown",on:{"click":function($event){$event.stopPropagation();}}},[_c('WalletsSwitcher',{attrs:{"variant":"selector"},on:{"onSwitch":_vm.switchTypeWallets}}),_vm._l((_vm.filteredWallets),function(wallet){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(wallet.visibility_state),expression:"wallet.visibility_state"}],key:wallet.id,staticClass:"wallet-item",on:{"click":function($event){return _vm.selectWallet(wallet)}}},[_c('div',[_c('div',{staticClass:"wallet-item__header"},[(!wallet.is_piggy_bank)?_c('h5',{staticClass:"wallet-item__name mb-0"},[_vm._v(" "+_vm._s(wallet.nickname)+" ")]):_c('h5',{staticClass:"wallet-item__name mb-0"},[_vm._v(" "+_vm._s(wallet.piggy_bank.target_title)+" ")]),_c('p',{staticClass:"wallet-item__address mb-0"},[_vm._v(" "+_vm._s(_vm.getShortWalletAddress(wallet.address))+" ")])]),_c('div',{staticClass:"wallet-item__currency-list"},_vm._l((wallet.currency_list),function(currency){return _c('div',{key:currency.id,staticClass:"wallet-item__currency-item",class:{
              selected:
                _vm.selectedCurrency.id === currency.id &&
                _vm.selectedWallet.id === wallet.id,
            },attrs:{"id":`currency-item_${wallet.id}`},on:{"click":function($event){return _vm.selectCurrency(currency)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"icon-block"},[_c('img',{staticClass:"icon",attrs:{"src":currency.icon_base,"alt":""}})]),_c('h5',{staticClass:"name mb-0"},[_vm._v(_vm._s(currency.name))])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"balance mb-0"},[_vm._v(" "+_vm._s(_vm.convertBalanceCrypto(currency.balance))+" "+_vm._s(_vm.sliceNetworkCodeCurrency(currency.code))+" ")]),_c('div',{staticClass:"bage ms-2"},[_vm._v(" "+_vm._s(wallet.network_object.full_name)+" ")])])])}),0)])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }