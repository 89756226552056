var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fee-card",class:{ active: _vm.isActive }},[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{attrs:{"cols":"6","sm":"5"}},[_c('div',{staticClass:"fee-card__left"},[_c('img',{staticClass:"variant-icon",attrs:{"src":require(`@/assets/images/feeSettings/${_vm.variant}.svg`),"alt":""}}),_c('div',[_c('p',{staticClass:"variant-text mb-0"},[_vm._v(_vm._s(_vm.variantText))]),(_vm.duration)?_c('div',{staticClass:"fee-card__center d-flex align-items-center d-sm-none"},[_c('p',{staticClass:"duration-text mb-0"},[_vm._v("Время:")]),_c('p',{staticClass:"duration-data mb-0",class:{
                red: !_vm.isOptimalVariant,
                green: _vm.isOptimalVariant,
              }},[_vm._v(" ~"+_vm._s(_vm.duration)+" сек ")])]):_vm._e()])])]),(_vm.duration)?_c('b-col',{staticClass:"d-none d-sm-block",attrs:{"cols":"3"}},[_c('div',{staticClass:"fee-card__center"},[_c('p',{staticClass:"duration-data mb-0",class:{
            red: !_vm.isOptimalVariant,
            green: _vm.isOptimalVariant,
          }},[_vm._v(" ~"+_vm._s(_vm.duration)+" сек ")]),_c('p',{staticClass:"duration-text mb-0"},[_vm._v("Время")])])]):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"6","sm":"4"}},[_c('div',{staticClass:"fee-card__right"},[_c('p',{staticClass:"fee-data mb-0"},[_vm._v(_vm._s(_vm.amountFee)+" "+_vm._s(_vm.currencyFee))]),_c('p',{staticClass:"fee-text text-end text-md-start mb-0"},[_vm._v("Комиссия")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }