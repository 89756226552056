<template>
  <div class="custom-select-crypto" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{
        open: open,
      }"
      @click="toggleSelect"
    >
      <b-row align-h="between" class="justify-content-start align-items-center">
        <b-col cols="auto" class="pr-0" v-if="selected.icon">
          <div class="d-flex align-items-center">
            <img :src="selected.icon" alt="" class="icon-currancy-select" />
            <h2 class="mb-0 select-title ms-2">
              {{ selected.fullname }}
            </h2>
          </div>
        </b-col>
        <b-col cols="auto">
          <img src="../../assets/images/arrow-icon.svg" alt="" />
        </b-col>
      </b-row>
    </div>
    <div
      class="items animate__animated animate__faster animate__fadeInDown"
      :class="{ selectHide: !open }"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          selectItem(option);
        "
        class="wrapper-options"
        :class="{ hide: option.id == selected.id }"
      >
        <div
          class="justify-content-between align-items-center"
          v-if="option.id != selected.id"
        >
          <div class="d-flex align-items-center">
            <img :src="option.icon" alt="" class="icon-currancy-select" />
            <h2 class="select-title ms-2 mb-0">
              {{ option.fullname }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Tooltip from "../tooltip.vue";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // selected: this.default
      //   ? this.default
      //   : this.options.length > 0
      //     ? this.options[0]
      //     : null,
      open: false,
    };
  },
  computed: {
    selected: {
      get() {
        return this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0]
          : null;
      },
      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
  methods: {
    setCode(code) {
      if (
        code.includes("USDT") ||
        code.includes("USDC") ||
        code.includes("TUSD")
      ) {
        return code.split("_")[0];
      }
      return code;
    },
    toggleSelect() {
      if (!this.isLoading) {
        this.open = !this.open;
      }
    },
    sliceAmount(amount) {
      return amount.slice(0, 8);
    },
    shortenWalletAddress(address) {
      return `${address.substring(0, 6)}...${address.substring(
        address.length - 14
      )}`;
    },
    selectItem(item) {
      this.$emit("change", item);
    },
  },
  mounted() {
    this.$emit("change", this.selected);
  },
  //   components: { Tooltip },
};
</script>

<style lang="scss" scoped>
.custom-select-crypto {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 18px;
  z-index: 100;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
}
.fs-12 {
  font-size: 12px;
}
.active-select {
  border: solid 1px #4a40c6 !important;
  border-radius: 8px;
}

.loading {
  cursor: wait !important;
}

.custom-select-crypto .selected {
  position: relative;
  z-index: 12;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  color: #353945;
  padding: 12px 18px;
  /* padding-left: 1em; */
  cursor: pointer;
  user-select: none;
  @media (max-width: 576px) {
    padding: 10px;
  }
}

.select-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3c3c3c;
}

.select-amount {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3c3c3c;
}

@media (max-width: 400px) {
  .custom-select-crypto .selected {
    padding: 10px;
  }
}

.custom-select-crypto .selected.open {
  border: 1px solid #f0f0f0;
}

.icon-arrow {
  background: url("../../assets/images/arrow-icon.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
}

.icon-arrow.open {
  transform: rotate(180deg);
}

.custom-select-crypto .items {
  max-height: 395px;
  color: #353945;
  border-radius: 0px 0px 6px 6px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 49px;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 5px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.5s;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  @media (max-width: 576px) {
    top: 40px;
  }
}
.custom-select-crypto .items.eu_card {
  top: 85px;
}

.custom-select-crypto .items .wrapper-options {
  color: #353945;
  padding: 12px 18px;
  cursor: pointer;
  user-select: none;
  position: static;
  @media (max-width: 576px) {
    padding: 10px;
  }
}

@media (max-width: 570px) {
  .custom-select-crypto .items.eu_card {
    top: 90px;
  }
}
@media (max-width: 576px) {
  .custom-select-crypto .items.eu_card {
    top: 85px;
  }
}

.icon-currancy-select {
  width: 32px;
  height: 32px;
  border: 1px solid #f0f0f0;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
}

@media (max-width: 576px) {
  .icon-currancy-select {
    width: 22px;
    height: 22px;
    border: 1px solid #f0f0f0;
    padding: 2px;
    border-radius: 4px;
  }
}

.custom-select-crypto div.items div.wrapper-options:empty,
.wrapper-options.hide {
  display: none;
}
.custom-select-crypto .items > div {
  border: 1px solid transparent;
  /* box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05); */
  border-radius: 8px;
}

.custom-select-crypto .items > div:hover {
  border: 1px solid #bfbcdd;
  box-shadow: 0px 5px 10px -5px rgba(15, 15, 15, 0.05);
  border-radius: 8px;
}

.selectHide {
  display: none;
  transition: 0.8s;
}
</style>
