import Vue from "vue";
import VueI18n from "vue-i18n";
import ru from "./locales/ru.json";
import en from "./locales/en.json";

Vue.use(VueI18n);

const messages = {
  en,
  ru,
};

const i18n = new VueI18n({
  locale: "ru",
  messages,
});
export default i18n;
