<template>
  <Modal id="change-password-invoice">
    <template v-slot:modal-header>
      <div class="settings-title d-flex align-items-center">
        <img src="@/assets/images/settings/key-icon.svg" alt="" />
        <h3 class="mb-0 ms-2">Смена пароля для доступа к счету хранения</h3>
      </div>
    </template>
    <template v-slot:modal-body>
      <form @submit.prevent="changePassword" action="">
        <div>
          <div class="label">
            <h6>Старый пароль</h6>
          </div>
          <CCInput></CCInput>
        </div>
        <div class="mt-4">
          <div class="label">
            <h6>Новый пароль</h6>
          </div>
          <CCInput v-model="newPassword" @keyup="checkPassword"></CCInput>
          <ValidationPassword
            class="mt-2"
            :length="passwordRules.length"
            :upper-case-letters="passwordRules.letterBig"
            :numbers="passwordRules.number"
            :lower-case-letters="passwordRules.letterSmall"
          ></ValidationPassword>
        </div>
        <div class="mt-4">
          <div class="label">
            <h6>Подтверждение пароля</h6>
          </div>
          <CCInput></CCInput>
        </div>
        <CCButton class="mt-4" size="lg" fontWeight="medium"
          >Сменить пароль</CCButton
        >
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "pinia";
import Modal from "../UI/Modal.vue";
import ValidationPassword from "../ValidationPassword.vue";
import { useSettingsStore } from "@/stores/settingsStore";
export default {
  components: { Modal, ValidationPassword },
  data: () => ({
    newPassword: "",
    passwordRules: {
      letterSmall: false,
      letterBig: false,
      number: false,
      length: false,
    },
  }),
  methods: {
    ...mapActions(useSettingsStore, ["setStatusChangePasswordInvoice"]),
    checkPassword() {
      // Validate lowercase letters
      let lowerCaseLetters = /[a-z]/g;
      if (this.newPassword.match(lowerCaseLetters)) {
        this.passwordRules.letterSmall = true;
      } else {
        this.passwordRules.letterSmall = false;
      }

      // Проверка заглавных букв
      let upperCaseLetters = /[A-Z]/g;
      if (this.newPassword.match(upperCaseLetters)) {
        this.passwordRules.letterBig = true;
      } else {
        this.passwordRules.letterBig = false;
      }

      // Проверка чисел
      let numbers = /[0-9]/g;
      if (this.newPassword.match(numbers)) {
        this.passwordRules.number = true;
      } else {
        this.passwordRules.number = false;
      }

      // Проверка длины
      if (this.newPassword.length >= 8) {
        this.passwordRules.length = true;
      } else {
        this.passwordRules.length = false;
      }
    },
    changePassword() {
      this.setStatusChangePasswordInvoice("success");
      this.$bvModal.hide("change-password-invoice");
    },
  },
};
</script>

<style></style>
