<template>
  <div class="confirm">
    <ConfirmData
      :balance="balance"
      :amount="totalAmountRows"
      :currency="selectedCurrency.code"
      :feeMode="selectedVariantFee.modeName"
      :feeAmount="fee.total"
      :isLoading="isLoadingFee"
      @openSettingsMode="openSettingsFee"
    />
    <div class="mt-4">
      <h4 class="title mb-0">Список получателей</h4>
      <RowsList :rowsList="rowsListConfirm" @removeItem="removeRowAndUpdateFee" class="mt-3" />
    </div>
    <BTCSettingsPopup
      v-if="selectedNetwork === 'Bitcoin'"
      :isLoadingVariants="isLoadingVariantFees"
    />
  </div>
</template>

<script>
import ConfirmData from "../Confirm/ConfirmData.vue";
import RowsList from "./RowsList.vue";
import { mapActions, mapState } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { getRoundedAmountCrypto } from "@/utils";
import { useSendMultiStore } from "@/stores/sendMultiStore";
import { CalculateFeeTransactionBtc } from "@/wallets/Bitcoin";
import BTCSettingsPopup from "@/components/FeeSettings/BTCSettingsPopup.vue";

export default {
  components: { ConfirmData, RowsList, BTCSettingsPopup },
  data: () => ({
    isLoadingVariantFees: false,
  }),
  methods: {
    ...mapActions(useSendTransferStore, [
      "setIsLoadingFee",
      "setFee",
      "setSelectedVariantFee",
      "setVariantsFee",
    ]),
    ...mapActions(useSendMultiStore, ["removeRow", "checkValidRow"]),
    openSettingsFee() {
      if (this.selectedNetwork !== "Bitcoin") return;
      this.$bvModal.show("btc-fee-modal");
    },
    removeRowAndUpdateFee(id) {
      this.removeRow(id);
      this.generalCalcFees();
    },
    calcFeeLitecoin() {
      this.setIsLoadingFee(true);
      const feeObj = {
        total: 100000 / 10 ** 8,
        gasLimit: null,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      };
      this.setFee(feeObj);
      this.setIsLoadingFee(false);
    },
    async calcFeeBitcoin(isUseSavedVariant = false) {
      const calcFee = new CalculateFeeTransactionBtc(
        Number(this.totalAmountRows),
        this.selectedWallet.address
      );
      this.isLoadingVariantFees = true;
      this.setIsLoadingFee(true);
      try {
        const variantsFee = await calcFee.getFeeSatoshiByte();
        const variantLow = {
          mode: "low",
          modeName: "Медленный",
          amountFeeSatoshi: variantsFee.economyFee,
          amountFeeBtc: await calcFee.getFee(
            variantsFee.economyFee,
            this.validRowsCount.length
          ),
        };
        const variantMedium = {
          mode: "medium",
          modeName: "Рыночный",
          amountFeeSatoshi: variantsFee.hourFee,
          amountFeeBtc: await calcFee.getFee(
            variantsFee.hourFee,
            this.validRowsCount.length
          ),
        };
        const variantHigh = {
          mode: "high",
          modeName: "Приоритетный",
          amountFeeSatoshi: variantsFee.fastestFee,
          amountFeeBtc: await calcFee.getFee(
            variantsFee.fastestFee,
            this.validRowsCount.length
          ),
        };
        const variantsFeeArray = [variantLow, variantMedium, variantHigh];
        this.setVariantsFee(variantsFeeArray);
        if (isUseSavedVariant) {
          const savedVariantFee = JSON.parse(
            localStorage.getItem("selectedVariantFeeBtc")
          );
          if (savedVariantFee) {
            this.setSelectedVariantFee(savedVariantFee);
            if (savedVariantFee.mode === "custom") {
              this.setFeeSatoshi(savedVariantFee.amountFeeSatoshi);
            }
          } else {
            this.setSelectedVariantFee(variantsFeeArray[1]);
          }
        } else {
          this.setSelectedVariantFee(variantsFeeArray[1]);
        }
        const newFeeObj = {
          total: this.selectedVariantFee.amountFeeBtc,
          gasLimit: null,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        };
        this.setFee(newFeeObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingVariantFees = false;
        this.setIsLoadingFee(false);
      }
    },
    generalCalcFees() {
      this.selectedNetwork === "Bitcoin"
        ? this.calcFeeBitcoin()
        : this.calcFeeLitecoin();
    },
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "selectedCurrency",
      "selectedWallet",
      "selectedVariantFee",
      "isLoadingFee",
      "fee",
    ]),
    ...mapState(useSendMultiStore, [
      "totalAmountRows",
      "validRowsCount",
      "rowsListConfirm",
    ]),
    selectedNetwork() {
      return this.selectedWallet.network_object.full_name;
    },
    balance() {
      return getRoundedAmountCrypto(this.selectedCurrency.balance);
    },
  },
  mounted() {
    this.checkValidRow();
    this.generalCalcFees();
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
</style>
