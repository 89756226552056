<template>
  <CCCard>
    <div class="wallet-success">
      <div
        class="wallet-success__header d-flex align-items-center justify-content-between"
      >
        <h3 class="wallet-success__title mb-0">Копилка создана</h3>
      </div>
      <div class="wallet-success__content">
        <img
          class="status-icon"
          src="@/assets/images/success-marker.svg"
          alt=""
        />
        <p class="status-message mb-0 mt-3">Накопительный счет успешно создан</p>
        <div class="status-btn mt-3 mt-sm-4">
          <router-link to="/">
            <CCButton
              fontWeight="medium"
              size="lg"
              class="d-none d-sm-block px-4"
            >
              Вернуться в личный кабинет
            </CCButton>
            <CCButton
              fontWeight="medium"
              size="md"
              class="d-block d-sm-none px-4"
            >
              Вернуться в личный кабинет
            </CCButton>
          </router-link>
        </div>
      </div>
    </div>
  </CCCard>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wallet-success {
  &__title {
    color: #3c3c3c;
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 576px) {
      font-size: 28px;
    }
  }
  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .status-message {
      color: #939393;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .error-text {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #3c3c3c;
    }
    .error-link {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #4a40c6;
      text-decoration: underline;
      word-break: break-all;
    }
    .status-icon {
      margin-top: 10px;
      width: 80px;
      height: 80px;
    }
    .status-txid {
      width: 90%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    .status-btn {
      width: 60%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}
</style>
