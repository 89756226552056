<template>
  <div class="aml-buy-page">
    <HeaderBackLink class="d-none d-sm-block" :title="$t('aml_page.title')">
      <img
        class="exchange-icon icon"
        src="../assets/images/exchange/exchange-icon.png"
        alt=""
      />
    </HeaderBackLink>
    <HeaderMobile
      :title="$t('aml_page.title_mobile')"
      class="d-block d-sm-none"
    >
      <img
        v-if="step > 1"
        @click="setStepBack"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
      <router-link v-else slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link v-if="step === 1" slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <div class="aml-buy-widget">
          <div v-if="step === 1">
            <h1 class="aml-buy__title mb-0">{{ $t('aml_page.title2') }}</h1>
            <TypeForm
              :isLoading="isLoadingPage"
              :pillsOptions="numberOfChecks"
              :selectedPill="selectedNumberCheck"
              @changePills="selectCheck"
              @typeInput="typeInputChecks"
            />
            <AmlData
              :isLoading="isLoadingInfo"
              :amount="totalAmount"
              :address="address"
              :fee="fee"
            />
            <div class="send-transfer__footer d-none d-sm-block">
              <div class="send-transfer__line"></div>
              <b-row align-v="center" v-if="!isLoadingPage">
                <b-col cols="6" class="color-light send-transfer__step">
                  {{ $t('aml_page.step') }} 1 из 2
                </b-col>
                <b-col cols="6" class="ms-auto">
                  <CCButton
                    :disabled="isLoadingCreateTransaction"
                    @click="createTransaction"
                    fontWeight="medium"
                    size="lg"
                    class="px-4"
                  >
                    {{ $t('aml_page.btn_next') }}
                  </CCButton>
                </b-col>
              </b-row>
              <b-row align-v="center" v-else>
                <b-col cols="6" class="color-light send-transfer__step">
                  <b-skeleton class="sm" width="80%" height="24px"></b-skeleton>
                </b-col>
                <b-col cols="6" class="ms-auto">
                  <b-skeleton
                    class="button"
                    width="100%"
                    height="48px"
                  ></b-skeleton>
                </b-col>
              </b-row>
            </div>
            <div class="d-block d-sm-none">
              <div v-if="!isLoadingPage" class="mt-4">
                <CCButton
                  :disabled="isLoadingCreateTransaction"
                  @click="createTransaction"
                  fontWeight="medium"
                  size="md"
                  class="px-4"
                >
                  {{ $t('aml_page.btn_next') }}
                </CCButton>
              </div>
              <div v-else class="mt-4">
                <b-skeleton
                  class="button"
                  width="100%"
                  height="40px"
                ></b-skeleton>
              </div>
            </div>
          </div>
          <div v-if="step === 2">
            <ConfirmTransaction
              @submitForm="confirmTransaction"
              @cancel="clearTransaction"
            />
          </div>
          <LoadingScreen v-if="step === 3" />
          <StatusTransaction v-if="step === 4" />
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="mt-4 mt-lg-0 pb-4 pb-sm-0">
        <HistoryList
          variant="aml"
          :isLoading="isLoadingPage"
          :historyItems="historyList"
          @selectItem="pasteDataFromHistory"
        >
          <Pagination
            v-if="!isLoadingPage"
            slot="pagination"
            :currentPage="currentPageHistory"
            :totalPages="totalPagesHistory"
            @changePage="setCurrentPage"
          />
        </HistoryList>
      </b-col>
    </b-row>
    <SelectWalletPopup
      @confirm="confirmWallet"
      variant="aml"
      currencyTron="USDT_TRC20"
    />
  </div>
</template>

<script>
import LoadingScreen from '@/components/Transactions/LoadingScreen.vue';
import ConfirmTransaction from '@/components/Transactions/ConfirmTransaction';
import SelectCurrency from '@/components/UI/SelectNetwork.vue';
import { debounce } from '@/utils/debounce';
import { WalletController, ServiceController } from '@/core/system/controllers';
import { mapActions, mapState } from 'pinia';
import { useWalletStore } from '@/stores/wallet';
import { useUserStore } from '@/stores/userStore';
import HeaderBackLink from '@/components/Headers/HeaderBackLink.vue';
import SelectWalletPopup from '@/components/modals/SelectWalletPopup.vue';
import { useInterfaceStore } from '@/stores/interfaceStore';
import SelectPills from '@/components/UI/SelectPills.vue';
import TypeForm from '@/components/AmlBuy/TypeForm.vue';
import { useAmlStore } from '@/stores/amlStore';
import AmlData from '@/components/AmlBuy/AmlData.vue';
import HeaderMobile from '@/components/Headers/HeaderMobile.vue';
import HistoryList from '@/components/HistoryTransactions/HistoryCards/HistoryList.vue';
import Pagination from '@/components/UI/Pagination.vue';
import { useStakingStore } from '@/stores/stakingStore';
import StatusTransaction from '@/components/Transactions/StatusTransaction.vue';

export default {
  components: {
    ConfirmTransaction,
    LoadingScreen,
    SelectCurrency,
    HeaderBackLink,
    SelectWalletPopup,
    SelectPills,
    TypeForm,
    AmlData,
    HeaderMobile,
    HistoryList,
    Pagination,
    StatusTransaction,
  },
  data: () => ({
    interval: null,
    step: 1,
    fee: null,
    isLoadingPage: true,
    isLoadingInfo: true,
    isLoadingCreateTransaction: false,
    totalAmount: null,
    orderId: null,
    address: '',
    selectedNumberCheck: {
      id: 1,
      name: '1 шт',
      value: 1,
    },
  }),
  methods: {
    ...mapActions(
      () => useWalletStore(),
      ['setTransactionData', 'updateBalance', 'getBalanceTrxAddress'],
    ),
    ...mapActions(() => useInterfaceStore(), ['showMessage']),
    ...mapActions(() => useUserStore(), ['setCurrenctUser']),
    ...mapActions(
      () => useAmlStore(),
      ['setCurrentPage', 'loadHistoryList', 'setChecksInput'],
    ),
    ...mapActions(() => useStakingStore(), ['fetchStakingData']),
    setStepBack() {
      this.step -= 1;
    },
    clearTransaction() {
      this.step = 1;
    },
    confirmWallet() {
      this.getInfo();
      this.fetchStakingData(this.walletUsdt.address);
      this.$root.$emit('bv::hide::modal', 'select-wallet-modal');
    },
    selectCheck(check) {
      this.selectedNumberCheck = check;
      this.setChecksInput(check.value);
      this.debouncedCalcTotalAmount();
    },
    typeInputChecks() {
      this.debouncedCalcTotalAmount();
    },
    pasteDataFromHistory(item) {
      console.log(item);
      const quantity = this.numberOfChecks.find(
        (pill) => pill.value === item.number_service_units,
      );
      if (quantity) {
        this.selectCheck(quantity);
      } else {
        this.setChecksInput(item.number_service_units);
        this.debouncedCalcTotalAmount();
      }
      if (window.innerWidth <= 576) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    async getInfo() {
      const payload = {
        service_id: 1,
      };
      try {
        const resp = await ServiceController.serviceInfo(payload);
        (this.address = resp.address), (this.totalAmount = resp.amount);
        await this.getBalanceTrxAddress(
          this.walletUsdt.address,
          this.address,
          'USDT_TRC20',
        );
        this.fee = this.recipientBalance > 0 ? 16 : 32;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingInfo = false;
        this.isLoadingPage = false;
      }
    },
    async calcTotalAmount() {
      this.isLoadingInfo = true;
      const payload = {
        service_id: 1,
        count: Number(this.checksInput),
      };
      try {
        const resp = await ServiceController.calculationAmount(payload);
        this.totalAmount = resp.calculation_amount;
        this.address = resp.address;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingInfo = false;
      }
    },
    async createTransaction() {
      if (this.checkFeeAndBalance) {
        this.isLoadingCreateTransaction = true;
        const payload = {
          service_id: 1,
          count: Number(this.checksInput),
          wallet_id: this.walletUsdt.id,
        };
        const newTransactionData = {
          selectedNetwork: 'TRON',
          amount: String(this.totalAmount),
          fromAddress: this.walletUsdt.address,
          toAddress: this.address,
          currency: 'USDT',
        };
        try {
          const resp = await ServiceController.serviceCreate(payload);
          this.step = 2;
          this.setTransactionData(newTransactionData);
          this.orderId = resp.id;
        } catch (error) {
          this.showMessage('danger', this.$t('messages.error_global'));
        } finally {
          this.isLoadingCreateTransaction = false;
        }
      } else {
        this.showMessage('danger', this.$t('messages.error_fee'));
      }
    },
    async confirmTransaction() {
      const payload = {
        order_id: this.orderId,
        tx_id: this.txId,
        currency_code: 'USDT-TRC20',
      };
      try {
        if (this.txId) {
          await ServiceController.servicePay(payload);
          this.loadHistoryList();
          this.updateBalanceWallet();
          this.step = 3;
          this.interval = setInterval(() => {
            this.checkStatusTransaction();
          }, 30000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkStatusTransaction() {
      const payload = {
        order_id: this.orderId,
        wallet_id: this.walletUsdt.id,
      };
      const resp = await ServiceController.serviceStatus(payload);
      if (resp.status === 'pay') {
        this.step = 4;
        this.updateBalance();
        this.loadHistoryList();
        this.setCurrenctUser();
        clearInterval(this.interval);
      }
    },
    async updateBalanceWallet() {
      const payload = {
        wallet_network_id: this.walletUsdt.id,
      };
      await WalletController.updateBalance(payload);
    },
  },
  computed: {
    ...mapState(
      () => useWalletStore(),
      ['selectedWallet', 'txId', 'recipientBalance', 'myBalance'],
    ),
    ...mapState(() => useStakingStore(), ['allResources']),
    ...mapState(
      () => useAmlStore(),
      [
        'historyList',
        'isLoadingHistoryList',
        'currentPageHistory',
        'totalPagesHistory',
        'checksInput',
      ],
    ),
    myBalanceEnergy() {
      return this.allResources.available_energy;
    },
    walletUsdt() {
      return JSON.parse(localStorage.getItem('tron_wallet'));
    },
    checkFeeAndBalance() {
      if (this.myBalance < this.fee) {
        if (this.recipientBalance > 0) {
          return this.myBalanceEnergy >= 32000;
        } else {
          return this.myBalanceEnergy >= 65000;
        }
      } else {
        return true;
      }
    },
    numberOfChecks() {
      return [
        {
          id: 1,
          name: `1 ${this.$t('aml_page.quantity_unit')}`,
          value: 1,
        },
        {
          id: 2,
          name: `10 ${this.$t('aml_page.quantity_unit')}`,
          value: 10,
        },
      ];
    },
  },
  mounted() {
    this.$bvModal.show('select-wallet-modal');
    this.loadHistoryList();
  },
  created() {
    this.debouncedCalcTotalAmount = debounce(this.calcTotalAmount, 300);
  },
};
</script>

<style lang="scss" scoped>
.aml-buy-widget {
  position: relative;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 0;
    background: none;
  }
}

.aml-buy__title {
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 22px;
  }
}

.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  user-select: none;
}

.select-pills {
  margin-left: 10px;
  .pill {
    user-select: none;
    margin-left: 4px;
    width: 80px;
    height: 56px;
    cursor: pointer;
    padding: 16px 14px;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &.active {
      border: 1px solid #6a62ca;
      background: #fafaff;
      color: #6a62ca;
    }
  }
}
</style>
