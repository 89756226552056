<template>
  <div class="confirm-transaction">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="confirm-transaction__title">
        {{ $t("confirm_transaction.title") }}
      </h1>
      <img
        v-if="isCloseBtn"
        @click="cancelTransaction"
        class="pointer d-none d-sm-block"
        src="@/assets/images/close-modal.svg"
        alt=""
      />
    </div>
    <form
      class="d-none d-sm-block"
      @submit.prevent="confirmTransaction"
      action=""
    >
      <div class="mt-4 input-block energy">
        <div class="label">
          <b-row align-v="center">
            <b-col cols="auto">
              <h6 class="my-0">
                {{ $t("confirm_transaction.password_label") }}
              </h6>
            </b-col>
          </b-row>
        </div>
        <div class="send-transfer__input-with-icon">
          <CCInput
            v-model="password"
            :placeholder="$t('confirm_transaction.password_placeholder')"
            type="password"
            class="mt-3"
          ></CCInput>
          <img width="24" src="@/assets/images/guard.svg" />
        </div>
      </div>
      <div class="mt-4 input-block energy">
        <div class="label">
          <b-row align-v="center">
            <b-col cols="auto">
              <h6 class="my-0">{{ $t("confirm_transaction.aes_label") }}</h6>
            </b-col>
          </b-row>
        </div>
        <div class="send-transfer__input-with-icon">
          <CCInput
            :placeholder="$t('confirm_transaction.aes_placeholder')"
            v-model="aes"
            type="password"
            class="mt-3"
          >
          </CCInput>
          <img width="24" src="@/assets/images/guard.svg" />
        </div>
      </div>
      <b-row class="mt-32">
        <b-col cols="12">
          <CCButton
            fontWeight="medium"
            size="lg"
            class="px-4"
            :disabled="isLoadingConfirm"
          >
            {{ $t("confirm_transaction.btn_sign") }}
          </CCButton>
        </b-col>
      </b-row>
    </form>
    <form
      class="d-block d-sm-none"
      @submit.prevent="confirmTransaction"
      action=""
    >
      <div class="mt-4 input-block energy">
        <div class="label">
          <b-row align-v="center">
            <b-col cols="auto">
              <h6 class="my-0">
                {{ $t("confirm_transaction.password_label") }}
              </h6>
            </b-col>
          </b-row>
        </div>
        <div class="send-transfer__input-with-icon">
          <CCInput
            v-model="password"
            :placeholder="$t('confirm_transaction.password_placeholder')"
            type="password"
            class="mt-2"
            size="md"
          ></CCInput>
          <img width="24" src="@/assets/images/guard.svg" />
        </div>
      </div>
      <div class="mt-4 input-block energy">
        <div class="label">
          <b-row align-v="center">
            <b-col cols="auto">
              <h6 class="my-0">{{ $t("confirm_transaction.aes_label") }}</h6>
            </b-col>
          </b-row>
        </div>
        <div class="send-transfer__input-with-icon">
          <CCInput
            :placeholder="$t('confirm_transaction.aes_placeholder')"
            v-model="aes"
            type="password"
            class="mt-2"
            size="md"
          ></CCInput>
          <img width="24" src="@/assets/images/guard.svg" />
        </div>
      </div>
      <b-row class="mt-4">
        <b-col cols="12">
          <CCButton
            :disabled="isLoadingConfirm"
            fontWeight="medium"
            size="md"
            class="px-4"
          >
            {{ $t("confirm_transaction.btn_sign") }}
          </CCButton>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { decodePassword } from "@/utils/encryption";
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import { CreateTronTransaction, TronStaking } from "@/wallets/Tron";
import {
  CreateEthereumTransactions,
  CreateTokensTransactions,
} from "@/wallets/Ethereum";
import { CreateLitecoinTransactions } from "@/wallets/Litecoin";
import { CreateBitcoinTransactions } from "@/wallets/Bitcoin";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { useSendTransferStore } from "@/stores/sendTransferStore";

export default {
  props: {
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isLoadingConfirm: false,
    password: "",
    aes: "",
    txId: null,
  }),
  methods: {
    ...mapActions(() => useWalletStore(), ["setTxId"]),
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    cancelTransaction() {
      this.$emit("cancel");
    },
    confirmTransaction() {
      switch (this.typeTransaction) {
        case "send":
          this.confirmTransactionSend();
          break;
        case "staking":
          this.confirmTransactionStaking();
          break;

        default:
          break;
      }
    },
    async confirmTransactionStaking() {
      await this.createTronStaking();
      this.$emit("submitForm");
    },
    async confirmTransactionSend() {
      switch (this.transactionData.selectedNetwork) {
        case "TRON":
          await this.createTronTransaction();
          break;
        case "Ethereum":
          await this.createEthTransaction();
          break;
        case "Litecoin":
          await this.createLtcTransaction();
          break;
        case "Bitcoin":
          await this.createBtcTransaction();
          break;
        default:
          break;
      }
    },
    async createTronTransaction() {
      const decodeData = decodePassword(this.aes, this.password);
      if (decodeData != null) {
        const createTx = new CreateTronTransaction(
          decodeData.privateKey,
          this.transactionData.fromAddress,
          this.transactionData.amount,
          this.transactionData.toAddress,
          this.transactionData.currency
        );
        this.isLoadingConfirm = true;
        try {
          const transaction = await createTx.create();
          console.log(transaction);
          this.setTxId(transaction.txid);
          this.$emit("submitForm");
        } catch (error) {
          this.showMessage("danger", "Ошибка отправки");
          console.log(error);
        } finally {
          this.isLoadingConfirm = false;
        }
      } else {
        this.showMessage("danger", "Ошибка подписи транзакции");
      }
    },
    async createTronStaking() {
      const decodeData = decodePassword(this.aes, this.password);
      if (decodeData != null) {
        const createTx = new TronStaking(
          decodeData.privateKey,
          this.stakingData.amount,
          this.stakingData.resource
        );
        this.isLoadingConfirm = true;
        try {
          const transaction = await createTx.freezeTrx();
          console.log(transaction);
          this.setTxId(transaction.txid);
          this.$emit("submitForm");
        } catch (error) {
          this.showMessage("danger", "Ошибка стейкинга");
          console.log(error);
        } finally {
          this.isLoadingConfirm = false;
        }
      } else {
        this.showMessage("danger", "Ошибка подписи транзакции");
      }
    },
    async createEthTransaction() {
      const decodeData = decodePassword(this.aes, this.password);
      if (decodeData != null) {
        if (this.transactionData.currency === "ETH") {
          const createTx = new CreateEthereumTransactions(
            decodeData.privateKey,
            this.transactionData.amount,
            this.transactionData.toAddress,
            this.fee.gasLimit,
            this.fee.maxPriorityFeePerGas,
            this.fee.maxFeePerGas
          );
          this.isLoadingConfirm = true;
          try {
            const txid = await createTx.create();
            this.setTxId(txid);
            // this.successTransaction();
            this.$emit("submitForm");
          } catch (error) {
            this.showMessage("danger", "Ошибка отправки");
            console.log(error);
          } finally {
            this.isLoadingConfirm = false;
          }
        } else {
          const createTx = new CreateTokensTransactions(
            decodeData.privateKey,
            this.transactionData.currency,
            this.transactionData.amount,
            this.transactionData.toAddress,
            this.fee.gasLimit,
            this.fee.maxPriorityFeePerGas,
            this.fee.maxFeePerGas
          );
          this.isLoadingConfirm = true;
          try {
            const txid = await createTx.sendToken();
            this.setTxId(txid);
            // this.successTransaction();
            this.$emit("submitForm");
          } catch (error) {
            this.showMessage("danger", "Ошибка отправки");
            console.log(error);
          } finally {
            this.isLoadingConfirm = false;
          }
        }
      } else {
        this.showMessage("danger", "Ошибка подписи транзакции");
      }
    },
    async createLtcTransaction() {
      const decodeData = decodePassword(this.aes, this.password);
      if (decodeData != null) {
        const createTx = new CreateLitecoinTransactions(
          decodeData.privateKey,
          this.transactionData.fromAddress,
          this.transactionData.amount,
          this.transactionData.toAddress
        );
        this.isLoadingConfirm = true;
        try {
          const tx = await createTx.sendRawTransaction();
          this.setTxId(tx.result);
          // this.successTransaction();
          this.$emit("submitForm");
        } catch (error) {
          this.showMessage("danger", "Ошибка отправки");
          console.log(error);
        } finally {
          this.isLoadingConfirm = false;
        }
      } else {
        this.showMessage("danger", "Ошибка подписи транзакции");
      }
    },
    async createBtcTransaction() {
      const decodeData = decodePassword(this.aes, this.password);
      if (decodeData != null) {
        const createTx = new CreateBitcoinTransactions(
          decodeData.privateKey,
          this.transactionData.fromAddress,
          this.transactionData.amount,
          this.transactionData.toAddress,
          this.transactionData.outputs || [],
          this.fee.total
        );
        this.isLoadingConfirm = true;
        try {
          const tx = await createTx.sendRawTransaction();
          this.setTxId(tx.result);
          this.$emit("submitForm");
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoadingConfirm = false;
        }
      } else {
        this.showMessage("danger", "Ошибка подписи транзакции");
      }
    },
  },
  computed: {
    ...mapState(
      () => useWalletStore(),
      ["transactionData", "stakingData", "txId", "typeTransaction"]
    ),
    ...mapState(() => useSendTransferStore(), ["fee"]),
  },
};
</script>

<style lang="scss" scoped>
.mt-32 {
  margin-top: 32px;
}

.confirm-transaction__title {
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  color: #3c3c3c;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 22px;
  }
}
</style>
