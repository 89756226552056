<template>
  <Modal id="enable2fa">
    <template v-slot:modal-header>
      <div class="settings-title d-flex align-items-center">
        <img src="@/assets/images/settings/lock-icon.svg" alt="" />
        <h3 class="mb-0 ms-2">2FA-авторизация</h3>
      </div>
    </template>
    <template v-slot:modal-body>
      <div v-if="status2Fa === 'start'">
        <form @submit.prevent="setStatus2Fa('qr')" action="">
          <div class="label">
            <h6>Введите пароль от аккаунта</h6>
          </div>
          <CCInput></CCInput>
          <CCButton class="mt-4" size="lg" fontWeight="medium">Далее</CCButton>
        </form>
      </div>
      <div v-if="status2Fa === 'qr'">
        <form @submit.prevent="setStatus2Fa('code')" action="">
          <div class="label">
            <h6 class="mb-0">
              Сканируйте qr-код в приложении Google Authenticator на вашем
              мобильном устройстве.
            </h6>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <img
              width="248px"
              height="248px"
              src="../../assets/images/settings/qr-img.png"
              alt=""
            />
          </div>
          <div class="label mt-4">
            <h6 class="mb-0">
              После сканирования у вас появится новая запись в приложении, после
              чего можете нажать кнопку Далее
            </h6>
          </div>
          <CCButton class="mt-4" size="lg" fontWeight="medium">Далее</CCButton>
        </form>
      </div>
      <div v-if="status2Fa === 'code'">
        <form @submit.prevent="enable2fa" action="">
          <div class="label">
            <h6>Введите 6-ти значный код из приложения</h6>
          </div>
          <CCInput v-model="code" :mask="'###-###'"></CCInput>
          <div class="label mt-2">
            <h6 class="mb-0">
              После ввода кода настройка двухфакторной аутентификации для вашего
              аккаунта будет завершена. Также вы получите сообщение об этом на
              электронный адрес, используемый в качестве логина.
            </h6>
          </div>
          <CCButton class="mt-4" size="lg" fontWeight="medium">
            <span class="d-none d-lg-inline">
              Включить двухфакторную-авторизацию
            </span>
            <span class="d-inline d-lg-none"> Включить 2FA-авторизацию </span>
          </CCButton>
        </form>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Modal from "../UI/Modal.vue";
import { useSettingsStore } from "@/stores/settingsStore";

export default {
  components: { Modal },
  data: () => ({
    code: "",
  }),
  methods: {
    ...mapActions(useSettingsStore, ["setIsEnable2Fa", "setStatus2Fa"]),
    enable2fa() {
      this.$bvModal.hide("enable2fa");
      this.setStatus2Fa("start");
      this.setIsEnable2Fa(true);
    },
  },
  computed: {
    ...mapState(useSettingsStore, ["status2Fa"]),
  },
};
</script>

<style></style>
