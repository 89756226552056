import {
  ExchangeController,
  WalletController,
} from "@/core/system/controllers";
import { defineStore } from "pinia";
import { useInterfaceStore } from "./interfaceStore";
import i18n from "@/i18n";

export const useExchangeStore = defineStore("exchange", {
  state: () => ({
    isLoadingOperatorsList: false,
    isLoadingCurrencyList: true,
    dataWalletToExchange: null,
    step: 1,
    typeExchange: "",
    status: "start",
    uuid: "",
    depositAddress: "",
    listCurrencyGive: [],
    listCurrencyReceive: [],
    selectedCurrencyGive: {
      address_smart_contract: "",
      balance: null,
      balance_usd: null,
      code: "",
      count_decimals: null,
      full_code: "",
      full_name: "",
      icon_base: "",
      icon_network: "",
      icon_qr: "",
      id: null,
      is_active: true,
      is_stable_coin: false,
      name: "",
      order: null,
    },
    selectedWalletGive: {
      address: "",
      balance_usd: null,
      currency_list: [],
      id: null,
      network_object: {
        code: "",
        full_name: "",
        icon: "",
        id: null,
      },
      nickname: "",
    },
    selectedCurrencyReceive: {
      address_smart_contract: "",
      balance: null,
      balance_usd: null,
      code: "",
      count_decimals: null,
      full_code: "",
      full_name: "",
      icon_base: "",
      icon_network: "",
      icon_qr: "",
      id: null,
      is_active: true,
      is_stable_coin: false,
      name: "",
      order: null,
    },
    selectedWalletReceive: {
      address: "",
      balance_usd: null,
      currency_list: [],
      id: null,
      network_object: {
        code: "",
        full_name: "",
        icon: "",
        id: null,
      },
      nickname: "",
    },
    selectedCurrencyGive: {
      address_smart_contract: "",
      balance: null,
      balance_usd: null,
      code: "",
      count_decimals: null,
      full_code: "",
      full_name: "",
      icon_base: "",
      icon_network: "",
      icon_qr: "",
      id: null,
      is_active: true,
      is_stable_coin: false,
      name: "",
      order: null,
    },
    operatorsList: [],
    selectedOperator: {
      id: null,
      name: "",
      icon: "",
      amount_from: null,
      amount_to: null,
      currency_from: "",
      currency_to: "",
      min_summ_exchange: null,
      is_valid_min: null,
    },
    walletsReceiveList: [],
    // selectedWalletGive: {
    //   id: null,
    //   address: "",
    // },
    // selectedWalletReceive: {
    //   id: null,
    //   address: "",
    // },
  }),
  actions: {
    setStep(step) {
      this.step = step;
    },
    setDataWalletToExchange(data) {
      this.dataWalletToExchange = data;
    },
    setStepBack() {
      this.step -= 1;
    },
    setStatus(status) {
      this.status = status;
    },
    setTypeExchange(type) {
      this.typeExchange = type;
    },
    setSelectedCurrencyGive(currency) {
      this.selectedCurrencyGive = currency;
    },
    setSelectedWalletGive(wallet) {
      this.selectedWalletGive = wallet;
    },
    setSelectedCurrencyReceive(currency) {
      this.selectedCurrencyReceive = currency;
    },
    setSelectedWalletReceive(wallet) {
      this.selectedWalletReceive = wallet;
    },
    setSelectedOperator(operator) {
      this.selectedOperator = operator;
    },
    async fetchCurrencyList() {
      this.isLoadingCurrencyList = true;
      try {
        const list = await ExchangeController.getCurrencyList();
        this.listCurrencyReceive = list;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingCurrencyList = false;
      }
    },
    async fetchProfitAmount(amount) {
      const payload = {
        type: this.typeExchange,
        from_currency: this.selectedCurrencyGive.code,
        from_wallet_address: this.selectedWalletGive.address,
        to_currency: this.selectedCurrencyReceive.code,
        amount: amount,
      };
      try {
        const rate = await ExchangeController.getProfitAmount(payload);
        if (rate != undefined) {
          return rate.result;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchOperatorsList(amount) {
      const payload = {
        type: this.typeExchange,
        from_currency: this.selectedCurrencyGive.code,
        to_currency: this.selectedCurrencyReceive.code,
        amount: amount,
      };
      this.isLoadingOperatorsList = true;
      const interfaceStore = useInterfaceStore();
      try {
        const operators = await ExchangeController.getInfoPair(payload);
        this.operatorsList = operators;
        const operatorsAvailable = this.operatorsList.filter(
          (operator) => operator.is_valid_min
        );
        if (operatorsAvailable.length > 0) {
          this.setStep(2);
          this.setSelectedOperator(operatorsAvailable[0]);
        } else {
          interfaceStore.showMessage(
            "danger",
            i18n.t("messages.operators_not_found")
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingOperatorsList = false;
      }
    },
    async fetchAvailableWallets() {
      const currencyTo = {
        currency: this.selectedCurrencyReceive.code,
      };
      try {
        const walletsReceive = await WalletController.getSelectedBlockchain(
          currencyTo
        );
        this.walletsReceiveList = walletsReceive.map((wallet) => {
          return {
            id: wallet.id,
            address: wallet.address,
            nickname: wallet.nickname,
          };
        });
        this.setSelectedWalletReceive(this.walletsReceiveList[0]);
      } catch (error) {
        console.log(error);
      }
    },
    async createTransactionExchange(amount) {
      const payload = {
        operator: this.selectedOperator.name,
        type: this.typeExchange,
        from_address: this.selectedWalletGive.address,
        from_code: this.selectedCurrencyGive.code,
        wallet_to: this.selectedWalletReceive.id,
        amount: amount,
        address: this.selectedWalletReceive.address,
      };
      try {
        const resp = await ExchangeController.createExchange(payload);
        this.uuid = resp.uuid;
        this.depositAddress = resp.deposit_address;
      } catch (error) {
        console.log(error);
      }
    },
    async checkStatusExchange() {
      const payload = {
        uuid: this.uuid,
      };
      try {
        const resp = await ExchangeController.getStatusExchange(payload);
        this.status = resp.status;
      } catch (error) {
        console.log(error);
      }
    },
    async updateBalanceWallet() {
      const payload = {
        wallet_network_id: this.selectedWalletGive.id,
      };
      await WalletController.updateBalance(payload);
    },
  },
  getters: {
    shortFromCurrency: (state) => {
      return state.selectedCurrencyGive.code.split("-")[0];
    },
    selectedBlockchain: (state) => {
      return state.selectedCurrencyGive.full_network === "Tron"
        ? "TRON"
        : state.selectedCurrencyGive.full_network;
    },
    filteredReceiveCurrency: (state) => {
      return state.listCurrencyReceive.filter(
        (currency) => currency.code != state.selectedCurrencyGive.code
      );
    },
  },
});
