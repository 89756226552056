import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Exchange from "../views/Exchange.vue";
import WalletDetail from "../views/WalletDetail.vue";
import BuyCrypto from "../views/BuyCrypto.vue";
import TransactionCurrency from "../views/TransactionCurrency.vue";
import AcceptTransfer from "../views/AcceptTransfer.vue";
import Settings from "../views/Settings.vue";
import SendTransfer from "../views/SendTransfer.vue";
import SendMulti from "../views/SendMulti.vue";
import Registration from "../views/Registration.vue";
import Login from "../views/Login.vue";
import NotFound from "../views/NotFound.vue";
import WaitEmail from "../views/WaitEmail.vue";
import ActivationEmail from "../views/ActivationEmail.vue";
import ResetPassword from "../views/ResetPassword.vue";
import CreateWallet from "../views/CreateWallet.vue";
import Staking from "../views/Staking.vue";
import AmlBuy from "../views/AmlBuy.vue";
import EnergyBuy from "../views/EnergyBuy.vue";
import MenuPage from "../views/MenuPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "main",
      title: "Главная",
      authRequired: true
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: MenuPage,
    meta: {
      layout: "main",
      title: "Меню",
      authRequired: true
    },
  },
  {
    path: "/exchange",
    name: "exchange",
    component: Exchange,
    meta: {
      layout: "main",
      title: "Обмен валют",
      authRequired: true
    },
  },
  {
    path: "/wallet/:address",
    name: "invoice",
    component: WalletDetail,
    meta: {
      layout: "main",
      authRequired: true
    },
  },
  {
    path: "/staking",
    name: "staking",
    component: Staking,
    meta: {
      layout: "main",
      title: "Стейкинг",
      authRequired: true
    },
  },
  {
    path: "/buy-energy",
    name: "buy-energy",
    component: EnergyBuy,
    meta: {
      layout: "main",
      title: "Аренда Energy",
      authRequired: true
    },
  },
  {
    path: "/create-wallet",
    name: "invoice",
    component: CreateWallet,
    meta: {
      layout: "auth",
      title: "Счет для хранения",
      authRequired: true
    },
  },
  {
    path: "/buy",
    name: "buy",
    component: BuyCrypto,
    meta: {
      layout: "main",
      title: "Купить криптовалюту",
      authRequired: true
    },
  },
  {
    path: "/buy-aml",
    name: "buy-aml",
    component: AmlBuy,
    meta: {
      layout: "main",
      title: "Покупка AML проверок",
      authRequired: true
    },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: TransactionCurrency,
    meta: {
      layout: "main",
      title: "Главная",
      authRequired: true
    },
  },
  {
    path: "/accept/:currency",
    name: "accept-transfer",
    component: AcceptTransfer,
    meta: {
      layout: "main",
      title: "Счет  ",
      authRequired: true
    },
  },
  {
    path: "/send",
    name: "send-transfer",
    component: SendTransfer,
    meta: {
      layout: "main",
      title: "Отправить перевод",
      authRequired: true
    },
  },
  {
    path: "/send/multi",
    name: "send-multi",
    component: SendMulti,
    meta: {
      layout: "main",
      title: "Мультиотправка",
      authRequired: true
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      layout: "main",
      title: "Настройки",
      authRequired: true
    },
  },
  {
    path: "/registration",
    name: "registration",
    component: Registration,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: WaitEmail,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
  {
    path: "/activation/:key",
    name: "activation-email",
    component: ActivationEmail,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
  {
    path: "/*",
    name: "not-found",
    component: NotFound,
    meta: {
      layout: "auth",
      authRequired: false
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
