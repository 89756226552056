<template>
  <div class="send-transfer__input-with-icon">
    <div class="d-none d-sm-block">
      <CCInput
        v-model="searchQuery"
        @input="searchContact"
        :placeholder="$t('send_page.address_book.search_placeholder')"
      ></CCInput>
      <img
        width="15px"
        height="15px"
        src="@/assets/images/search.svg"
        alt="scan-icon"
      />
    </div>
    <div class="d-block d-sm-none">
      <CCInput
        v-model="searchQuery"
        @input="searchContact"
        :placeholder="$t('send_page.address_book.search_placeholder')"
        size="md"
      ></CCInput>
      <img
        width="15px"
        height="15px"
        src="@/assets/images/search.svg"
        alt="scan-icon"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/debounce";
import { mapActions, mapState } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";

export default {
  methods: {
    ...mapActions(() => useSendTransferStore(), ["setSearchQueryContacts"]),
    searchContact() {
      this.debouncedEmitSearch();
    },
  },
  computed: {
    ...mapState(() => useSendTransferStore(), ["searchQueryContacts"]),
    searchQuery: {
      get() {
        return this.searchQueryContacts;
      },
      set(value) {
        this.setSearchQueryContacts(value);
      },
    },
  },
  created() {
    this.debouncedEmitSearch = debounce(() => {
      this.$emit("search");
    }, 300);
  },
};
</script>

<style></style>
