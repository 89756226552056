<template>
  <div class="history-item" v-if="!isLoading">
    <div class="history-item__header">
      <b-row align-h="between">
        <b-col cols="auto">
          <div class="d-flex align-items-center">
            <div class="icon-wrapper">
              <img class="icon-currency" :src="icon" alt="" />
            </div>
            <div>
              <div class="d-flex align-items-center">
                <p class="currency-name mb-0">{{ currency }}</p>
                <p class="network-name mb-0">{{ networkName }}</p>
              </div>
              <p class="datetime mb-0">{{ normalizedDate }}</p>
            </div>
          </div>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-end">
          <div>
            <p
              class="amount-transaction mb-0"
              :class="{
                up: type === 'up',
                out: type === 'out',
              }"
            >
              {{ typeTransaction }} {{ amountCrypto }} {{ currency }}
            </p>
            <p
              class="amount-transaction-usd mb-0"
              :class="{
                up: type === 'up',
                out: type === 'out',
              }"
            >
              {{ typeTransaction }}${{ amountUsd }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="history-item__address-info mt-3"
      @click.prevent
      v-clipboard:copy="address"
      v-clipboard:success="copyData"
    >
      <p class="address mb-0">{{ getShortWalletAddress(address) }}</p>
      <Clipboard
        @onCopySuccess="copyData"
        :isActiveCopy="isCopyData"
        :copyText="address"
        :size="18"
      />
    </div>
  </div>
  <div class="history-item" v-else>
    <div class="history-item__header">
      <b-row align-h="between">
        <b-col cols="auto">
          <div class="d-flex align-items-center">
            <b-skeleton class="sm" width="32px" height="32px"></b-skeleton>
            <div>
              <div class="d-flex align-items-center">
                <b-skeleton
                  class="sm ms-2"
                  width="50%"
                  height="14px"
                ></b-skeleton>
                <b-skeleton
                  class="sm ms-2"
                  width="50%"
                  height="14px"
                ></b-skeleton>
              </div>
              <b-skeleton
                class="sm ms-2 mt-1"
                width="100px"
                height="14px"
              ></b-skeleton>
            </div>
          </div>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-end">
          <div>
            <b-skeleton class="sm" width="40px" height="14px"></b-skeleton>
            <b-skeleton class="sm mt-1" width="40px" height="14px"></b-skeleton>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="history-item__address-info mt-3">
      <b-skeleton class="sm" width="100%" height="18px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import Clipboard from "@/components/UI/Clipboard.vue";
import { sliceNetworkCodeCurrency, getShortWalletAddress } from "@/utils";
import moment from "moment";
export default {
  components: { Clipboard },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    icon: String,
    currencyName: {
      type: String,
      default: () => "USDT",
    },
    networkName: {
      type: String,
      default: () => "TRC20",
    },
    type: String,
    amountCrypto: {
      type: Number,
      default: () => 0,
    },
    amountUsd: {
      type: Number,
      default: () => 0,
    },
    address: {
      type: String,
      default: () => "TXwWQoAbT9bPd2vbQdGfpeMKCxKVKdCey3",
    },
    txId: {
      type: String,
      default: () => "txid",
    },
    dateTime: {
      type: String,
      default: () => "2024-05-27 07:20:35.633698+00:00",
    },
  },
  data: () => ({
    isCopyData: false,
  }),
  methods: {
    getShortWalletAddress,
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
  },
  computed: {
    normalizedDate() {
      const localDate = moment.utc(this.dateTime).local();
      return localDate.format("DD.MM.YYYY • HH:mm");
    },
    typeTransaction() {
      return this.type === "up" ? "+" : "-";
    },
    currency() {
      return sliceNetworkCodeCurrency(this.currencyName);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-item {
  padding: 8px 8px 16px 8px;
  border-radius: 6px;
  background: #fbfbfb;
  &__header {
    .icon-wrapper {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4.5px;
      border: 1px solid #f0f0f0;
      background: #fff;
      .icon-currency {
        width: 20px;
        height: 20px;
      }
    }
    .currency-name {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #3c3c3c;
    }
    .network-name {
      margin-left: 4px;
      color: #9f9f9f;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .datetime {
      margin-left: 8px;
      color: #9f9f9f;
      font-size: 10px;
      font-weight: 300;
      line-height: 16px;
    }
    .amount-transaction {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.12px;
      &.up {
        color: #58bd7d !important;
      }
      &.out {
        color: #fa6666;
      }
    }
    .amount-transaction-usd {
      color: #9f9f9f;
      text-align: right;
      font-size: 10px;
      font-weight: 300;
      line-height: 16px;
    }
  }
  &__address-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .address {
      color: #4a40c6;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
