<template>
  <div class="preview-card h-100">
    <b-row align-h="between" align-v="center">
      <b-col cols="auto">
        <div class="d-flex align-items-center">
          <img
            class="preview-card__title-icon"
            src="@/assets/images/staking-icon.svg"
            alt=""
          />
          <h3 class="preview-card__title ms-2 ms-sm-3 mb-0">
            {{ $t("staking_page.title_res") }}
          </h3>
        </div>
      </b-col>
      <b-col cols="auto" class="d-none d-sm-block">
        <LinkBackPage @click="$router.back()" />
      </b-col>
    </b-row>
    <b-row class="mt-3 mt-md-4">
      <b-col cols="12" md="6">
        <p class="staking-text" v-html="$t('staking_page.text')"></p>
      </b-col>
      <b-col cols="12" md="6" class="mt-3 mt-md-0">
        <div class="d-flex align-items-center">
          <img src="@/assets/images/accept-icon.svg" alt="" />
          <p class="staking-text ms-2">{{ $t("staking_page.li1") }}</p>
        </div>
        <div class="d-flex align-items-center mt-3">
          <img src="@/assets/images/accept-icon.svg" alt="" />
          <p class="staking-text ms-2">{{ $t("staking_page.li2") }}</p>
        </div>
        <div class="d-flex align-items-center mt-3">
          <img src="@/assets/images/accept-icon.svg" alt="" />
          <p class="staking-text ms-2">{{ $t("staking_page.li3") }}</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LinkBackPage from "../UI/LinkBackPage.vue";
export default {
  components: { LinkBackPage },
};
</script>

<style lang="scss" scoped>
.preview-card {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  &__title-icon {
    width: 40px;
    height: 40px;
  }
  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
  }
  @media (max-width: 576px) {
    padding: 0;
    background: none;
    .preview-card__title {
      font-size: 20px;
      line-height: 22px;
    }
    .preview-card__title-icon {
      width: 24px;
      height: 24px;
    }
  }
}
.staking-text {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3c3c3c;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}
</style>
