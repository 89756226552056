import Api from "../../api/BaseAPI";

class ServiceController {
  constructor() {
    this.api = new Api();
  }
  async serviceInfo(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/info`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async calculationAmount(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/calculation/amount`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async serviceCreate(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/order/create`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async servicePay(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/order/pay`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async serviceStatus(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/order/info`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async serviceOrdersList(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/service/order/list`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new ServiceController();
