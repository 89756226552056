<template>
  <div class="pay">
    <ConfirmTransaction
      v-if="stagePay === 'confirm'"
      class="confirm"
      :isCloseBtn="false"
    />
    <StatusTransaction
      v-else-if="stagePay === 'success'"
      isShowTxId
      :txId="txId"
    />
  </div>
</template>

<script>
import ConfirmTransaction from "@/components/Transactions/ConfirmTransaction.vue";
import { mapActions, mapState } from "pinia";
import { useSendMultiStore } from "@/stores/sendMultiStore";
import StatusTransaction from "@/components/Transactions/StatusTransaction.vue";
import { useWalletStore } from "@/stores/wallet";
import { useSendTransferStore } from "@/stores/sendTransferStore";

export default {
  components: { ConfirmTransaction, StatusTransaction },
  methods: {
    ...mapActions(useSendMultiStore, ["setStagePay"]),
    ...mapActions(useSendTransferStore, [
      "updateBalanceWallet",
      "addTransactionToHistory",
    ]),
    async confirmSendCrypto() {
      this.setStagePay("success");
      this.updateBalanceWallet();
      this.addTransactionToHistory();
    },
  },
  computed: {
    ...mapState(useSendMultiStore, ["stagePay"]),
    ...mapState(useWalletStore, ["txId"]),
  },
};
</script>

<style lang="scss" scoped>
.pay {
  width: 100%;
  height: 100%;
  min-height: 506px;
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm {
    width: 75%;
    padding: 24px;
    border-radius: 12px;
    background: #fff;
  }
}
</style>
