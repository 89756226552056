import BaseEntity from "../entities/baseEntity";
import CurrencyEntity from "./currencyEntitry";

class WalletEntity extends BaseEntity {
  constructor(data = {}) {
    super("wallet");
    this.id = data.id || "";
    this.user = data.user || "";
    this.currencyObject = data.currency_object || {};
    this.address = data.address || "";
    this.isActive = data.is_active || false;
    this.balance = data.balance || 0;
    this.balanceUsd = data.balance_usd || 0;
  }
}

export default WalletEntity;
