<template>
  <div class="send-multi">
    <HeaderBackLink
      class="d-none d-sm-block"
      title="Подготовка к мультиотправке"
    >
      <img
        class="download-icon"
        src="@/assets/images/upload-icon.svg"
        alt="upload-icon"
      />
    </HeaderBackLink>
    <HeaderMobile title="Мультиотправка" class="d-block d-sm-none">
      <img
        @click="$router.push('/send')"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
    </HeaderMobile>
    <div class="send-multi__card mt-4">
      <div class="card-content">
        <StepsInfo
          :steps="steps"
          :copletedSteps="completedStepsId"
          :selectedStep="selectedStep"
        />
        <div class="content-steps mt-4">
          <Preparation v-if="selectedStep.code === 'preparation'" />
          <Confirm v-if="selectedStep.code === 'confirm'" />
          <Pay v-if="selectedStep.code === 'pay'" />
          <div class="steps-footer mt-4" v-if="selectedStep.code !== 'pay'">
            <b-row align-v="center" align-h="between">
              <b-col cols="3" class="d-none d-md-block">
                <p class="steps-info mb-0">
                  шаг {{ selectedStep.id }} из {{ steps.length }}
                </p>
              </b-col>
              <b-col cols="12" md="9">
                <b-row align-h="end">
                  <b-col cols="12" md="6" v-if="selectedStep.id > 1">
                    <CCButton
                      fontWeight="medium"
                      @click="goToBackPageAction"
                      variant="secondary"
                      size="lg"
                      class="d-none d-md-block px-4"
                    >
                      Назад
                    </CCButton>
                    <CCButton
                      fontWeight="medium"
                      @click="goToBackPageAction"
                      variant="secondary"
                      size="md"
                      class="d-block d-md-none px-4"
                    >
                      Назад
                    </CCButton>
                  </b-col>
                  <b-col cols="12" md="6" class="mt-3 mt-md-0 mb-4 mb-md-0">
                    <CCButton
                      fontWeight="medium"
                      @click="swapStepForward"
                      :disabled="!isAvailableNextStep"
                      size="lg"
                      class="d-none d-md-block px-4"
                    >
                      Далее
                    </CCButton>
                    <CCButton
                      fontWeight="medium"
                      @click="swapStepForward"
                      :disabled="!isAvailableNextStep"
                      size="md"
                      class="d-block d-md-none px-4"
                    >
                      Далее
                    </CCButton>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <SettingsPopup />
  </div>
</template>

<script>
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import StepsInfo from "@/components/SendMulti/StepsInfo.vue";
import { useSendMultiStore } from "@/stores/sendMultiStore";
import { mapActions, mapState } from "pinia";
import Preparation from "@/components/SendMulti/Preparation/Preparation.vue";
import Pay from "@/components/SendMulti/Pay/Pay.vue";
import SettingsPopup from "@/components/SendMulti/SettingsPopup.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import Confirm from "@/components/SendMulti/Confirm/Confirm.vue";
import { sliceNetworkCodeCurrency } from "@/utils";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { useWalletStore } from "@/stores/wallet";
import { useSendTransferStore } from "@/stores/sendTransferStore";

export default {
  components: {
    HeaderBackLink,
    StepsInfo,
    Preparation,
    SettingsPopup,
    HeaderMobile,
    Confirm,
    Pay,
  },
  methods: {
    ...mapActions(useSendMultiStore, [
      "goToNextStep",
      "goToPrevStep",
      "checkValidRow",
      "setCompletedStepsId",
      "setRowsListConfirm",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    ...mapActions(useWalletStore, [
      "setTransactionData",
      "addNewFieldTransactionData",
    ]),
    sliceNetworkCodeCurrency,
    swapStepForward() {
      if (this.selectedStep.code === "preparation") {
        this.checkValidRow();
        this.checkRowsList();
      } else if (this.selectedStep.code === "confirm") {
        if (this.amountToSendWithFee > 0) {
          this.goToNextStep();
          this.setCompletedStepsId(2);
          this.goToConfirmTransaction();
        } else {
          this.showMessage(
            "danger",
            "Недостаточно средств для оплаты комиссии"
          );
        }
      }
    },
    goToConfirmTransaction() {
      const newTransactionData = {
        selectedNetwork: this.selectedNetwork,
        amount: this.amountToSendWithFee,
        fromAddress: this.selectedWallet.address,
        toAddress: this.address,
        currency: this.sliceNetworkCodeCurrency(this.selectedCurrency.code),
      };
      this.setTransactionData(newTransactionData);
      this.addNewFieldTransactionData("outputs", this.validRowsCount);
    },
    checkRowsList() {
      const validRows = this.rowsListConfirm.filter((row) => row.isValid);
      if (validRows.length > 1) {
        this.goToNextStep();
        this.setCompletedStepsId(1);
      } else {
        this.showMessage("warning", "Нет валидных адресов");
      }
    },
    goToBackPageAction() {
      this.goToPrevStep();
      if (this.selectedStep.code === "preparation") {
        this.setRowsListConfirm([]);
      }
    },
  },
  computed: {
    ...mapState(useSendMultiStore, [
      "steps",
      "selectedStep",
      "addressList",
      "rowsListConfirm",
      "completedStepsId",
      "validRowsCount",
    ]),
    ...mapState(useWalletStore, ["transactionData"]),
    ...mapState(useSendTransferStore, [
      "amount",
      "address",
      "fee",
      "selectedWallet",
      "selectedCurrency",
    ]),
    amountToSendWithFee() {
      return +this.amount - this.fee.total;
    },
    selectedNetwork() {
      return this.selectedWallet.network_object.full_name;
    },
    isAvailableNextStep() {
      if (this.selectedStep.code === "preparation") {
        const filtered = this.addressList.filter(
          (row) => row.address && row.amount
        );
        return filtered.length > 0;
      } else if (this.selectedStep.code === "confirm") {
        return this.rowsListConfirm.length > 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-multi {
  &__card {
    padding: 24px;
    width: 100%;
    height: 774px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 576px) {
      height: auto;
      padding: 0;
      background: none;
    }
    .card-content {
      width: 100%;
      max-width: 736px;
      .content-steps {
        width: 100%;
        min-height: 526px;
        padding: 24px;
        border-radius: 12px;
        background: #f6f6f6;
        @media (max-width: 576px) {
          padding: 0;
        }
      }
    }
  }
}
</style>
