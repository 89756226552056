<template>
  <div class="exchange-page">
    <b-container class="container-header-back-link">
      <HeaderBackLink
        class="d-none d-sm-block"
        :title="$t('exchange_page.title')"
      >
        <img
          class="exchange-icon icon"
          src="../assets/images/exchange/exchange-icon.png"
          alt=""
        />
      </HeaderBackLink>
      <HeaderMobile
        :title="$t('exchange_page.title')"
        class="d-block d-sm-none"
      >
        <img
          v-if="step > 1"
          @click="clearAmountsChangeStep"
          width="24px"
          height="24px"
          slot="button-left"
          src="../assets/images/back-icon.svg"
          alt=""
        />
        <router-link v-else slot="button-left" to="/">
          <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
        </router-link>
        <router-link v-if="step === 1" slot="button-right" to="/create-wallet">
          <img
            width="24px"
            src="../assets/images/create-wallet-icon.svg"
            alt=""
          />
        </router-link>
      </HeaderMobile>
    </b-container>
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <div class="exchange-card">
          <div class="exchange-card__header">
            <div class="d-flex justify-content-between">
              <h2
                v-if="step === 1"
                class="h3-text mb-0"
                v-html="$t('exchange_page.step1_title')"
              ></h2>
              <h2
                v-if="step === 2"
                class="h3-text mb-0"
                v-html="$t('exchange_page.step2_title')"
              ></h2>
              <h2
                v-if="step === 3"
                class="h3-text mb-0"
                v-html="$t('exchange_page.step3_title')"
              ></h2>
              <LinkBackPage
                class="d-none d-sm-block"
                v-if="step > 1 && step < 4"
                @click="clearAmountsChangeStep"
              />
              <div class="step-info d-block d-sm-none">
                {{ $t("exchange_page.step") }} {{ step }} из 4
              </div>
            </div>
          </div>
          <div class="exchange-card__steps">
            <TypeAmount
              class="mt-4"
              v-if="step === 1"
              placeholder-input-give="0.0005926"
              placeholder-input-receive="0.031556"
              :input-amount-give="inputGive"
              :input-amount-receive="inputReceive"
              :is-loading-currency="isLoadingCurrencyList"
              :is-loading-wallets="isLoadingWalletList"
              :is-loading-amount-give="isLoadingGive"
              :is-loading-amount-receive="isLoadingReceive"
              :walletsList="walletsList"
              :selectedWallet="selectedWalletGive"
              :selectedCurrencyGive="selectedCurrencyGive"
              :listCurrencyReceive="filteredReceiveCurrency"
              :selectedCurrencyReceive="selectedCurrencyReceive"
              @selectCurrencyGive="selectCurrencyGive"
              @selectWallet="selectWalletGive"
              @selectCurrencyReceive="selectCurrencyReceive"
              @selectWalletReceive="selectWalletReceive"
              @typeAmountGive="getAmountGive"
              @typeAmountReceive="getAmountReceive"
            />
            <div v-if="step === 2">
              <b-row class="mt-4">
                <b-col
                  @click="checkAndSetOperator(operator)"
                  v-for="operator in operatorsList"
                  :key="operator.id"
                  cols="12"
                >
                  <SelectOperator
                    :default="operator"
                    :type="typeExchange"
                    :isValidMin="operator.is_valid_min"
                    :minAmount="operator.min_summ_exchange"
                    :currencyFrom="operator.currency_from"
                    :class="{
                      'border-accent': selectedOperator.id == operator.id,
                    }"
                  ></SelectOperator>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 3">
              <SelectOperator
                class="mt-4"
                style="cursor: auto"
                :type="typeExchange"
                :default="selectedOperator"
              ></SelectOperator>
              <div class="mt-4">
                <div class="label">
                  <h6>{{ $t("exchange_page.wallet_receive") }}</h6>
                </div>
                <SelectWallet
                  :options="walletsReceiveList"
                  :default="selectedWalletReceive"
                  :zIndex="20"
                  class="mt-2"
                  @changeSelect="selectWalletReceive"
                />
              </div>
            </div>
            <ConfirmTransaction
              v-if="step === 4"
              @submitForm="confirmExchange"
              @cancel="clearExchange"
            />
            <LoadingScreen
              v-if="step === 5"
              :is-loading-percent="false"
              :loading-text="statusLoading"
            />
          </div>
          <div class="step-card d-none d-sm-block" v-if="step < 4">
            <b-row align-v="center" align-h="between">
              <b-col cols="auto">
                <div class="step-info">
                  {{ $t("exchange_page.step") }} {{ step }} из 4
                </div>
              </b-col>
              <b-col cols="6">
                <CCButton
                  @click="getOperatorsList"
                  :disabled="!blockedExchange || isLoadingOperatorsList"
                  v-if="step === 1"
                  size="lg"
                  class="px-4"
                  fontWeight="medium"
                  >{{ $t("exchange_page.btn_next") }}</CCButton
                >
                <CCButton
                  v-if="step === 2"
                  @click="selectOperator"
                  :disabled="isLoadingWallets"
                  size="lg"
                  class="px-4"
                  fontWeight="medium"
                  >{{ $t("exchange_page.btn_select") }}</CCButton
                >
                <CCButton
                  v-if="step === 3"
                  @click="startExchange"
                  :disabled="isLoadingExchange"
                  size="lg"
                  class="px-4"
                  fontWeight="medium"
                  >{{ $t("exchange_page.btn_exchange") }}</CCButton
                >
              </b-col>
            </b-row>
          </div>
          <div class="mt-4 pb-4 d-block d-sm-none">
            <CCButton
              @click="getOperatorsList"
              :disabled="!blockedExchange || isLoadingOperatorsList"
              v-if="step === 1"
              size="md"
              class="px-4"
              fontWeight="medium"
              >{{ $t("exchange_page.btn_next") }}</CCButton
            >
            <CCButton
              v-if="step === 2"
              @click="selectOperator"
              :disabled="isLoadingWallets"
              size="md"
              class="px-4"
              fontWeight="medium"
              >{{ $t("exchange_page.btn_select") }}</CCButton
            >
            <CCButton
              v-if="step === 3"
              @click="startExchange"
              :disabled="isLoadingExchange"
              size="md"
              class="px-4"
              fontWeight="medium"
              >{{ $t("exchange_page.btn_exchange") }}</CCButton
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { useExchangeStore } from "@/stores/exchangeStore";
import { mapActions, mapState } from "pinia";
import HeaderBackLink from "@/components/Headers/HeaderBackLink.vue";
import TypeAmount from "@/components/Exchange/TypeAmount.vue";
import LinkBackPage from "@/components/UI/LinkBackPage.vue";
import SelectOperator from "@/components/SelectOperator.vue";
import SelectWallet from "@/components/UI/SelectWallet.vue";
import { useWalletStore } from "@/stores/wallet";
import ConfirmTransaction from "@/components/Transactions/ConfirmTransaction";
import LoadingScreen from "@/components/Transactions/LoadingScreen.vue";
import { useInterfaceStore } from "@/stores/interfaceStore";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";

export default {
  components: {
    HeaderBackLink,
    TypeAmount,
    LinkBackPage,
    SelectOperator,
    SelectWallet,
    ConfirmTransaction,
    LoadingScreen,
    HeaderMobile,
  },
  data: () => ({
    inputGive: "",
    inputReceive: "",
    isLoadingGive: false,
    isLoadingReceive: false,
    isLoadingWallets: false,
    isLoadingExchange: false,
    interval: null,
  }),
  methods: {
    ...mapActions(useExchangeStore, [
      "setStep",
      "setStepBack",
      "setStatus",
      "setSelectedCurrencyGive",
      "setSelectedCurrencyReceive",
      "setTypeExchange",
      "setSelectedOperator",
      "setSelectedWalletGive",
      "setSelectedWalletReceive",
      "fetchCurrencyList",
      "fetchProfitAmount",
      "fetchOperatorsList",
      "createTransactionExchange",
      "checkStatusExchange",
      "updateBalanceWallet",
    ]),
    ...mapActions(useWalletStore, ["setTransactionData", "getAllWallets"]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    async setSelectedWalletAndCurrency() {
      if (this.dataWalletToExchange != null) {
        const needWallet = this.walletsList.find(
          (wallet) => wallet.address == this.dataWalletToExchange.address
        );
        const needCurrency = needWallet.currency_list.find(
          (currency) => currency.code == this.dataWalletToExchange.code
        );
        this.setSelectedWalletGive(needWallet);
        this.setSelectedCurrencyGive(needCurrency);
      } else {
        this.setSelectedWalletGive(this.walletsList[0]);
        this.setSelectedCurrencyGive(this.walletsList[0].currency_list[0]);
      }
    },
    clearAmountsChangeStep() {
      this.setStepBack();
      if (this.step === 1) {
        (this.inputGive = ""), (this.inputReceive = "");
      }
    },
    clearExchange() {
      this.inputGive = "";
      this.inputReceive = "";
      this.setStep(1);
    },
    excludesIdenticalCurrencies() {
      const indexCurrencyReceive = this.filteredReceiveCurrency.indexOf(
        this.selectedCurrencyReceive
      );
      const selectedCurrencyGiveCode = this.selectedCurrencyGive.code;
      const selectedCurrencyReceiveCode = this.selectedCurrencyReceive.code
        .split("-")
        .join("_");
      if (selectedCurrencyGiveCode === selectedCurrencyReceiveCode) {
        if (indexCurrencyReceive === -1) {
          this.setSelectedCurrencyReceive(
            this.filteredReceiveCurrency[indexCurrencyReceive + 1]
          );
        } else {
          this.setSelectedCurrencyReceive(
            this.filteredReceiveCurrency[indexCurrencyReceive - 1]
          );
        }
      }
    },
    checkAndSetOperator(operator) {
      if (!operator.is_valid_min) return;
      this.setSelectedOperator(operator);
    },
    checkBalance(balance, amount) {
      if (balance) return amount;
      this.clearExchange();
      this.showMessage("danger", this.$t("messages.low_balance"));
    },
    async selectCurrencyCalcAmount() {
      if (this.inputGive != "" || this.inputReceive != "") {
        if (this.typeExchange === "float") {
          this.isLoadingReceive = true;
          try {
            const rate = await this.fetchProfitAmount(this.amount);
            const amount = this.checkBalance(rate.balance, rate.profit_rate);
            this.inputReceive = amount;
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoadingReceive = false;
          }
        } else {
          this.isLoadingGive = true;
          try {
            const rate = await this.fetchProfitAmount(this.amount);
            const amount = this.checkBalance(rate.balance, rate.profit_rate);
            this.inputGive = amount;
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoadingGive = false;
          }
        }
      }
    },
    selectWalletGive(value) {
      this.setSelectedWalletGive(value);
      this.selectCurrencyCalcAmount();
    },
    selectWalletReceive(value) {
      this.setSelectedWalletReceive(value);
    },
    async selectCurrencyGive(value) {
      this.setSelectedCurrencyGive(value);
      this.excludesIdenticalCurrencies();
    },
    async selectCurrencyReceive(value) {
      this.setSelectedCurrencyReceive(value);
      this.selectCurrencyCalcAmount();
    },
    async getAmountGive(amount) {
      this.inputGive = amount;
      this.setTypeExchange("float");
      this.isLoadingReceive = true;
      try {
        const rate = await this.fetchProfitAmount(this.amount);
        if (rate != undefined) {
          const amount = this.checkBalance(rate.balance, rate.profit_rate);
          this.inputReceive = amount;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingReceive = false;
      }
    },
    async getAmountReceive(amount) {
      this.inputReceive = amount;
      this.setTypeExchange("fixed");
      this.isLoadingGive = true;
      try {
        const rate = await this.fetchProfitAmount(this.amount);
        if (rate != undefined) {
          this.checkBalance(rate.balance, rate.profit_rate);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingGive = false;
      }
    },
    async getOperatorsList() {
      await this.fetchOperatorsList(this.amount);
    },
    async selectOperator() {
      this.isLoadingWallets = true;
      try {
        await this.fetchAvailableWallets();
        this.setStep(3);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingWallets = false;
      }
    },
    async startExchange() {
      this.isLoadingExchange = true;
      try {
        await this.createTransactionExchange(this.amount);
        const newTransactionData = {
          selectedNetwork: this.selectedBlockchain,
          amount: String(this.amount),
          fromAddress: this.selectedWalletGive.address,
          toAddress: this.depositAddress,
          currency: this.shortFromCurrency,
        };
        this.setTransactionData(newTransactionData);
        this.setStep(4);
      } catch (error) {
        console.log(error);
        this.showMessage("danger", this.$t("messages.error_global"));
      } finally {
        this.isLoadingExchange = false;
      }
    },
    async confirmExchange() {
      this.setStep(5);
      this.updateBalanceWallet();
      this.checkExchange();
    },
    checkExchange() {
      this.interval = setInterval(async () => {
        await this.checkStatusExchange();
        if (this.status === "completed") {
          this.setStep(1);
          clearInterval(this.interval);
        }
      }, 30000);
    },
  },
  computed: {
    ...mapState(useExchangeStore, [
      "isLoadingOperatorsList",
      "isLoadingCurrencyList",
      "step",
      "status",
      "typeExchange",
      "filteredReceiveCurrency",
      "selectedCurrencyGive",
      "selectedWalletGive",
      "selectedCurrencyReceive",
      "operatorsList",
      "selectedOperator",
      "walletsReceiveList",
      "selectedWalletReceive",
      "fetchAvailableWallets",
      "shortFromCurrency",
      "selectedBlockchain",
      "depositAddress",
      "listCurrencyReceive",
      "dataWalletToExchange",
    ]),
    ...mapState(useWalletStore, ["walletsList", "isLoadingWalletList"]),
    amount() {
      return this.typeExchange === "float"
        ? Number(this.inputGive)
        : Number(this.inputReceive);
    },
    statusLoading() {
      // let text;
      // switch (this.status) {
      //   case "waiting":
      //     text = "Обмен создан";
      //     break;
      //   case "confirming":
      //     text = "Депозит внесен";
      //     break;
      //   case "exchanging":
      //     text = "Происходит обмен";
      //     break;
      //   case "sending":
      //     text = "Отправляем на указанный кошелек";
      //     break;
      //   default:
      //     break;
      // }
      return this.$t(`exchange_page.statuses.${this.status}`);
    },
    blockedExchange() {
      return Number(this.inputGive) > 0 && Number(this.inputReceive) > 0;
    },
  },
  async mounted() {
    await this.getAllWallets();
    this.setSelectedWalletAndCurrency();
    await this.fetchCurrencyList();
    this.setSelectedCurrencyReceive(this.filteredReceiveCurrency[0]);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.clearExchange();
  },
};
</script>

<style lang="scss" scoped>
.exchange-card {
  border-radius: 12px;
  background: #fff;
  &__header {
    padding: 24px 24px 0 24px;
  }
  &__steps {
    padding: 0 24px 50px 24px;
  }
  @media (max-width: 576px) {
    background: none;
    border-radius: 0px;
    .exchange-card__steps {
      padding: 0;
    }
    .exchange-card__header {
      padding: 0;
    }
  }
}

.exchange-form-card {
  background: #ffffff;
  border-radius: 12px;
  .no-padding {
    border-bottom: 2px solid #f0f0f0;
  }
  .select-currency {
    padding: 24px 24px 36px 24px;
    @media (max-width: 576px) {
      padding: 18px 12px;
    }
  }
  @media (max-width: 576px) {
    background: none;
  }
}
</style>
