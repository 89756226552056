var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"validation-check"},[_c('b-row',{staticClass:"gy-3",class:{ 'gx-1': _vm.gap == 1 },attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"6","xl":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"check-block",class:{
            success: _vm.upperCaseLetters,
          }},[_c('img',{staticClass:"check-block__marker",attrs:{"src":require("@/assets/images/uncastodial/marker.svg"),"alt":""}})]),_c('div',{staticClass:"validation-check__name ms-1"},[_vm._v(" "+_vm._s(_vm.$t("validation_pass.big_letters"))+" ")])])]),_c('b-col',{attrs:{"cols":"6","xl":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"check-block",class:{
            success: _vm.lowerCaseLetters,
          }},[_c('img',{staticClass:"check-block__marker",attrs:{"src":require("@/assets/images/uncastodial/marker.svg"),"alt":""}})]),_c('div',{staticClass:"validation-check__name ms-1"},[_vm._v(" "+_vm._s(_vm.$t("validation_pass.small_letters"))+" ")])])]),_c('b-col',{attrs:{"cols":"6","xl":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"check-block",class:{
            success: _vm.numbers,
          }},[_c('img',{staticClass:"check-block__marker",attrs:{"src":require("@/assets/images/uncastodial/marker.svg"),"alt":""}})]),_c('div',{staticClass:"validation-check__name ms-1"},[_vm._v(" "+_vm._s(_vm.$t("validation_pass.numbers"))+" ")])])]),_c('b-col',{attrs:{"cols":"6","xl":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"check-block",class:{
            success: _vm.length,
          }},[_c('img',{staticClass:"check-block__marker",attrs:{"src":require("@/assets/images/uncastodial/marker.svg"),"alt":""}})]),_c('div',{staticClass:"validation-check__name ms-1"},[_vm._v(" "+_vm._s(_vm.$t("validation_pass.symbols"))+" ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }