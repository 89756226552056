import Api from "../../api/BaseAPI";

class AmlController {
  constructor() {
    this.api = new Api();
  }

  async checkAmlActivate(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/aml/check`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async checkAmlInfo(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/aml/check/info`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new AmlController();
