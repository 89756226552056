var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"history-item",class:{
    even: _vm.isEven,
    uneven: !_vm.isEven,
  }},[_c('div',{staticClass:"history-item__content"},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"currency-info d-flex align-items-center"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon-currency",attrs:{"src":_vm.icon,"alt":""}})]),_c('p',{staticClass:"currency-name mb-0"},[_vm._v(_vm._s(_vm.currency))]),_c('p',{staticClass:"network-name mb-0"},[_vm._v(_vm._s(_vm.networkName))])])]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"amount-transaction mb-0",class:{
            up: _vm.type === 'up',
            out: _vm.type === 'out',
          }},[_vm._v(" "+_vm._s(_vm.typeTransaction)+" "+_vm._s(_vm.amountCrypto)+" "+_vm._s(_vm.currency)+" ")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"amount-transaction-usd mb-0",class:{
            up: _vm.type === 'up',
            out: _vm.type === 'out',
          }},[_vm._v(" "+_vm._s(_vm.typeTransaction)+"$"+_vm._s(_vm.amountUsd)+" ")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.address),expression:"address",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyData),expression:"copyData",arg:"success"}],staticClass:"address-info d-flex align-items-center",on:{"click":function($event){$event.preventDefault();}}},[_c('Clipboard',{attrs:{"isActiveCopy":_vm.isCopyData,"copyText":_vm.address,"size":18},on:{"onCopySuccess":_vm.copyData}}),_c('p',{staticClass:"address mb-0"},[_vm._v(_vm._s(_vm.address))])],1)]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"datetime mb-0"},[_vm._v(" "+_vm._s(_vm.normalizedDate)+" ")])])],1)],1)]):_c('div',[_c('b-skeleton',{staticClass:"sm",class:{ white: _vm.isEven },attrs:{"width":"100%","height":"40px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }