<template>
  <div class="select-wallet">
    <div class="label">
      <h6 class="mb-0">Адрес токена</h6>
    </div>
    <b-row class="mt-3 justify-content-between justify-content-md-start">
      <b-col cols="12" md="8">
        <SelectCurrencyWallet
          :isLoading="isLoading"
          :options="wallets"
          :defaultCurrency="defaultCurrency"
          :defaultWallet="defaultWallet"
          @selectCurrency="setSelectedCurrency"
          @selectWallet="setSelectedWallet"
        />
      </b-col>
      <b-col cols="5" md="4" class="mt-3 mt-md-0">
        <div class="balance" v-if="!isLoading">
          <p class="balance__text mb-0">Баланс:</p>
          <p class="balance__amount mb-0" v-if="defaultCurrency">
            {{ defaultCurrency.balance }}
            {{ sliceNetworkCodeCurrency(defaultCurrency.code) }}
          </p>
        </div>
        <div class="balance" v-else>
          <b-skeleton class="md white" width="50%" height="24px"></b-skeleton>
          <b-skeleton
            class="md white mt-1"
            width="70%"
            height="24px"
          ></b-skeleton>
        </div>
      </b-col>
      <b-col cols="7" class="d-block d-md-none mt-4 mt-md-0">
        <b-row align-h="end">
          <b-col cols="6" class="d-flex justify-content-end">
            <label for="fileInput">
              <ButtonMobile name="Загрузить CSV" variant="row">
                <img src="@/assets/images/upload-icon.svg" alt="" />
              </ButtonMobile>
            </label>
            <input
              @change="loadCsv"
              type="file"
              id="fileInput"
              accept=".csv"
              style="display: none"
            />
          </b-col>
          <b-col
            cols="6"
            @click="openSettings"
            class="d-flex justify-content-end"
          >
            <ButtonMobile name="Настройки" variant="row">
              <img src="@/assets/images/settings/settings2.svg" alt="" />
            </ButtonMobile>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { sliceNetworkCodeCurrency } from "@/utils";
import SelectCurrencyWallet from "../../UI/SelectCurrencyWallet.vue";
import ButtonMobile from "../../UI/ButtonMobile.vue";
export default {
  components: { SelectCurrencyWallet, ButtonMobile },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    wallets: {
      type: Array,
      default: [],
    },
    defaultWallet: {
      type: Object,
      default: {},
    },
    defaultCurrency: {
      type: Object,
      default: {},
    },
  },
  methods: {
    setSelectedCurrency(currency) {
      this.$emit("onSelectCurrency", currency);
    },
    setSelectedWallet(wallet) {
      this.$emit("onSelectWallet", wallet);
    },
    openSettings() {
      this.$emit("onOpenSettings");
    },
    loadCsv() {
      this.$emit("onLoadCsv");
    },
    sliceNetworkCodeCurrency,
  },
};
</script>

<style lang="scss" scoped>
.select-wallet {
  @media (max-width: 576px) {
    padding: 16px;
    border-radius: 12px;
    background: #fff;
  }
}
.balance {
  &__text {
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  &__amount {
    margin-top: 6px;
    color: #3c3c3c;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    @media (max-width: 768px) {
      margin-top: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #4a40c6;
    }
  }
}
</style>
