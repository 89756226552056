<template>
  <div class="menu-page d-flex d-sm-none">
    <div>
      <HeaderMobile :title="$t('menu_page.title')">
        <img
          @click="$router.back()"
          width="24px"
          height="24px"
          slot="button-left"
          src="../assets/images/back-icon.svg"
          alt=""
        />
      </HeaderMobile>
      <div class="menu-page__card">
        <div class="d-flex align-items-center">
          <div class="menu-page__avatar">{{ letterEmail }}</div>
          <p class="menu-page__email mb-0 ms-2">{{ user.email }}</p>
        </div>
      </div>
      <div class="menu-navs mt-3">
        <div class="menu-navs__items">
          <router-link to="/buy" class="menu-nav__item">
            <div class="item-icon">
              <img
                width="24px"
                height="24px"
                src="@/assets/images/menu-page/buy.svg"
                alt=""
              />
            </div>
            <div class="item-content">
              <h6 class="item-content__name mb-0">{{ $t('menu_page.buycrypto') }}</h6>
              <img
                class="item-content__arrow"
                src="../assets/images/menu-page/arrow.svg"
                alt=""
              />
            </div>
          </router-link>
          <router-link to="/buy-aml" class="menu-nav__item">
            <div class="item-icon">
              <img
                width="24px"
                height="24px"
                src="@/assets/images/menu-page/aml.svg"
                alt=""
              />
            </div>
            <div class="item-content">
              <h6 class="item-content__name mb-0">{{ $t('menu_page.aml') }}</h6>
              <img
                class="item-content__arrow"
                src="../assets/images/menu-page/arrow.svg"
                alt=""
              />
            </div>
          </router-link>
          <router-link to="/settings" class="menu-nav__item">
            <div class="item-icon">
              <img
                width="24px"
                height="24px"
                src="@/assets/images/menu-page/settings.svg"
                alt=""
              />
            </div>
            <div class="item-content">
              <h6 class="item-content__name mb-0">{{ $t('menu_page.settings') }}</h6>
              <img
                class="item-content__arrow"
                src="../assets/images/menu-page/arrow.svg"
                alt=""
              />
            </div>
          </router-link>
          <a href="" class="menu-nav__item">
            <div class="item-icon">
              <img
                width="24px"
                height="24px"
                src="@/assets/images/menu-page/support.svg"
                alt=""
              />
            </div>
            <div class="item-content">
              <h6 class="item-content__name mb-0">{{ $t('menu_page.support') }}</h6>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="menu-page__footer">
      <b-row align-h="center">
        <b-col cols="auto">
          <div @click="logout" class="footer-logout d-flex align-items-center">
            <img src="@/assets/images/menu-page/logout.svg" alt="" />
            <p class="logout-text mb-0">{{ $t('menu_page.support') }}</p>
          </div>
        </b-col>
        <b-col cols="10" class="mt-3">
          <a href="">
            <p class="footer-terms mb-0">
              {{ $t('menu_page.licence') }} ©&nbsp;CryptoCloud - {{ currentYear }}
            </p>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import { mapState } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { getFullYear } from '@/utils/date';

export default {
  components: { HeaderMobile },
  methods: {
    checkScreenUser() {
      if (window.innerWidth >= 576) {
        this.$router.back();
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapState(() => useUserStore(), ["user"]),
    currentYear() {
      return getFullYear();
    },
    letterEmail() {
      return this.user.email.slice(0, 1).toUpperCase();
    },
  },
  created() {
    this.checkScreenUser();
  },
};
</script>

<style lang="scss" scoped>
.menu-page {
  height: 69vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__card {
    padding: 12px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 15px 30px -10px rgba(60, 60, 60, 0.02);
  }
  &__avatar {
    width: 24px;
    height: 24px;
    background: #4a40c6;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  &__email {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #3c3c3c;
  }
  .menu-navs {
    padding: 12px 0 12px 12px;
    border-radius: 12px;
    background: #fff;
    .menu-nav__item {
      margin-top: 12px;
      display: flex;
      .item-icon {
        width: 24px;
        height: 24px;
      }
      .item-content {
        width: 100%;
        margin-left: 8px;
        padding-bottom: 12px;
        padding-top: 3px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__name {
          color: #3c3c3c;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.14px;
        }
        &__arrow {
          padding-right: 12px;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        .item-content {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  &__footer {
    .footer-logout {
      .logout-text {
        margin-left: 4px;
        color: #939393;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .footer-terms {
      color: #3c3c3c;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
