import Api from "../../api/BaseAPI";

class BuyController {
  constructor() {
    this.api = new Api();
  }
  async getCurrencyList() {
    try {
      const response = await this.api.get(
        `/api/v1/wallet/buy/currency/list`,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getProfitAmount(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/buy/currency/convert`,
        data,
        true,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getOperatorsList(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/buy/operator/list`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async createBuy(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/buy/invoice/create`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async changeBuy(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/buy/invoice/change`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
  async getStatusBuy(data) {
    try {
      const response = await this.api.post(
        `/api/v1/wallet/buy/invoice/info`,
        data,
        true
      );
      return response.result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new BuyController();
