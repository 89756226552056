<template>
  <header class="header-mobile">
    <b-row align-h="between" align-v="center">
      <b-col cols="2">
        <slot name="button-left"></slot>
      </b-col>
      <b-col cols="8" class="d-flex justify-content-center">
        <h1 class="header-mobile__title mb-0">{{ title }}</h1>
      </b-col>
      <b-col cols="2" class="d-flex justify-content-end">
        <slot name="button-right"></slot>
      </b-col>
    </b-row>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "title",
    },
  },
};
</script>

<style lang="scss" scoped>
.header-mobile {
  position: fixed;
  z-index: 888;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  background: #f6f6f6;
  border-bottom: 1px solid #cdcdcd;
  &__title {
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
  }
}
</style>
