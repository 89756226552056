<template>
  <div class="save-aes">
    <CCCard padding="card24">
      <div class="d-flex justify-content-between">
        <h2
          class="save-wallet__title mb-0"
          v-html="$t('create_wallet_page.save_wallet.title')"
        ></h2>
      </div>
      <div class="warning d-flex mt-4">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p class="warning-text mb-0 ms-2">
          {{ $t("create_wallet_page.save_wallet.text1") }}
        </p>
      </div>
      <div class="warning d-flex mt-2">
        <img
          class="warning-icon"
          src="@/assets/images/warning-icon.svg"
          alt=""
        />
        <p
          class="warning-text mb-0 ms-2"
          v-html="$t('create_wallet_page.save_wallet.text2')"
        ></p>
      </div>
      <CopyDataCard title="Адрес кошелька" :data="address" class="mt-3">
        <img slot="icon" src="@/assets/images/wallet-icon.svg" alt="" />
      </CopyDataCard>
      <CopyDataCard title="AES-ключ от кошелька" :data="aesString" class="mt-3">
        <img slot="icon" src="@/assets/images/key-icon.svg" alt="" />
      </CopyDataCard>
      <CCButton @click="setStep(4)" size="lg" class="mt-4">{{
        $t("create_wallet_page.save_wallet.btn_savekey")
      }}</CCButton>
    </CCCard>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import CopyDataCard from "./CopyDataCard.vue";
export default {
  methods: {
    ...mapActions(useWalletStore, ["setStep", "setStepBack"]),
  },
  computed: {
    ...mapState(useWalletStore, ["aesString", "address"]),
  },
  components: { CopyDataCard },
};
</script>

<style lang="scss" scoped>
.save-wallet__title {
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #3c3c3c;
}
</style>
