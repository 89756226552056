<template>
  <div class="balance-card-mobile">
    <div v-if="!isLoading">
      <div
        class="title-block d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <img src="@/assets/images/balance-mobile.svg" alt="" />
          <h5 class="title mb-0">{{ titleCard }}</h5>
        </div>
        <a v-if="variant === 'inside'" :href="historyLink" target="_blank">
          <img src="@/assets/images/coins.svg" alt="" />
        </a>
      </div>
      <div class="balance-block mt-3">
        <b-row align-h="between">
          <b-col cols="7">
            <h5 class="balance mb-0">$ {{ convertBalanceUsd(balance) }}</h5>
          </b-col>
          <b-col
            cols="5"
            class="d-flex justify-content-end"
            v-if="variant === 'general'"
          >
            <router-link to="/send">
              <ButtonMobile :name="$t('balance_card.btn_send')" variant="row">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 6.5L17 6.5C19.2091 6.5 21 8.29086 21 10.5L21 15.5C21 17.7091 19.2091 19.5 17 19.5L16 19.5L11 19.5L6 19.5L5 19.5C2.79086 19.5 1 17.7091 1 15.5L1 10.5C1 8.29086 2.79086 6.5 5 6.5L7 6.5"
                    stroke="#4A40C6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M14 3.5L11.7071 1.20711C11.3166 0.816583 10.6834 0.816583 10.2929 1.20711L8 3.5"
                    stroke="#4A40C6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11 1.5L11 13.5"
                    stroke="#4A40C6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </ButtonMobile>
            </router-link>
          </b-col>
        </b-row>
        <div
          v-if="variant === 'inside'"
          class="copy-block d-flex justify-content-between align-items-center"
          @click.prevent
          v-clipboard:copy="address"
          v-clipboard:success="copyData"
        >
          <p class="address mb-0">{{ address }}</p>
          <Clipboard
            @onCopySuccess="copyData"
            :copyText="address"
            :isActiveCopy="isCopyData"
            size="18"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="title-block d-flex align-items-center">
        <b-skeleton class="sm" width="60%" height="20px"></b-skeleton>
      </div>
      <div class="balance-block mt-3">
        <b-row align-h="between">
          <b-col cols="7">
            <b-skeleton class="sm" width="80%" height="36px"></b-skeleton>
          </b-col>
          <b-col cols="5" class="d-flex justify-content-end">
            <b-skeleton class="button" width="45px" height="32px"></b-skeleton>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getHistoryAddressLink } from "@/utils";
import ButtonMobile from "../UI/ButtonMobile.vue";
import Clipboard from "../UI/Clipboard.vue";
import { convertBalanceUsd } from "@/utils";

export default {
  components: { ButtonMobile, Clipboard },
  data: () => ({
    isCopyData: false,
  }),
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "general",
      validator: (value) => {
        return ["inside", "general"].includes(value);
      },
    },
    balance: {
      type: Number || undefined,
      required: true,
      default: () => 0,
    },
    address: String,
    titleCard: String,
    network: String,
  },
  methods: {
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
    convertBalanceUsd,
  },
  computed: {
    historyLink() {
      return getHistoryAddressLink(this.network, this.address);
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-card-mobile {
  .title {
    margin-left: 8px;
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.14px;
  }
  .balance {
    color: #4a40c6;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
  }
  .copy-block {
    margin-top: 8px;
    .address {
      color: #4a40c6;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
