import { StakingController, WalletController } from "@/core/system/controllers";
import { defineStore } from "pinia";

export const useStakingStore = defineStore("staking", {
  state: () => ({
    isLoadingStakingData: true,
    step: 1,
    stakingPills: [
      {
        id: 1,
        title: "Bandwidth + Tron Power",
        code: "BANDWIDTH",
      },
      {
        id: 2,
        title: "Energy + Tron Power",
        code: "ENERGY",
      },
    ],
    selectedStakingPill: {
      id: null,
      title: "",
      code: "",
    },
    allResources: {
      available_bandwidth: 0,
      in_bandwidth: 0,
      available_energy: 0,
      in_energy: 0,
    },
    energyResources: {
      from_staking: 0,
      delegated_by_other: 0,
      delegated_to_other: 0,
    },
    bandwidthResources: {
      from_staking: 0,
      delegated_by_other: 0,
      delegated_to_other: 0,
    },
    walletTrx: null,
  }),
  actions: {
    setStep(step) {
      this.step = step;
    },
    setIsLoadingStakingData(boolean) {
      this.isLoadingStakingData = boolean;
    },
    setSelectedStakingPill(pill) {
      this.selectedStakingPill = pill;
    },
    async fetchStakingData(address) {
      this.isLoadingStakingData = true;
      const payload = {
        address,
      };
      try {
        const data = await StakingController.getBalanceAll(payload);
        this.allResources = data.all_resources;
        this.energyResources = data.energy_resources;
        this.bandwidthResources = data.bandwidth_resources;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingStakingData = false;
      }
    },
    async fetchWalletTrx(address) {
      const payload = {
        currency: "TRX",
        address,
      };
      const data = await WalletController.getSelectedBlockchain(payload);
      this.walletTrx = data[0];
    },
    async updateBalanceTrx() {
      const payload = {
        wallet_id: this.walletTrx.id,
      };
      await WalletController.getWalletBalance(payload);
    },
  },
  getters: {},
});
