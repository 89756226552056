import { defineStore } from "pinia";

export const useStoriesStore = defineStore("stories", {
  state: () => ({
    isOpenStories: false,
    storiesList: [
      {
        id: 1,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 2,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 3,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 4,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 5,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 6,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 7,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 8,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 9,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 10,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 11,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 12,
        cover: require('@/assets/images/history-3.png'),
        type: "image",
        category: "promo",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: null,
      },
      {
        id: 13,
        cover: require('@/assets/images/history-3.png'),
        type: "video",
        category: "video",
        title: 'Список популярных обеспеченных стейблкоинов в 2023 году',
        linkVideo: 'https://www.youtube.com/watch?v=-FXiXWMUFsQ&ab_channel=ZSMotoVlogs'
      },
    ],
  }),
  actions: {
    setOpenStories(bool) {
      this.isOpenStories = bool;
    },
  },
  getters: {},
});
