<template>
  <div class="qr-block">
    <div class="qr-block__image" v-if="!isLoading">
      <QRCanvas class="qr-code" :options="qrData"></QRCanvas>
    </div>
    <div class="qr-block__image" v-else>
      <b-skeleton class="sm" width="100%" height="100%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";

export default {
  components: {
    QRCanvas,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    qrData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-block {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  &__image {
    border-radius: 10px;
    width: 213px;
    height: 213px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f0f0f0;
    background: transparent;
    .qr-code {
      width: 170px;
      height: 170px;
    }
  }
}
</style>
