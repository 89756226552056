<template>
  <div class="wallets-switcher" :class="variant">
    <div class="wallets-switcher__content d-none d-md-flex">
      <h3 v-if="variant === 'main'" class="content-title mb-0">
        Открытые счета
      </h3>
      <div class="switcher" :class="variant">
        <div
          class="switch-item"
          v-for="item in switcherItems"
          :key="item.id"
          :class="{ active: item.id === selectedSwitchItem.id }"
          @click="switchWallet(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="wallets-switcher__content d-block d-md-none">
      <div class="switcher">
        <div
          class="switch-item mx-1"
          v-for="item in switcherItems"
          :key="item.id"
          :class="{ active: item.id === selectedSwitchItem.id }"
          @click="switchWallet(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "main",
    },
  },
  data: () => ({
    switcherItems: [
      { id: 1, name: "Кошельки", code: "default" },
      { id: 2, name: "Копилки", code: "hold" },
    ],
    selectedSwitchItem: { id: 1, name: "Кошельки", code: "default" },
  }),
  methods: {
    switchWallet(item) {
      this.selectedSwitchItem = item;
      this.$emit("onSwitch", item.code);
    },
  },
  computed: {
    selectedItemStorage() {
      const storageData = localStorage.getItem("typeCreatedWallet");
      if (storageData) {
        return this.switcherItems.find((item) => item.code == storageData);
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (this.variant === "main") {
      if (this.selectedItemStorage)
        this.selectedSwitchItem = this.selectedItemStorage;
    }
  },
};
</script>

<style lang="scss" scoped>
.wallets-switcher {
  &.selector {
    padding: 12px;
    @media (max-width: 768px) {
      padding: 0;
      background: none;
    }
  }
  &.main {
    padding: 24px;
    border-radius: 12px;
    background: #fff;
    @media (max-width: 768px) {
      padding: 0;
      background: none;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c3c;
    }
    .switcher {
      display: flex;
      align-items: center;

      .switch-item {
        cursor: pointer;
        text-align: center;
        width: 160px;
        padding-bottom: 8px;
        border-bottom: 2px solid #e9e8f2;
        color: #5f5f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        &.active {
          color: #4a40c6;
          font-weight: 500;
          border-bottom: 2px solid #4a40c6;
        }
        @media (max-width: 768px) {
          width: 50%;
          height: 36px;
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 24px;
          &.active {
            border: 1px solid #4a40c6;
          }
        }
      }
      &.selector {
        width: 100%;
        .switch-item {
          width: 50%;
        }
      }
    }
  }
}
</style>
