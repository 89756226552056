<template>
  <div
    v-if="!isLoading"
    class="currency-select"
    :tabindex="tabindex"
    @blur="closeSelect"
    @click="openSelect"
  >
    <div class="currency-select__main">
      <div class="currency-select__main-content">
        <img
          class="main-content__icon"
          :src="selectedOptionModel.icon"
          alt=""
        />
        <div class="ms-1">
          <h6
            v-if="selectedOptionModel.is_stablecoin"
            class="main-content__network"
          >
            {{ selectedOptionModel.network }}
          </h6>
          <h3 class="main-content__name">{{ selectedOptionModel.name }}</h3>
        </div>
      </div>
      <img
        class="arrow-select"
        :class="{
          open: isOpen,
        }"
        src="@/assets/images/arrow-select.svg"
        alt=""
      />
    </div>
    <div
      class="currency-select__dropdown"
      v-show="isOpen"
      :style="{ 'z-index': zIndex }"
    >
      <div class="dropdown-header">
        <h3 class="dropdown-title mb-0">Выберите валюту</h3>
        <img
          @click.stop="closeSelect"
          class="dropdown-close"
          src="@/assets/images/close-icon.svg"
          alt=""
        />
      </div>
      <div class="dropdown-search">
        <input v-model="searchQuery" class="search-input" type="text" />
        <img class="search-icon" src="@/assets/images/search.svg" alt="" />
      </div>
      <div class="dropdown-filters">
        <div
          class="filter-pill"
          :class="{
            active: selectedFilter.value === filter.value,
          }"
          v-for="(filter, index) in filters"
          :key="index"
          @click="selectFilter(filter)"
        >
          {{ filter.name }}
        </div>
      </div>
      <div class="dropdown-currency">
        <div
          v-for="currency in filteredOptions"
          :key="currency.id"
          class="currency-item"
          :class="{
            active: currency.code === selectedOptionModel.code,
          }"
          @click.stop="selectOption(currency)"
        >
          <div class="currency-item__left">
            <img :src="currency.icon" class="currency-item__icon" />
            <h3 class="currency-item__name">{{ currency.name }}</h3>
            <div v-if="currency.is_stablecoin" class="currency-item__network">
              {{ currency.network }}
            </div>
          </div>
          <div class="currency-item__right">
            <div class="currency-item__fullname">{{ currency.fullName }}</div>
            <div class="currency-item__fullnetwork">
              {{ currency.fullNetwork }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="currency-select loading" :tabindex="tabindex">
    <div class="currency-select__main">
      <div class="currency-select__main-content">
        <b-skeleton class="button" width="24px" height="24px"></b-skeleton>
        <div class="ms-1">
          <b-skeleton class="sm" width="40px" height="19px"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
      default: () => ({
        id: null,
        code: "",
        icon: "",
        isStablecoin: null,
        isPopular: null,
        name: "",
        fullName: "",
        network: "",
        fullNetwork: "",
      }),
    },
    tabindex: Number,
    zIndex: {
      type: Number | String,
      default: 0
    },
  },
  data() {
    return {
      isOpen: false,
      internalSelectedOption: this.selectedOption,
      searchQuery: "",
      selectedFilter: { name: "Все", value: "all" },
      filters: [
        { name: "Все", value: "all" },
        { name: "Стейблкоины", value: "stable" },
        { name: "Популярные", value: "popular" },
      ],
    };
  },
  computed: {
    selectedOptionModel: {
      get() {
        return this.internalSelectedOption;
      },
      set(value) {
        this.internalSelectedOption = value;
      },
    },
    filteredOptions() {
      let filteredArr;
      switch (this.selectedFilter.value) {
        case "stable":
          filteredArr = this.options.filter(
            (currency) => currency.is_stablecoin
          );
          break;
        case "popular":
          filteredArr = this.options.filter((currency) => currency.is_popular);
          break;
        default:
          filteredArr = this.options;
          break;
      }
      return filteredArr.filter(
        (currency) =>
          currency.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          currency.full_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },
  },
  watch: {
    selectedOption(newValue) {
      this.internalSelectedOption = newValue;
    },
  },
  methods: {
    openSelect() {
      const app = document.querySelector("html, body");
      app.classList.add("opened-mobile-select");
      this.isOpen = true;
    },
    closeSelect() {
      const app = document.querySelector("html, body");
      app.classList.remove("opened-mobile-select");
      this.isOpen = false;
    },
    handleFocusout(event) {
      const relatedTarget = event.relatedTarget;
      const dropdown = document.querySelectorAll(".currency-select__dropdown");
      let containsRelatedTarget = false;
      dropdown.forEach((el) => {
        if (el.contains(relatedTarget)) {
          containsRelatedTarget = true;
        }
      });
      if (!containsRelatedTarget) {
        this.closeSelect();
      }
    },
    selectOption(option) {
      this.$emit("change", option);
      this.selectedOptionModel = option;
      this.closeSelect();
    },
    selectFilter(filter) {
      this.selectedFilter = filter;
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-select {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 8px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  background: #ffffff;
  &.loading {
    cursor: wait;
  }
  .currency-select__main {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .currency-select__main-content {
      display: flex;
      align-items: center;
      .main-content__icon {
        width: 24px;
        height: 24px;
        @media (max-width: 425px) {
          width: 20px;
          height: 20px;
        }
      }
      .main-content__name {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #3c3c3c;
        @media (max-width: 425px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .main-content__network {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 8px;
        line-height: 8px;
        color: #4a40c6;
        @media (max-width: 425px) {
          font-size: 6px;
          line-height: 8px;
        }
      }
    }
    .arrow-select {
      transition: 0.1s ease-in;
      cursor: pointer;
      width: 8px;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .currency-select__dropdown {
    overflow: hidden;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 285px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 5px 50px rgba(15, 15, 15, 0.05);
    border-radius: 6px;
    @media (max-width: 425px) {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .dropdown-header {
      padding: 14px 12px 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dropdown-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #3c3c3c;
        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
      .dropdown-close {
        cursor: pointer;
        user-select: none;
        @media (max-width: 425px) {
          width: 14px;
        }
      }
    }
    .dropdown-search {
      padding: 0 12px 0 12px;
      margin-top: 8px;
      width: 100%;
      height: 32px;
      position: relative;
      @media (max-width: 425px) {
        margin-top: 12px;
        height: 40px;
      }
      .search-input {
        padding-left: 9px;
        width: 100%;
        height: 100%;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #939191;
        &:focus {
          border: 1px solid #f0f0f0;
          outline: none;
        }
        @media (max-width: 425px) {
          font-size: 14px;
        }
      }
      .search-icon {
        position: absolute;
        right: 16px;
        top: 6px;
        @media (max-width: 425px) {
          top: 10px;
        }
      }
    }
    .dropdown-filters {
      padding: 0 12px 0 12px;
      display: flex;
      justify-content: space-between;
      .filter-pill {
        cursor: pointer;
        margin-top: 10px;
        padding: 1px 12px 0 12px;
        background: #f1f3f9;
        border-radius: 50px;
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        color: #5f5f5f;
        border: 1px solid transparent;
        @media (max-width: 425px) {
          font-size: 14px;
          padding: 3px 14px 3px 14px;
        }
        &.active {
          border: 1px solid #958ee8;
        }
      }
    }
    .dropdown-currency {
      padding: 0 12px 0 12px;
      overflow: auto;
      height: 250px;
      margin-top: 4px;
      padding-bottom: 8px;
      @media (max-width: 425px) {
        height: 100%;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f3f9;
      }
      &::-webkit-scrollbar-thumb {
        height: 40px;
        background-color: #bfbcdd;
        border-radius: 100px;
      }
      .currency-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        margin-top: 6px;
        border: 1px solid #f1f3f9;
        border-radius: 6px;
        &.active {
          border: 1px solid #958ee8;
        }
        .currency-item__left {
          display: flex;
          align-items: center;
          .currency-item__icon {
            width: 20px;
            @media (max-width: 425px) {
              width: 24px;
            }
          }
          .currency-item__name {
            margin-left: 7px;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #3c3c3c;
          }
          .currency-item__network {
            margin-left: 4px;
            padding: 1px 4px 0 4px;
            background: #fbfbfb;
            border: 0.5px solid #f0f0f0;
            border-radius: 50px;
            font-weight: 500;
            font-size: 8px;
            line-height: 12px;
            color: #4a40c6;
          }
        }
        .currency-item__right {
          .currency-item__fullname {
            font-weight: 300;
            font-size: 10px;
            line-height: 10px;
            text-align: right;
            color: #5f5f5f;
          }
          .currency-item__fullnetwork {
            margin-top: 4px;
            font-weight: 300;
            font-size: 8px;
            line-height: 10px;
            text-align: right;
            color: #9a9a9a;
          }
        }
      }
    }
  }
}
</style>
