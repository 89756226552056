<template>
  <div @click="click" class="currency-card">
    <CCCard padding="card18" class="h-100" v-if="!isLoading">
      <div class="d-flex align-items-center">
        <div class="currency-card__image">
          <img :src="icon" alt="currency-icon" />
        </div>
        <div class="currency-card__name color-light ms-2">
          {{ currencyName }}
        </div>
        <div class="ms-2 currency-card__bad c-badge c-badge-is-bg-silver">
          {{ badgeName }}
        </div>
      </div>
      <h3 class="mt-3 ms-0 currency-card__balance">
        {{ convertBalanceCrypto(balanceCurrency) }}
        {{ sliceNetworkCodeCurrency(badgeName) }}
      </h3>
      <b-row class="mt-3">
        <b-col cols="12" md="6" class="pe-2">
          <button @click="send" class="currency-card__button">
            <img width="12" src="@/assets/images/upload-icon.svg" alt="" />
            <span> {{ $t("currency_card.send_btn") }}</span>
          </button>
        </b-col>
        <b-col cols="12" md="6" class="ps-2">
          <button @click="get" class="currency-card__button">
            <img width="12" src="@/assets/images/download-icon.svg" alt="" />
            <span> {{ $t("currency_card.accept_btn") }}</span>
          </button>
        </b-col>
      </b-row>
    </CCCard>
    <CCCard padding="card18" class="h-100" v-else>
      <div class="d-flex align-items-center">
        <b-skeleton class="sm" width="35px" height="35px"></b-skeleton>
        <b-skeleton class="sm ms-2" width="125px" height="20px"></b-skeleton>
      </div>
      <b-skeleton class="sm mt-3" width="139px" height="24px"></b-skeleton>
      <b-row class="mt-3">
        <b-col cols="12" md="6" class="pe-2">
          <b-skeleton class="button" width="100%" height="35px"></b-skeleton>
        </b-col>
        <b-col cols="12" md="6" class="ps-2">
          <b-skeleton class="button" width="100%" height="35px"></b-skeleton>
        </b-col>
      </b-row>
    </CCCard>
  </div>
</template>

<script>
import Clipboard from "../UI/Clipboard.vue";
import { convertBalanceCrypto, sliceNetworkCodeCurrency } from "@/utils";

export default {
  name: "CurrencyCard",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currencyName: String,
    currencyList: {
      type: Array,
      default: () => [],
    },
    badgeName: String,
    balanceCurrency: Number || String,
    icon: String,
    address: String,
    nickname: String,
  },
  components: { Clipboard },
  data: () => ({
    isCopyData: false,
  }),
  methods: {
    convertBalanceCrypto,
    sliceNetworkCodeCurrency,
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
    send() {
      this.$emit("send");
    },
    get() {
      this.$emit("get");
    },
    click() {
      this.$emit("click");
    },
    showWallet() {
      this.$emit("showWallet");
    },
    hideWallet() {
      this.$emit("hideWallet");
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-card {
  position: relative;
  height: 163px;
  // &.hidden {}
  cursor: pointer;
  // .card-main {
  //   padding-right: 48px !important;
  // }
  &__opacity-wrapper {
    cursor: auto;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    z-index: 10;
    opacity: 0.5;
  }
  &-link {
    cursor: pointer;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.75;
    }
  }
  .wallet-name {
    cursor: auto;
    position: relative;
    user-select: text;
  }
  .link {
    color: #4a40c6;
    font-size: 16px;
    font-weight: 500;
  }
  &__image {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    .currency-icon {
      width: 18px;
      height: 18px;
    }
    &.full {
    }
    &.part {
    }
  }
  &__button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    border: 1px solid #f0f0f0;
    background: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #3c3c3c;
    span {
      margin-left: 6px;
    }
  }

  &__name {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    // &.full {
    //   font-size: 16px;
    //   font-weight: 400;
    //   line-height: 18px; /* 112.5% */
    //   letter-spacing: 0.16px;
    // }
    // &.part {
    //   font-size: 12px;
    //   font-weight: 400;
    //   line-height: 24px;
    // }
  }
  &__badge {
    font-size: 10px;
  }
  &__crypto-name {
    margin-top: 8px !important;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px; /* 125% */
    letter-spacing: 0.32px;
    color: #3c3c3c;
    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 439px) {
      font-size: 16px;
    }
  }
  &__balance {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px; /* 100% */
    letter-spacing: 0.24px;
    color: #3c3c3c;
    // &.full {
    //   font-size: 32px;
    //   font-weight: 400;
    //   line-height: 38px;
    //   color: #3c3c3c;
    //   letter-spacing: 0.32px;
    //   @media (max-width: 439px) {
    //     font-size: 10px;
    //   }
    // }
    // &.part {
    //   font-size: 24px;
    //   font-weight: 400;
    //   line-height: 24px; /* 100% */
    //   letter-spacing: 0.24px;
    //   color: #3c3c3c;
    // }
  }
  &__diff-currency {
    @media (max-width: 439px) {
      font-size: 9px;
    }
  }
  .hide-button {
    transition: 0.1s ease-in;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 11;
    cursor: pointer;
    margin-left: 8px;
    padding: 2px 6px;
    border-radius: 40px;
    background: #f0f0f0;
    &__text {
      color: #5f5f5f;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.2px;
    }
    &__icon {
      margin-left: 6px;
      fill: #5f5f5f;
      .icon-vector {
        stroke: #5f5f5f;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .show-button {
    transition: 0.1s ease-in;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 11;
    cursor: pointer;
    margin-left: 8px;
    padding: 2px 6px;
    border-radius: 40px;
    background: #f0f0f0;
    &__text {
      color: #5f5f5f;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.2px;
    }
    &__icon {
      margin-left: 6px;
      fill: #5f5f5f;
      .icon-vector {
        stroke: #5f5f5f;
      }
    }
    &:hover {
      background: #4a40c6;
      .show-button__text {
        color: #fff;
      }
      .show-button__icon {
        fill: #fff;
        .icon-vector {
          stroke: #fff;
        }
      }
    }
  }
}
</style>
