import BaseEntity from "../entities/baseEntity";

class NetworkEntity extends BaseEntity {
  constructor(data = {}) {
    super();
    this.code = data.code || "";
    this.icon = data.icon || "";
    this.fullname = data.full_name || "";
  }
}

export default NetworkEntity;
