<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <span class="send-transfer__label">{{ $t("send_page.you_send") }}</span>
      <LinkBackPage class="d-none d-sm-block" @click="goBack" />
      <div class="send-transfer__step d-block d-sm-none">
        {{ $t("send_page.step") }} {{ step }} из 2
      </div>
    </div>
    <h3 class="m-0 mt-2 send-transfer__balance" v-if="!isLoadingFee">
      {{ amountToSendWithFee.toFixed(6) }}
      {{ sliceNetworkCodeCurrency(selectedCurrency.code) }}
    </h3>
    <b-skeleton v-else class="md mt-2" width="50%" height="39px"></b-skeleton>
    <b-row class="send-transfer-info d-flex flex-column">
      <!-- <b-col class="send-transfer-info__item">
        <div class="send-transfer-info__param-name">Сумма перевода в USD</div>
        <div class="send-transfer-info__param-value">$ 251,02</div>
      </b-col> -->
      <b-col cols="12" v-if="selectedNetwork === 'TRON'">
        <EnergyInfoWidget :address="selectedWallet.address" />
      </b-col>
      <b-col class="send-transfer-info__item">
        <div class="send-transfer-info__param-name">
          {{ $t("send_page.address_placeholder") }}
        </div>
        <div class="send-transfer-info__param-value address">
          {{ showAddressInterface(address) }}
        </div>
      </b-col>
      <b-col class="send-transfer-info__item">
        <b-row align-v="center">
          <b-col cols="5">
            <div v-if="!isLoadingFee">
              <div class="send-transfer-info__param-name">
                {{ $t("send_page.fee") }}
              </div>
              <div class="send-transfer-info__param-value">
                {{ fee.total.toFixed(6) }} {{ currencyFee }}
              </div>
            </div>
            <div v-else>
              <b-skeleton class="sm" width="80%" height="24px"></b-skeleton>
              <b-skeleton class="sm mt-2" width="70%" height="24px"></b-skeleton>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="send-transfer-info__param-name">
              {{ $t("send_page.network") }}
            </div>
            <div class="send-transfer-info__param-value">
              {{ selectedNetwork }}
            </div>
          </b-col>
          <b-col v-if="selectedNetwork === 'Ethereum' || selectedNetwork === 'Bitcoin'
        " cols="4">
            <div v-if="!isLoadingVariantFees" @click="openSettingsFee">
              <div class="send-transfer-info__param-name">Режим</div>
              <div class="d-flex align-items-center pointer">
                <div class="send-transfer-info__param-value address">
                  {{ selectedVariantFee.modeName }}
                </div>
                <img class="mt-2 ms-2" src="@/assets/images/change-icon.svg" alt="" />
              </div>
            </div>
            <div v-else>
              <b-skeleton class="sm" width="40%" height="24px"></b-skeleton>
              <div class="d-flex align-items-center mt-2">
                <b-skeleton class="sm" width="80px" height="24px"></b-skeleton>
                <b-skeleton class="button ms-2" width="24px" height="24px"></b-skeleton>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- <b-col class="send-transfer-info__item">
        <div class="send-transfer-info__param-name">Счет отправления</div>
        <div class="send-transfer-info__param-value">Для оплат</div>
      </b-col> -->
    </b-row>
    <!-- footer -->
    <div class="send-transfer__footer d-none d-sm-block">
      <div class="send-transfer__line"></div>
      <b-row align-v="center">
        <b-col cols="6" class="color-light send-transfer__step">
          {{ $t("send_page.step") }} {{ step }} из 2
        </b-col>
        <b-col cols="6" class="ms-auto">
          <CCButton fontWeight="medium" size="lg" class="px-4"
            :disabled="disabledBtn || isLoadingVariantFees || isEmptyAmount" :loading="disabledBtn"
            @click.prevent="sendTransaction">
            {{ $t("send_page.btn_send") }}
          </CCButton>
        </b-col>
      </b-row>
    </div>
    <div class="d-block d-sm-none mt-4">
      <CCButton fontWeight="medium" size="md" class="px-4"
        :disabled="disabledBtn || isLoadingVariantFees || isEmptyAmount" :loading="disabledBtn"
        @click.prevent="sendTransaction">
        {{ $t("send_page.btn_send") }}
      </CCButton>
    </div>
    <ETHSettingsPopup v-if="selectedNetwork === 'Ethereum'" :isLoadingVariants="isLoadingVariantFees" />
    <BTCSettingsPopup v-if="selectedNetwork === 'Bitcoin'" :isLoadingVariants="isLoadingVariantFees" />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { useWalletStore } from "@/stores/wallet";
import LinkBackPage from "../UI/LinkBackPage.vue";
import EnergyInfoWidget from "../EnergyRent/EnergyInfoWidget.vue";
import { useInterfaceStore } from "@/stores/interfaceStore";
import { sliceNetworkCodeCurrency } from "@/utils";
import { useStakingStore } from "@/stores/stakingStore";
import { CalculateFeeEthereumTransaction } from "@/wallets/Ethereum";
import { CalculateFeeTransactionBtc } from "@/wallets/Bitcoin";
import ETHSettingsPopup from "@/components/FeeSettings/ETHSettingsPopup.vue";
import BTCSettingsPopup from "../FeeSettings/BTCSettingsPopup.vue";
import { getShortWalletAddress } from "@/utils";

export default {
  components: {
    LinkBackPage,
    EnergyInfoWidget,
    ETHSettingsPopup,
    BTCSettingsPopup,
  },
  name: "SendTransferStep2",
  props: {
    step: Number,
  },
  data() {
    return {
      disabledBtn: false,
      isEmptyAmount: false,
      isLoadingVariantFees: false,
      intervalEthFee: null,
      intervalBtcFee: null,
    };
  },
  computed: {
    ...mapState(useSendTransferStore, [
      "selectedCurrency",
      "selectedWallet",
      "optionsCurrency",
      "address",
      "amount",
      "fee",
      "selectedVariantFee",
      "isLoadingFee",
    ]),
    ...mapState(useWalletStore, ["recipientBalance", "myBalance"]),
    ...mapState(useStakingStore, ["allResources"]),
    amountToSendWithFee() {
      const currencies = ["TRX", "ETH", "LTC", "BTC"];
      const amountWithFee = +this.amount - this.fee.total;
      if (!currencies.includes(this.selectedCurrency.code)) return +this.amount;
      // if (amountWithFee > 1) {
      //   return amountWithFee;
      // } else {
      //   this.showMessage("danger", this.$t("messages.low_amount"));
      //   this.isEmptyAmount = true;
      //   return 0;
      // }
      return amountWithFee
    },
    amountToSend() {
      return Number(this.amount).toFixed(6);
    },
    selectedNetwork() {
      return this.selectedWallet.network_object.full_name;
    },
    selectedCurrencyCode() {
      return this.selectedCurrency.code;
    },
    myBalanceEnergy() {
      return this.allResources.available_energy;
    },
    currencyFee() {
      let code;
      switch (this.selectedWallet.network_object.full_name) {
        case "TRON":
          code = "TRX";
          break;
        case "Ethereum":
          code = "ETH";
          break;
        case "Bitcoin":
          code = "BTC";
          break;
        case "Litecoin":
          code = "LTC";
          break;
        default:
          break;
      }
      return code;
    },
    checkFeeAndBalance() {
      let number_amount = Number(this.amountToSendWithFee.toFixed(6));
      let fee_amount = Number(this.fee.total);
      let total_amount = number_amount + fee_amount;
      if (this.selectedNetwork === "TRON") {
        if (this.selectedCurrencyCode != "TRX") {
          if (parseInt(this.myBalance) < parseInt(this.fee.total)) {
            if (this.recipientBalance > 0) {
              return this.myBalanceEnergy >= 32000;
            } else {
              return this.myBalanceEnergy >= 65000;
            }
          } else {
            return true;
          }
        } else {
          return (
            this.selectedCurrency.balance >= Number(total_amount.toFixed(6))
          );
        }
      } else if (this.selectedNetwork === "Ethereum") {
        const currencyEth = this.selectedWallet.currency_list.find(
          (currency) => currency.code === "ETH"
        );
        return currencyEth.balance >= fee_amount;
      } else {
        return this.selectedCurrency.balance >= Number(total_amount.toFixed(6));
      }
    },
  },
  methods: {
    ...mapActions(useSendTransferStore, [
      "setAmount",
      "setAddress",
      "setStep",
      "setVariantsFee",
      "setSelectedVariantFee",
      "setMaxFeeGwei",
      "setPriorityFeeGwei",
      "setFee",
      "setIsLoadingFee",
      "setFeeSatoshi",
    ]),
    ...mapActions(useWalletStore, [
      "setTransactionData",
      "getBalanceTrxAddress",
    ]),
    ...mapActions(useInterfaceStore, ["showMessage"]),
    getShortWalletAddress,
    sliceNetworkCodeCurrency,
    goBack() {
      this.setStep(1);
    },
    openSettingsFee() {
      this.selectedNetwork === "Ethereum"
        ? this.$bvModal.show("eth-fee-modal")
        : this.$bvModal.show("btc-fee-modal");
    },
    showAddressInterface(address) {
      return this.selectedNetwork === "Bitcoin"
        ? this.getShortWalletAddress(address)
        : address;
    },
    checkBalanceAndFeeDefault(balance) {
      let number_amount = Number(this.amountToSendWithFee.toFixed(6));
      let fee_amount = Number(this.fee.total);
      let total_amount = number_amount + fee_amount;
      return balance >= Number(total_amount.toFixed(6));
    },
    goToConfirmTransaction() {
      const newTransactionData = {
        selectedNetwork: this.selectedNetwork,
        amount: this.amountToSendWithFee,
        fromAddress: this.selectedWallet.address,
        toAddress: this.address,
        currency: this.sliceNetworkCodeCurrency(this.selectedCurrency.code),
      };
      this.setTransactionData(newTransactionData);
      this.setStep(3);
    },
    async calcFeeTron() {
      this.setIsLoadingFee(true);
      try {
        await this.getBalanceTrxAddress(
          this.selectedWallet.address,
          this.address,
          this.selectedCurrency.code
        );
        let total;
        if (this.selectedCurrency.code === "TRX") {
          total = 1;
        } else {
          total = this.recipientBalance > 0 ? 16 : 32;
        }
        const feeObj = {
          total,
          gasLimit: null,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        };
        this.setFee(feeObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.setIsLoadingFee(false);
      }
    },
    calcFeeLitecoin() {
      this.setIsLoadingFee(true);
      const feeObj = {
        total: 100000 / 10 ** 8,
        gasLimit: null,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      };
      this.setFee(feeObj);
      this.setIsLoadingFee(false);
    },
    async calcFeeEthereum(isUseSavedVariant = false) {
      const calcFee = new CalculateFeeEthereumTransaction(
        this.selectedCurrency.code
      );
      this.isLoadingVariantFees = true;
      this.setIsLoadingFee(true);
      try {
        const variantsFee = await calcFee.getAllVariantsFee();
        const variantLow = {
          ...variantsFee.low,
          mode: "low",
          modeName: "Медленный",
          maxWaitTimeEstimateSeconds: 30,
          amountFeeEth:
            (Number(variantsFee.low.suggestedMaxFeePerGas) * calcFee.gasLimit) /
            10 ** 9,
        };
        const variantMedium = {
          ...variantsFee.medium,
          mode: "medium",
          modeName: "Рыночный",
          maxWaitTimeEstimateSeconds: 30,
          amountFeeEth:
            (Number(variantsFee.medium.suggestedMaxFeePerGas) *
              calcFee.gasLimit) /
            10 ** 9,
        };
        const variantHigh = {
          ...variantsFee.high,
          mode: "high",
          modeName: "Приоритетный",
          maxWaitTimeEstimateSeconds: 15,
          amountFeeEth:
            (Number(variantsFee.high.suggestedMaxFeePerGas) *
              calcFee.gasLimit) /
            10 ** 9,
        };
        const variantsFeeArray = [variantLow, variantMedium, variantHigh];
        this.setVariantsFee(variantsFeeArray);
        if (isUseSavedVariant) {
          const savedVariantFee = localStorage.getItem("selectedVariantFeeEth");
          if (savedVariantFee) {
            this.setSelectedVariantFee(JSON.parse(savedVariantFee));
          } else {
            this.setSelectedVariantFee(variantsFeeArray[1]);
          }
        }
        // this.setMaxFeeGwei(
        //   this.selectedVariantFeeEthereum.suggestedMaxFeePerGas
        // );
        // this.setPriorityFeeGwei(
        //   this.selectedVariantFeeEthereum.suggestedMaxPriorityFeePerGas
        // );
        const newFeeObj = {
          total: this.selectedVariantFee.amountFeeEth,
          gasLimit: calcFee.gasLimit,
          maxPriorityFeePerGas:
            this.selectedVariantFee.suggestedMaxPriorityFeePerGas,
          maxFeePerGas: this.selectedVariantFee.suggestedMaxFeePerGas,
        };
        this.setFee(newFeeObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingVariantFees = false;
        this.setIsLoadingFee(false);
      }
    },
    async calcFeeBitcoin(isUseSavedVariant = false) {
      const calcFee = new CalculateFeeTransactionBtc(
        Number(this.amount),
        this.selectedWallet.address
      );
      this.isLoadingVariantFees = true;
      this.setIsLoadingFee(true);
      try {
        const variantsFee = await calcFee.getFeeSatoshiByte();
        const variantLow = {
          mode: "low",
          modeName: "Медленный",
          amountFeeSatoshi: variantsFee.economyFee,
          amountFeeBtc: await calcFee.getFee(variantsFee.economyFee),
        };
        const variantMedium = {
          mode: "medium",
          modeName: "Рыночный",
          amountFeeSatoshi: variantsFee.hourFee,
          amountFeeBtc: await calcFee.getFee(variantsFee.hourFee),
        };
        const variantHigh = {
          mode: "high",
          modeName: "Приоритетный",
          amountFeeSatoshi: variantsFee.fastestFee,
          amountFeeBtc: await calcFee.getFee(variantsFee.fastestFee),
        };
        const variantsFeeArray = [variantLow, variantMedium, variantHigh];
        this.setVariantsFee(variantsFeeArray);
        if (isUseSavedVariant) {
          const savedVariantFee = JSON.parse(
            localStorage.getItem("selectedVariantFeeBtc")
          );
          if (savedVariantFee) {
            this.setSelectedVariantFee(savedVariantFee);
            if (savedVariantFee.mode === "custom") {
              this.setFeeSatoshi(savedVariantFee.amountFeeSatoshi);
            }
          } else {
            this.setSelectedVariantFee(variantsFeeArray[1]);
          }
        }
        const newFeeObj = {
          total: this.selectedVariantFee.amountFeeBtc,
          gasLimit: null,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        };
        this.setFee(newFeeObj);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingVariantFees = false;
        this.setIsLoadingFee(false);
      }
    },
    generalCalcFees() {
      switch (this.selectedNetwork) {
        case "TRON":
          this.calcFeeTron();
          break;
        case "Ethereum":
          this.calcFeeEthereum(true);
          this.intervalEthFee = setInterval(() => {
            this.calcFeeEthereum();
          }, 12000);
          break;
        case "Litecoin":
          this.calcFeeLitecoin();
          break;
        case "Bitcoin":
          this.calcFeeBitcoin(true);
          this.intervalBtcFee = setInterval(() => {
            this.calcFeeBitcoin();
          }, 60000);
          break;

        default:
          break;
      }
    },
    async sendTransaction() {
      if (this.amountToSendWithFee > 0) {
        if (this.checkFeeAndBalance) {
          this.goToConfirmTransaction();
        } else {
          this.showMessage("danger", this.$t("messages.error_fee"));
        }
      }
    },
  },
  mounted() {
    this.generalCalcFees();
  },
  beforeDestroy() {
    clearInterval(this.intervalEthFee);
    clearInterval(this.intervalBtcFee);
  },
};
</script>
<style>
.break-all {
  word-break: break-all;
}
</style>
