<template>
  <div>
    <nav
      class="mobile-menu d-block d-sm-none"
      :style="{ 'z-index': isOpenStories ? 0 : 100 }"
      :class="{ active: isMenuOpen }"
    >
      <ul class="mobile-menu__inner">
        <li class="mobile-menu__item" v-for="link in navLinks" :key="link.id">
          <router-link
            class="item-link"
            :to="link.route"
            :class="{
              'router-link-exact-active router-link-active': isActiveLink(link),
            }"
          >
            <img
              v-if="isActiveLink(link)"
              :src="link.iconActive"
              alt=""
            />
            <img
              v-else
              :src="link.iconBase"
              alt=""
            />
            <span class="mobile-menu__name">{{ link.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState } from "pinia";
import ButtonMobile from "../UI/ButtonMobile.vue";
import Notification from "../Notification.vue";
import UserDropdown from "../UserDropdown.vue";
import { useStoriesStore } from "@/stores/storiesStore";

export default {
  name: "MobileMenu",
  components: {
    ButtonMobile,
    UserDropdown,
    Notification,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapState(useStoriesStore, ["isOpenStories"]),
    navLinks() {
      return [
        {
          id: 1,
          name: this.$t("mobile_menu.wallet"),
          route: "/",
          iconBase: require("@/assets/images/sidebar/home.svg"),
          iconActive: require("@/assets/images/sidebar/home-active.svg"),
          active_routes: ["/wallet", "/send", "/accept"],
        },
        {
          id: 2,
          name: this.$t("mobile_menu.exchange"),
          route: "/exchange",
          iconBase: require("@/assets/images/sidebar/exchange.svg"),
          iconActive: require("@/assets/images/sidebar/exchange-active.svg"),
          active_routes: ["/exchange"],
        },
        {
          id: 3,
          name: this.$t("mobile_menu.energy"),
          route: "/buy-energy",
          iconBase: require("@/assets/images/sidebar/energy.svg"),
          iconActive: require("@/assets/images/sidebar/energy-active.svg"),
          active_routes: ["/buy-energy"],
        },
        {
          id: 4,
          name: this.$t("mobile_menu.staking"),
          route: "/staking",
          iconBase: require("@/assets/images/sidebar/staking.svg"),
          iconActive: require("@/assets/images/sidebar/staking-active.svg"),
          active_routes: ["/staking"],
        },
        {
          id: 5,
          name: this.$t("mobile_menu.menu"),
          route: "/menu",
          iconBase: require("@/assets/images/sidebar/menu.svg"),
          iconActive: require("@/assets/images/sidebar/menu-active.svg"),
          active_routes: ["/menu"],
        },
      ];
    },
  },
  methods: {
    isActiveLink(link) {
      if (link.route.includes("buy") || this.$route.path === "/") {
        return this.$route.path === link.route;
      } else {
        if (link.active_routes) {
          return link.active_routes.some((route) =>
            this.$route.path.startsWith(route)
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 5px 50px -5px rgba(15, 15, 15, 0.05);
  border-radius: 6px 6px 0px 0px;
  padding: 12px 16px;
  border-top: 1px solid #f0f0f0;
  // z-index: 9999;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: inherit;
    padding-left: 0 !important;
  }
  &__item {
    position: relative;
    list-style: none;
    width: 48px;
    .item-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 6px 2px 6px;
      border-radius: 5px;
      .mobile-menu__icon {
        width: 24px;
        height: 24px;
      }
      .mobile-menu__name {
        margin-top: 4px;
        color: #3c3c3c;
        font-size: 8px;
        font-weight: 400;
        line-height: 10px; /* 125% */
        letter-spacing: 0.24px;
      }
      &.router-link-exact-active.router-link-active {
        background: rgba(191, 188, 221, 0.1);
        transition: opacity 0.2s;
      }
    }
  }
}
</style>
