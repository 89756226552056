<template>
  <div class="send-transfer-info d-flex flex-column">
    <b-row v-if="!isLoading">
      <b-col cols="12" class="send-transfer-info__item">
        <div class="send-transfer-info__param-name">
          {{ $t("aml_page.price_checks") }}
        </div>
        <div class="send-transfer-info__param-value">{{ amount }} USDT</div>
      </b-col>
      <b-col cols="12" class="send-transfer-info__item">
        <div class="send-transfer-info__param-name">
          {{ $t("aml_page.address") }}
        </div>
        <div class="send-transfer-info__param-value">
          <span class="link">{{ address }}</span>
        </div>
      </b-col>
      <b-col class="send-transfer-info__item">
        <b-row>
          <b-col cols="6">
            <div class="send-transfer-info__param-name">{{ $t("aml_page.fee") }}</div>
            <div class="send-transfer-info__param-value">{{ fee }} TRX</div>
          </b-col>
          <b-col cols="6">
            <div class="send-transfer-info__param-name">{{ $t("aml_page.network") }}</div>
            <div class="send-transfer-info__param-value">TRON</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="send-transfer-info__item">
        <b-skeleton class="sm" width="70%" height="24px"></b-skeleton>
        <b-skeleton class="sm mt-2" width="40%" height="24px"></b-skeleton>
      </b-col>
      <b-col cols="12" class="send-transfer-info__item">
        <b-skeleton class="sm" width="40%" height="24px"></b-skeleton>
        <b-skeleton class="sm mt-2" width="100%" height="24px"></b-skeleton>
      </b-col>
      <b-col class="send-transfer-info__item">
        <b-row>
          <b-col cols="6">
            <b-skeleton class="sm" width="50%" height="24px"></b-skeleton>
            <b-skeleton class="sm mt-2" width="30%" height="24px"></b-skeleton>
          </b-col>
          <b-col cols="6">
            <b-skeleton class="sm" width="50%" height="24px"></b-skeleton>
            <b-skeleton class="sm mt-2" width="30%" height="24px"></b-skeleton>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    amount: Number,
    address: String,
    fee: Number,
  },
};
</script>

<style></style>
