<template>
  <div @blur="handleOutsideClick" tabindex="1" class="position-relative">
    <!-- Button Notification -->
    <div @click.stop="changeState" class="button-notification ms-0 ms-sm-3">
      <span class="button-notification__circle">30</span>
      <img src="@/assets/images/bell-icon.svg" alt="bell-icon" />
    </div>
    <!-- Notification -->
    <div v-if="isOpen" @click.stop class="notification">
      <b-row class="notification__header">
        <b-col class="d-flex align-items-center">
          <img
            class="notification__image-notif"
            src="@/assets/images/notification/notification-icon.png"
            alt="notification-icon"
          />
          <h3 class="m-0 ms-2 notification__title">Центр уведомлений</h3>
        </b-col>
        <b-col cols="auto" class="ms-auto">
          <img
            class="notification__image-close"
            @click.stop="changeState"
            src="@/assets/images/close-icon.svg"
            alt="close-icon"
          />
        </b-col>
      </b-row>
      <b-row class="notification-wrapper-cards">
        <!-- card -->
        <b-col cols="12" class="notification-card">
          <b-row align-v="center" class="notification-card__header">
            <b-col class="d-flex align-items-center">
              <img
                class="notification-card__image-status"
                src="@/assets/images/notification/circle-blue.svg"
                alt="status-icon"
              />
              <span class="notification-card__title">Новая транзакция</span>
            </b-col>
            <b-col cols="auto" class="ms-auto d-flex align-items-center">
              <span class="notification-card__date color-light"
                >13.07.22 11:16</span
              >
              <img
                class="ms-2 notification-card__image-close"
                src="@/assets/images/close-icon.svg"
                alt="close-icon"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__currency d-flex align-items-center"
            >
              <img src="@/assets/images/incoming-icon.svg" alt="" />
              <img
                class="ms-2"
                src="@/assets/images/btc-icon.svg"
                alt="currency-icon"
              />
              <span class="ms-2">Bitcoin</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <a href="#" class="link link-back">
                <img src="@/assets/images/link-icon.png" alt="link-icon" />
                <span class="ms-2">INV-AGYBN5</span>
              </a>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__balance d-flex align-items-center"
            >
              <span class="ms-2">+ 0,00020027 BTC</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <span class="ms-2">+ $ 1 257,89</span>
            </b-col>
          </b-row>
        </b-col>
        <!-- ./card -->
        <!-- card -->
        <b-col cols="12" class="notification-card">
          <b-row align-v="center" class="notification-card__header">
            <b-col class="d-flex align-items-center">
              <img
                class="notification-card__image-status"
                src="@/assets/images/notification/circle-yellow.svg"
                alt="status-icon"
              />
              <span class="notification-card__title">Заголовок</span>
            </b-col>
            <b-col cols="auto" class="ms-auto d-flex align-items-center">
              <span class="notification-card__date color-light"
                >13.07.22 11:16</span
              >
              <img
                class="ms-2 notification-card__image-close"
                src="@/assets/images/close-icon.svg"
                alt="close-icon"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__currency d-flex align-items-center"
            >
              <img src="@/assets/images/incoming-icon.svg" alt="" />
              <img
                class="ms-2"
                src="@/assets/images/btc-icon.svg"
                alt="currency-icon"
              />
              <span class="ms-2">Bitcoin</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <a href="#" class="link link-back">
                <img src="@/assets/images/link-icon.png" alt="link-icon" />
                <span class="ms-2">INV-AGYBN5</span>
              </a>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__balance d-flex align-items-center"
            >
              <span class="ms-2">+ 0,00020027 BTC</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <span class="ms-2">+ $ 1 257,89</span>
            </b-col>
          </b-row>
        </b-col>
        <!-- ./card -->
        <!-- card -->
        <b-col cols="12" class="notification-card">
          <b-row align-v="center" class="notification-card__header">
            <b-col class="d-flex align-items-center">
              <img
                class="notification-card__image-status"
                src="@/assets/images/notification/circle-red.svg"
                alt="status-icon"
              />
              <span class="notification-card__title">Новая транзакция</span>
            </b-col>
            <b-col cols="auto" class="ms-auto d-flex align-items-center">
              <span class="notification-card__date color-light"
                >13.07.22 11:16</span
              >
              <img
                class="ms-2 notification-card__image-close"
                src="@/assets/images/close-icon.svg"
                alt="close-icon"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__text d-flex align-items-center"
            >
              <p class="m-0 p-0">
                Спасибо за регистрацию в нашем сервисе. Для использования
                сервиса вам необходимо добавить проект и провести интеграцию с
                вашим сайтом.
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols class="d-flex align-items-center">
              <a class="link notification-card__link" href="#">
                Создать счет хранения
              </a>
            </b-col>
          </b-row>
        </b-col>
        <!-- ./card -->
        <!-- card -->
        <b-col cols="12" class="notification-card">
          <b-row align-v="center" class="notification-card__header">
            <b-col class="d-flex align-items-center">
              <img
                class="notification-card__image-status"
                src="@/assets/images/notification/circle-green.svg"
                alt="status-icon"
              />
              <span class="notification-card__title">Новая транзакция</span>
            </b-col>
            <b-col cols="auto" class="ms-auto d-flex align-items-center">
              <span class="notification-card__date color-light"
                >13.07.22 11:16</span
              >
              <img
                class="ms-2 notification-card__image-close"
                src="@/assets/images/close-icon.svg"
                alt="close-icon"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__currency d-flex align-items-center"
            >
              <img src="@/assets/images/incoming-icon.svg" alt="" />
              <img
                class="ms-2"
                src="@/assets/images/btc-icon.svg"
                alt="currency-icon"
              />
              <span class="ms-2">Bitcoin</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <a href="#" class="link link-back">
                <img src="@/assets/images/link-icon.png" alt="link-icon" />
                <span class="ms-2">INV-AGYBN5</span>
              </a>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols
              class="notification-card__balance d-flex align-items-center"
            >
              <span class="ms-2">+ 0,00020027 BTC</span>
            </b-col>
            <b-col cols="auto" class="ms-auto">
              <span class="ms-2">+ $ 1 257,89</span>
            </b-col>
          </b-row>
        </b-col>
        <!-- ./card -->
      </b-row>
    </div>
  </div>
</template>

<script>
import dropdownMixin from "@/mixins/dropdown";

export default {
  name: "Notification",
  mixins: [dropdownMixin],
};
</script>
