import Vue from "vue";
import { WalletController } from "@/core/system/controllers";
import NetworkCyrrencyEntity from "@/core/system/entities/networkCurrencyEntity";
import { defineStore } from "pinia";

export const useWalletStore = defineStore("walletStore", {
  state: () => ({
    step: 1,
    txId: null,
    myBalance: null,
    recipientBalance: null,
    aesString: "",
    address: "",
    idCreatedWallet: null,
    networksList: [new NetworkCyrrencyEntity({})],
    selectedNetworkCurrency: new NetworkCyrrencyEntity({}),
    isLoadingWalletList: true,
    isLoadingWallet: false,
    isLoadingNetworks: false,
    isLoadingHistory: true,
    walletsList: [],
    selectedIdWalletNetwork: null,
    selectedWallet: {
      id: null,
      nickname: "",
      address: "",
      network: "",
      currencyList: [],
    },
    selectedNetwork: {
      id: null,
      icon: "",
      name: "",
      currencies: [],
    },
    typeTransaction: "send",
    stakingData: {
      amount: null,
      resource: "",
    },
    transactionData: {
      selectedNetwork: "",
      amount: null,
      fromAddress: "",
      toAddress: "",
      currency: "",
    },
    isTronWallet: false,
    isLoadingTronWallets: true,
    tronWalletsList: [],
    selectedTronWallet: {
      id: null,
      address: "",
    },
    historyList: [],
    totalItemsHistory: 0,
    limitItemsHistory: 7,
    currentPageHistory: 1,
    historyFilters: {},
    searchQueryHistory: "",
  }),
  actions: {
    setIsLoadingHistory(boolean) {
      this.isLoadingHistory = boolean;
    },
    addFilterHistory(key, value) {
      this.historyFilters[key] = value;
    },
    removeFilterHistory(key) {
      delete this.historyFilters[key];
    },
    setQuerySearchHistory(query) {
      this.searchQueryHistory = query;
    },
    setSelectedNetwork(network) {
      this.selectedNetwork = network;
    },
    setSelectedWalletIdNetwork(id) {
      this.selectedIdWalletNetwork = id;
    },
    setStep(step) {
      this.step = step;
    },
    setStepBack() {
      this.step -= 1;
    },
    setTxId(txId) {
      this.txId = txId;
    },
    setAesString(aes) {
      this.aesString = aes;
    },
    setAddressString(address) {
      this.address = address;
    },
    setIdCreatedWallet(id) {
      this.idCreatedWallet = id;
    },
    setTypeTransaction(type) {
      this.typeTransaction = type;
    },
    setTransactionData(data) {
      this.transactionData = data;
    },
    addNewFieldTransactionData(key, value) {
      this.transactionData[key] = value;
    },
    setStakingData(data) {
      this.stakingData = data;
    },
    setSelectedNetworkCurrency(network) {
      this.selectedNetworkCurrency = network;
    },
    setSelectedTronWallet(wallet) {
      this.selectedTronWallet = wallet;
      localStorage.setItem("tron_wallet", JSON.stringify(wallet));
    },
    setCurrentPage(page) {
      this.currentPageHistory = page;
      this.fetchHistoryList();
    },
    async fetchHistoryList() {
      const payload = {
        q: this.searchQueryHistory,
        limit: this.limit,
        offset: this.offset,
        filters: this.historyFilters,
      };
      this.isLoadingHistory = true;
      try {
        const data = await WalletController.getTransactionsList(payload);
        this.totalItemsHistory = data.all_count;
        this.historyList = data.result;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingHistory = false;
      }
    },
    async fetchTronWallets(currency = "TRX") {
      const payload = {
        currency,
      };
      try {
        const resp = await WalletController.getSelectedBlockchain(payload);
        if (resp.length) {
          this.tronWalletsList = resp.map((wallet) => {
            return {
              id: wallet.id,
              address: wallet.address,
              nickname: wallet.nickname,
            };
          });
          this.selectedTronWallet = this.tronWalletsList[0];
          const stringifySelectedWallet = JSON.stringify(
            this.selectedTronWallet,
          );
          localStorage.setItem("tron_wallet", stringifySelectedWallet);
          this.isTronWallet = true;
        } else {
          this.isTronWallet = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingTronWallets = false;
      }
    },
    async getAllWallets() {
      this.isLoadingWalletList = true;
      try {
        const data = await WalletController.getBlockchainBalance();
        if (data !== undefined) this.walletsList = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingWalletList = false;
      }
    },
    async getAllNetwokCurrency() {
      this.isLoadingNetworks = true;
      try {
        const response = await WalletController.getActiveNetworkCurrencies();
        this.networksList = response;
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingNetworks = false;
      }
    },
    async updateBalance(address) {
      const payload = {
        wallet_network_address: address,
      };
      this.isLoadingWallet = true;
      try {
        const response = await WalletController.updateBalance(payload);
        this.selectedWallet = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingWallet = false;
      }
    },
    async getBalanceTrxAddress(addressFrom, addressTo, currency = "TRX") {
      const payloadMyAddress = {
        network_code: "TRX",
        currency_code: "TRX",
        address: addressFrom,
      };
      const payloadRecipientAddress = {
        network_code: "TRX",
        currency_code: currency,
        address: addressTo,
      };
      const myBalance = await WalletController.getBalanceAddress(
        payloadMyAddress,
      );
      const recipientBalance = await WalletController.getBalanceAddress(
        payloadRecipientAddress,
      );
      this.recipientBalance = recipientBalance;
      this.myBalance = myBalance;
    },
    async toggleVisibilityWallet(id, visible) {
      const payload = {
        wallet_network_id: id,
        visibility: visible,
      };

      this.isLoadingWalletList = true;
      try {
        await WalletController.toggleVisibleWallet(payload);
        const walletIndex = this.walletsList.findIndex(
          (wallet) => wallet.id === id,
        );
        if (walletIndex !== -1 && this.walletsList[walletIndex]) {
          Vue.set(this.walletsList[walletIndex], "visibility_state", visible);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingWalletList = false;
      }
    },
  },
  getters: {
    limit() {
      return this.currentPageHistory * 7;
    },
    offset: (state) => {
      return (state.currentPageHistory - 1) * state.limitItemsHistory;
    },
    totalPagesHistory: (state) => {
      return Math.ceil(state.totalItemsHistory / state.limitItemsHistory);
    },
    typeWallet: () => {
      return localStorage.getItem("typeCreatedWallet");
    },
  },
});
