<template>
  <div>
    <HeaderMobile :title="$t('home_page.title')" class="d-block d-sm-none">
      <router-link slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link slot="button-right" to="/create-wallet">
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row>
      <b-col
        cols="12"
        lg="5"
        class="d-flex flex-column justify-content-between"
      >
        <BalanceCard
          class="h-100 d-none d-sm-block"
          :isLoading="isLoadingUser"
          :balance="user.balance_usd"
        />
        <BalanceCardMobile
          :titleCard="$t('balance_card.title1')"
          :isLoading="isLoadingUser"
          :balance="user.balance_usd"
          class="d-block d-sm-none"
        />
      </b-col>
      <b-col cols="12" lg="7" class="mt-4 mt-lg-0 d-none d-md-block">
        <CreateWalletCard class="h-100" variant="lg" />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <WalletsSwitcher @onSwitch="switchType" />
      </b-col>
    </b-row>
    <div class="d-none d-sm-block">
      <b-row v-if="!isLoadingWalletList">
        <b-col
          cols="12"
          class="mt-4"
          v-for="(card, index) in openWallets"
          :key="index"
        >
          <router-link
            :to="card.visibility_state ? `/wallet/${card.address}` : ''"
          >
            <WalletCard
              :typeCard="typeWallet"
              @showWallet="toggleVisibilityWallet(card.id, true)"
              @hideWallet="toggleVisibilityWallet(card.id, false)"
              :isVisible="card.visibility_state"
              :address="card.address"
              :nickname="card.nickname"
              :targetName="card.piggy_bank.target_title"
              :icon="card.network_object.icon"
              :walletName="card.network_object.full_name"
              :balanceWallet="card.balance_usd"
              :targetAmount="card.piggy_bank.target_amount"
              :currencyList="card.currency_list"
              :badgeName="card.network_object.code"
            />
          </router-link>
        </b-col>
        <b-col cols="12" class="mt-4">
          <HiddenWallets v-if="hiddenWallets.length">
            <template slot="content">
              <div
                v-for="(card, index) in hiddenWallets"
                :key="card.id"
                :class="{ 'mt-4': index > 0 }"
              >
                <WalletCard
                  :typeCard="typeWallet"
                  @showWallet="toggleVisibilityWallet(card.id, true)"
                  @hideWallet="toggleVisibilityWallet(card.id, false)"
                  :isVisible="card.visibility_state"
                  :address="card.address"
                  :nickname="card.nickname"
                  :targetName="card.piggy_bank.target_title"
                  :icon="card.network_object.icon"
                  :walletName="card.network_object.full_name"
                  :balanceWallet="card.balance_usd"
                  :targetAmount="card.piggy_bank.target_amount"
                  :currencyList="card.currency_list"
                  :badgeName="card.network_object.code"
                />
              </div>
            </template>
          </HiddenWallets>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" class="mt-4" v-for="index in 4" :key="index">
          <WalletCard :isLoading="isLoadingWalletList" />
        </b-col>
      </b-row>
    </div>
    <div class="d-block d-sm-none mb-4">
      <b-row v-if="!isLoadingWalletList">
        <b-col
          class="mt-4"
          cols="12"
          v-for="(card, index) in openWallets"
          :key="index"
        >
          <router-link
            :to="card.visibility_state ? `/wallet/${card.address}` : ''"
          >
            <WalletCardMobile
              :typeCard="typeWallet"
              @showWallet="toggleVisibilityWallet(card.id, true)"
              @hideWallet="toggleVisibilityWallet(card.id, false)"
              :isVisible="card.visibility_state"
              :address="card.address"
              :nickname="card.nickname"
              :icon="card.network_object.icon"
              :walletName="card.network_object.full_name"
              :balanceWallet="card.balance_usd"
              :walletList="card.currency_list"
              :badgeName="card.network_object.code"
              :targetName="card.piggy_bank.target_title"
              :targetAmount="card.piggy_bank.target_amount"
            />
          </router-link>
        </b-col>
        <b-col
          cols="12"
          class="mt-4 d-block d-md-none"
          v-if="walletsList.length < 2"
        >
          <CreateWalletCard class="h-100" variant="lg" />
        </b-col>
        <b-col cols="12" class="mt-4">
          <HiddenWallets v-if="hiddenWallets.length">
            <template slot="content">
              <div
                v-for="(card, index) in hiddenWallets"
                :key="card.id"
                :class="{ 'mt-4': index > 0 }"
              >
                <WalletCardMobile
                  :typeCard="typeWallet"
                  @showWallet="toggleVisibilityWallet(card.id, true)"
                  @hideWallet="toggleVisibilityWallet(card.id, false)"
                  :isVisible="card.visibility_state"
                  :address="card.address"
                  :nickname="card.nickname"
                  :targetName="card.piggy_bank.target_title"
                  :icon="card.network_object.icon"
                  :walletName="card.network_object.full_name"
                  :balanceWallet="card.balance_usd"
                  :targetAmount="card.piggy_bank.target_amount"
                  :currencyList="card.currency_list"
                  :badgeName="card.network_object.code"
                />
              </div>
            </template>
          </HiddenWallets>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="mt-4" cols="12" v-for="index in 4" :key="index">
          <CurrencyCardMobile :isLoading="isLoadingWalletList" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CreateWalletCard from "@/components/CreateWallet/CreateWalletCard.vue";
import BalanceCard from "@/components/Balance/BalanceCard.vue";
import { useUserStore } from "@/stores/userStore";
import { mapState, mapActions } from "pinia";
import { useWalletStore } from "@/stores/wallet";
import CurrencyCard from "@/components/Currency/CurrencyCard.vue";
import BalanceCardMobile from "@/components/Balance/BalanceCardMobile.vue";
import CurrencyCardMobile from "@/components/Currency/CurrencyCardMobile.vue";
import HeaderMobile from "@/components/Headers/HeaderMobile.vue";
import WalletsSwitcher from "@/components/Wallets/WalletsSwitcher.vue";
import WalletCard from "@/components/Wallets/WalletCard.vue";
import WalletCardMobile from "@/components/Wallets/WalletCardMobile.vue";
import HiddenWallets from "@/components/Wallets/HiddenWallets.vue";

export default {
  name: "Home",
  components: {
    BalanceCard,
    CreateWalletCard,
    CurrencyCard,
    BalanceCardMobile,
    CurrencyCardMobile,
    HeaderMobile,
    WalletsSwitcher,
    WalletCard,
    WalletCardMobile,
    HiddenWallets,
  },
  data: () => ({
    typeWallet: localStorage.getItem("typeCreatedWallet") || "default",
    filteredWalletsOpen: [],
    filteredWalletsHidden: [],
  }),
  methods: {
    ...mapActions(
      () => useWalletStore(),
      ["getAllWallets", "toggleVisibilityWallet", "setSelectedWalletIdNetwork"],
    ),
    buyCrypto() {
      this.$router.push("/buy");
    },
    exchangeCrypto() {
      this.$router.push("/exchange");
    },
    filterWalletsOpen(typeWallet) {
      if (typeWallet === "default") {
        return this.walletsList.filter(
          (wallet) => !wallet.is_piggy_bank && wallet.visibility_state,
        );
      } else {
        return this.walletsList.filter(
          (wallet) => wallet.is_piggy_bank && wallet.visibility_state,
        );
      }
    },
    filterWalletsHidden(typeWallet) {
      if (typeWallet === "default") {
        return this.walletsList.filter(
          (wallet) => !wallet.is_piggy_bank && !wallet.visibility_state,
        );
      } else {
        return this.walletsList.filter(
          (wallet) => wallet.is_piggy_bank && !wallet.visibility_state,
        );
      }
    },
    switchType(type) {
      localStorage.setItem("typeCreatedWallet", type);
      this.typeWallet = type;
    },
  },

  computed: {
    ...mapState(() => useUserStore(), ["user", "isLoadingUser"]),
    ...mapState(() => useWalletStore(), ["walletsList", "isLoadingWalletList"]),
    openWallets() {
      if (this.typeWallet === "default") {
        return this.walletsList.filter(
          (wallet) => !wallet.is_piggy_bank && wallet.visibility_state,
        );
      } else {
        return this.walletsList.filter(
          (wallet) => wallet.is_piggy_bank && wallet.visibility_state,
        );
      }
    },

    hiddenWallets() {
      if (this.typeWallet === "default")
        return this.walletsList.filter(
          (wallet) => !wallet.is_piggy_bank && !wallet.visibility_state,
        );
      else {
        return this.walletsList.filter(
          (wallet) => wallet.is_piggy_bank && !wallet.visibility_state,
        );
      }
    },
  },
  async mounted() {
    await this.getAllWallets();
    this.filteredWalletsOpen = this.filterWalletsOpen(this.typeWallet);
    this.filteredWalletsHidden = this.filterWalletsHidden(this.typeWallet);
  },
};
</script>
