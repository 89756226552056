<template>
  <div class="clipboard">
    <img
      v-if="isActiveCopy"
      :width="`${size}px`"
      :height="`${size}px`"
      src="@/assets/images/copy-icon-active.svg"
      alt=""
    />
    <img
      v-else
      @click.prevent
      v-clipboard:copy="copyText"
      v-clipboard:success="onCopySuccess"
      :width="`${size}px`"
      :height="`${size}px`"
      src="@/assets/images/copy-icon-accent.svg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String | Number,
      default: "24",
    },
    isActiveCopy: {
      type: Boolean,
      default: false,
    },
    copyText: {
      type: String,
      default: "copytext",
      required: true,
    },
  },
  methods: {
    onCopySuccess() {
      this.$emit("onCopySuccess");
    },
  },
};
</script>

<style lang="scss" scoped>
.clipboard {
  cursor: pointer;
}
</style>
