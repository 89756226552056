<template>
  <div class="hidden-wallets">
    <div class="hidden-wallets__header" @click="visibleToggle">
      <img src="@/assets/images/hide2.svg" alt="" />
      <p class="header-text mb-0">Скрытые счета</p>
      <img
        class="arrow"
        :class="{ active: isVisible }"
        src="@/assets/images/arrow-icon.svg"
        alt=""
      />
    </div>
    <div class="hidden-wallets__content mt-4">
      <b-collapse id="collapse-4" v-model="isVisible">
        <slot name="content"></slot>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isVisible: false,
  }),
  methods: {
    visibleToggle() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-wallets {
  &__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-text {
      margin-left: 6px;
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .arrow {
      margin-left: 6px;
      transition: 0.2s ease-in;
      &.active {
        transform: rotate(180deg);
      }
    }
    @media (max-width: 576px) {
      justify-content: start;
      .header-text {
        font-size: 14px;
      }
      .arrow {
        width: 7px;
        height: 7px;
      }
    }
  }
}
</style>
