import CryptoJS from "crypto-js";

export const encodePassword = (password, wallet) => {
  const encryptedText = CryptoJS.AES.encrypt(wallet, password).toString();
  return encryptedText;
};

export const decodePassword = (str, password) => {
  const decryptedText = CryptoJS.AES.decrypt(str, password).toString(
    CryptoJS.enc.Utf8
  );
  if (decryptedText) {
    return JSON.parse(decryptedText);
  } else {
    return null;
  }
};
