import BaseEntity from "../entities/baseEntity";
import CurrencyNetworkEntity from "./currencyNetworkEntitry";

class NetworkCyrrencyEntity extends BaseEntity {
  constructor(data = {}) {
    super();
    this.id = data.id || 1;
    this.code = data.code || "";
    this.icon = data.icon || "";
    this.fullname = data.full_name || "";
    this.currency = data.currency_list || [new CurrencyNetworkEntity({})];
  }
}

export default NetworkCyrrencyEntity;
