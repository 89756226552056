<template>
  <div class="accept-data-mobile">
    <p class="param-name mb-0">
      {{ $t("accept_page.address") }} {{ fullName }}
    </p>
    <div
      class="copy-data d-flex align-items-center"
      v-clipboard:copy="address"
      v-clipboard:success="copyData"
    >
      <Clipboard
        :copyText="address"
        :isActiveCopy="isCopyData"
        @onCopySuccess="copyData"
        size="18"
      />
      <p class="address mb-0">{{ address }}</p>
    </div>
    <div class="accept-data">
      <b-row>
        <b-col cols="12" class="d-flex justify-content-center">
          <div class="qr-wrapper">
            <div class="qr-image">
              <QRCanvas class="qr-code" :options="qrData"></QRCanvas>
            </div>
          </div>
        </b-col>
        <b-col cols="12" class="mt-4">
          <b-row>
            <b-col cols="12">
              <div class="network">
                <p class="param-name mb-0">{{ $t("accept_page.network") }}</p>
                <p class="param-value mb-0">{{ network }}</p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-3">
              <div class="balance">
                <p class="param-name mb-0">{{ $t("accept_page.balance") }}</p>
                <p class="param-value balance mb-0">
                  {{ getRoundedAmountCrypto(balance) }}
                  {{ sliceNetworkCodeCurrency(currencyCode) }}
                </p>
              </div>
            </b-col>
            <b-col cols="6" class="mt-3">
              <div class="balance-usd">
                <p class="param-name mb-0">{{ $t("accept_page.in_usd") }}</p>
                <p class="param-value mb-0">
                  $ {{ getRoundedAmountFiat(balanceUsd) }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="buttons">
      <b-row>
        <b-col cols="3" @click="buy">
          <ButtonMobile :name="$t('accept_page.btn_buy')" variant="row">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3L3.04936 3.20987C3.91136 3.38227 4.55973 4.09732 4.6472 4.97203L4.8 6.5L5.7886 14.7383C5.90922 15.7435 6.76195 16.5 7.77435 16.5H16.7673C18.3733 16.5 19.7733 15.407 20.1628 13.8489L21.2855 9.35783C21.6485 7.90619 20.5505 6.5 19.0542 6.5H13.4H8.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M13 13.5H9"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="8.5" cy="20" r="1.5" fill="#4A40C6" />
              <circle cx="17.5" cy="20" r="1.5" fill="#4A40C6" />
            </svg>
          </ButtonMobile>
        </b-col>
        <b-col cols="3" @click="exchange">
          <ButtonMobile :name="$t('accept_page.btn_exchange')" variant="row">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3082 8.7459C18.7081 7.39808 17.747 6.24245 16.5311 5.40679C15.3152 4.57112 13.8919 4.08798 12.4185 4.01076C10.9452 3.93354 9.47917 4.26527 8.18258 4.96926C7.50896 5.33501 6.8947 5.79383 6.3565 6.32947"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M3.23187 5.51544L3.96128 8.67497C4.08551 9.2131 4.62246 9.54864 5.16059 9.4244L8.32013 8.69499"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M4.69161 15.2539C5.2917 16.6017 6.25282 17.7574 7.46872 18.593C8.68462 19.4287 10.1079 19.9118 11.5813 19.989C13.0546 20.0663 14.5207 19.7345 15.8172 19.0305C16.4318 18.6969 16.9969 18.2858 17.5 17.8095"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20.7681 18.4846L20.0387 15.325C19.9145 14.7869 19.3775 14.4514 18.8394 14.5756L15.6799 15.305"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </ButtonMobile>
        </b-col>
        <b-col cols="3" @click="send">
          <ButtonMobile :name="$t('accept_page.btn_send')" variant="row">
            <svg
              width="20"
              height="20"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.5L17 6.5C19.2091 6.5 21 8.29086 21 10.5L21 15.5C21 17.7091 19.2091 19.5 17 19.5L16 19.5L11 19.5L6 19.5L5 19.5C2.79086 19.5 1 17.7091 1 15.5L1 10.5C1 8.29086 2.79086 6.5 5 6.5L7 6.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M14 3.5L11.7071 1.20711C11.3166 0.816583 10.6834 0.816583 10.2929 1.20711L8 3.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11 1.5L11 13.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </ButtonMobile>
        </b-col>
        <b-col cols="3" class="d-flex justify-content-center">
          <a :href="historyLink" target="_blank">
            <ButtonMobile :name="$t('accept_page.history')" variant="row">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Frame 481686">
                  <g id="Coins">
                    <path
                      id="Vector"
                      d="M16.0833 4.20588C16.0833 5.42416 13.285 6.41176 9.83325 6.41176C6.38147 6.41176 3.58325 5.42416 3.58325 4.20588M16.0833 4.20588C16.0833 2.98761 13.285 2 9.83325 2C6.38147 2 3.58325 2.98761 3.58325 4.20588M16.0833 4.20588V14.7941C16.0833 16.0124 13.285 17 9.83325 17C6.38147 17 3.58325 16.0124 3.58325 14.7941V4.20588M16.0833 7.73529C16.0833 8.95357 13.285 9.94118 9.83325 9.94118C6.38147 9.94118 3.58325 8.95357 3.58325 7.73529M16.0833 11.2647C16.0833 12.483 13.285 13.4706 9.83325 13.4706C6.38147 13.4706 3.58325 12.483 3.58325 11.2647"
                      stroke="#4A40C6"
                    />
                  </g>
                </g>
              </svg>
            </ButtonMobile>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";
import ButtonMobile from "../UI/ButtonMobile.vue";
import Clipboard from "../UI/Clipboard.vue";
import {
  getHistoryAddressLink,
  getRoundedAmountCrypto,
  getRoundedAmountFiat,
  sliceNetworkCodeCurrency,
} from "@/utils";

export default {
  components: { Clipboard, ButtonMobile, QRCanvas },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    fullName: {
      type: String,
      default: "full_name",
    },
    address: {
      type: String,
      default: "address",
    },
    network: {
      type: String,
      default: "network",
    },
    balance: {
      type: Number,
      default: 0,
    },
    balanceUsd: {
      type: Number,
      default: 0,
    },
    currencyCode: {
      type: String,
      default: "code",
    },
    qrData: {
      type: Object,
    },
  },
  data: () => ({
    isCopyData: false,
  }),
  methods: {
    getRoundedAmountCrypto,
    getRoundedAmountFiat,
    sliceNetworkCodeCurrency,
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
    buy() {
      this.$emit("buy");
    },
    exchange() {
      this.$emit("exchange");
    },
    send() {
      this.$emit("send");
    },
  },
  computed: {
    networkName() {
      if (this.network === "TRX" || this.network.includes("TRC20")) {
        return "TRON";
      } else if (this.network === "ETH" || this.network.includes("ERC20")) {
        return "Ethereum";
      } else if (this.network === "BTC") {
        return "Bitcoin";
      } else if (this.network === "LTC") {
        return "Litecoin";
      }
    },
    historyLink() {
      return getHistoryAddressLink(this.networkName, this.address);
    },
  },
};
</script>

<style lang="scss" scoped>
.accept-data-mobile {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  .param-name {
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
  .param-value {
    color: #3c3c3c;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    &.balance {
      color: #4a40c6;
    }
  }
  .copy-data {
    margin-top: 10px;
    .address {
      margin-left: 6px;
      color: #4a40c6;
      font-size: 12px;
      font-weight: 300;
      line-height: 24px;
    }
  }
  .accept-data {
    margin-top: 20px;
    .qr-wrapper {
      width: 174px;
      height: 174px;
      border-radius: 6px;
      background: #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;
      .qr-image {
        border-radius: 10px;
        width: 146px;
        height: 146px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        .qr-code {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  .buttons {
    margin-top: 28px;
  }
}
</style>
