<template>
  <div class="user-data">
    <div class="settings-title d-flex align-items-center">
      <img src="../../assets/images/settings/user-icon.svg" alt="" />
      <h3 class="mb-0 ms-2 ms-sm-3">
        {{ $t("settings_page.user_data.title") }}
      </h3>
    </div>
    <div class="label mt-3 mt-sm-4">
      <h6 class="mb-0">{{ $t("settings_page.user_data.email_label") }}</h6>
    </div>
    <div class="user-data__email d-flex align-items-center mt-2 mt-sm-3">
      <div class="email-circle">{{ shortEmail }}</div>
      <div class="email address ms-2">{{ email }}</div>
    </div>
    <ChangePasswordAccount class="mt-5 mt-sm-4"></ChangePasswordAccount>
  </div>
</template>

<script>
import ChangePasswordAccount from "./ChangePasswordAccount.vue";

export default {
  components: { ChangePasswordAccount },
  props: {
    email: String,
  },
  computed: {
    shortEmail() {
      return this.email ? this.email.split("")[0].toLocaleUpperCase() : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-data {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 16px;
    border-radius: 12px 12px 0 0;
  }
}
</style>
