<template>
  <b-modal id="settings-multi-modal" centered hide-footer>
    <div class="settings-popup">
      <div class="settings-popup__header">
        <h3 class="header-title mb-0">Настройки</h3>
        <img
          @click="closeModal"
          class="close-btn"
          src="../../assets/images/close-modal.svg"
          alt=""
        />
      </div>
      <div class="settings-popup__content mt-md-4 mt-3">
        <p class="content-text mb-0">
          Укажите удобной для вас знак для введения валют и ссылки кошелька.
        </p>
        <div class="input-block mt-4">
          <div class="label">
            <h6>Разделитель</h6>
            <CCInput
              placeholder=";"
              v-model="separatorInput"
              class="send-address-input mt-2"
            ></CCInput>
          </div>
        </div>
        <div class="input-block mt-4">
          <div class="label">
            <h6>Указать сумму</h6>
            <CCInput
              v-model.number="totalAmountInput"
              placeholder="0.0002"
              class="send-address-input mt-2"
            ></CCInput>
          </div>
        </div>
        <div class="mt-4" @click="saveChanges">
          <CCButton
            fontWeight="medium"
            size="lg"
            class="d-none d-md-block px-4"
          >
            Применить
          </CCButton>
          <CCButton
            fontWeight="medium"
            size="md"
            class="d-block d-md-none px-4"
          >
            Применить
          </CCButton>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSendMultiStore } from "@/stores/sendMultiStore";
export default {
  data: () => ({
    oldSeparator: ";",
  }),
  methods: {
    ...mapActions(useSendMultiStore, [
      "setSeparator",
      "setTotalAmount",
      "setAddressesData",
      "setAddressList",
    ]),
    closeModal() {
      this.$bvModal.hide("settings-multi-modal");
    },
    saveChanges() {
      if (this.separator != "") {
        const regex = new RegExp(this.oldSeparator, "g");
        const replacesSeparator = this.addressesTypedData.replace(
          regex,
          this.separator
        );
        this.setAddressesData(replacesSeparator);
        this.oldSeparator = this.separator;
      }
      if (this.totalAmount != "") {
        if (this.addressList.length) {
          const newArr = this.addressList.map((row) => ({
            ...row,
            amount: this.totalAmount,
          }));
          this.setAddressList(newArr);
          const addressAmountString = this.addressList
            .map((row) => `${row.address}${this.separator}${row.amount}`)
            .join("\n");
          this.setAddressesData(addressAmountString);
        }
      }
      this.closeModal();
    },
  },
  computed: {
    ...mapState(useSendMultiStore, [
      "separator",
      "totalAmount",
      "addressesTypedData",
      "addressList",
    ]),
    separatorInput: {
      get() {
        return this.separator;
      },
      set(value) {
        this.setSeparator(value);
      },
    },
    totalAmountInput: {
      get() {
        return this.totalAmount;
      },
      set(value) {
        this.setTotalAmount(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-popup {
  .close-btn {
    cursor: pointer;
    width: 24px;
    width: 24px;
    @media (max-width: 768px) {
      width: 20px;
      width: 20px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-title {
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      color: #3c3c3c;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 36px;
      }
    }
  }
}
</style>
