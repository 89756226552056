<template>
  <div class="custom-checkbox">
    <input
      class="custom-checkbox__checkbox"
      type="checkbox"
      v-bind="$attrs"
      :checked="checked"
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  name: "checkbox",
  inheritAttrs: false,
  props: {
    checked: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
  },
  model: {
    prop: "checked",
    event: "change",
  },
  methods: {
    onChange(evt) {
      this.$emit("change", evt.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  width: 16px;
  height: 16px;
  &__checkbox {
    margin-right: 8px;
    position: relative;
    min-width: 16px;
    min-height: 16px;
    color: #3c3c3c;
    border: 1px solid #3c3c3c;
    border-radius: 3px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 1px;
      left: 5px;
      width: 5px;
      height: 10px;
      border-style: solid;
      border-color: #4a40c6;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: #fff;
      border-color: #4a40c6;
      background: #fff;

      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
