<template>
  <form>
    <div class="d-none d-sm-block">
      <div>
        <div class="label">
          <h6>{{ $t("exchange_page.you_give") }}</h6>
        </div>
        <SelectCurrencyWallet
          :isLoading="isLoadingWallets"
          :options="walletsList"
          :defaultCurrency="selectedCurrencyGive"
          :defaultWallet="selectedWallet"
          @selectCurrency="selectCurrencyGive"
          @selectWallet="selectWallet"
        />
        <div class="amount-input mt-3">
          <div class="amount-input__input">
            <CCInput
              @input="typeAmountGive($event)"
              v-model.trim="localInputGive"
              :placeholder="placeholderInputGive"
              :disabled="isLoadingAmountGive"
              size="lg"
            ></CCInput>
            <div class="amount-input__loader" v-if="isLoadingAmountGive">
              <img
                class="loader"
                width="16px"
                src="@/assets/images/loader_accent.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="label">
          <h6>{{ $t("exchange_page.you_receive") }}</h6>
        </div>
        <div class="amount-input mt-3">
          <div class="amount-input__input">
            <CCInput
              @input="typeAmountReceive($event)"
              v-model.trim="localInputReceive"
              :placeholder="placeholderInputReceive"
              :disabled="isLoadingAmountReceive"
              size="lg"
            ></CCInput>
            <div class="amount-input__loader" v-if="isLoadingAmountReceive">
              <img
                class="loader"
                width="16px"
                src="@/assets/images/loader_accent.svg"
                alt=""
              />
            </div>
          </div>
          <SelectCurrencySmall
            :z-index="9999"
            :tabindex="2"
            :isLoading="isLoadingCurrency"
            :options="listCurrencyReceive"
            :selectedOption="selectedCurrencyReceive"
            @change="selectCurrencyReceive"
            class="amount-select-currency"
          ></SelectCurrencySmall>
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none">
      <div>
        <div class="label">
          <h6>{{ $t("exchange_page.you_give") }}</h6>
        </div>
        <SelectCurrencyWallet
          :isLoading="isLoadingWallets"
          :options="walletsList"
          :defaultCurrency="selectedCurrencyGive"
          :defaultWallet="selectedWallet"
          @selectCurrency="selectCurrencyGive"
          @selectWallet="selectWallet"
        />
        <div class="amount-input mt-2">
          <div class="amount-input__input">
            <CCInput
              @input="typeAmountGive($event)"
              v-model.trim="localInputGive"
              :disabled="isLoadingAmountGive"
              :placeholder="placeholderInputGive"
              size="md"
            ></CCInput>
            <div class="amount-input__loader" v-if="isLoadingAmountGive">
              <img
                class="loader"
                width="16px"
                src="@/assets/images/loader_accent.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="label">
          <h6>{{ $t("exchange_page.you_receive") }}</h6>
        </div>
        <div class="amount-input">
          <div class="amount-input__input">
            <CCInput
              @input="typeAmountReceive($event)"
              v-model.trim="localInputReceive"
              :disabled="isLoadingAmountReceive"
              :placeholder="placeholderInputReceive"
              size="md"
            ></CCInput>
            <div class="amount-input__loader" v-if="isLoadingAmountReceive">
              <img
                class="loader"
                width="16px"
                src="@/assets/images/loader_accent.svg"
                alt=""
              />
            </div>
          </div>
          <SelectCurrencySmall
            :z-index="9998"
            :isLoading="isLoadingCurrency"
            :options="listCurrencyReceive"
            :selectedOption="selectedCurrencyReceive"
            @change="selectCurrencyReceive"
            class="amount-select-currency"
          ></SelectCurrencySmall>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import SelectCurrencySmall from "../UI/SelectCurrencySmall.vue";
import {
  isValidNumberFormat,
  validateAndFormatNumber,
} from "@/utils/validateInput";
import { debounce } from "@/utils/debounce";
import SelectCurrencyWallet from "../UI/SelectCurrencyWallet.vue";

export default {
  props: {
    inputAmountGive: String,
    inputAmountReceive: String,
    placeholderInputGive: String,
    placeholderInputReceive: String,
    isLoadingWallets: {
      type: Boolean,
      default: false,
    },
    isLoadingCurrency: {
      type: Boolean,
      default: false,
    },
    isLoadingAmountGive: {
      type: Boolean,
      default: false,
    },
    isLoadingAmountReceive: {
      type: Boolean,
      default: false,
    },
    listCurrencyReceive: {
      type: Array,
      default: [],
    },
    walletsList: {
      type: Array,
      default: [],
    },
    selectedWallet: {
      type: Object,
      default: {
        address: "",
        balance_usd: null,
        currency_list: [],
        id: null,
        network_object: {
          code: "",
          full_name: "",
          icon: "",
          id: null,
        },
        nickname: "",
        visibility_state: null,
      },
    },
    selectedCurrencyGive: {
      type: Object,
      default: {
        address_smart_contract: "",
        balance: null,
        balance_usd: null,
        code: "",
        count_decimals: null,
        full_code: "",
        full_name: "",
        icon_base: "",
        icon_network: "",
        icon_qr: "",
        id: null,
        is_active: null,
        is_stable_coin: null,
        name: "",
        order: null,
      },
    },
    selectedCurrencyReceive: {
      type: Object,
      default: {
        code: "",
        full_name: "",
        full_network: "",
        icon: "",
        id: null,
        is_popular: null,
        is_stablecoin: null,
        name: "",
        network: "",
      },
    },
  },
  data: () => ({
    localInputGive: "",
    localInputReceive: "",
  }),
  watch: {
    inputAmountGive(newVal) {
      this.localInputGive = newVal;
    },
    inputAmountReceive(newVal) {
      this.localInputReceive = newVal;
    },
  },
  methods: {
    selectWallet(option) {
      this.$emit("selectWallet", option);
    },
    selectCurrencyGive(option) {
      this.$emit("selectCurrencyGive", option);
    },
    selectCurrencyReceive(option) {
      this.$emit("selectCurrencyReceive", option);
    },
    typeAmountGive(value) {
      const isValid = isValidNumberFormat(value);
      const formattedValue = validateAndFormatNumber(value);
      this.localInputGive = formattedValue;
      if (isValid) this.debouncedEmitTypeAmountGive(formattedValue);
    },
    typeAmountReceive(value) {
      const isValid = isValidNumberFormat(value);
      const formattedValue = validateAndFormatNumber(value);
      this.localInputReceive = formattedValue;
      if (isValid) this.debouncedEmitTypeAmountReceive(formattedValue);
    },
  },
  components: { SelectCurrencySmall, SelectCurrencyWallet },
  created() {
    // Создаем "debounced" версию метода для эмиссии события
    this.debouncedEmitTypeAmountGive = debounce((formattedValue) => {
      this.$emit("typeAmountGive", formattedValue);
    }, 300);
    this.debouncedEmitTypeAmountReceive = debounce((formattedValue) => {
      this.$emit("typeAmountReceive", formattedValue);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.amount-input {
  position: relative;
  .amount-input__input {
    position: relative;
    input {
      padding-right: 150px !important;
    }
    .amount-input__loader {
      width: 50%;
      height: 35px;
      background: #fff;
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      left: 15px;
      @media (max-width: 576px) {
        top: 7px;
      }
    }
  }
  .amount-select-currency {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 22%;
    height: 100%;
    @media (max-width: 632px) {
      width: 103px;
    }
  }
}
</style>
