var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"currency-select",attrs:{"tabindex":_vm.tabindex},on:{"blur":_vm.closeSelect,"click":_vm.openSelect}},[_c('div',{staticClass:"currency-select__main"},[_c('div',{staticClass:"currency-select__main-content"},[_c('img',{staticClass:"main-content__icon",attrs:{"src":_vm.selectedOptionModel.icon,"alt":""}}),_c('div',{staticClass:"ms-1"},[(_vm.selectedOptionModel.is_stablecoin)?_c('h6',{staticClass:"main-content__network"},[_vm._v(" "+_vm._s(_vm.selectedOptionModel.network)+" ")]):_vm._e(),_c('h3',{staticClass:"main-content__name"},[_vm._v(_vm._s(_vm.selectedOptionModel.name))])])]),_c('img',{staticClass:"arrow-select",class:{
        open: _vm.isOpen,
      },attrs:{"src":require("@/assets/images/arrow-select.svg"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"currency-select__dropdown",style:({ 'z-index': _vm.zIndex })},[_c('div',{staticClass:"dropdown-header"},[_c('h3',{staticClass:"dropdown-title mb-0"},[_vm._v("Выберите валюту")]),_c('img',{staticClass:"dropdown-close",attrs:{"src":require("@/assets/images/close-icon.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeSelect.apply(null, arguments)}}})]),_c('div',{staticClass:"dropdown-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"search-input",attrs:{"type":"text"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),_c('img',{staticClass:"search-icon",attrs:{"src":require("@/assets/images/search.svg"),"alt":""}})]),_c('div',{staticClass:"dropdown-filters"},_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"filter-pill",class:{
          active: _vm.selectedFilter.value === filter.value,
        },on:{"click":function($event){return _vm.selectFilter(filter)}}},[_vm._v(" "+_vm._s(filter.name)+" ")])}),0),_c('div',{staticClass:"dropdown-currency"},_vm._l((_vm.filteredOptions),function(currency){return _c('div',{key:currency.id,staticClass:"currency-item",class:{
          active: currency.code === _vm.selectedOptionModel.code,
        },on:{"click":function($event){$event.stopPropagation();return _vm.selectOption(currency)}}},[_c('div',{staticClass:"currency-item__left"},[_c('img',{staticClass:"currency-item__icon",attrs:{"src":currency.icon}}),_c('h3',{staticClass:"currency-item__name"},[_vm._v(_vm._s(currency.name))]),(currency.is_stablecoin)?_c('div',{staticClass:"currency-item__network"},[_vm._v(" "+_vm._s(currency.network)+" ")]):_vm._e()]),_c('div',{staticClass:"currency-item__right"},[_c('div',{staticClass:"currency-item__fullname"},[_vm._v(_vm._s(currency.fullName))]),_c('div',{staticClass:"currency-item__fullnetwork"},[_vm._v(" "+_vm._s(currency.fullNetwork)+" ")])])])}),0)])]):_c('div',{staticClass:"currency-select loading",attrs:{"tabindex":_vm.tabindex}},[_c('div',{staticClass:"currency-select__main"},[_c('div',{staticClass:"currency-select__main-content"},[_c('b-skeleton',{staticClass:"button",attrs:{"width":"24px","height":"24px"}}),_c('div',{staticClass:"ms-1"},[_c('b-skeleton',{staticClass:"sm",attrs:{"width":"40px","height":"19px"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }