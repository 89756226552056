<template>
  <div class="calendar" :class="{ open: isCalendarVisible }">
    <img
      class="d-none d-lg-block"
      @click="toggleCalendar"
      v-if="!isCalendarVisible"
      src="@/assets/images/calendar-icon.svg"
      alt=""
    />
    <button
      @click="toggleCalendar"
      v-if="!isCalendarVisible"
      class="calendar-btn d-flex d-lg-none"
    >
      <span class="calendar-btn__text">Период</span>
      <img
        class="calendar-btn__icon"
        src="@/assets/images/calendar-icon.svg"
        alt=""
      />
    </button>
    <div v-if="isCalendarVisible" class="calendar__wrapper">
      <div class="calendar__header">
        <div
          class="header-content d-flex justify-content-between align-items-center"
        >
          <h2 class="calendar-title mb-0">Выберите дату</h2>
          <img
            @click="toggleCalendar"
            class="close-icon"
            src="@/assets/images/close-modal.svg"
            alt=""
          />
        </div>
      </div>
      <VDatePicker
        class="mt-3 d-none d-lg-block"
        v-if="!isVisibleMobile"
        v-show="isCalendarVisible"
        v-model="selectedDate"
        @input="onDateSelected"
        :columns="2"
        is-range
      ></VDatePicker>
      <div
        class="mt-3 d-flex d-lg-none justify-content-center"
        v-if="isVisibleMobile"
      >
        <VDatePicker
          v-show="isCalendarVisible"
          v-model="selectedDate"
          @input="onDateSelected"
          :columns="1"
          is-range
        >
        </VDatePicker>
      </div>
      <div class="calendar__footer">
        <div class="footer-content">
          <b-row align-v="center" align-h="between">
            <b-col cols="3">
              <button @click.stop="resetDate" class="btn-reset">
                Сбросить
              </button>
            </b-col>
            <b-col cols="5">
              <CCButton
                @click="toggleCalendar"
                fontWeight="medium"
                size="lg"
                class="px-4"
              >
                Сохранить
              </CCButton>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  data() {
    return {
      isCalendarVisible: false,
      selectedDate: null,
    };
  },
  methods: {
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
      if (window.innerWidth <= 992) {
        const body = document.querySelector("html, body");
        if (this.isCalendarVisible) {
          body.classList.add("opened-mobile-select");
        } else {
          body.classList.remove("opened-mobile-select");
        }
      }
    },
    resetDate() {
      this.selectedDate = null;
      this.$emit("onResetDate");
      this.isCalendarVisible = false;
    },
    formatDate(date) {
      if (date && date.start && date.end) {
        const start = format(new Date(date.start), "yyyy-MM-dd");
        const end = format(new Date(date.end), "yyyy-MM-dd");
        return { start, end };
      } else {
        this.$emit("onResetDate");
        return { start: null, end: null };
      }
    },
    onDateSelected() {
      this.$emit("onDateSelected", this.formatDate(this.selectedDate));
    },
  },
  computed: {
    isVisibleMobile() {
      return window.innerWidth <= 576;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-btn {
  width: 100%;
  min-width: 98px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}

.calendar {
  position: relative;
  z-index: 100;

  &.open {
    @media (max-width: 576px) {
      position: fixed;
      top: 30%;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  //   @media (max-width: 576px) {

  //   }
  &__wrapper {
    border-radius: 12px;
    background: #fff;

    .calendar__header {
      .header-content {
        padding: 16px;
        padding-bottom: 0;

        .calendar-title {
          color: #3c3c3c;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        .close-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }

    .calendar__footer {
      border-top: 2px solid #f0f0f0;

      .footer-content {
        padding: 16px;

        .btn-reset {
          color: #a5a5a5;
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          background: none;
          border: none;
        }
      }
    }
  }
}

.vc-title {
  font-family: "Gilroy" !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: theme-color("basic") !important;
}

.vc-container {
  font-family: "Gilroy" !important;
  border: none !important;
  background-color: #ffffff !important;
  --vc-white: theme-color("basic") !important;
  --vc-gray-200: #4a40c6 !important;
  --vc-gray-600: #4a40c6 !important;
  --gray-800: #3c3c3c !important;
  --blue-600: #4a40c6;
  --blue-200: #e9e8f2;
  --blue-700: #4a40c6;
  --font-semibold: 400;
  --font-bold: 400;
  --rounded: 100%;
  /* --vc-gray-900: #fff !important; */
}

.vc-weekday {
  font-size: 10px !important;
  font-weight: normal !important;
  color: theme-color("basic") !important;
}

.vc-day-layer ~ .vc-day-content {
  background: #4a40c6 !important;
  color: #fff;
}

.vc-day-content.vc-focusable {
  background: #4a40c6 !important;
}

.vc-day-content {
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  color: theme-color("basic");
}

.calendar-element {
  color: #fff !important;
}
</style>
