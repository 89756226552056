<template>
  <div
    v-if="!isLoading"
    class="select-filter"
    :tabindex="tabindex"
    @click="toggleSelect"
    @blur="closeSelect"
  >
    <div class="position-relative z-index-20">
      <div class="select-filter__selected">
        <div class="d-flex align-items-center">
          <div class="item-icon" v-if="selected.icon_base">
            <img :src="selected.icon_base" alt="" />
          </div>
          <p class="item-text mb-0">{{ selected.full_name }}</p>
        </div>
        <img
          class="arrow"
          :class="{
            active: isOpen,
          }"
          src="@/assets/images/arrow-icon.svg"
          alt=""
        />
      </div>
    </div>
    <div class="select-filter__items" v-show="isOpen">
      <div
        v-for="option in options"
        :key="option.id"
        @click.stop="selectItem(option)"
      >
        <div class="select-filter__item" v-if="option.id != selected.id">
          <div class="d-flex align-items-center">
            <div class="item-icon" v-if="option.icon_base">
              <img :src="option.icon_base" alt="" />
            </div>
            <p class="item-text mb-0">{{ option.full_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="select-filter" v-else>
    <b-skeleton class="sm" width="100%" height="32px"></b-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      default: [],
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    zIndex: {
      type: Number || String,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    closeSelect() {
      this.isOpen = false;
    },
    selectItem(item) {
      this.$emit("changeSelect", item);
      this.selected = item;
      this.closeSelect();
    },
  },
  computed: {
    selected: {
      get() {
        return this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0]
          : null;
      },
      set(value) {
        this.$emit("update:default", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.z-index-20 {
  z-index: 20;
}

.arrow {
  width: 8px;
  height: 8px;
  user-select: none;
  transition: 0.1s ease-in;
  &.active {
    transform: rotate(180deg);
  }
}
.select-filter {
  cursor: pointer;
  width: 100%;
  position: relative;
  .item-text {
    margin-left: 4px;
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
  .item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  &__selected {
    max-height: 32px;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f0f0;
    background: #f9f9f9;
  }
  &__items {
    width: 100%;
    background: #f9f9f9;
    position: absolute;
    z-index: 8;
    top: 25px;
    left: 0;
    border: 1px solid #f0f0f0;
    border-radius: 0 0 6px 6px;
    .select-filter__item {
      max-height: 32px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      border: 1px solid transparent;
      padding: 8px;
      &:first-child {
        margin-top: 7px;
      }
      &:hover {
        border: 1px solid #6a62ca;
        background: #fafaff;
      }
    }
  }
}
</style>
