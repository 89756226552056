<template>
  <div
    class="copy-data-card"
    v-clipboard:copy="data"
    v-clipboard:success="copyText"
  >
    <div class="card-top d-flex align-items-center">
      <div class="card-top__icon">
        <slot name="icon"></slot>
      </div>
      <h6 class="card-top__title mb-0">{{ title }}</h6>
    </div>
    <div class="card-bottom">
      <b-row class="w-100 justify-content-between">
        <b-col cols="10">
          <div class="card-bottom__data">
            {{ data }}
          </div>
        </b-col>
        <b-col cols="2" class="d-flex justify-content-end pe-0">
          <div class="card-bottom__clipboard">
            <Clipboard
              :copyText="data"
              :isActiveCopy="isCopyText"
              @onCopySuccess="copyText"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Clipboard from "../UI/Clipboard.vue";

export default {
  components: { Clipboard },
  data: () => ({
    isCopyText: false,
  }),
  methods: {
    copyText() {
      this.isCopyText = true;
      setTimeout(() => {
        this.isCopyText = false;
      }, 300);
    },
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    data: {
      type: String,
      default: "data",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-data-card {
  cursor: pointer;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
  .card-top {
    &__icon {
      width: 24px;
      height: 24px;
    }
    &__title {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.16px;
      color: #5f5f5f;
    }
  }
  .card-bottom {
    margin-top: 10px;
    margin-left: 32px;
    &__data {
      color: #4a40c6;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      word-break: break-all;
    }
  }
}
</style>
