<template>
  <div class="preparation-screen">
    <SelectWallet
      :isLoading="isLoadingWalletList"
      :wallets="btcAndLtcWallets"
      :defaultWallet="selectedWallet"
      :defaultCurrency="selectedCurrency"
      @onSelectCurrency="setSelectedCurrency"
      @onSelectWallet="setSelectedWallet"
      @onOpenSettings="openSettings"
    />
    <TypeRowsForm
      @onLoadCsv="loadCsv"
      @onOpenSettings="openSettings"
      class="mt-4"
    />
  </div>
</template>

<script>
import SelectWallet from "@/components/SendMulti/Preparation/SelectWallet.vue";
import TypeRowsForm from "@/components/SendMulti/Preparation/TypeRowsForm.vue";
import { mapActions, mapState } from "pinia";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { useSendMultiStore } from "@/stores/sendMultiStore";
import { useWalletStore } from "@/stores/wallet";

export default {
  components: {
    SelectWallet,
    TypeRowsForm,
  },
  methods: {
    ...mapActions(useWalletStore, ["getAllWallets"]),
    ...mapActions(useSendTransferStore, [
      "setSelectedWallet",
      "setSelectedCurrency",
    ]),
    openSettings() {
      this.$bvModal.show("settings-multi-modal");
    },
    async setSelectedWalletAndCurrency() {
      if (this.dataWalletToSend != null) {
        const needWallet = this.btcAndLtcWallets.find(
          (wallet) => wallet.address == this.dataWalletToSend.address
        );
        const needCurrency = needWallet.currency_list.find(
          (currency) => currency.code == this.dataWalletToSend.code
        );
        this.setSelectedWallet(needWallet);
        this.setSelectedCurrency(needCurrency);
      } else {
        this.setSelectedWallet(this.btcAndLtcWallets[0]);
        this.setSelectedCurrency(this.btcAndLtcWallets[0].currency_list[0]);
      }
    },
    loadCsv(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        const sendMultiStore = useSendMultiStore()
        function parseCsv(csvData) {
          // Разделение входных данных на строки
          const rows = csvData.trim().split("\n");
          const data = [];

          // Проход по каждой строке
          for (let i = 0; i < rows.length; i++) {
            const rowData = rows[i].split(";");
            if (rowData.length === 2) {
              // Убедимся, что в строке два элемента
              const obj = {
                address: rowData[0].trim(), // Присваиваем первый элемент как адрес
                amount: parseFloat(rowData[1].trim()), // Второй элемент как сумму, преобразованную в число
              };
              data.push(obj);
            }
          }
          return data;
        }
        const csvData = event.target.result;
        const data = parseCsv(csvData);
        sendMultiStore.setAddressesData(csvData)
        sendMultiStore.setAddressList(data)
      };
      console.log(file);
      reader.readAsText(file);
    },
  },
  computed: {
    ...mapState(useSendMultiStore, ["steps", "selectedStep"]),
    ...mapState(useWalletStore, ["walletsList", "isLoadingWalletList"]),
    ...mapState(useSendTransferStore, [
      "dataWalletToSend",
      "selectedWallet",
      "selectedCurrency",
    ]),
    btcAndLtcWallets() {
      return this.walletsList.filter(
        (wallet) =>
          wallet.network_object.code === "BTC" ||
          wallet.network_object.code === "LTC"
      );
    },
  },
  async mounted() {
    await this.getAllWallets();
    await this.setSelectedWalletAndCurrency();
  },
};
</script>

<style></style>
