<template>
  <div class="history-transactions">
    <div class="h-100 d-flex flex-column justify-content-between">
      <div>
        <div class="d-flex align-items-center">
          <img
            class="history-transactions__title-icon"
            src="@/assets/images/history.svg"
            alt=""
          />
          <h2 class="history-transactions__title mb-0 ms-2 ms-sm-3">
            {{ $t("history.title") }}
          </h2>
        </div>
        <div class="history-transactions__list pt-2" v-if="!isLoading">
          <div v-if="historyItems.length">
            <div
              v-for="item in historyItems"
              :key="item.id"
              @click="selectItem(item)"
            >
              <HistoryItem
                :type="variant"
                :inProcess="item.in_process"
                :amount="item.amount"
                :duration="item.duration"
                :quantity="item.number_service_units"
                :status="item.status"
                :currency="item.currency"
                :date="item.creat_at"
              />
            </div>
          </div>
          <div v-else>
            <p class="error-text mb-0 mt-4">{{ $t("history.title_empty") }}</p>
          </div>
        </div>
        <div class="history-transactions__list pt-2" v-else>
          <HistoryItem v-for="index in 5" :key="index" loading />
        </div>
      </div>
      <div class="d-flex justify-content-center justify-content-sm-end mt-3">
        <slot name="pagination"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryItem from "../HistoryCards/HistoryItem.vue";

export default {
  props: {
    variant: {
      type: String,
      default: "energy",
      validator: (value) => {
        return ["energy", "aml"].includes(value);
      },
    },
    historyItems: {
      type: Array,
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { HistoryItem },
  methods: {
    selectItem(item) {
      this.$emit("selectItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.history-transactions {
  height: 479px;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  @media (max-width: 576px) {
    padding: 16px;
  }
  &__title-icon {
    width: 40px;
    height: 40px;
    @media (max-width: 576px) {
      width: 24px;
      height: 24px;
    }
  }
  &__title {
    color: #3c3c3c;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    @media (max-width: 576px) {
      font-size: 20px;
      line-height: 22px;
    }
  }
}
</style>
