<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <MessagesList/>
  </div>
</template>

<script>
import MainLayout from "./layouts/MainLayout.vue";
import AuthLayout from "./layouts/AuthLayout.vue";
import MessagesList from "./components/UI/MessagesList.vue";

export default {
  components: {
    MainLayout,
    AuthLayout,
    MessagesList
},
  computed: {
    layout() {
      return this.$route.meta.layout + "-layout";
    },
  },
};
</script>

<style></style>
