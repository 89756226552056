<template>
  <div class="accept-data">
    <b-row class="gx-5 gy-2" align-v="center">
      <b-col cols="12">
        <b-row class="d-flex flex-column">
          <b-col class="d-flex align-items-center justify-content-between">
            <span class="accept-data__param-name">
              {{ $t("accept_page.address") }}
              {{ fullName }}
            </span>
            <a
              target="_blank"
              class="accept-data__param-value history d-flex align-items-center"
              :href="historyLink"
            >
              <span class=""> {{ $t("accept_page.history") }}</span>
              <img
                class="ms-2"
                width="24px"
                height="24px"
                src="@/assets/images/history-stack-icon.svg"
                alt="history-stack-icon"
              />
            </a>
          </b-col>
          <b-col class="my-1">
            <!-- <a class="link link-back" href="#"> -->
            <div
              class="copy-data pointer d-none d-md-flex align-items-center"
              v-clipboard:copy="address"
              v-clipboard:success="copyData"
            >
              <Clipboard
                :copyText="address"
                @onCopySuccess="copyData"
                :isActiveCopy="isCopyData"
              />
              <span class="ms-2 accept-data__address">
                {{ address }}
              </span>
            </div>
            <div
              class="copy-data pointer d-flex d-md-none align-items-center"
              v-clipboard:copy="address"
              v-clipboard:success="copyData"
            >
              <Clipboard
                size="18"
                :copyText="address"
                @onCopySuccess="copyData"
                :isActiveCopy="isCopyData"
              />
              <span class="ms-2 accept-data__address">
                {{ address }}
              </span>
            </div>
            <!-- </a> -->
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row class="mt-2 d-flex flex-column">
          <b-col>
            <span class="accept-data__param-name">
              {{ $t("accept_page.network") }}
            </span>
          </b-col>
          <b-col class="my-1">
            <span class="accept-data__param-value">{{ network }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="d-flex">
        <b-row class="d-flex flex-column">
          <b-col>
            <span class="accept-data__param-name">
              {{ $t("accept_page.balance") }}
            </span>
          </b-col>
          <b-col class="my-1">
            <span class="accept-data__param-value balance">
              {{ getRoundedAmountCrypto(balance) }}
              {{ sliceNetworkCodeCurrency(currencyCode) }}
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row class="mt-2 d-flex flex-column">
          <b-col>
            <span class="accept-data__param-name">
              {{ $t("accept_page.in_usd") }}</span
            >
          </b-col>
          <b-col class="my-1">
            <span class="accept-data__param-value"
              >$ {{ getRoundedAmountFiat(balanceUsd) }}</span
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="accept-data__buttons mt-4 mt-xl-auto">
      <b-col lg="4">
        <CCButton
          @click="buy"
          size="lg"
          variant="secondary"
          fontWeight="medium"
        >
          <div
            class="d-flex justify-content-center align-items-center px-4 w-100"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3L3.04936 3.20987C3.91136 3.38227 4.55973 4.09732 4.6472 4.97203L4.8 6.5L5.7886 14.7383C5.90922 15.7435 6.76195 16.5 7.77435 16.5H16.7673C18.3733 16.5 19.7733 15.407 20.1628 13.8489L21.2855 9.35783C21.6485 7.90619 20.5505 6.5 19.0542 6.5H13.4H8.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M13 13.5H9"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="8.5" cy="20" r="1.5" fill="#4A40C6" />
              <circle cx="17.5" cy="20" r="1.5" fill="#4A40C6" />
            </svg>

            <span class="ms-2">{{ $t("accept_page.btn_buy") }}</span>
          </div>
        </CCButton>
      </b-col>
      <b-col lg="4" class="mt-2 mt-lg-0">
        <CCButton
          @click="exchange"
          size="lg"
          variant="secondary"
          fontWeight="medium"
        >
          <div
            class="d-flex align-items-center justify-content-center px-4 w-100"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3082 8.7459C18.7081 7.39808 17.747 6.24245 16.5311 5.40679C15.3152 4.57112 13.8919 4.08798 12.4185 4.01076C10.9452 3.93354 9.47917 4.26527 8.18258 4.96926C7.50896 5.33501 6.8947 5.79383 6.3565 6.32947"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M3.23187 5.51544L3.96128 8.67497C4.08551 9.2131 4.62246 9.54864 5.16059 9.4244L8.32013 8.69499"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M4.69161 15.2539C5.2917 16.6017 6.25282 17.7574 7.46872 18.593C8.68462 19.4287 10.1079 19.9118 11.5813 19.989C13.0546 20.0663 14.5207 19.7345 15.8172 19.0305C16.4318 18.6969 16.9969 18.2858 17.5 17.8095"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20.7681 18.4846L20.0387 15.325C19.9145 14.7869 19.3775 14.4514 18.8394 14.5756L15.6799 15.305"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            <span class="ms-2">{{ $t("accept_page.btn_exchange") }}</span>
          </div>
        </CCButton>
      </b-col>
      <b-col lg="4" class="mt-2 mt-lg-0">
        <CCButton
          @click="send"
          size="lg"
          variant="secondary"
          fontWeight="medium"
        >
          <div
            class="d-flex align-items-center justify-content-center px-4 w-100"
          >
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.5L17 6.5C19.2091 6.5 21 8.29086 21 10.5L21 15.5C21 17.7091 19.2091 19.5 17 19.5L16 19.5L11 19.5L6 19.5L5 19.5C2.79086 19.5 1 17.7091 1 15.5L1 10.5C1 8.29086 2.79086 6.5 5 6.5L7 6.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M14 3.5L11.7071 1.20711C11.3166 0.816583 10.6834 0.816583 10.2929 1.20711L8 3.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11 1.5L11 13.5"
                stroke="#4A40C6"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            <span class="ms-2">{{ $t("accept_page.btn_send") }}</span>
          </div>
        </CCButton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  getHistoryAddressLink,
  getRoundedAmountCrypto,
  getRoundedAmountFiat,
  sliceNetworkCodeCurrency,
} from "@/utils";
import Clipboard from "../UI/Clipboard.vue";

export default {
  components: { Clipboard },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    fullName: {
      type: String,
      default: "full_name",
    },
    address: {
      type: String,
      default: "address",
    },
    network: {
      type: String,
      default: "network",
    },
    balance: {
      type: Number,
      default: 0,
    },
    balanceUsd: {
      type: Number,
      default: 0,
    },
    currencyCode: {
      type: String,
      default: "code",
    },
  },
  data: () => ({
    isCopyData: false,
  }),
  methods: {
    getRoundedAmountCrypto,
    getRoundedAmountFiat,
    sliceNetworkCodeCurrency,
    copyData() {
      this.isCopyData = true;
      setTimeout(() => {
        this.isCopyData = false;
      }, 300);
    },
    buy() {
      this.$emit("buy");
    },
    exchange() {
      this.$emit("exchange");
    },
    send() {
      this.$emit("send");
    },
  },
  computed: {
    networkName() {
      if (this.network === "TRX" || this.network.includes("TRC20")) {
        return "TRON";
      } else if (this.network === "ETH" || this.network.includes("ERC20")) {
        return "Ethereum";
      } else if (this.network === "BTC") {
        return "Bitcoin";
      } else if (this.network === "LTC") {
        return "Litecoin";
      }
    },
    historyLink() {
      return getHistoryAddressLink(this.networkName, this.address);
    },
  },
};
</script>

<style lang="scss" scoped>
.accept-data {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  &__param-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.12px;
    color: #5f5f5f;
  }
  &__param-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
    &.balance {
      font-weight: 500;
      color: #4a40c6;
      letter-spacing: 0.16px;
    }
    &.history {
      color: #4a40c6;
    }
  }
  &__address {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #4a40c6;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: normal;
    }
  }
}
</style>
