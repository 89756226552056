<template>
  <div>
    <CCCard class="d-none d-sm-block" padding="card24">
      <div class="d-flex justify-content-between align-items-center">
        <span class="send-transfer__label">{{
          $t("energy_page.confirm_rent.title")
        }}</span>
        <LinkBackPage @click="setStepEnergyBack" />
      </div>
      <h3 class="m-0 mt-2 send-transfer__balance">{{ countEnergy }} ENERGY</h3>
      <b-row class="send-transfer-info d-flex flex-column">
        <b-col class="send-transfer-info__item">
          <div class="send-transfer-info__param-name">
            {{ $t("energy_page.confirm_rent.amount_label") }}
          </div>
          <div class="send-transfer-info__param-value">
            {{ totalAmount }} TRX
          </div>
        </b-col>
        <b-col class="send-transfer-info__item">
          <div class="send-transfer-info__param-name">
            {{ $t("energy_page.confirm_rent.address") }}
          </div>
          <div class="send-transfer-info__param-value">
            <span class="link">{{ address }}</span>
          </div>
        </b-col>
        <b-col class="send-transfer-info__item">
          <b-row align-v="center">
            <b-col cols="6">
              <div class="send-transfer-info__param-name">
                {{ $t("energy_page.confirm_rent.fee") }}
              </div>
              <div class="send-transfer-info__param-value">1 TRX</div>
            </b-col>
            <b-col cols="6">
              <div class="send-transfer-info__param-name">
                {{ $t("energy_page.confirm_rent.network") }}
              </div>
              <div class="send-transfer-info__param-value">TRON</div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- footer -->
      <div class="send-transfer__footer">
        <div class="send-transfer__line"></div>
        <b-row align-v="center">
          <b-col cols="6" class="color-light send-transfer__step">
            {{ $t("energy_page.step") }} 2 из 3
          </b-col>
          <b-col cols="6" class="ms-auto">
            <CCButton
              @click="createTransaction"
              :disable="isLoadingCreateTransaction"
              fontWeight="medium"
              size="lg"
              class="px-4"
            >
              {{ $t("energy_page.btn_next") }}
            </CCButton>
          </b-col>
        </b-row>
      </div>
    </CCCard>
    <div class="d-block d-sm-none">
      <div class="d-flex justify-content-between">
        <span class="send-transfer__label">{{
          $t("energy_page.confirm_rent.title")
        }}</span>
        <div class="send-transfer__step">
          {{ $t("energy_page.step") }} 2 из 3
        </div>
      </div>
      <h3 class="m-0 mt-2 send-transfer__balance">{{ countEnergy }} ENERGY</h3>
      <div class="card-mobile mt-3">
        <b-row>
          <b-col cols="12">
            <p class="card-mobile__param-name mb-0">
              {{ $t("energy_page.amount_label2") }}
            </p>
            <p class="card-mobile__param-value mb-0">{{ totalAmount }} TRX</p>
          </b-col>
          <b-col cols="12" class="mt-3">
            <p class="card-mobile__param-name mb-0">
              {{ $t("energy_page.confirm_rent.address") }}
            </p>
            <p class="card-mobile__param-value address mb-0">
              {{ address }}
            </p>
          </b-col>
          <b-col cols="6" class="mt-3">
            <p class="card-mobile__param-name mb-0">
              {{ $t("energy_page.confirm_rent.fee") }}
            </p>
            <p class="card-mobile__param-value mb-0">1 TRX</p>
          </b-col>
          <b-col cols="6" class="mt-3">
            <p class="card-mobile__param-name mb-0">
              {{ $t("energy_page.confirm_rent.network") }}
            </p>
            <p class="card-mobile__param-value mb-0">TRON</p>
          </b-col>
        </b-row>
      </div>
      <div class="mt-4">
        <CCButton
          @click="createTransaction"
          :disable="isLoadingCreateTransaction"
          fontWeight="medium"
          size="md"
          class="px-4"
        >
          {{ $t("energy_page.confirm_rent.btn_send") }}
        </CCButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import LinkBackPage from "../UI/LinkBackPage.vue";
import { useEnergyStore } from "@/stores/energyStore";
import { useSendTransferStore } from "@/stores/sendTransferStore";
import { ServiceController } from "@/core/system/controllers";
import { useWalletStore } from "@/stores/wallet";
import { useInterfaceStore } from "@/stores/interfaceStore";
export default {
  components: {
    LinkBackPage,
  },
  data: () => ({
    isLoadingCreateTransaction: false,
  }),
  methods: {
    ...mapActions(
      () => useEnergyStore(),
      ["setStepEnergy", "setOrderId", "setStepEnergyBack"]
    ),
    ...mapActions(() => useWalletStore(), ["setTransactionData"]),
    ...mapActions(() => useInterfaceStore(), ["showMessage"]),
    async createTransaction() {
      this.isLoadingCreateTransaction = true;
      const payload = {
        service_id: 2,
        count: this.countEnergy,
        wallet_id: this.currencyTrx.id,
        amount_pay: this.totalAmount,
        duration: this.selectedPillTime.valueServer,
      };
      try {
        const resp = await ServiceController.serviceCreate(payload);
        this.setOrderId(resp.id);
        const newTransactionData = {
          selectedNetwork: "TRON",
          amount: String(this.totalAmount),
          fromAddress: this.currencyTrx.address,
          toAddress: this.address,
          currency: "TRX",
        };
        this.setTransactionData(newTransactionData);
        this.setStepEnergy(3);
      } catch (error) {
        console.log(error);
        this.showMessage("danger", this.$t("messages.error_global"));
      } finally {
        this.isLoadingCreateTransaction = false;
      }
    },
  },
  computed: {
    ...mapState(
      () => useEnergyStore(),
      ["address", "totalAmount", "countEnergy", "selectedPillTime"]
    ),
    ...mapState(() => useWalletStore(), ["transactionData"]),
    ...mapState(() => useSendTransferStore(), ["optionsCurrency"]),
    currencyTrx() {
      return JSON.parse(localStorage.getItem("tron_wallet"));
    },
  },
};
</script>
<style lang="scss" scoped>
.break-all {
  word-break: break-all;
}

.card-mobile {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  &__param-name {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: #3c3c3c;
  }
  &__param-value {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #3c3c3c;
    line-height: 24px;
    &.address {
      font-weight: 300;
      color: #4a40c6;
    }
  }
}
</style>
