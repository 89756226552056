import { WalletController } from "@/core/system/controllers";
import { defineStore } from "pinia";
import { useWalletStore } from "./wallet";

export const useSendTransferStore = defineStore("sendTransfer", {
  state: () => ({
    isLoadingWallets: false,
    step: 1,
    stepAddressBook: "list",
    searchQueryContacts: "",
    isLoadingContactsList: false,
    isLoadingContactDetail: false,
    formAddContact: {
      nickname: "",
      address: "",
      network: null,
    },
    contactsList: [],
    selectedContact: {
      address: "",
      create_at: "",
      id: null,
      is_active: null,
      name: "",
      network_object: {
        code: "",
        full_name: "",
        icon: "",
        id: null,
      },
      user: "",
    },
    dataWalletToSend: null,
    optionsCurrency: [],
    selectedCurrency: {
      address_smart_contract: "",
      balance: null,
      balance_usd: null,
      code: "",
      count_decimals: null,
      full_code: "",
      full_name: "",
      icon_base: "",
      icon_network: "",
      icon_qr: "",
      id: 3,
      is_active: true,
      is_stable_coin: false,
      name: "",
      order: null,
    },
    selectedWallet: {
      address: "",
      balance_usd: null,
      currency_list: [],
      id: null,
      network_object: {
        code: "",
        full_name: "",
        icon: "",
        id: null,
      },
      nickname: "",
    },
    giveCurrencyName: "BTC",
    address: "",
    amount: "",
    currency: null,
    currencyUsd: null,
    contactsList: [],
    txInfo: null,
    isSendTx: false,
    lastTransactionsList: [],
    isLoadingFee: false,
    fee: {
      total: 1,
      gasLimit: null,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    },
    variantsFee: [],
    selectedVariantFee: {
      mode: "",
      modeName: "",
    },
    maxBaseFeeGwei: "",
    priorityFeeGwei: "",
    feeSatoshi: "",
  }),
  actions: {
    setIsLoadingFee(boolean) {
      this.$state.isLoadingFee = boolean;
    },
    setVariantsFee(variants) {
      this.$state.variantsFee = variants;
    },
    setSelectedVariantFee(variant) {
      this.$state.selectedVariantFee = variant;
    },
    setMaxFeeGwei(fee) {
      this.$state.maxBaseFeeGwei = fee;
    },
    setPriorityFeeGwei(fee) {
      this.$state.priorityFeeGwei = fee;
    },
    setFeeSatoshi(fee) {
      this.$state.feeSatoshi = fee;
    },
    setDataWalletToSend(data) {
      this.$state.dataWalletToSend = data;
    },
    setAddress(address) {
      this.$state.address = address;
    },
    setIsSendTx(isSendTx) {
      this.$state.isSendTx = isSendTx;
    },
    setAmount(amount) {
      this.$state.amount = amount;
    },
    setFee(newFee) {
      this.$state.fee.total = newFee.total;
      this.$state.fee.gasLimit = newFee.gasLimit;
      this.$state.fee.maxPriorityFeePerGas = newFee.maxPriorityFeePerGas;
      this.$state.fee.maxFeePerGas = newFee.maxFeePerGas;
    },
    setGiveCurrencyName(currencyName) {
      this.$state.giveCurrencyName = currencyName;
    },
    setSelectedCurrency(currency) {
      this.$state.selectedCurrency = currency;
    },
    setSelectedWallet(wallet) {
      this.$state.selectedWallet = wallet;
      this.getLastTransactions();
    },
    setStep(step) {
      this.$state.step = step;
    },
    setAddressAddContact(address) {
      this.formAddContact.address = address;
    },
    setNicknameAddContact(nickname) {
      this.formAddContact.nickname = nickname;
    },
    setNetworkAddContact(network) {
      this.formAddContact.network = network;
    },
    setStepAddressBook(step) {
      this.stepAddressBook = step;
    },
    setSearchQueryContacts(query) {
      this.searchQueryContacts = query;
    },
    setStepBack() {
      this.step -= 1;
    },
    clearTransaction() {
      this.setAddress("");
      this.setAmount("");
      this.setStep(1);
    },
    async getContactsList() {
      this.isLoadingContactsList = true;
      const payload = {
        limit: 10000000,
        offset: 0,
        q: this.searchQueryContacts,
      };
      try {
        const response = await WalletController.getAddressBookList(payload);
        this.contactsList = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingContactsList = false;
      }
    },
    async getContactDetail(id) {
      const payload = {
        address_book_id: id,
      };
      const response = await WalletController.getAddressContactDetail(payload);
      this.selectedContact = response;
    },
    async removeContact(id) {
      const payload = {
        address_book_id: id,
      };
      await WalletController.removeContactFromAddressList(payload);
      this.getContactsList();
    },
    async getContactDetail(id) {
      this.isLoadingContactDetail = true;
      const payload = {
        address_book_id: id,
      };
      try {
        const response = await WalletController.getAddressContactDetail(
          payload
        );
        this.selectedContact = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingContactDetail = false;
      }
    },
    async getLastTransactions() {
      const payload = {
        network_code: this.selectedWallet.network_object.code,
      };
      const response = await WalletController.getHistoryList(payload);
      this.lastTransactionsList = response;
    },
    async addTransactionToHistory() {
      const walletStore = useWalletStore();
      const payload = {
        to_address: this.address,
        network_id: this.selectedWallet.network_object.id,
        wallet_id: this.selectedWallet.id,
        tx_id: walletStore.txId,
        currency_code: this.selectedCurrency.code,
      };
      try {
        await WalletController.addHistoryItem(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async updateBalanceWallet() {
      const payload = {
        wallet_network_id: this.selectedWallet.id,
      };
      await WalletController.updateBalance(payload);
    },
    // async getAllCurrencyBalance() {
    //   this.isLoadingWallets = true;
    //   let response = [];
    //   try {
    //     const result = await WalletController.getWallets();
    //     result.forEach((obj) => {
    //       let data = {
    //         id: obj.id,
    //         address: obj.address,
    //         icon: obj.currencyObject.icon_network,
    //         title: obj.currencyObject.name,
    //         subtitle: obj.currencyObject.network_object.full_name,
    //         code: obj.currencyObject.code,
    //         amount: obj.balance.toString(),
    //       };
    //       response.push(data);
    //     });
    //     this.optionsCurrency = response;
    //     if (this.idWalletToSend != null) {
    //       const selectedWalletToSend = this.optionsCurrency.find(
    //         (currency) => currency.id === this.idWalletToSend
    //       );
    //       this.selectedCurrency = selectedWalletToSend;
    //     } else {
    //       this.selectedCurrency = this.optionsCurrency[0];
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.isLoadingWallets = false;
    //   }
    // },
  },
  getters: {
    gasLimitEth: (state) => {
      return state.selectedCurrency.code === "ETH" ? 21000 : 100000;
    },
  },
});
