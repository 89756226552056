<template>
  <div class="steps-info">
    <div
      class="step-item"
      v-for="(step, index) in steps"
      :key="step.id"
      :class="{ 'ms-md-2': index > 0, active: step.id === selectedStep.id }"
    >
      <img v-if="copletedSteps.includes(step.id)" src="@/assets/images/success-marker2.svg" alt="" />
      <div v-else class="step-item__circle">{{ step.id }}</div>
      <p class="step-item__text mb-0">{{ step.name }}</p>
      <div
        class="step-item__line"
        :class="`line${index + 1}`"
        v-if="index < steps.length - 1"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: [],
    },
    copletedSteps: {
      type: Array,
      default: [],
    },
    selectedStep: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-info {
  width: 100%;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    max-height: none;
    justify-content: space-between;
  }
  .step-item {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &__circle {
      width: 32px;
      height: 32px;
      background: #e9e8f2;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4a40c6;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    &__text {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c3c;
      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
    &__line {
      margin-left: 12px;
      width: 82px;
      height: 2px;
      border-radius: 20px;
      background: #f0f0f0;
      @media (max-width: 768px) {
        background: #cdcdcd;
        margin-left: 0;
        position: absolute;
        height: 1px;
        top: 16px;
        &.line1 {
          left: 85%;
        }
        &.line2 {
          left: 74%;
        }
      }
      @media (max-width: 410px) {
        width: 68px;
        &.line1 {
          left: 80%;
        }
        &.line2 {
          left: 70%;
        }
      }
    }
    &.active {
      .step-item__circle {
        background: #4a40c6;
        color: #fff;
      }
    }
  }
}
</style>
