import { UserController } from "@/core/system/controllers";
import UserEntitry from "@/core/system/entities/userEntity";
import router from "@/router";
import { defineStore } from "pinia";
import { useInterfaceStore } from "./interfaceStore";
import i18n from "@/i18n";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    isLoadingUser: false,
    user: new UserEntitry(),
    is_auth: !!localStorage.getItem("token"),
  }),
  actions: {
    setIsAuth(boolean) {
      this.is_auth = boolean;
    },
    async setCurrenctUser() {
      this.isLoadingUser = true;
      const interfaceStore = useInterfaceStore();
      try {
        this.user = await UserController.getCurrentUser();
        localStorage.setItem("hasLoggedSystem", "true");
      } catch (error) {
        if (error.name == "AuthRequiredError") {
          if (localStorage.getItem("hasLoggedSystem")) {
            interfaceStore.showMessage(
              "warning",
              i18n.t("messages.session_end")
            );
            localStorage.removeItem("hasLoggedSystem");
          } else {
            interfaceStore.showMessage("danger", i18n.t("messages.error_auth"));
          }
          localStorage.removeItem("token");
          router.push("/login");
        } else {
          console.log("Произошла другая ошибка: ", error.message);
          // Обработка других типов ошибок
        }
      } finally {
        this.isLoadingUser = false;
      }
    },
  },
  getters: {},
});
