<template>
  <div class="energy-buy-page">
    <HeaderBackLink class="d-none d-sm-block" title="Аренда Energy">
      <img
        class="download-icon"
        src="@/assets/images/upload-icon.svg"
        alt="upload-icon"
      />
    </HeaderBackLink>
    <HeaderMobile title="Аренда Energy" class="d-block d-sm-none">
      <img
        v-if="stepEnergy > 1"
        @click="setStepEnergyBack"
        width="24px"
        height="24px"
        slot="button-left"
        src="../assets/images/back-icon.svg"
        alt=""
      />
      <router-link v-else slot="button-left" to="/">
        <img width="33px" src="../assets/images/logo-icon.svg" alt="" />
      </router-link>
      <router-link
        v-if="stepEnergy === 1"
        slot="button-right"
        to="/create-wallet"
      >
        <img
          width="24px"
          src="../assets/images/create-wallet-icon.svg"
          alt=""
        />
      </router-link>
    </HeaderMobile>
    <b-row class="mt-sm-4">
      <b-col cols="12" lg="6">
        <TypeForm :step="stepEnergy" v-if="stepEnergy === 1" />
        <ConfirmRent v-if="stepEnergy === 2" />
        <CCCard class="d-none d-sm-block" v-if="stepEnergy === 3">
          <ConfirmTransaction
            @submitForm="confirmBuyEnergy"
            @cancel="clearTransaction"
          />
        </CCCard>
        <ConfirmTransaction
          v-if="stepEnergy === 3"
          class="d-block d-sm-none"
          @submitForm="confirmBuyEnergy"
          @cancel="clearTransaction"
        />
        <CCCard v-if="stepEnergy === 4">
          <LoadingScreen />
        </CCCard>
        <CCCard class="d-none d-sm-block" v-if="stepEnergy === 5">
          <StatusTransaction />
        </CCCard>
        <StatusTransaction v-if="stepEnergy === 5" class="d-block d-sm-none" />
      </b-col>
      <b-col cols="12" lg="6" class="mt-4 mt-lg-0 mb-4 mb-lg-0">
        <HistoryList
          :isLoading="isLoadingEnergyPage"
          :historyItems="historyList"
          @selectItem="pasteDataFromHistory"
        >
          <Pagination
            v-if="!isLoadingEnergyPage"
            slot="pagination"
            :currentPage="currentPageHistory"
            :totalPages="totalPagesHistory"
            @changePage="setCurrentPage"
          />
        </HistoryList>
      </b-col>
    </b-row>
    <SelectWalletPopup
      variant="energy"
      currencyTron="TRX"
      @confirm="confirmWallet"
    />
  </div>
</template>

<script>
import ConfirmRent from '@/components/EnergyRent/ConfirmRent.vue';
import TypeForm from '@/components/EnergyRent/TypeForm.vue';
import HeaderBackLink from '@/components/Headers/HeaderBackLink.vue';
import LoadingScreen from '@/components/Transactions/LoadingScreen.vue';
import ConfirmTransaction from '@/components/Transactions/ConfirmTransaction';
import { mapActions, mapState } from 'pinia';
import { useEnergyStore } from '@/stores/energyStore';
import { WalletController, ServiceController } from '@/core/system/controllers';
import SelectWalletPopup from '@/components/modals/SelectWalletPopup.vue';
import { useWalletStore } from '@/stores/wallet';
import HeaderMobile from '@/components/Headers/HeaderMobile.vue';
import HistoryList from '@/components/HistoryTransactions/HistoryCards/HistoryList.vue';
import Pagination from '@/components/UI/Pagination.vue';
import StatusTransaction from '@/components/Transactions/StatusTransaction.vue';

export default {
  components: {
    HeaderBackLink,
    TypeForm,
    ConfirmRent,
    ConfirmTransaction,
    LoadingScreen,
    SelectWalletPopup,
    HeaderMobile,
    HistoryList,
    Pagination,
    StatusTransaction,
  },
  data: () => ({
    interval: null,
  }),
  methods: {
    ...mapActions(useEnergyStore, [
      'setStepEnergy',
      'setIsLoadingEnergyPage',
      'setStepEnergyBack',
      'loadHistoryList',
      'setCurrentPage',
      'setSelectedPillAmount',
      'setSelectedPillTime',
      'setQuantityInput',
      'setDurationInput',
    ]),
    clearTransaction() {
      this.setQuantityInput(32000);
      this.setDurationInput(1);
      this.setSelectedPillAmount(this.energyPillsAmount[0]);
      this.setSelectedPillTime(this.energyPillsTime[0]);
      this.setStepEnergy(1);
    },
    pasteDataFromHistory(item) {
      const quantity = this.energyPillsAmount.find(
        (pill) => pill.value === item.number_service_units,
      );
      if (quantity != undefined) {
        this.setSelectedPillAmount(quantity);
      }
      const duration = this.energyPillsTime.find(
        (pill) => pill.valueServer === item.duration,
      );
      if (duration != undefined) {
        this.setSelectedPillTime(duration);
      }
      this.setQuantityInput(item.number_service_units);
      this.setDurationInput(item.duration);
      if (window.innerWidth <= 576) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    confirmWallet() {
      this.$root.$emit('bv::hide::modal', 'select-wallet-modal');
      this.setIsLoadingEnergyPage(false);
    },
    async confirmBuyEnergy() {
      const payload = {
        order_id: this.orderId,
        tx_id: this.txId,
        currency_code: 'TRX',
      };
      try {
        if (this.txId != null) {
          await ServiceController.servicePay(payload);
          this.loadHistoryList();
          this.updateBalanceWallet();
          this.setStepEnergy(4);
          this.interval = setInterval(() => {
            this.checkStatusBuyEnergy();
          }, 30000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkStatusBuyEnergy() {
      const payload = {
        order_id: this.orderId,
        wallet_id: this.currencyTrx.id,
      };
      try {
        const resp = await ServiceController.serviceStatus(payload);
        if (resp.status === 'pay') {
          this.setStepEnergy(5);
          this.loadHistoryList();
          clearInterval(this.interval);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateBalanceWallet() {
      const payload = {
        wallet_network_id: this.currencyTrx.id,
      };
      await WalletController.updateBalance(payload);
    },
  },
  computed: {
    ...mapState(useEnergyStore, [
      'energyPillsTime',
      'energyPillsAmount',
      'stepEnergy',
      'orderId',
      'historyList',
      'currentPageHistory',
      'totalPagesHistory',
      'isLoadingHistoryList',
      'isLoadingEnergyPage',
    ]),
    ...mapState(useWalletStore, ['txId']),
    currencyTrx() {
      return JSON.parse(localStorage.getItem('tron_wallet'));
    },
  },
  mounted() {
    this.$bvModal.show('select-wallet-modal');
    this.loadHistoryList();
  },
  beforeDestroy() {
    this.clearTransaction();
    this.setIsLoadingEnergyPage(true);
  },
};
</script>

<style></style>
