import { defineStore } from "pinia";
import { validateBlockchainAddress } from "@/utils/validateInput";
import { getRoundedAmountCrypto } from "@/utils";
import { useSendTransferStore } from "./sendTransferStore";

export const useSendMultiStore = defineStore("sendMulti", {
  state: () => ({
    stepNumber: 1,
    steps: [
      { id: 1, name: "Подготовка", code: "preparation" },
      { id: 2, name: "Подтверждение", code: "confirm" },
      { id: 3, name: "Отправка", code: "pay" },
    ],
    selectedStep: { id: 1, name: "Подготовка", code: "preparation" },
    completedStepsId: [],
    stagePay: "confirm",
    currencyToSend: null,
    addressesTypedData: "",
    addressList: [],
    rowsListConfirm: [],
    separator: "",
    totalAmount: "",
  }),
  actions: {
    goToNextStep() {
      this.stepNumber++;
      const changedStep = this.steps.find(
        (step) => step.id === this.stepNumber
      );
      this.selectedStep = changedStep;
    },
    goToPrevStep() {
      this.stepNumber--;
      const changedStep = this.steps.find(
        (step) => step.id === this.stepNumber
      );
      this.selectedStep = changedStep;
    },
    setCompletedStepsId(step) {
      this.completedStepsId.push(step);
    },
    setStagePay(stage) {
      this.stagePay = stage;
    },
    setAddressList(list) {
      this.addressList = list;
    },
    setRowsListConfirm(list) {
      this.rowsListConfirm = list;
    },
    setSeparator(separator) {
      this.separator = separator;
    },
    setTotalAmount(amount) {
      this.totalAmount = amount;
    },
    setAddressesData(addresses) {
      this.addressesTypedData = addresses;
    },
    checkValidRow() {
      const sendTransferStore = useSendTransferStore();
      this.addressList.map((row) => {
        const checkAddress = validateBlockchainAddress(
          row.address,
          sendTransferStore.selectedWallet.network_object.full_name
        );
        const regexAmount = /^\d+(\.\d+)?$/;
        const checkAmount = regexAmount.test(Number(row.amount));
        const result = {
          ...row,
          isValid: checkAddress && checkAmount,
        };
        const isDuplicate = this.rowsListConfirm.some(
          (existingRow) =>
            existingRow.address === result.address &&
            existingRow.amount === result.amount &&
            existingRow.isValid === result.isValid
        );

        if (!isDuplicate) {
          this.rowsListConfirm.push(result);
        }
      });
    },
    removeRow(id) {
      let el = this.rowsListConfirm.find((row) => row.id === id);
      let index = this.rowsListConfirm.indexOf(el);
      this.rowsListConfirm.splice(index, 1);
    },
  },
  getters: {
    totalAmountRows: (state) => {
      if (state.rowsListConfirm.length) {
        const amount = state.rowsListConfirm.reduce(
          (acc, row) => acc + Number(row.amount),
          0
        );
        return Number(getRoundedAmountCrypto(amount));
      }
    },
    validRowsCount: (state) => {
      return state.rowsListConfirm.filter((row) => row.isValid);
    },
  },
});
