var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"history-item"},[_c('div',{staticClass:"history-item__header"},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"icon-wrapper"},[_c('img',{staticClass:"icon-currency",attrs:{"src":_vm.icon,"alt":""}})]),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"currency-name mb-0"},[_vm._v(_vm._s(_vm.currency))]),_c('p',{staticClass:"network-name mb-0"},[_vm._v(_vm._s(_vm.networkName))])]),_c('p',{staticClass:"datetime mb-0"},[_vm._v(_vm._s(_vm.normalizedDate))])])])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"auto"}},[_c('div',[_c('p',{staticClass:"amount-transaction mb-0",class:{
              up: _vm.type === 'up',
              out: _vm.type === 'out',
            }},[_vm._v(" "+_vm._s(_vm.typeTransaction)+" "+_vm._s(_vm.amountCrypto)+" "+_vm._s(_vm.currency)+" ")]),_c('p',{staticClass:"amount-transaction-usd mb-0",class:{
              up: _vm.type === 'up',
              out: _vm.type === 'out',
            }},[_vm._v(" "+_vm._s(_vm.typeTransaction)+"$"+_vm._s(_vm.amountUsd)+" ")])])])],1)],1),_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.address),expression:"address",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyData),expression:"copyData",arg:"success"}],staticClass:"history-item__address-info mt-3",on:{"click":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"address mb-0"},[_vm._v(_vm._s(_vm.getShortWalletAddress(_vm.address)))]),_c('Clipboard',{attrs:{"isActiveCopy":_vm.isCopyData,"copyText":_vm.address,"size":18},on:{"onCopySuccess":_vm.copyData}})],1)]):_c('div',{staticClass:"history-item"},[_c('div',{staticClass:"history-item__header"},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-skeleton',{staticClass:"sm",attrs:{"width":"32px","height":"32px"}}),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-skeleton',{staticClass:"sm ms-2",attrs:{"width":"50%","height":"14px"}}),_c('b-skeleton',{staticClass:"sm ms-2",attrs:{"width":"50%","height":"14px"}})],1),_c('b-skeleton',{staticClass:"sm ms-2 mt-1",attrs:{"width":"100px","height":"14px"}})],1)],1)]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"auto"}},[_c('div',[_c('b-skeleton',{staticClass:"sm",attrs:{"width":"40px","height":"14px"}}),_c('b-skeleton',{staticClass:"sm mt-1",attrs:{"width":"40px","height":"14px"}})],1)])],1)],1),_c('div',{staticClass:"history-item__address-info mt-3"},[_c('b-skeleton',{staticClass:"sm",attrs:{"width":"100%","height":"18px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }