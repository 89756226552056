var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history-list"},[_c('div',{staticClass:"history-list__header d-none d-lg-block"},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"column-name"},[_vm._v("Валюта")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"column-name"},[_vm._v("количество")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"column-name"},[_vm._v("Общая Сумма")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"column-name"},[_vm._v("Кошелек")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"column-name"},[_vm._v("ДАТА")])])],1)],1),(!_vm.isLoading && _vm.historyList.length)?_c('div',{staticClass:"list-wrapper"},_vm._l((_vm.historyList),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"d-none d-lg-block"},[_c('a',{attrs:{"href":_vm.getBlockchainLink(
              item.currency_object.network_object.full_name,
              item.tx_id
            ),"target":"_blank"}},[_c('HistoryItem',{attrs:{"isEven":(index + 1) % 2 != 0,"amountCrypto":_vm.convertBalanceCrypto(item.amount),"amountUsd":_vm.convertBalanceUsd(item.amount_usd),"type":item.type,"address":item.address_from,"dateTime":item.created_at,"icon":item.currency_object.icon_base,"currencyName":item.currency_object.code,"networkName":item.currency_object.network_object.full_name,"txId":item.tx_id}})],1)]),_c('div',{staticClass:"d-block d-lg-none"},[_c('a',{attrs:{"href":_vm.getBlockchainLink(
              item.currency_object.network_object.full_name,
              item.tx_id
            ),"target":"_blank"}},[_c('HistoryItemMobile',{attrs:{"amountCrypto":_vm.convertBalanceCrypto(item.amount),"amountUsd":_vm.convertBalanceUsd(item.amount_usd),"type":item.type,"address":item.address_from,"dateTime":item.created_at,"icon":item.currency_object.icon_base,"currencyName":item.currency_object.code,"networkName":item.currency_object.network_object.full_name,"txId":item.tx_id}})],1)])])}),0):(_vm.isLoading)?_c('div',_vm._l((7),function(index){return _c('div',{key:index},[_c('div',{staticClass:"d-none d-lg-block"},[_c('HistoryItem',{attrs:{"isEven":(index + 1) % 2 != 0,"isLoading":""}})],1),_c('div',{staticClass:"d-block d-lg-none"},[_c('HistoryItemMobile',{attrs:{"isLoading":""}})],1)])}),0):(!_vm.isLoading && !_vm.historyList.length)?_c('div',{staticClass:"list-wrapper w-100 d-flex justify-content-center align-items-center"},[_vm._v(" Транзакции криптовалюты отсутствуют ")]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pagination-block"},[_vm._t("pagination")],2):_c('div',{staticClass:"d-flex justify-content-end mt-4 pb-4"},[_c('b-skeleton',{staticClass:"sm",attrs:{"width":"150px","height":"24px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }