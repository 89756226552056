<template>
  <div class="progress-component" v-if="variant === 'default'">
    <div class="label d-flex justify-content-between">
      <div class="label__name-info d-flex align-items-center">
        <p class="name mb-0">{{ name }}</p>
        <img
          v-if="isTooltip"
          class="ms-2"
          src="@/assets/images/tooltip-icon2.svg"
          alt=""
        />
      </div>
      <div class="label__amount-info">
        <span class="placeholder-text">{{ placeholderText }}</span>
        <span class="amount-num ms-1">{{ amountValue }}</span>
        <span class="amount-num">{{ amountMax }}</span>
      </div>
    </div>
    <b-progress
      :value="amountValue"
      :max="amountMax"
      class="mt-2 mt-sm-3"
    ></b-progress>
  </div>
  <div class="progress-component" v-else-if="variant === 'hold_wallet'">
    <div class="label__amount-info d-flex justify-content-between">
      <div>
        <span class="amount-usd">$ {{ amountValue }}</span>
        <p class="prompt mb-0">Текущая сумма</p>
      </div>
      <div>
        <span class="amount-usd">$ {{ amountMax }}</span>
        <p class="prompt text-end mb-0">Цель</p>
      </div>
    </div>
    <b-progress :value="amountValue" :max="amountMax" class="mt-2"></b-progress>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      default: "Name",
    },
    placeholderText: {
      type: String,
      default: "Доступно",
    },
    isTooltip: {
      type: Boolean,
      default: false,
    },
    amountValue: {
      type: Number,
      default: 0,
    },
    amountMax: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt {
  margin-top: 2px;
  color: #9f9f9f;
  font-size: 12px;
  font-weight: 300;
  line-height: 10px;
}

.progress {
  height: 4px;
  border-radius: 40px;
  background: #e9e8f2;
}
.label {
  &__name-info {
    .name {
      font-size: 16px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.16px;
      color: #5f5f5f;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
  &__amount-info {
    .placeholder-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.16px;
      color: #5f5f5f;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .amount-usd {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
    .amount-num {
      font-size: 16px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.16px;
      color: #5f5f5f;
      &::after {
        content: " ";
      }
      &:last-child {
        font-weight: 400;
        &::before {
          content: "/ ";
        }
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
}
</style>
